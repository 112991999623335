import React, { useEffect, useState } from 'react';
import {TextField,Box,FormControl,MenuItem,Select, Typography, Button, Grid,Paper,TablePagination,TableHead,TableCell,TableRow,TableBody,Table,TableContainer, Container } from '@mui/material';
import InputMask from 'react-input-mask';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import useAxiosInstance from '../../api/axios';
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';


const ViewMerchantDetails = () => {
    const { Id } = useParams();
    const navigate = useNavigate();

    const [merchantDetails, setMerchantDetails] = useState({
        merchantId:Id,
      merchantName:'',
      email:'',
      contactNumber:'',
      contactPerson:'',
      offerCode:'',
      commissionRate:'',
      locations:[]

    });

    const axios = useAxiosInstance();




  const [locations, setLocations] = useState([]);


  
  
  
  useEffect(() => {
    const getByMerchantId = async () => {
      try {
        const response = await axios.get(`/api/merchants/${Id}`);

       
        // console.log(response?.data);
        setMerchantDetails(response?.data)
    
  
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };
  
    getByMerchantId();
  }, [Id]);


  useEffect(() => {
    const getByMerchantLocationId = async () => {
      try {
        const response = await axios.get(`/api/merchant-locations?merchantId=${Id}`);

       
        // console.log(response?.data.content);
        setLocations(response?.data.content)
    
  
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };
  
    getByMerchantLocationId();
  }, []);

  const [pageLocation, setLocationPage] = useState(0);
  const [rowsLocationPerPage, setLocationRowsPerPage] = useState(5);

  const handleLocationChangePage = (event, newPage) => {
    setLocationPage(newPage);
  };

  const handleLocationChangeRowsPerPage = (event) => {
    setLocationRowsPerPage(parseInt(event.target.value, 10));
    setLocationPage(0);
  };


    return (
        <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>

            <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
                <h2>View Merchant</h2>
            </div>

            <div style={{
                padding:'2%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'91%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Merchant Details
      </Typography>

      <Grid container spacing={3} style={{ margin: '5vh 10px 10px 0px' }} className="form-container">
      <Grid container spacing={3} item xs={12} md={12} lg={12} >
    

  

           

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Name</Typography>
              <TextField
              placeholder='Enter Name'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.merchantName}
                InputProps={{
                    readOnly: true,
                  }}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Email</Typography>
              <TextField
              type='Enter email'
              placeholder='Merchant Email'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.email}
                InputProps={{
                    readOnly: true,
                  }}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Offer Code</Typography>
              <TextField
              placeholder='Enetr offer code'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.offerCode}
                InputProps={{
                    readOnly: true,
                  }}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Person</Typography>
              <TextField
              placeholder='Enter contact person'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.contactPerson}
                InputProps={{
                    readOnly: true,
                  }}
              />
            </Grid>
           
            

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Number</Typography>
              <TextField
              placeholder='Enter contact no.'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.contactNumber}
                InputProps={{
                    readOnly: true,
                  }}
              />
            </Grid>

         
      </Grid>
      </Grid>
    </Container>


            </div>

            <div style={{
                marginTop:'5%',
                padding:'3%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'89%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Merchant Location
      </Typography>

      <Grid container spacing={3} style={{ margin: '5vh 10px 10px 5px' }} className="form-container">
    
      </Grid>
  
    </Container>



{/* {merchantDetails.locations && merchantDetails.locations.length > 0 ? (
<Grid container spacing={3} item xs={12} md={12} lg={12} sx={{marginTop:'1%',marginLeft:'1%',marginBottom:'1%'}}>
{merchantDetails.locations.map((location, index) => (
    <> */}
        {/* <Grid item xs={3.5} sm={3.5} md={3.5} >
        <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Area</Typography>
            
          <TextField
            placeholder="Enter area"
            fullWidth        
            value={location.area}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
            size="small"
            InputProps={{
                readOnly: true,
              }}
          />
        </Grid>
        <Grid item xs={3.5} sm={3.5} md={3.5}>
        <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Address</Typography>
       
          <TextField
            placeholder="Enter address"
            fullWidth
            value={location.address}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
            size="small"
            InputProps={{
                readOnly: true,
              }}
          />
        </Grid>
        <Grid item xs={3.5} sm={3.5} md={3.5}>
        <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>City</Typography>
       
          <TextField
            placeholder="Enter city"
            fullWidth
            value={location.city}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
            size="small"
            InputProps={{
                readOnly: true,
              }}
          />
        </Grid> */}

        {/* </>
   ))}
      </Grid>
): (
    <div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No location available</div>
)} */}



{locations && locations.length > 0 ? (
  <Grid container item xs={12} md={12} lg={12} sx={{ marginTop: '1%', marginLeft: '1%', marginBottom: '1%' }}>
    <React.Fragment>
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow className="table-heading">
              <TableCell style={{ fontWeight: "bold" }}>Area</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location, index) => (
              <TableRow key={index}>
                <TableCell>{location.area}</TableCell>
                <TableCell>{location.address}</TableCell>
                <TableCell>{location.city}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ float: 'right', marginTop: '10px' }}> {/* Adjust marginTop as needed */}
        <TablePagination
          rowsPerPageOptions={[2]}
          component="div"
          count={merchantDetails.locations.length}
          rowsPerPage={rowsLocationPerPage}
          page={pageLocation}
          onPageChange={handleLocationChangePage}
          onRowsPerPageChange={handleLocationChangeRowsPerPage}
        />
      </div>
    </React.Fragment>
  </Grid>
) : (
  <div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No location available</div>
)}



            </div>
         



            <Link to="/dashboard/merchantDetails">
<Button variant="contained"  
        sx={{
          float: "right",
          marginTop:'1%',
          marginRight:'3%',
          marginBottom:'2%'
        }}>
        Go Back
      </Button>
      </Link>
        </div>
    );
};

export default ViewMerchantDetails;
