import React, { lazy,useEffect,useState } from 'react';
import { Suspense } from "react";
import './App.css';
import Login from './pages/user-managment/login/Login';
import ForgotPswd from './pages/user-managment/register/ForgotPassword';
import ResetPswd from './pages/user-managment/reset-password/ResetPassword';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import SubRoutes from './subRoutes';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Sidebar from './components/dynamic/Sidebar';
import ErrorPage from './pages/404/ErrorPage';
import jwt_decode from "jwt-decode";
import { logoutUserAuth } from "../src/features/auth/authSlice";
import { useDispatch } from 'react-redux';
import {useNavigate } from 'react-router-dom';

// const { Header, Footer, Sider, Content } = Layout;
const App = ()=> {
 
  const authObject = useSelector((state: any) => state?.auth?.userDetails)
 
  const location = useLocation()
  // const dispatch=useDispatch();
  // const navigate=useNavigate();


  // useEffect(()=>{
  //   // const tokenValue = localStorage.getItem("token");

  //   if(authObject){

   
  //   setTimeout(() => {
  //     localStorage.removeItem('token')
  //   localStorage.removeItem('Role')
  //   localStorage.removeItem('Permission')

  //   dispatch((logoutUserAuth()))
  //   // dispatch((getCurrentPage('1')))
  
  //     navigate('/')
  //   window.location.reload()
  //   }, 50000); // 50,000 milliseconds = 50 seconds
  // }

  // },[])
 
  const [isShowSideBar, setIsShowSideBar] = useState(true);
  // const [languageDetect, setLanguageDetect] = useState("");
 
  const showHideSideBar = () => {
    setTimeout(() => {
      setIsShowSideBar(!isShowSideBar);
    }, 300);
  };
 
  const isResetPasswordPath = /\/ResetPswd\/.*/.test(location.pathname);

  
  return (
   
    <>
    <div>
     <Suspense >
        {
           location.pathname === '/'
             ? (
               <Login/>      
             ) :
             location.pathname === '/ForgotPswd' ?
             <ForgotPswd/> :

             isResetPasswordPath ?
             <Routes>
             <Route path="/ResetPswd/:email" element={<ResetPswd />} />
             </Routes>
              :

               authObject ? <Sidebar />: <Login/>
               
           }
     </Suspense>
 
   </div>
 
   </>
  );
}
 
export default App;
 
 