import React, { useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, TextField, IconButton,Modal,Paper,
    FormControl,Select,MenuItem,Typography, Button, Grid, Container,Alert, Snackbar, Checkbox, RadioGroup, FormControlLabel, Radio, Autocomplete  } from '@mui/material';
    import VisibilityIcon from '@mui/icons-material/Visibility';
    import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
    import InputAdornment from '@mui/material/InputAdornment';
    // import { message } from "antd";
    import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import emailTemplate from '../../../components/static/emailTemplate';
import usePublicAxiosInstance from '../../../api/pubicAxios';
import axiosAPI from '../../../api/axiosAPI';


const countries = [
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
];

function ForgotPassword() {
  
  const axios = usePublicAxiosInstance();
  const [modalOpen, setModalOpen] = useState(false);
  const [disableField, setDisableField] = useState(false);



  const [formData, setFormData] = useState({
    // email:'zain@covalent.global'
    email:'',
    country: ''
    });

    const [errors, setErrors] = useState({
      email: '',
      country: '',
    });

  
 
  
    

  const handleUserCloseModal = () => {
    setModalOpen(false);
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevState) => ({ ...prevState, [name]: '' }));
    
  };


  const handleCountryChange = (event, value) => { // value directly represents the selected option
    setFormData((prevData) => ({
      ...prevData,
      country: value ? value.code : '', // Use value directly, which represents the selected option
    }));
    setErrors((prevState) => ({ ...prevState, country: '' }));
  };
  


  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = 'Please enter an email.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email.';
    }
    
    if(!formData.country){
      newErrors.country = 'Please select a country.';
    }
    return newErrors;
  };


  const handleAddClick = async () => {

    // console.log(formData)
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }


    const emailPayload = {
      to: formData.email,
      containImages: true,
      subject: "Forgot Password",
      body: emailTemplate(formData.email,formData.country,axiosAPI()),
    };
  
    console.log("Sending email with payload:", emailPayload);
    setDisableField(true);
    try {
      const emailResponse = await axios.post("/api/email/public", emailPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Email sent successfully:", emailResponse.data);
      setModalOpen(true);
    } catch (error) {
      setDisableField(false)
      console.error("Error sending email:", error);
      if (error.response) {
        setDisableField(false)
        console.error("Error response data:", error.response.data);
      }
    }

  };


  return (
   <>

<Grid container spacing={2} >
<Grid item sm={6} md={6} lg={6} style={{ backgroundImage: `url("assets/Auth/LoginImage.png")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100vh' }}>

{/* <Grid item sm={6} md={6} lg={6} style={{backgroundImage: `url("assets/Auth/LoginImage.png")`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat',height:'667px'}}> */}
{/* <LazyLoadImage 
        src="assets/Images/LoginImage.png"   alt="Login Image"
      /> */}
    </Grid>

    <Grid item sm={6} md={6} lg={6} >
    {/* <div style={{marginLeft:'80%',marginTop:'25px',color:'blue'}}>English</div> */}
   
    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: '15%',marginLeft:'13%' }}>
    Can’t sign in?
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: '2%',marginLeft:'13%' }}>
Let us assist you
      </Typography>
      <Typography gutterBottom sx={{  marginTop: '2%',marginLeft:'13%' }}>
      Provide us following details and reset your credentials instantly
      </Typography>
    
     
    <Grid container spacing={2} sx={{  marginTop: '2%',marginLeft:'-5%', marginTop:'20px' }}>
    <Grid item sm={2} md={2} lg={2} >
</Grid>
    <Grid item sm={8} md={8} lg={8} >

      {/* <form id='login-form' > */}
  <div >
    <Typography sx={{ textAlign: 'left'}}>
      Enter your registered email
    </Typography>
    <TextField 
   value={formData.email}
   onChange={handleChange}
      margin="normal"
      placeholder='Enter email ID'
      required
      fullWidth
      id="username"
      name="email"
      autoComplete="username"
      autoFocus
      error={!!errors.email}
      helperText={errors.email}

    />
  </div>

  <div>
    <Typography variant="body2" sx={{ textAlign: 'left', marginBottom: '0.5rem' }}>
      Country
    </Typography>
  <Autocomplete
      id="country-select-demo"
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={handleCountryChange}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
   
      
      renderInput={(params) => (
        <TextField
        {...params}
        error={!!errors.country}
        helperText={errors.country}
        placeholder='Select Country'
          // label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', 
          }}
        />
      )}
    />

    </div>
 
{/* </form> */}
</Grid>
    </Grid>

    <Grid container spacing={2} style={{marginTop:'1%',marginLeft:'-5%'}}>
    <Grid item sm={2} md={2} lg={2} >
      </Grid>
    <Grid item sm={4} md={4} lg={4} >
    <Link to="/">
    <Button 
    type="submit" 
    fullWidth
    variant="outlined"
    // color="primary"
    sx={{ mt: 2}}
  >
    Back
  </Button>
  </Link>

</Grid>

<Grid item sm={4} md={4} lg={4} >
    <Button 
    onClick={() => handleAddClick()}
    type="submit" 
    fullWidth
    variant="contained"
    // color="primary"
    sx={{ mt: 2 ,backgroundColor:'blue'}}
    disabled={disableField}

  >
    Next
  </Button>

</Grid>

    </Grid>
    </Grid>
   
    </Grid>


    <Modal open={modalOpen} onClose={handleUserCloseModal}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2 style={{textAlign:'center',marginBottom:'4%'}}>Check your email</h2>
            {/* <hr></hr> */}

            <div style={{textAlign:"center" , marginBottom:'4%'}}> 
              Password reset instructions have been sent to your email ID.
            </div>
          
          </Paper>

         <Link to="/"> 
          <Button style={{ float: "inline-end", marginRight: '36%', marginTop: '2%' }} variant="contained" color="primary">
            Back to Login
          </Button>
        </Link>
        </div>
      </Modal>


    <Snackbar
//   open={open}
  autoHideDuration={6000}
//   onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    // onClose={handleClose}
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Logged In SuccessFully!
  </Alert>
</Snackbar>

<Snackbar
//   open={openEmailPswd}
  autoHideDuration={6000}
//   onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    // onClose={handleClose}
    severity="error"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Invalid email or password!
  </Alert>
</Snackbar>


<Snackbar
//   open={openStatus}
  autoHideDuration={6000}
//   onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    // onClose={handleClose}
    severity="warning"
    variant="filled"
    sx={{ width: '100%' }}
  >
    This account is suspended. Try another account!
  </Alert>
</Snackbar>

   </>
  );
}

export default ForgotPassword;