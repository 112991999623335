import React, { useEffect, useState } from 'react';
import {TextField,Box,FormControl,MenuItem,Select, Typography, Button, Grid,Paper,TablePagination,TableHead,TableCell,TableRow,TableBody,Table,TableContainer, Container } from '@mui/material';
import InputMask from 'react-input-mask';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import useAxiosInstance from '../../api/axios';
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddLocationModal from './AddLocationModal';
import EditLocationList from './EditLocationList';
import { v4 as uuidv4 } from 'uuid'; 

const EditMerchantDetails = () => {
    const { Id } = useParams();
    const navigate = useNavigate();

    const [merchantDetails, setMerchantDetails] = useState({
        merchantId:Id,
      merchantName:'',
      email:'',
      contactNumber:'',
      contactPerson:'',
      offerCode:'',
      commissionRate:'',
      locations:[]

    });

    const axios = useAxiosInstance();


    const generateUniqueId = () => {
      return uuidv4(); // Returns a unique ID
    };

    const isUUID = (str) => {
      const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return uuidPattern.test(str);
    };
  
  
  
  useEffect(() => {
    const getByMerchantId = async () => {
      try {
        const response = await axios.get(`/api/merchants/${Id}`);

        // console.log(response?.data);
        setMerchantDetails(prevState => ({
          ...prevState,
          merchantName: response.data.merchantName,
          email: response.data.email,
          contactNumber: response.data.contactNumber,
          contactPerson: response.data.contactPerson,
          offerCode: response.data.offerCode,
          commissionRate: response.data.commissionRate
        }));
    

        const response1 = await axios.get(`/api/merchant-locations?merchantId=${Id}`);

       
        setMerchantDetails(prevState => ({
          ...prevState,
          locations: response1.data.content
        }));
    

           
    
  
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };
  
    getByMerchantId();
  }, []);


  const handleChange = (field, value) => {
    setMerchantDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };


  const  submitMerchantDetails =async () =>{
 
 
    const locationsWithId = merchantDetails.locations.filter(location => isUUID(location.locationId));
    const locationsWithoutId = locationsWithId.map(({ locationId, ...rest }) => rest);
    console.log(locationsWithoutId);
    


    const res = {
        merchantId:Id,
        merchantName:merchantDetails.merchantName,
        email:merchantDetails.email,
        contactNumber:merchantDetails.contactNumber,
        contactPerson:merchantDetails.contactPerson,
        offerCode:merchantDetails.offerCode
    }

    try {
       
      await axios.put(
        "/api/merchants",
        res,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );


      if(locationsWithoutId.length !== 0){

        await axios.post(
          `/api/merchant-locations/${Id}`,
          locationsWithoutId,
        {
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );

      }
    
      console.log("Merchant Details Update successfully !");
    
      setTimeout(() => {
        navigate('/dashboard/merchantDetails');
      }, 1000);
    
    
    } catch (err) {
      if (err.response && err.response.data) {
      
        console.log("Internal Server Error");
      } else {
        console.log("Error occurred. Please try again.")
      }
    }
    
      }




      const [errors, setErrors] = useState({
        area: '',
        address: '',
        city: '',
      });


      const [locationModalOpen, setLocationModalOpen] = useState(false);

      const handleOpenLocationModal = () => {
        setLocationModalOpen(true);
      };
    
      const handleCloseLocationModal = () => {
        setLocationModalOpen(false);

        setErrors({
          area: '',
          address: '',
          city: '',
        })
      };


   



      const handleAddLocation = (newLocation) => {
        // Check if the new location has a locationId, otherwise generate one
        if (!newLocation.locationId) {
          newLocation.locationId = generateUniqueId(); // You need to define generateUniqueId function
        }
      
        setMerchantDetails((prevDetails) => {
          const updatedLocations = Array.isArray(prevDetails.locations)
            ? [...prevDetails.locations, newLocation]
            : [newLocation];
      
          return {
            ...prevDetails,
            locations: updatedLocations,
          };
        });
      };
      
   


      const handleEditLocation = async (editedLocation) => {

        
        if (!isUUID(editedLocation.locationId)) {
          
          console.log(editedLocation);
          const res = {
            locationId:editedLocation.locationId,
            area : editedLocation.area,
            city : editedLocation.city,
            address : editedLocation.address,
          }
          
          await axios.put(
                `/api/merchant-locations/${editedLocation.locationId}`,
                res,
                {
                  headers: {
                    "Content-Type": "application/json"
                  },
                }
              );
          

        }
        setMerchantDetails((prevDetails) => {
          const updatedLocations = prevDetails.locations.map((location) => {
            if (location.locationId === editedLocation.locationId) {
              return { ...location, ...editedLocation };
            }
            return location;
          });
      
          return {
            ...prevDetails,
            locations: updatedLocations,
          };
        });
      };
      

   
      
      const handleRemoveLocation =async (locationToRemove) => {

        if (!isUUID(locationToRemove.locationId)) {

              await axios.delete(
                `/api/merchant-locations/${locationToRemove.locationId}`
              );
          console.log(locationToRemove);

        }
        // Filter out the location with the specified locationId
        const updatedLocations = merchantDetails.locations.filter(
          (location) => location.locationId !== locationToRemove.locationId
        );
        setMerchantDetails((prevDetails) => ({
          ...prevDetails,
          locations: updatedLocations,
        }));
      };
      



      // const [selectedIds, setSelectedIds] = useState([]);


      // const handleCheckBox = (id) => {
      //   const isSelected = selectedIds.includes(id);
      
      //   let newSelectedIds = [];
      //   if (isSelected) {
      //     // Remove the ID if it was previously selected
      //     newSelectedIds = selectedIds.filter((selectedId) => selectedId !== id);
      //   } else {
      //     // Add the ID if it was not previously selected and is not null
      //     if (id !== null) {
      //       newSelectedIds = [...selectedIds, id];
      //     } else {
      //       newSelectedIds = [...selectedIds]; // No change
      //     }
      //   }
      
      //   setSelectedIds(newSelectedIds);
      // };


    return (
        <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>

            <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
                <h2>Edit Merchant</h2>
            </div>

            <div style={{
                padding:'2%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'91%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Merchant Details
      </Typography>

      <Grid container spacing={3} style={{ margin: '5vh 10px 10px 0px' }} className="form-container">
      <Grid container spacing={3} item xs={12} md={12} lg={12} >
    

  

           

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Name</Typography>
              <TextField
              placeholder='Enter Name'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.merchantName}
                onChange={(e) => handleChange('merchantName', e.target.value)}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Email</Typography>
              <TextField
              type='Enter email'
              placeholder='Merchant Email'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.email}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Offer Code</Typography>
              <TextField
              placeholder='Enetr offer code'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.offerCode}
                onChange={(e) => handleChange('offerCode', e.target.value)}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Person</Typography>
              <TextField
              placeholder='Enter contact person'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.contactPerson}
                onChange={(e) => handleChange('contactPerson', e.target.value)}
              />
            </Grid>
           
            

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Number</Typography>
              <TextField
              placeholder='Enter contact no.'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.contactNumber}
                onChange={(e) => handleChange('contactNumber', e.target.value)}
              />
            </Grid>

         
      </Grid>
      </Grid>
    </Container>


            </div>

            <div style={{
                marginTop:'5%',
                padding:'3%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'91%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Merchant Location
      </Typography>

   
      <Button variant="contained" color="primary" onClick={handleOpenLocationModal} 
      style={{float:'right',marginBottom:'2%'}}
     >
    Add Location
      </Button>

  
    </Container>

    <EditLocationList locations={merchantDetails.locations || []} onEditLocation={handleEditLocation} onRemoveLocation={handleRemoveLocation}/>


    <AddLocationModal open={locationModalOpen} onClose={handleCloseLocationModal} onAddLocation={handleAddLocation} setErrors={setErrors} errors={errors}/>
    


            </div>
         

            <Button variant="contained" color="primary" onClick={submitMerchantDetails}
        sx={{
          float: "right",
          marginTop:'1%',
          marginRight:'3%',
          marginLeft:'1%',
          marginBottom:'2%'
        }}>
        Update
      </Button>


            <Link to="/dashboard/merchantDetails">
<Button variant="outlined"  
        sx={{
          float: "right",
          marginTop:'1%',
          marginBottom:'2%'
        }}>
        Go Back
      </Button>
      </Link>
        </div>
    );
};

export default EditMerchantDetails;
