// GasStationTable.js
import React, { useState } from 'react';
import { Typography, Modal, Table, TableBody,Grid, TableCell,TablePagination, TableContainer,Backdrop, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';


const LocationList = ({ locations, onEditLocation,onRemoveLocation }) => {

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);



  const handleEditClick = (location) => {
    setSelectedLocation(location);
    setEditDialogOpen(true);
  };

  

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedLocation(null);
  };

  const handleEditFieldChange = (field, value) => {
    setSelectedLocation((prevGasStation) => ({
      ...prevGasStation,
      [field]: value,
    }));
  };


  

  const handleEditDialogSave = () => {
    // Check if onEditLocation is a function before calling it
    if (typeof onEditLocation === 'function') {
      onEditLocation(selectedLocation);
    }
  
    // Close the dialog
    handleEditDialogClose();
  };


  const handleRemoveLocation = (location) => {
    if (typeof onRemoveLocation === 'function') {
      onRemoveLocation(location);
    }
  };
  


  if (!locations || locations.length === 0) {
    // Render some message or an empty state when locations is undefined or empty
    return (
      <div style={{marginTop:'3%',marginLeft:'2%'}}>
        No Location available.
      </div>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <>      
    <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
          <Table>
          <TableHead >
            <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Area</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
              <TableCell style={{fontWeight:"bold"}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {(rowsPerPage > 0
              ? locations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : locations
            ).map((location, index) => (

              <TableRow key={index}>
     

                <TableCell>{index+1}</TableCell>
                <TableCell>{location.area}</TableCell>
                <TableCell>{location.address}</TableCell>
                <TableCell>{location.city}</TableCell>
           
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEditClick(location)}>
                    Edit
                  </Button>
                  <Button variant="contained" color="error" onClick={() => handleRemoveLocation(location)} style={{marginLeft:'2%'}}>
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]} // Customize rows per page options as needed
        component="div"
        count={locations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />


      {/* Edit Gas Station Dialog */}
      

      <Modal open={editDialogOpen} onClose={handleEditDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Paper style={{ padding: '20px', background: '#fff', width: '100vh', height:"auto", padding:"20px 50px", zIndex:1 }}>
          <h2 style={{fontSize:'bold'}}>Edit Location</h2>
          <Grid container spacing={2} sx={{paddingLeft:"20px", marginTop:"30px"}}>
         
         


            <Grid container spacing={2} style={{marginTop:'10px'}}>
          <Grid item xs={6} md={6} lg={6}>

<TextField
value={selectedLocation?.area || ''}
onChange={(e) => handleEditFieldChange('area', e.target.value)}
type='text'
 label="Area"
sx={{
'& .MuiInputBase-root': {
  height: '30px',
},
'& .MuiFormLabel-root': {
  fontSize: '11px',
},
}}
fullWidth
required
size="small"
/>

        
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
          <TextField
    label="City"
   fullWidth
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      height: '30px' /* Add other custom styles as needed */,
                    }, '& .MuiFormLabel-root':{
                        fontSize:"11px"
                    }
                  }}
    value={selectedLocation?.city || ''}
    onChange={(e) => handleEditFieldChange('city', e.target.value)}
  />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
          <TextField
    label="Address"
   fullWidth
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      height: '30px' /* Add other custom styles as needed */,
                    }, '& .MuiFormLabel-root':{
                        fontSize:"11px"
                    }
                  }}
    value={selectedLocation?.address || ''}
    onChange={(e) => handleEditFieldChange('address', e.target.value)}
  />
          </Grid>

        

            </Grid>
       

       

          </Grid>
          <Button onClick={handleEditDialogClose} color="secondary" style={{ marginTop: '20px', float:"right" }}>
            Cancel
          </Button>
          <Button onClick={handleEditDialogSave} color="primary" style={{ marginTop: '20px', float:"right" }}>
            Save
          </Button>
        </Paper>
      </div>
    </Modal>
      </>

  );
};

export default LocationList;
