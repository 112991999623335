import React, { useEffect, useState } from 'react';
import {TextField,Box,FormControl,MenuItem,Select, Typography, Button, Grid, Container } from '@mui/material';
import InputMask from 'react-input-mask';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import useAxiosInstance from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddLocationModal from './AddLocationModal';
import LocationList from './LocationList';
import { v4 as uuidv4 } from 'uuid'; 



const AddMerchantDetails = () => {
  const navigate = useNavigate();
  const [merchantDetails, setMerchantDetails] = useState({
    merchantName: '',
    email: '',
    contactNumber: '',
    contactPerson: '',
    offerCode: '',
    locations: []
  });
  const [errorsMerchant, setErrorsMerchant] = useState({
    merchantName: '',
    email: '',
    contactNumber: '',
    contactPerson: '',
    offerCode: ''
  });

  const axios = useAxiosInstance();

    const generateUniqueId = () => {
      return uuidv4(); // Returns a unique ID
    };

    const isUUID = (str) => {
      const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return uuidPattern.test(str);
    };


    // const [errorsMerchant, setErrorsMerchant] = useState({
    //   merchantName: '',
    //   email: '',
    //   contactNumber: '',
    //   contactPerson:'',
    //   offerCode:''
    // });
    const handleChange = (field, value) => {
      let filteredValue = value;
      
      // If the field is 'contactNumber', filter out non-numeric characters
      if (field === 'contactNumber') {
        filteredValue = value.replace(/[^0-9]/g, ''); // Replace any non-digit character with an empty string
      }
    
      // If the field is 'contactPerson', filter out non-numeric characters
   
    
      // Set the state with filtered or unfiltered value based on the field
      setMerchantDetails(prev => ({
        ...prev,
        [field]: filteredValue
      }));
    
      let errorMessage = '';
    
      // Validation for empty fields
      if (!filteredValue.trim()) {
        errorMessage = 'This field cannot be empty.';
      }
    
      // Specific validation for the email field
      if (field === 'email' && filteredValue) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(filteredValue.trim())) {
          errorMessage = 'Please enter a valid email address.';
        }
      }
    
      // Specific validation for the contact number field
      if (field === 'contactNumber' && filteredValue) {
        const phoneRegex = /^\d{10}$/; // Adjust regex according to your country's standards
        if (!phoneRegex.test(filteredValue.trim())) {
          errorMessage = 'Contact number must be 10 digits.';
        }
      }
    
    
    
      // Update errors state
      setErrorsMerchant(prev => ({
        ...prev,
        [field]: errorMessage
      }));
    };
    
    
    
  // const handleChange = (field, value) => {
  //   setMerchantDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [field]: value,
  //   }));

  //   setErrorsMerchant((prevState) => ({ ...prevState, [field]: '' }));
  // };




  const  submitMerchantDetails =async () =>{
  // Form submission logic, checking for any errors before proceeding
  const hasErrors = Object.values(errorsMerchant).some(error => error !== '');
  if (hasErrors) {
    alert('Please correct the errors before submitting.');
    return;
  }

const newErrors = {};
    if (!merchantDetails.merchantName) {
      newErrors.merchantName = 'Please enter a merchant name.';
    }
    if (!merchantDetails.email) {
      newErrors.email = 'Please enter an email.';
    }
    if (!merchantDetails.contactNumber) {
      newErrors.contactNumber = 'Please select a contact no.';
    }
    if (!merchantDetails.contactPerson) {
      newErrors.contactPerson = 'Please select a contact person.';
    }
    if (!merchantDetails.offerCode) {
      newErrors.offerCode = 'Please select a offer Code';
    }

    if (Object.keys(newErrors).length === 0) {

      const locationsWithId = merchantDetails.locations.filter(location => isUUID(location.locationId));
      const locationsWithoutId = locationsWithId.map(({ locationId, ...rest }) => rest);
      // console.log(locationsWithoutId);
      
  
  
      const res = {
          merchantName:merchantDetails.merchantName,
          email:merchantDetails.email,
          contactNumber:merchantDetails.contactNumber,
          contactPerson:merchantDetails.contactPerson,
          offerCode:merchantDetails.offerCode,
          // locations:locationsWithoutId
      }
  
      try {
         
    const response =  await axios.post(
          "/api/merchants",
          res,
          {
            headers: {
              "Content-Type": "application/json"
            },
          }
        );
  
// console.log(response?.data)

const { merchantId} = response.data;
        if (locationsWithoutId.length !== 0) {

          await axios.post(
              `/api/merchant-locations/${merchantId}`,
              locationsWithoutId,
            {
              headers: {
                'Content-Type': 'application/json'
              },
            }
          );
      
          console.log("Merchant Details Create successfully !");
        }
  
      
        setTimeout(() => {
          navigate('/dashboard/merchantDetails');
        }, 1000);
      
      
      } catch (err) {
        if (err.response && err.response.data) {
        
          console.log("Internal Server Error");
        } else {
          console.log("Error occurred. Please try again.")
        }
      }

} else {
  // Display validation errors
  setErrorsMerchant(newErrors);
}

  }



  const [errors, setErrors] = useState({
    area: '',
    address: '',
    city: '',
  });


  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const handleOpenLocationModal = () => {
    setLocationModalOpen(true);
  };

  const handleCloseLocationModal = () => {
    setLocationModalOpen(false);

    setErrors({
      area: '',
      address: '',
      city: '',
    })
  };






  const handleAddLocation = (newLocation) => {
    // Check if the new location has a locationId, otherwise generate one
    if (!newLocation.locationId) {
      newLocation.locationId = generateUniqueId(); // You need to define generateUniqueId function
    }
  
    setMerchantDetails((prevDetails) => {
      const updatedLocations = Array.isArray(prevDetails.locations)
        ? [...prevDetails.locations, newLocation]
        : [newLocation];
  
      return {
        ...prevDetails,
        locations: updatedLocations,
      };
    });
  };
  



  const handleEditLocation = async (editedLocation) => {

    setMerchantDetails((prevDetails) => {
      const updatedLocations = prevDetails.locations.map((location) => {
        if (location.locationId === editedLocation.locationId) {
          return { ...location, ...editedLocation };
        }
        return location;
      });
  
      return {
        ...prevDetails,
        locations: updatedLocations,
      };
    });
  };
  


  
  const handleRemoveLocation =async (locationToRemove) => {

    // Filter out the location with the specified locationId
    const updatedLocations = merchantDetails.locations.filter(
      (location) => location.locationId !== locationToRemove.locationId
    );
    setMerchantDetails((prevDetails) => ({
      ...prevDetails,
      locations: updatedLocations,
    }));
  };
  


    return (
        <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>

            <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
                <h2>Add New Merchant</h2>
            </div>

            <div style={{
                padding:'2%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'91%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Merchant Details
      </Typography>

      <Grid container spacing={3} style={{ margin: '5vh 10px 10px 0px' }} className="form-container">
      <Grid container spacing={3} item xs={12} md={12} lg={12} >
    

  

           

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Name</Typography>
              <TextField
              placeholder='Enter Name'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={setMerchantDetails.merchantName}
                onChange={(e) => handleChange('merchantName', e.target.value)}
                error={!!errorsMerchant.merchantName}
                helperText={errorsMerchant.merchantName}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Email</Typography>
              <TextField
              type='Enter email'
              placeholder='Merchant Email'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={setMerchantDetails.email}
                onChange={(e) => handleChange('email', e.target.value)}
                error={!!errorsMerchant.email}
                helperText={errorsMerchant.email}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Offer Code</Typography>
              <TextField
              placeholder='Enetr offer code'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={setMerchantDetails.offerCode}
                onChange={(e) => handleChange('offerCode', e.target.value)}
                error={!!errorsMerchant.offerCode}
                helperText={errorsMerchant.offerCode}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Person</Typography>
              <TextField
              placeholder='Enter contact person'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.contactPerson} 
                error={!!errorsMerchant.contactPerson}
                onChange={e => handleChange('contactPerson', e.target.value)}
                helperText={errorsMerchant.contactPerson }
                type="tel" // This helps to get numeric keyboard on mobile devices
              />
            </Grid>
           
            

            <Grid item xs={4} sm={4} md={4}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Number</Typography>
              <TextField
              placeholder='Enter contact no.'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={merchantDetails.contactNumber}
                onChange={e => handleChange('contactNumber', e.target.value)}
                error={!!errorsMerchant.contactNumber}
                helperText={errorsMerchant.contactNumber}
             
              />
            </Grid>

      </Grid>
      </Grid>
    </Container>


            </div>



            <div style={{
                marginTop:'5%',
                padding:'3%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'91%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Merchant Location
      </Typography>

   
      <Button variant="contained" color="primary" onClick={handleOpenLocationModal} 
      style={{float:'right',marginBottom:'2%'}}
     >
    Add Location
      </Button>

  
    </Container>

    <LocationList locations={merchantDetails.locations || []} onEditLocation={handleEditLocation} onRemoveLocation={handleRemoveLocation}/>


    <AddLocationModal open={locationModalOpen} onClose={handleCloseLocationModal} onAddLocation={handleAddLocation} setErrors={setErrors} errors={errors}/>
    


            </div>
         

            <Button variant="contained" color="primary" onClick={submitMerchantDetails}
        sx={{
          float: "right",
          marginTop:'1%',
          marginRight:'3%',
          marginLeft:'1%',
          marginBottom:'2%'
        }}>
        Confirm
      </Button>

      <Link to="/dashboard/merchantDetails">

<Button variant="outlined"  
        sx={{
          float: "right",
          marginTop:'1%',
          marginBottom:'2%'
        }}>
        Cancel
      </Button>
      </Link>
        </div>
    );
};

export default AddMerchantDetails;
