// // Sidebar.js
// import React, { useEffect, useState } from 'react';
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Collapse,
  
//   Divider,
//   Typography,
//   IconButton,
// } from '@mui/material';
// import { Link, useLocation,useNavigate } from 'react-router-dom';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { useDispatch } from 'react-redux';
// import { logoutUserAuth } from "../../features/auth/authSlice";
// import Paper from '@mui/material/Paper';
// import SubRoutes from './../../subRoutes';
// // import { Layout } from "antd";
// import ListItemAvatar from '@mui/material/ListItemAvatar';
// import Avatar from '@mui/material/Avatar';
// import axios from '../../api/axios';
// // import { message } from "antd";
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import StorefrontIcon from '@mui/icons-material/Storefront';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import SettingsIcon from '@mui/icons-material/Settings';
// import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
// import LogoutIcon from '@mui/icons-material/Logout';
// import WebAssetIcon from '@mui/icons-material/WebAsset';
// import SummarizeIcon from '@mui/icons-material/Summarize';
// import HomeSharpIcon from '@mui/icons-material/HomeSharp';
// import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
// import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';

// const Sidebar = () => {
//   // const { Header, Footer, Sider, Content } = Layout;
//   const dispatch=useDispatch();
//   const navigate=useNavigate();
//   const location = useLocation();
//   const [openUser, setOpenUser] = useState(false);
//   const [userLogout, setUserLogout]=useState(false)
//   const [openCustomer, setOpenCustomer] = useState(false);
//   const [openVehicle, setOpenVehicle] = useState(false);
//   const [openSupplier, setOpenSupplier] = useState(false);
//   const [openTrade, setOpenTrade] = useState(false);
// const [userDetail,setUserDetail] =useState({});
// // const [messageApi, contextHolder] = message.useMessage();


//   const handleLogout = () => {

//     console.log("LogOut....");
//     localStorage.removeItem('UserRoleName')
//     localStorage.removeItem('UserRoleID')
//     localStorage.removeItem('userId')

//     dispatch((logoutUserAuth()))
//     // dispatch((getCurrentPage('1')))
  
//       navigate('/')
//       setUserLogout(true)
//     window.location.reload()
//   }

// //   useEffect(()=>{

// //     const userDetails=async()=>{
// //       try {
// //       const tokenValue = localStorage.getItem("token");
// //       const userId = localStorage.getItem("userId");

// //       const response =await axios.get(`/api/user/get/${userId}`, {
// //         headers: {
// //           Authorization: `Bearer ${tokenValue}`,
// //         },
// //       });
  
// //       const { code, description, user } = response.data;

// // const imageData = user.image.split('base64,')[1];

// // setUserDetail({
// //   ...user,
// //   image: imageData,
// // });
// //       // console.log(user)

// //   }catch(err){

// //     // messageApi.open({
// //     //   type: "error",
// //     //   content: "Internal Server Error !",
// //     // });
// //   }
// // }
// //     userDetails()  
// //   },[])

// const permission = localStorage.getItem("UserRoleName");
// // MERCHANT
// // MASTER

//   return (
//     <>
//          <SubRoutes />   
        
//     <Drawer id="dashboard-sidebar" variant="permanent" anchor="left" 
//     sx={
//       {
//         position:"fixed",

//         bottom:"0"
//       }
//     }
//        >


// <Paper style={{
//       backgroundColor: "#f7f8f9" ,
//       border: "none",
//       color: "black",
//       width: "18vw",
     
//      // height: "100vh",
//     }} >

  

//    <List >

//    <ListItem
//           button
//           component={Link}
//           to="/dashboard"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//               <ListItemAvatar>
//     <HomeSharpIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Dashboard" />
//         </ListItem>

//    {/* {
//           (permission.includes('MASTER'))
//          && (
//           <> */}
        

//         <ListItem
//   button
//   component={Link}
//   to="/dashboard/user"
//   sx={{
//     backgroundColor:
//       location.pathname === '/dashboard/user' ? '#f5f5f5' : 'initial',
//     '&:hover': {
//       backgroundColor:
//         location.pathname === '/dashboard/user' ? '#f5f5f5' : 'initial',
//     },
//   }}
// >
//   <ListItemAvatar>
//     <ManageAccountsIcon />
//   </ListItemAvatar>
//   <ListItemText primary="User Management" />
// </ListItem>


// <ListItem
//           button
//           component={Link}
//           to="/dashboard/merchant"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/merchant'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/merchant'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <StorefrontIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Merchant Management" />
//         </ListItem>


//         <ListItem
//           button
//           component={Link}
//           to="/dashboard/applicationTracker"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/applicationTracker'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/applicationTracker'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <WebAssetIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Application Tracker" />
//         </ListItem>

//         <ListItem
//           button
//           component={Link}
//           to="/dashboard/report"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/report'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/report'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <SummarizeIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Report" />
//         </ListItem>


//         <ListItem
//           button
//           component={Link}
//           to="/dashboard/eligibilityQuestion"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/eligibilityQuestion'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/eligibilityQuestion'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <QuestionAnswerIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Eligibility Question" />
//         </ListItem>

//         <ListItem
//           button
//           component={Link}
//           to="/dashboard/productFeatures"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/productFeatures'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/productFeatures'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <FeaturedPlayListIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Product Features" />
//         </ListItem>

//         <ListItem
//           button
//           component={Link}
//           to="/dashboard/productDetails"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/productDetails'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/productDetails'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <ProductionQuantityLimitsIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Product Details" />
//         </ListItem>
//         <ListItem
//           button
//           component={Link}
//           to="/dashboard/merchantDetails"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/merchantDetails'  ? '#f5f5f5' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/merchantDetails'  ? '#f5f5f5' : 'initial',
//             },
//           }}
//         >
//           <ListItemAvatar>
//     <StorefrontIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Merchant Details" />
//         </ListItem>
      
     

// {/* {
//           (permission.includes('MerchantID'))
//          && ( */}
       
         

//         {/* <ListItem
//           button
//           component={Link}
//           to="/dashboard/invoice"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/invoice' ? '#555' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/invoice' ? '#555' : 'initial',
//             },
//           }}
//         >
//               <ListItemAvatar>
//     <ContactSupportIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Help" />
//         </ListItem> */}


//         {/* <ListItem
//           button
//           component={Link}
//           to="/dashboard/invoice"
//           sx={{
//             backgroundColor:
//               location.pathname === '/dashboard/invoice' ? '#555' : 'initial',
//             '&:hover': {
//               backgroundColor:
//                 location.pathname === '/dashboard/invoice' ? '#555' : 'initial',
//             },
//           }}
//         >
//               <ListItemAvatar>
//     <SettingsIcon />
//   </ListItemAvatar>
//           <ListItemText primary="Setting" />
//         </ListItem> */}


//         <ListItem
//         button
//         onClick={handleLogout} 
//         className="avatar_logout"
//         type="link"
//       >
//             <ListItemAvatar>
//     <LogoutIcon />
//   </ListItemAvatar>
//         <ListItemText primary="Logout" />
               
//       </ListItem>
//       </List>

//       {/* </div> */}
//       </Paper>
//     </Drawer>
    
   

         
//     </>
//   );
// };

// export default Sidebar;
// ===
import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemAvatar, Avatar, Paper } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUserAuth } from "../../features/auth/authSlice";
import SubRoutes from './../../subRoutes';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StorefrontIcon from '@mui/icons-material/Storefront';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import SummarizeIcon from '@mui/icons-material/Summarize';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userLogout, setUserLogout] = useState(false);

  const permission = localStorage.getItem("UserRoleName");
 

  const handleLogout = () => {
    console.log("LogOut....");
    localStorage.clear();
    dispatch(logoutUserAuth());
    navigate('/');
    setUserLogout(true);
    window.location.reload();
  };

  return (
    <>
      <SubRoutes />
      <Drawer id="dashboard-sidebar" variant="permanent" anchor="left" sx={{ position: "fixed", bottom: "0" }}>
        <Paper style={{ backgroundColor: "#f7f8f9", width: "18vw" }}>
          <List>
            <ListItem button component={Link} to="/dashboard" sx={{ backgroundColor: location.pathname === '/dashboard' ? '#f5f5f5' : 'initial' }}>
              <ListItemAvatar><HomeSharpIcon /></ListItemAvatar>
              <ListItemText primary="Dashboard" />
            </ListItem>

                <ListItem button component={Link} to="/dashboard/merchant" sx={{ backgroundColor: location.pathname === '/dashboard/merchant' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><StorefrontIcon /></ListItemAvatar>
                  <ListItemText primary="Merchant Performance" />
                </ListItem>

                

                <ListItem button component={Link} to="/dashboard/report" sx={{ backgroundColor: location.pathname === '/dashboard/report' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><SummarizeIcon /></ListItemAvatar>
                  <ListItemText primary="Report" />
                </ListItem>
            {permission !== 'MERCHANT' && (
              <>
                <ListItem button component={Link} to="/dashboard/user" sx={{ backgroundColor: location.pathname === '/dashboard/user' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><ManageAccountsIcon /></ListItemAvatar>
                  <ListItemText primary="User Management" />
                </ListItem>
                <ListItem button component={Link} to="/dashboard/applicationTracker" sx={{ backgroundColor: location.pathname === '/dashboard/applicationTracker' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><WebAssetIcon /></ListItemAvatar>
                  <ListItemText primary="Application Tracker" />
                </ListItem>

                <ListItem button component={Link} to="/dashboard/eligibilityQuestion" sx={{ backgroundColor: location.pathname === '/dashboard/eligibilityQuestion' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><QuestionAnswerIcon /></ListItemAvatar>
                  <ListItemText primary="Eligibility Question" />
                </ListItem>

                <ListItem button component={Link} to="/dashboard/productFeatures" sx={{ backgroundColor: location.pathname === '/dashboard/productFeatures' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><FeaturedPlayListIcon /></ListItemAvatar>
                  <ListItemText primary="Product Features" />
                </ListItem>

                <ListItem button component={Link} to="/dashboard/productDetails" sx={{ backgroundColor: location.pathname === '/dashboard/productDetails' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><ProductionQuantityLimitsIcon /></ListItemAvatar>
                  <ListItemText primary="Product Details" />
                </ListItem>

                <ListItem button component={Link} to="/dashboard/merchantDetails" sx={{ backgroundColor: location.pathname === '/dashboard/merchantDetails' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><StorefrontIcon /></ListItemAvatar>
                  <ListItemText primary ="Merchant Details" />
                </ListItem>

                <ListItem button component={Link} to="/dashboard/promotionalBanner" sx={{ backgroundColor: location.pathname === '/dashboard/merchantDetails' ? '#f5f5f5' : 'initial' }}>
                  <ListItemAvatar><ViewCarouselIcon /></ListItemAvatar>
                  <ListItemText primary ="Promotional Banner" />
                </ListItem>
              </>
            )}

            <ListItem button onClick={handleLogout}>
              <ListItemAvatar><LogoutIcon /></ListItemAvatar>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Paper>
      </Drawer>
    </>
  );
};

export default Sidebar;
