import React,{useEffect,useState} from 'react';
import { Table,
    Box,
    Tabs,
    Tab,
    TextField,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    FormControl,
    Select,
    TableRow,
    Paper,
    Modal,
    MenuItem,
    InputAdornment,
    Backdrop,
    Typography,
    Grid,
    Button,
    TablePagination } from '@mui/material';

import useAxiosInstance from '../../api/axios';
import { Link } from 'react-router-dom';
import { useNavigate,useParams } from 'react-router-dom';
import { width } from '@mui/system';
import axiosAPI from '../../api/axiosAPI'

const ProductList = [
  {
    value: 'Card',
    label: 'Card',
  },
  {
    value: 'Loan',
    label: 'Loan',
  }
];


const EditProductFeatures = () => {
    const { Id } = useParams();
  const navigate = useNavigate();
  const base=`${axiosAPI()}api/images/public/`;



    const [productFeature,setProductFeature] = useState(true);
    const [subProductFeature,setSubProductFeature] = useState(true);
    const [featureByDefault,setFeatureByDefault] = useState([]);
    
    const [subProductFeatures,setSubProductFeatures] = useState({
        featureId:'',
        featureName:'',
        featureWeight:'',
        productType:'',
        status:'',
        imageUrl :null,
        subFeatures:[]
})

    const [productFeatureCreate,setProductFeatureCreate] = useState({
      featureId:'',
      featureName:'',
      featureWeight:'',
      productType:'',
      status:'',
      imageUrl :null,
      subFeatures:[]
    });

    const axios = useAxiosInstance();

    useEffect(() => {
        const getByProductFeatureId = async () => {
          try {
            const response = await axios.get(`/api/productFeatures/productFeature/${Id}`);
    
            setSubProductFeatures(response?.data);
            console.log(response?.data);
            
      
          } catch (error) {
            console.error("Axios Error:", error);
          }
        };
      
        getByProductFeatureId();
      }, [Id]);

   

      const handleAddChange = (field, value) => {
        setSubProductFeatures((prevDetails) => ({
          ...prevDetails,
          [field]: value,
        }));
     
      };

      const handleEditAreaChange = (event, index) => {
        const { value } = event.target;
        setSubProductFeatures((prevDetails) => {
          const updatedLocations = [...prevDetails.subFeatures];
          updatedLocations[index].featureName = value;
          return { ...prevDetails, subFeatures: updatedLocations };
        });
      };
      
      const handleEditLocationChange = (event, index) => {
        const { value } = event.target;
        setSubProductFeatures((prevDetails) => {
          const updatedLocations = [...prevDetails.subFeatures];
          updatedLocations[index].featureWeight = value;
          return { ...prevDetails, subFeatures: updatedLocations };
        });
      };
      
      

      const handleSubImageChange = (event, index) => {
        setSubProductFeature(false);
        const imageUrl = event.target.files[0];
      
        if (imageUrl && imageUrl.type.startsWith('image/')) {
          // const imageUrl = URL.createObjectURL(file); // Create object URL for image file
      
          // Create a new array with updated subFeatures
          const updatedSubFeatures = subProductFeatures.subFeatures.map((subFeature, idx) => {
            if (idx === index) {
              // Update imageUrl only for the specified index
              return { ...subFeature, imageUrl };
            }
            return subFeature;
          });
      
          // Update state with the modified subFeatures array
          setSubProductFeatures(prevState => ({
            ...prevState,
            subFeatures: updatedSubFeatures,
          }));
        } else {
          console.log('Please select an image file.');
          // Handle non-image file types here if needed
        }
      };
      
      

    const  handleSubmitModal =async () =>{

    

try {

  if (subProductFeatures.productType === 'Loan') {
        

    //------------------- Parent Feature -------------//

    const LoanformData = new FormData();

    const addLoanQuestion = { 
      featureId:Id,
      featureName:subProductFeatures.featureName,
      productType:"Loan",
      status:"active",
      // subFeatures:mergedSubFeatures
    }
console.log(addLoanQuestion)

LoanformData.append('feature',JSON.stringify(addLoanQuestion))

if(productFeatureCreate.imageUrl){
  
  LoanformData.append("image",  productFeatureCreate.imageUrl);
  // LoanformData.log("image",  productFeatureCreate.imageUrl);
}


//------------------- End Parent Feature -------------//


  await axios.put(
    "/api/productFeatures/updateParentFeature",
    LoanformData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
  );


  if (productFeatureCreate.subFeatures && productFeatureCreate.subFeatures.length !== 0) {
  
  
        console.log("productFeatureCreate Loan")
  
  
       // ----------- Add New Sub Features -----------------//

       productFeatureCreate.subFeatures.forEach(element => {

        console.log(element);
        const addLoanG = {
          featureName: element.featureName,
          productType: "Loan",
                  status: "active",
        }
    
        const formData = new FormData();
        formData.append("feature", JSON.stringify(addLoanG));
    
        formData.append("image", element.file);
    
        // console.log(element.images)
    
      axios.post(
        "/api/productFeatures/addParentFeature",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
        
      });
   
  
    } else {
      console.log("Not Given");
    }
  }
  else{

//------------------- Parent Feature -------------//

const formData = new FormData();

const addQuestion = {
  featureId:subProductFeatures.featureId,
  featureName:subProductFeatures.featureName,
  featureWeight:subProductFeatures.featureWeight,
  productType:'Card',
  status:"active",
  // subFeatures:mergedSubFeatures
}
console.log(addQuestion)

formData.append('feature',JSON.stringify(addQuestion))
formData.append("image",  productFeatureCreate.imageUrl);
console.log("image",  productFeatureCreate.imageUrl);


//------------------- End Parent Feature -------------//
   
  await axios.put(
    "/api/productFeatures/updateParentFeature",
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
  );


  if (subProductFeatures.subFeatures && subProductFeatures.subFeatures.length !== 0) {

  console.log("SubProductFeatures")

   // ----------- Modify Sub Features -----------------//

 const subOldFormData = new FormData();

 const subOF = {
   parentFeatureId : subProductFeatures.featureId,
   subFeatures:subProductFeatures.subFeatures.map(({ featureId,featureName, featureWeight }) => ({
     featureId,
     featureName,
     featureWeight ,
     productType:'Card',
     status:'active'
   }))
 }
 subOldFormData.append('subFeatures',JSON.stringify(subOF))
 
 
 subProductFeatures.subFeatures.forEach(item => {
   if (typeof item === 'object' && item.imageUrl && typeof item.imageUrl === 'string') {
     // Set imageUrl to empty string
     item.imageUrl = '';
     
     // Create an empty File object
     const emptyBlob = new Blob([], { type: 'application/octet-stream' });
     const file = new File([emptyBlob], "empty.png", { type: "image/png" });
     
     // Now you can use this 'file' variable where needed
     subOldFormData.append("images", file);
     // console.log(file);
   } else {
     // console.log("File:", item.imageUrl);
     subOldFormData.append("images", item.imageUrl);
   }
 });
 
 // console.log(subOF)
 
 //--------------------------- End Modify Sub Features ---------------//
 
  await axios.put(
    "/api/productFeatures/updateSubFeatures",
    subOldFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
  );
}

if (productFeatureCreate.subFeatures && productFeatureCreate.subFeatures.length !== 0) {


      console.log("productFeatureCreate")


     // ----------- Add New Sub Features -----------------//
 
  const subNewFormData = new FormData();
 
  const subNF = {
    parentFeatureId : subProductFeatures.featureId,
    subFeatures:productFeatureCreate.subFeatures.map(({ featureName, featureWeight }) => ({
      featureName,
      featureWeight ,
      productType:'Card',
      status:'active'
    }))
  }
  
  subNewFormData.append('subFeatures',JSON.stringify(subNF))
  
  productFeatureCreate.subFeatures.forEach(images => {
    subNewFormData.append("images",  images.file);
    // console.log("Files ", images.file);
  });
  // console.log(subNF)
   

    await axios.post(
      "/api/productFeatures/addSubFeatures",
      subNewFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }
    );

  } else {
    console.log("Not Given");
  }
 
}
  // console.log(response?.data);
  console.log("Product Feature Update successfully !");

  setTimeout(() => {
    navigate('/dashboard/productFeatures');
  }, 1000);



} catch (err) {
  console.log(err)
  if (err.response && err.response.data) {
  
    console.log("Internal Server Error");
  } else {
    console.log("Error occurred. Please try again.")
  }
}


    }
   
  
        const handleAddLocationClick = () => {
          setLocations((prevLocations) => [...prevLocations, renderLocationGrid(prevLocations.length)]);
        };
 
        const [subFeatures, setLocations] = useState([]);


        const renderLocationGrid = (index) => {

          const handleAreaChange = (event) => {
            const { value } = event.target;
            setProductFeatureCreate((prevDetails) => {
              const updatedLocations = [...prevDetails.subFeatures];
              if (!updatedLocations[index]) {
                updatedLocations[index] = {}; 
              }
              updatedLocations[index].featureName = value;
              return { ...prevDetails, subFeatures: updatedLocations };
            });
          };
        
          const handleLocationChange = (event) => {
            const { value } = event.target;
            setProductFeatureCreate((prevDetails) => {
              const updatedLocations = [...prevDetails.subFeatures];
              if (!updatedLocations[index]) {
                updatedLocations[index] = {}; // Ensure the address object exists
              }
              updatedLocations[index].featureWeight = value;
              return { ...prevDetails, subFeatures: updatedLocations };
            });
          };
        

        
          const handleImageChange = (event, index) => {
            const file = event.target.files[0];
          
            if (file && file.type.startsWith('image/')) {
              const formData = new FormData(); // Create a new FormData object
              formData.append('image', file); // Append the file to the FormData object
          
              // Assuming productFeatureCreate is your state object
              const updatedLocations = [...productFeatureCreate.subFeatures];
              if (!updatedLocations[index]) {
                updatedLocations[index] = {}; // Ensure the sub-feature object exists
              }
          
              // Store the uploaded file in the state
              updatedLocations[index].file = file;
          
              // Now, you can update the state with the FormData object and the file
              setProductFeatureCreate(prevDetails => ({
                ...prevDetails,
                subFeatures: updatedLocations,
                formData: formData // Store the FormData object in the state if needed
              }));
          
            } else {
              // Handle non-image file types here if needed
              console.log('Please select an image file.');
              // You can also show an error message to the user or take other actions
            }
          };
          
          
          
      
        
          return (
            <>
          
           

              <Grid item xs={4} sm={4} md={4} >
              
                  
                <TextField
                  placeholder="Enter feature "
                  label="Feature Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  onChange={handleAreaChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                      fontSize: '11px'
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '11px'
                    }
                  }}
                 
                />
              </Grid>

              {subProductFeatures.productType === 'Card' && (
              <Grid item xs={4} sm={4} md={4}>
             
                <TextField
                  placeholder="Enter Weightage %"
                  type='number'
                  label="Weightage %"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  onChange={handleLocationChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                      fontSize: '11px'
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '11px'
                    }
                  }}
                
                />
              </Grid>
              )}

              <Grid item xs={4} md={4} lg={4}>
  <Box mt={2} style={{
    width: '192px',
    height: '75px',
    backgroundColor: '#f0f0f0',
    border: "2px solid #f0f0f0",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }}>
    {productFeatureCreate.subFeatures[index]?.file ? (
      <img
        src={URL.createObjectURL(productFeatureCreate.subFeatures[index].file)}
        // src={productFeatureCreate.subFeatures[index].file === 'string' && productFeatureCreate.subFeatures[index].file.includes('api/images') ? `${base}${productFeatureCreate.subFeatures[index].file}` : productFeatureCreate.subFeatures[index].file}
        alt="Uploaded"
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
      />
    ) : (
      <Typography variant="body2" color="textSecondary">
        Select Feature Icon
      </Typography>
    )}
  </Box>
  <Button
            variant="outlined"
            component="label"
            style={{
           
                height: "40px",
                marginTop: "2%",
                width:'65%',
                marginBottom: "0%",
                textTransform: "none",
            }}
          >
    Upload Icon
    <input
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={(e) => handleImageChange(e, index)} 
    />
  </Button>
</Grid>



          
            </>
          );
        };


  
   const handleImageChange = (field, file) => {
  setProductFeature(false);
  if (file) {
    setProductFeatureCreate((prevDetails) => ({
      ...prevDetails,
      [field]: file,
    }));
  }else {
    console.log("File selection canceled");
  }
};


        
        


        
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
  
    <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'1.5%',paddingBottom:'2%'}}>
      <h2>Edit Product Features</h2>
    </div>

 
        <div >
          <Paper style={{ padding: '20px', background: '#fff', width: '85%', height: "auto", padding: "12px 65px",marginLeft:'2%',marginTop:'4%' }}>
          {subProductFeatures.productType === 'Loan'?
        <h2> Feature</h2>
        :   <h2>Main Feature</h2>
        }
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "20px" }}>
            
              <Grid container spacing={2} >
              <Grid item xs={3} md={3} lg={3}>
                  <TextField
                    label="Product Type"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={subProductFeatures.productType}
                    InputProps={{
                        readOnly: true,
                      }}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}

                  />
                </Grid>

                <Grid item xs={3} md={3} lg={3}>

                <TextField
                  label="Feature Name" 
                  placeholder='Enter  Name'
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  value={subProductFeatures.featureName}
                  onChange={(e) => handleAddChange('featureName', e.target.value)}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
                    }, '& .MuiFormLabel-root': {
                      fontSize: "11px"
                    }
                  }}

                />
            
                </Grid>

                {subProductFeatures.featureWeight !== 0 && (
                <Grid item xs={3} md={3} lg={3}>
                  <TextField
                    label="Feature Weight"
                    type='number'
                    placeholder='Enter Feature Weight %'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={subProductFeatures.featureWeight}
                    onChange={(e) => handleAddChange('featureWeight', e.target.value)}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}

                  />
                </Grid>
                )}

{subProductFeatures.imageUrl &&(
                <Grid className="imageGrid" item xs={3} md={3} lg={3}>
  <Grid item xs={12} sm={12} md={12}>
    {/* Display the uploaded Image or text if the Image is not uploaded */}
    <Box mt={2} style={{
      width: '192px', // Set your desired width
      height: '75px', // Set your desired height
      backgroundColor: '#f0f0f0', // Set your default background color
      border: "2px solid #f0f0f0",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    }}>
      {subProductFeatures.imageUrl ? (
        <img
          src={productFeature ? `${base + subProductFeatures.imageUrl}` :  URL.createObjectURL(productFeatureCreate.imageUrl) }
          alt="Uploaded"
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
        />
      ) : (
        <Typography variant="body2" color="textSecondary">
          Select Feature Icon
        </Typography>
      )}
    </Box>
    <Button
      variant="outlined"
      component="label"
      style={{
        '& .MuiButton-root': {
          height: '40px',
          marginTop:'5px',
          marginBottom:'-12%',
          textTransform: 'none',
        },
      }}
    >
      Upload Icon
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleImageChange('imageUrl', e.target.files[0])}
      />
    </Button>
  </Grid>
</Grid>
)}



                </Grid>

                <Grid container spacing={2} >
                    <Grid item xs={12} md={12} lg={12}>
                    {subProductFeatures.productType === 'Loan' ?
                <h2 style={{ marginBottom: "2%", marginTop: "5%" }}>
                  Add More Features
                </h2>
                 :   <h2 style={{ marginBottom: "2%", marginTop: "5%" }}>
                   Sub Features
               </h2>}
             
              <hr></hr>
              </Grid>
           </Grid>

           {subProductFeatures.subFeatures && subProductFeatures.subFeatures.length > 0 ? (
  <>
    {subProductFeatures.subFeatures.map((location, index) => (
      <Grid spacing={2} container key={index}>
        <Grid item xs={4} sm={4} md={4}>
          <TextField
            placeholder="Enter feature"
            label="Feature Name"
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            value={location.featureName}
            onChange={(e) => handleEditAreaChange(e, index)}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
          />
        </Grid>

        {location.featureWeight !== 0 && (
        <Grid item xs={4} sm={4} md={4}>
          <TextField
            placeholder="Enter Weightage %"
            type='number'
            label="Weightage %"
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            value={location.featureWeight}
            onChange={(e) => handleEditLocationChange(e, index)}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
          />
        </Grid>
        )}


        <Grid item xs={4} md={4} lg={4}>
  <Box mt={2} style={{
    width: '192px',
    height: '75px',
    backgroundColor: '#f0f0f0',
    border: "2px solid #f0f0f0",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }}>
{location?.imageUrl ? (
  <img 
    src={typeof location.imageUrl === 'string' && !location.imageUrl.includes('blob') ? `${base}${location.imageUrl}` : URL.createObjectURL(location.imageUrl)}
    // src={location.imageUrl ? `${base + location.imageUrl}` :  URL.createObjectURL(location.imageUrl) }
    alt="Uploaded"
    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
  />
) : (
  <Typography variant="body2" color="textSecondary">
    No Feature Icon
  </Typography>
)}


  </Box>
  <Button
    variant="outlined"
    component="label"
    style={{ height: '40px',width:'65%', marginTop: '5px', textTransform: 'none' }}
  >
    Upload Icon
    <input
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={(e) => handleSubImageChange(e, index)}
    />
  </Button>
</Grid>


      </Grid>
    ))}
  </>
) : (

  subProductFeatures.productType === 'Loan' ?
  <div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No Features available</div>
:
<div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No Sub Features available</div>
)}





      {subFeatures.map((address, index) => (
  <Grid key={index} spacing={2} container>{renderLocationGrid(index)}</Grid>
))}

<Grid container spacing={2} >
  {subProductFeatures.productType === 'Loan' ? 
<Button onClick={handleAddLocationClick} sx={{ float: 'left' ,marginTop:'2%'}}>
        Add More Features (+)
      </Button>
      :
<Button onClick={handleAddLocationClick} sx={{ float: 'left' ,marginTop:'2%'}}>
        Add Sub Feature (+)
      </Button>
  }
          </Grid>
     
            </Grid>
          </Paper>
          <Button style={{ float: "right", marginTop: '2%',marginBottom:'4%',marginRight:'2%' }} variant="contained" color="primary"  onClick={handleSubmitModal}>
            Updated
          </Button>
          <Link to="/dashboard/productFeatures">
          <Button style={{ float: "right", marginRight: '1%', marginTop: '2%' }} variant="outlined" >
            Back
          </Button>
          </Link>
        </div>
    


    

    </div>
  );
};

export default EditProductFeatures;
