import axios from "axios";
import axiosAPI from './axiosAPI';

const usePublicAxiosInstance = () => {
    
    const instance = axios.create({
        
        baseURL: axiosAPI(),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    instance.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        const countKey = localStorage.getItem('X-Country-Key');

        if (token) {
            config.headers['X-Country-Key'] = countKey;
        }
        return config;
    });

    return instance;
};

export default usePublicAxiosInstance;
