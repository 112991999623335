import React,{useEffect,useState} from 'react';
import { Box, Grid, Paper,TextField,MenuItem,Button, Typography } from '@mui/material';
import PieChart from './PieCenterLabel';
import Loader from '../../components/static/loader';
import useAxiosInstance from '../../api/axios';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PieChartComponent from './PieCenterLabel'; 
import ApplicationPieChar from './ApplicationPieChar';
import LocationPieChart from './LocationPieChart';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUserAuth } from "../../features/auth/authSlice";

const month = [
 
  {
    value: 'Jan',
    label: 'January',
  },
  {
    value: 'Feb',
    label: 'Feburary',
  }
];
const years = [
 
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  }
];



const Dashboard = () => {

  const axios = useAxiosInstance();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const [customerRecords,setCustomerRecords] = useState(null);
  const [applicationRecords,setApplicationRecords] = useState(null);
  const [locationRecords,setLocationRecords] = useState(null);
  const [allProductType, setAllProductType] = useState([]);

  const permission = localStorage.getItem("UserRoleName");
  const MerchantID = localStorage.getItem("MerchantID");

  useEffect(() => {
  
    const getAllCustomer = async () => {
      try {
        const response = await axios.get("/api/customer-profiles/stats");
    
          console.log(response?.data)
          setCustomerRecords(response?.data);
    
  
      } catch (error) {
        console.error("Axios Error:", error);
             }
      
    };

    
    const getAllApplications = async () => {
      try {
        var response;
        if(permission == "MERCHANT"){
           response = await axios.get(`/api/merchants/dashboard-stats/${MerchantID}`);
        }else{
           response = await axios.get("/api/applications/stats");
        }


        console.log(response?.data)
        setApplicationRecords(response?.data);
     
  
      } catch (error) {
      console.error("Axios Error:", error);
      }
    };

    const getAllLocation = async () => {
      try {
        
         const response = await axios.get(`/api/merchants/${MerchantID}/location-counts-by-city`);
     
       
        console.log(response?.data)
        setLocationRecords(response?.data);
      
  
      } catch (error) {
      console.error("Axios Error:", error);
      }
    };

    const getAllProductType = async () => {
      try {
        const response = await axios.get("/api/application/Lov/productType");
        setAllProductType(response?.data || []); // Set data or empty array on error
      } catch (error) {
        console.error("Axios Error:", error);
        setAllProductType([]); // Set empty array on error
      }
    };


    if(permission == "MERCHANT"){
      getAllLocation();
    }
    getAllCustomer();
    getAllApplications();
    getAllProductType();

  }, []);

  const countKey = localStorage.getItem('X-Country-Key');
 
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
  
    <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'1.5%',paddingBottom:'2%'}}>
      <h2>Summary Dashboard</h2>
    </div>

    <div style={{
      padding:'21px',
      paddingTop:'5px',
      paddingBottom:'10px',
      marginTop:'20px',
    width: '93%',
    backgroundColor: '#ffffff',
    marginLeft: '2%',
    borderRadius: '4px',
    boxShadow: '0px 0px 7px 0px #cfc5c56b'}}>

<Grid container spacing={1} sx={{paddingLeft:"10px", marginTop:"10px"}}>
       
          <Grid container spacing={1} >
          <Grid item xs={12} md={4} lg={2}>
  <TextField
  id="filled-select-currency"
  select
  label="Product Type"
  defaultValue="USD"
  variant="filled"
  fullWidth
  size="small"
  sx={{
    '& .MuiInputBase-root': {
      width: '100%',
      fontSize: '11px',
      borderRadius: '8px', // Border radius
      backgroundColor: 'white', // Background color
      border: '1px solid #ccc',
    },
    '& .MuiFormLabel-root': {
      fontSize: "11px"
    },
    '& .MuiFilledInput-root': { // Additional style for filled input
      backgroundColor: 'white', // Background color
    },
    '& .MuiFilledInput-underline:before': { // Additional style for filled input underline
      borderBottom: 'none', // Remove default underline
    },
    '& .MuiFilledInput-underline:after': { // Additional style for filled input underline after focus
      borderBottom: 'none', // Remove default underline
    },
  }}
>
  
<MenuItem value="showAll">Show All</MenuItem>
{
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
} 

</TextField>


  </Grid>

  <Grid item xs={12} md={4} lg={2}>
  <TextField
  id="filled-select-currency"
  select
  label="Select Month"
  defaultValue="Feb"
  variant="filled"
  fullWidth
  size="small"
  sx={{
    '& .MuiInputBase-root': {
      width: '100%',
      fontSize: '11px',
      borderRadius: '8px', // Border radius
      backgroundColor: 'white', // Background color
      border: '1px solid #ccc',
    },
    '& .MuiFormLabel-root': {
      fontSize: "11px"
    },
    '& .MuiFilledInput-root': { // Additional style for filled input
      backgroundColor: 'white', // Background color
    },
    '& .MuiFilledInput-underline:before': { // Additional style for filled input underline
      borderBottom: 'none', // Remove default underline
    },
    '& .MuiFilledInput-underline:after': { // Additional style for filled input underline after focus
      borderBottom: 'none', // Remove default underline
    },
  }}
>
  {month.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))}
</TextField>


  </Grid>

  <Grid item xs={12} md={4} lg={2}>
  <TextField
  id="filled-select-currency"
  select
  label="Select Year"
  defaultValue="2023"
  variant="filled"
  fullWidth
  size="small"
  sx={{
    '& .MuiInputBase-root': {
      width: '100%',
      fontSize: '11px',
      borderRadius: '8px', // Border radius
      backgroundColor: 'white', // Background color
      border: '1px solid #ccc',
    },
    '& .MuiFormLabel-root': {
      fontSize: "11px"
    },
    '& .MuiFilledInput-root': { // Additional style for filled input
      backgroundColor: 'white', // Background color
    },
    '& .MuiFilledInput-underline:before': { // Additional style for filled input underline
      borderBottom: 'none', // Remove default underline
    },
    '& .MuiFilledInput-underline:after': { // Additional style for filled input underline after focus
      borderBottom: 'none', // Remove default underline
    },
  }}
>
  {years.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ))}
</TextField>


  </Grid>

  <Grid item xs={1.5} md={1.5} lg={1.5}>
    </Grid>

 
  
    <Grid item xs={12} md={1.5} lg={1.5}>
<div style={{borderRightStyle:'groove'}} >
  <p style={{color:'gray',marginTop:'0px'}}>
    {permission === 'MERCHANT' ? 'Total Location' : 'Total Customer'}
 </p>
  <p style={{fontWeight: 'bold', textAlign: 'center', marginTop: '-12px', fontSize: 'small'}}>
    {permission === 'MERCHANT' 
        ? (locationRecords && locationRecords.length > 0
            ? locationRecords.map(element => element.locationCount).join(', ')
            : '0') 
        : (customerRecords?.totalCustomer || '0')}
</p>

</div>
  </Grid>


  <Grid item xs={12} md={1.6} lg={1.6}>
<div style={{borderRightStyle:'groove'}}>
  <p style={{color:'gray',marginTop:'0px'}}>Total Application </p>
  <p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '-12px', fontSize: 'small' }}>
  {permission === 'MERCHANT' 
    ? (applicationRecords?.totalMerchantApplication  || '0') 
    : (applicationRecords?.totalApplications || '0')}
</p>

</div>



  </Grid>

  <Grid item xs={12} md={1.4} lg={1.4}>
<div style={{borderRightStyle:'groove'}}>
  <p style={{color:'gray',marginTop:'0px'}}>Total Approval </p>
<p style={{fontWeight:'bold',textAlign:'center',marginTop:'-12px',fontSize:'small'}}>{applicationRecords?.approved || '0'}</p>
</div>



  </Grid>
         </Grid>


         <Grid container spacing={6} >

         <Grid item xs={12} md={12} lg={12}>
          
           <Button 
  variant="contained"
  style={{backgroundColor:'#3e3ecd',float:'right',marginTop:'10px',borderRadius:'8px'}}
  startIcon={<SystemUpdateAltIcon />}
>
  Download CSV
</Button>
    </Grid>
          </Grid>
     
         </Grid>

    </div>


    <Grid container spacing={2} sx={{paddingLeft:"10px", marginTop:"10px"}}>
       
       <Grid container spacing={2} >

       {permission !== 'MERCHANT' ?
       <Grid item xs={12} md={6} lg={6}>
    <div style={{
      padding:'21px',
      paddingTop:'5px',
      paddingBottom:'10px',
      marginTop:'20px',
      marginBottom:'20px',

    width: '70%',
    backgroundColor: '#ffffff',
    marginLeft: '7%',
    borderRadius: '32px',
    boxShadow: '0px 0px 12px 3px #cfc5c56b'}}>
        <div style={{
        fontSize:'x-large',fontWeight:'bold',fontStyle:'normal',marginTop:'2%',marginBottom:'2%'

      }}>Customer</div>

       {customerRecords && customerRecords.totalCustomer !== null ? (
        <PieChartComponent customer={customerRecords} />
      ) : (
        <p>Loading customer data...</p>
      )}


      </div>
</Grid>

       : 
       <Grid item xs={12} md={6} lg={6}>
       <div style={{
         padding:'21px',
         paddingTop:'5px',
         paddingBottom:'10px',
         marginTop:'20px',
         marginBottom:'20px',
   
       width: '70%',
       backgroundColor: '#ffffff',
       marginLeft: '7%',
       borderRadius: '32px',
       boxShadow: '0px 0px 12px 3px #cfc5c56b'}}>
           <div style={{
           fontSize:'x-large',fontWeight:'bold',fontStyle:'normal',marginTop:'2%',marginBottom:'2%'
   
         }}>Locations</div>
   
   {locationRecords && locationRecords.length > 0 ? (
  <LocationPieChart customer={locationRecords} />
) : (
  <p>Loading location data...</p>
)}

   
   
         </div>
   </Grid>
       }
<Grid item xs={12} md={6} lg={6}>
    <div style={{
      padding:'21px',
      paddingTop:'5px',
      paddingBottom:'10px',
      marginTop:'20px',
      marginBottom:'20px',
      
    width: '70%',
    backgroundColor: '#ffffff',
    marginLeft: '4%',
    borderRadius: '32px',
    boxShadow: '0px 0px 12px 3px #cfc5c56b'}}>
      <div style={{
        fontSize:'x-large',fontWeight:'bold',fontStyle:'normal',marginTop:'2%',marginBottom:'2%'

      }}>Application</div>
  {applicationRecords && applicationRecords.totalApplications !== null ? (
        <ApplicationPieChar application={applicationRecords} />
      ) : (
        <p>Loading application data...</p>
      )}

      </div>
</Grid>
      </Grid>

   
      </Grid>
    </div>
  );
};

export default Dashboard;
