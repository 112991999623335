// import React,{useEffect,useState} from 'react';
// import { Table,
//     Box,
//     Tabs,
//     Tab,
//     TextField,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     Modal,
//     MenuItem,
//     InputAdornment,
//     FormControl,
//     Select,
//     Backdrop,
//     Grid,
//     Button,
//     TablePagination,
//     FormHelperText
//    } from '@mui/material';

// import axios from '../../api/axios';
// import DeleteIcon from '@mui/icons-material/Delete';


// const questionList = [
//   {
//     value: 'yes/no',
//     label: 'Yes/No',
//   },
//   {
//     value: 'range',
//     label: 'Range',
//   }
// ];



// const EligibilityQuestion = () => {

//     const [eligibilityQuestion,setElibilityQuestion] = useState([]);
//     const [eligibilityQuestionCreate,setElibilityQuestionCreate] = useState({
//       questionId:'',
//       question:'',
//       type:'',
//       response1:'',
//       response2:'',
//       response3:'',
//       response4:''
//     });
//     const [eligibilityQuestionEdit,setElibilityQuestionEdit] = useState({
//       questionId:'',
//       question:'',
//       type:'',
//    options:[]
//     });
//     const [errors, setErrors] = useState({
//       question: '',
//       type: ''
//     });
//     const [modalOpen, setModalOpen] = useState(false);
//     const [updateModalOpen, setUpdateModalOpen] = useState(false);
//     const [selectedOption, setSelectedOption] = useState('');
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(5);
    
//     const [allProductType, setAllProductType] = useState([]);
    

//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//       };
    
//       const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);
//       };
 

//       const handleAddClick = async () => {
//         setModalOpen(true);
    
//       };
//       const validateForm = () => {
//         let tempErrors = {
//           question: eligibilityQuestionCreate.question ? '' : 'This field is required.',
//           type: eligibilityQuestionCreate.type ? '' : 'This field is required.'
//         };
//         setErrors(tempErrors);
      
//         // Returns true if no errors are found
//         return Object.values(tempErrors).every(x => x === "");
//       };
      
      
// //   const validateForm = () => {
// //   let tempErrors = {};
// //   tempErrors.question = eligibilityQuestionCreate.question ? '' : 'This field is required.';
// //   tempErrors.type = eligibilityQuestionCreate.type ? '' : 'This field is required.';
// //   setErrors({
// //     ...tempErrors
// //   });
  
// //   // Returns true if no errors
// //   return Object.values(tempErrors).every(x => x === "");
// // }
//       const handleModifyClick =async (Id) => {
//         const response = await axios.get(`/api/questions/${Id}`);
//         console.log(response?.data)
//         setElibilityQuestionEdit(response?.data);
//         // const { questionId, question, type, options } = response.data;
        
//         // // Check if options array exists and has at least four elements
//         // const response1 = options.length >= 1 ? options[0].optionText : '';
//         // const response2 = options.length >= 2 ? options[1].optionText : '';
//         // const response3 = options.length >= 3 ? options[2].optionText : '';
//         // const response4 = options.length >= 4 ? options[3].optionText : '';
        
//         // // Adjust the data in the desired format
//         // const adjustedData = {
//         //   questionId: questionId,
//         //   question: question,
//         //   type: type,
//         //   response1: type === 'range' ? response1 : '',
//         //   response2: type === 'range' ? response2 : '',
//         //   response3: type === 'range' ? response3 : '',
//         //   response4: type === 'range' ? response4 : ''
//         // };
        
        
    
//         // setElibilityQuestionCreate(adjustedData); // eligibilityQuestionCreate ko set karein
//         // console.log(adjustedData);
//        setUpdateModalOpen(true);
//       };

//       const handleCloseModal = () => {
//         setModalOpen(false);
//         setElibilityQuestionCreate({
//           optionText:'',
//           value:'',
//           response1:'',
//           response2:'',
//           response3:'',
//           response4:''
//         });
       
//       };


//       const handleUpdateCloseModal = () => {
//         setUpdateModalOpen(false);
       
//       };


//       const getAllQuestionList = async () => {
//         try {
//           const response = await axios.get("/api/questions");
//           console.log(response?.data)
//           setElibilityQuestion(response?.data);
    
//         } catch (error) {
//           console.error("Axios Error:", error);
//         }
//       };

//       const getAllProductType = async () => {
//         try {
//           const response = await axios.get("/api/application/Lov/productType");
//           setAllProductType(response?.data || []); // Set data or empty array on error
//         } catch (error) {
//           console.error("Axios Error:", error);
//           setAllProductType([]); // Set empty array on error
//         }
//       };
    
//       useEffect(() => {
//         getAllQuestionList();
//         getAllProductType();
//       }, []);

//       const handleAddChange = (property, value, index) => {
//         // Make a copy of the options array
//         const updatedOptions = [...eligibilityQuestionEdit.options];
      
//         // Update the value of the option at the specified index
//         updatedOptions[index] = {
//           ...updatedOptions[index],
//           [property]: value
//         };
      
//         // Filter out unwanted properties
//         const filteredOptions = updatedOptions.map(option => {
//           const { optionId, optionText, value, hardStop } = option;
//           return { optionId, optionText, value, hardStop }; // Retain only necessary properties
//         });
      
//         // Update the state with the modified and filtered options array
//         setElibilityQuestionEdit(prevState => ({
//           ...prevState,
//           options: filteredOptions
//         }));
//       };
      
      
      
//       const handleSubmitModal = async () => {
//         if (validateForm()) {
//           let options;
//           if (eligibilityQuestionCreate.type !== 'range') {
//             options = [
//               {"optionText": "Yes", "value": 20, "hardStop": false},
//               {"optionText": "No", "value": 0, "hardStop": false}
//             ];
//           } else {
//             options = [
//               {"optionText": eligibilityQuestionCreate.response1, "value": 20, "hardStop": false},
//               {"optionText": eligibilityQuestionCreate.response2, "value": 30, "hardStop": false},
//               {"optionText": eligibilityQuestionCreate.response3, "value": 40, "hardStop": false},
//               {"optionText": eligibilityQuestionCreate.response4, "value": 50, "hardStop": false}
//             ];
//           }
//           const addQuestion = {
//             question: eligibilityQuestionCreate.question,
//             type: eligibilityQuestionCreate.type,
//             options: options
//           };
//           try {
//             await axios.post("/api/questions", addQuestion, {
//               headers: {"Content-Type": "application/json"}
//             });
//             setModalOpen(false);
//             getAllQuestionList();
//             console.log("Question created successfully!");
//           } catch (err) {
//             console.error("Error occurred. Please try again.");
//           }
//         }
//       }
      
// //     const  handleSubmitModal =async () =>{
  
// //       let options;

// //       if (eligibilityQuestionCreate.type !== 'range') {
// //         options = [
// //           {
// //             "optionText": "Yes",
// //             "value": 20,
// //             "hardStop": false
// //           },
// //           {
// //             "optionText": "No",
// //             "value": 0,
// //             "hardStop": false
// //           }
// //         ];
// //       } else {
// //         options = [
// //           {
// //             "optionText": eligibilityQuestionCreate.response1,
// //             "value": 20,
// //             "hardStop": false
// //           },
// //           {
// //             "optionText": eligibilityQuestionCreate.response2,
// //             "value": 30,
// //             "hardStop": false
// //           },
// //           {
// //             "optionText": eligibilityQuestionCreate.response3,
// //             "value": 40,
// //             "hardStop": false
// //           },
// //           {
// //             "optionText": eligibilityQuestionCreate.response4,
// //             "value": 50,
// //             "hardStop": false
// //           }
// //         ];
// //       }
      
// //       const addQuestion = {
// //         question: eligibilityQuestionCreate.question,
// //         type: eligibilityQuestionCreate.type,
// //         options: options
// //       };
      
// // // console.log(addQuestion)

// // try {
   
// //   const response = await axios.post(
// //     "/api/questions",
// //     addQuestion,
// //     {
// //       headers: {
// //         "Content-Type": "application/json"
// //       },
// //     }
// //   );


// //   setModalOpen(false);
// //   getAllQuestionList();
// // console.log("Question create successfully !");


// // } catch (err) {
// //   if (err.response && err.response.data) {
  
// //     console.log("Internal Server Error");
// //   } else {
// //     console.log("Error occurred. Please try again.")
// //   }
// // }


// //     }



//     const  handleUpdatedModal =async () =>{
//       console.log(eligibilityQuestionEdit)

//       const res = {
//         question:eligibilityQuestionEdit.question,
//         productType:"Card",
//         type:eligibilityQuestionEdit.type,
//         options:eligibilityQuestionEdit.options,
//       }
  
// try {
 
//    await axios.put(
//     `/api/questions/${eligibilityQuestionEdit.questionId}`,
//     res,
//     {
//       headers: {
//         "Content-Type": "application/json"
//       },
//     }
//   );


//   setUpdateModalOpen(false);
//   getAllQuestionList();
// console.log("Question create successfully !");


// } catch (err) {
//   if (err.response && err.response.data) {
  
//     console.log("Internal Server Error");
//   } else {
//     console.log("Error occurred. Please try again.")
//   }
// }


//     }

//     const[userId , setuserId] = useState(null);
//     const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

//     const handleDeleteRecords = (userID) =>{
//       setuserId(userID)
//       setDeleteDialogOpen(true);
  
//     }
//     const handleDeleteDialogClose = () => {
//       setDeleteDialogOpen(false);
//       setuserId(null)
//     };
  
//     const handleDeleteDialog =async () => {
//           console.log(userId,"deleting user");
     
//           try {
        
          
//              await axios.delete(
//               `/api/questions/${userId}`
//             );

//    setDeleteDialogOpen(false);
//       setuserId(null)
//             getAllQuestionList();
           
//           } catch (err) {
//             if (err.response && err.response.data) {
             
//               console.log(err.response)
//             } else {
//               console.log("Internal Server Error")
//             }
//           }
    
//     };



//     const [selectedProductType, setSelectedProductType] = useState('showAll');
//     const [filteredQuestions, setFilteredQuestions] = useState(eligibilityQuestion);
  
//     useEffect(() => {
//       // Update filteredQuestions when eligibilityQuestion changes
//       setFilteredQuestions(eligibilityQuestion);
//     }, [eligibilityQuestion]);
  
//     const handleProductTypeChange = (event) => {
//       const selectedValue = event.target.value;
//       // console.log(selectedValue);
//       setSelectedProductType(selectedValue);
  
//       if (selectedValue === 'showAll') {
//         setFilteredQuestions(eligibilityQuestion); // Show all data
//       } else {
//         const filteredData = eligibilityQuestion.filter(question => question.productType === selectedValue);
//         setFilteredQuestions(filteredData);
//       }
//     };

//     const handleEditChange = (property, value) => {
//       setElibilityQuestionEdit(prevState => ({
//         ...prevState,
//         [property]: value
//       }));
//     };

//     const handleAddQuestionChange = (property, value) => {
//       setElibilityQuestionCreate(prevState => ({
//         ...prevState,
//         [property]: value
//       }));
//     };
    


//   return (
//     <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
  
//     <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'1.5%',paddingBottom:'2%'}}>
//       <h2>Eligibility Questions</h2>
//     </div>

//     <div style={{
//       padding:'21px',
//       paddingTop:'5px',
//       paddingBottom:'10px',
//       marginTop:'20px',
//     width: '93%',
//     backgroundColor: '#ffffff',
//     marginLeft: '2%',
//     borderRadius: '4px',
//     boxShadow: '0px 0px 7px 0px #cfc5c56b'}}>

// <Grid container spacing={1} sx={{paddingLeft:"10px", marginTop:"10px"}}>
       
//           <Grid container spacing={4} >
//           <Grid item xs={3} md={3} lg={3}>
//           <TextField
//         id="filled-select-currency"
//         select
//         label="Select Products"
//         value={selectedProductType}
//         onChange={handleProductTypeChange}
//         variant="filled"
//         fullWidth
//         size="small"
//         sx={{
//           '& .MuiInputBase-root': {
//             width: '100%',
//             fontSize: '11px',
//             borderRadius: '8px', // Border radius
//             backgroundColor: 'white', // Background color
//             border: '1px solid #ccc',
//           },
//           '& .MuiFormLabel-root': {
//             fontSize: "13px"
//           },
//           '& .MuiFilledInput-root': { // Additional style for filled input
//             backgroundColor: 'white', // Background color
//           },
//           '& .MuiFilledInput-underline:before': { // Additional style for filled input underline
//             borderBottom: 'none', // Remove default underline
//           },
//           '& .MuiFilledInput-underline:after': { // Additional style for filled input underline after focus
//             borderBottom: 'none', // Remove default underline
//           },
//         }}
//       >
//         <MenuItem value="showAll">Show All</MenuItem>
//         {allProductType &&
//           allProductType.map((option) => (
//             <MenuItem key={option.lovKey} value={option.lovKey}>
//               {option.lovKey}
//             </MenuItem>
//           ))}
//       </TextField>


//   </Grid>

//          </Grid>

//          </Grid>

//     </div>

//     <Button onClick={() => handleAddClick()}
//           variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right',marginTop:'2%',marginRight:'3%' }}>
//           Add Question
//           {/* <AddIcon /> */}
//         </Button>

//         <div style={{ margin: '2%', marginTop: '3%', width: '95%', }}>
//         <TableContainer className="table-container" >
//           <Table stickyHeader>
//             <TableHead>
//               <TableRow className="table-heading">
//                 {/* <TableCell>User ID</TableCell> */}
//                 <TableCell>List</TableCell>
//                 <TableCell>Response Type</TableCell>
//                 <TableCell>Action</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//             {(rowsPerPage > 0
//               ? filteredQuestions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               : filteredQuestions
//             ).map(user => (
//                 <TableRow key={user.questionId}>
//                 <TableCell>{user.question}</TableCell>
// <TableCell>{user.type !== 'yes/no' ? 'Range' : 'Yes/No'}</TableCell>

//  {/* <TableCell>{user.role.name}</TableCell>
//                   <TableCell>{user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : 'N/A'}</TableCell>
//                    */}

//                   <TableCell>
              
//                       {/* <Button color="primary">
//                         View
//                       </Button> */}
                  
//                       <Button  color="primary"  onClick={() => handleModifyClick(user.questionId)}>
//                         Modify
//                       </Button>
//                       <Button
//                   color='error'
//                   sx={{
//                     '& .MuiButton-root': {
//                       height: '30px',
//                       textTransform: 'none',
//                     },
//                   }}
//                   onClick={() => handleDeleteRecords(user.questionId)}
//                   // startIcon={<DeleteIcon />}
//                 >
//               <DeleteIcon />
//            </Button>
                   
                    
                   
                

//                   </TableCell>
//                 </TableRow>
//                ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <TablePagination
//         rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
//         component="div"
//         count={eligibilityQuestion.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />

//       </div>


//       <Modal open={modalOpen} onClose={handleCloseModal}>
//         <div className="modal-content">
//           <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
//             <h2>Add Question</h2>
//             <hr></hr>
//             <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "20px" }}>
            
//               <Grid container spacing={2} >
//                 <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                    label="Question"
//   placeholder="Enter question"
//   variant="outlined"
//   margin="normal"
//   fullWidth
//   size="small"
//   error={!!errors.question}
//   helperText={errors.question}
//   value={eligibilityQuestionCreate.question}
//                     onChange={(e) => handleAddQuestionChange('question', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>
//                 <Grid item xs={6} md={6} lg={6}>
//                 <FormControl  error={!!errors.type} fullWidth size="small"  margin="normal"
//                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
//                   <Select
//                     labelId="customer-name-label"
//                     value={setElibilityQuestionCreate.type}
//     error={!!errors.type}
//     helperText={errors.type}
//                     onChange={(e) => handleAddQuestionChange('type', e.target.value)}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200, // Set the maximum height of the dropdown menu
//                           width: 250, // Set the width of the dropdown menu if needed
//                         },
//                       },
//                     }}
//                   >
//                     {questionList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}


//                   </Select>
//                     <FormHelperText>{errors.type}</FormHelperText> {/* Error message here */}
//                 </FormControl>
//                 </Grid>


//               </Grid>

//               {eligibilityQuestionCreate.type  === 'range' && (
//           <>
  
// <Grid container spacing={2} >
//               <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="Option 1"
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={setElibilityQuestionCreate.response1}
//                     onChange={(e) => handleAddQuestionChange('response1', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>

//                 <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="Option 2"
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={setElibilityQuestionCreate.response2}
//                     onChange={(e) => handleAddQuestionChange('response2', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>
// </Grid>

// <Grid container spacing={2} >
//               <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="Option 3"
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={setElibilityQuestionCreate.response3}
//                     onChange={(e) => handleAddQuestionChange('response3', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>

//                 <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="Option 4"
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={setElibilityQuestionCreate.response4}
//                     onChange={(e) => handleAddQuestionChange('response4', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>
// </Grid>
// </>
//               )}
//             </Grid>
//           </Paper>
//           <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary"  onClick={handleSubmitModal}>
//             Continue
//           </Button>
//           <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleCloseModal}>
//             Cancel
//           </Button>

//         </div>
//       </Modal>


//       <Modal open={updateModalOpen} onClose={handleUpdateCloseModal}>
//         <div className="modal-content">
//           <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
//             <h2>Modify Question</h2>
//             <hr></hr>
//             <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "20px" }}>
            
//               <Grid container spacing={2} >
//                 <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="Question"
//                     placeholder='Enter  question'
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={eligibilityQuestionEdit.question}
//                     onChange={(e) => handleEditChange('question', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

                    
//                   />
//                 </Grid>

//                 <Grid item xs={6} md={6} lg={6}>
//                 <TextField
//                     label="Type"
//                     placeholder='Question Type'
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
               
//                     value={eligibilityQuestionEdit.type}
//                     // onChange={(e) => handleAddChange('type', e.target.value)}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}
//                     InputProps={{
//                       readOnly: true,
//                     }}
                    
//                   />
//                 </Grid>


//               </Grid>


//               {eligibilityQuestionEdit.type === 'range' && (
//   <>
//    <Grid container spacing={2} >
//     {eligibilityQuestionEdit.options.map((option, index) => (
     
//         <Grid item xs={6} md={6} lg={6} key={option.optionId}>
//       <TextField
//   label={`Option ${index + 1}`}
//   variant="outlined"
//   margin="normal"
//   fullWidth
//   size="small"
//   value={option.optionText}
//   onChange={(e) => handleAddChange('optionText', e.target.value, index)} // Pass property name and value
//   sx={{
//     '& .MuiInputBase-root': {
//       height: '30px',
//       fontSize: '11px',
//     },
//     '& .MuiFormLabel-root': {
//       fontSize: "11px"
//     }
//   }}
// />

//         </Grid>
 
//     ))}
//          </Grid>
//   </>
// )}

//           </Grid>
          
//             </Paper>
//           <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary"  onClick={handleUpdatedModal}>
//             Continue
//           </Button>
//           <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleUpdateCloseModal}>
//             Cancel
//           </Button>

//         </div>
//       </Modal>


//       <Modal open={deleteDialogOpen} onClose={handleDeleteDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
//       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
//       <Paper style={{ padding: '20px', background: '#fff', width: '70vh', height:"auto", padding:"20px 50px", zIndex:1 }}>
//           <h2 style={{fontSize:'bold'}}>Delete Question</h2>
//           <hr></hr>
//           <Grid container spacing={2} sx={{paddingLeft:"20px", marginTop:"20px"}}>
   
//           <Grid container spacing={2}>
    
//         <h4 style={{marginLeft:'70px'}}>Are you sure you want to delete ? </h4>
//             </Grid>


//           </Grid>
//           <Button onClick={handleDeleteDialog} variant="contained" color="success" style={{ marginTop: '20px', float:"right" }}>
//             Yes
//           </Button>
//           <Button onClick={handleDeleteDialogClose} variant="contained" style={{ marginTop: '20px', float:"right",marginRight:'10px' }}>
//             No
//           </Button>
//         </Paper>
//       </div>
//     </Modal>

//     </div>
//   );
// };

// export default EligibilityQuestion;
// ==================
import React, { useEffect, useState } from 'react';
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  MenuItem,
  InputAdornment,
  FormControl,
  Select,
  Backdrop,
  Grid,
  Button,
  TablePagination,
  FormHelperText,
  Checkbox,
  FormControlLabel
} from '@mui/material';

import useAxiosInstance from '../../api/axios';
import usePublicAxiosInstance from '../../api/pubicAxios';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from 'axios';

const questionList = [
  {
    value: 'yes/no',
    label: 'Yes/No',
  },
  {
    value: 'range',
    label: 'Range',
  }
];

const ProductList = [
  {
    value: 'Card',
    label: 'Card',
  },
  {
    value: 'Loan',
    label: 'Loan',
  }
];

const EligibilityQuestion = () => {
  const countKey = localStorage.getItem('X-Country-Key');
  
  const axio = usePublicAxiosInstance();
   

  const [eligibilityQuestion, setElibilityQuestion] = useState([]);
  const [eligibilityQuestionCreate, setElibilityQuestionCreate] = useState({
    questionId: '',
    question: '',
    type: '',
    productType: '',
    identifier: '',
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    hardStop0: false,
    hardStop1: false,
    hardStop2: false,
    hardStop3: false,
    hardStop4: false,
  });


  const [eligibilityQuestionEdit, setElibilityQuestionEdit] = useState({
    questionId: '',
    question: '',
    type: '',
    productType: '',
    identifier: '',
    options: []
  });
  const [errors, setErrors] = useState({
    question: '',
    type: '',
    productType: '',
    identifier: ''
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [allProductType, setAllProductType] = useState([]);
  const [allProductKeys, setAllProductKeys] = useState([]);

  const axios = useAxiosInstance();


  const [selectedProductType, setSelectedProductType] = useState('showAll');
  const [filteredQuestions, setFilteredQuestions] = useState(eligibilityQuestion);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddClick = async () => {
    setModalOpen(true);
  };

  const validateForm = () => {
    let tempErrors = {
      question: eligibilityQuestionCreate.question ? '' : 'This field is required.', 
      productType: eligibilityQuestionCreate.productType ? '' : 'This field is required.', 
      identifier: eligibilityQuestionCreate.identifier ? '' : 'This field is required.', 
      type: eligibilityQuestionCreate.type ? '' : 'This field is required.'
    };
    setErrors(tempErrors);

    return Object.values(tempErrors).every(x => x === "");
  };

  const handleModifyClick = async (Id) => {
    const response = await axios.get(`/api/questions/${Id}`);
    console.log(response?.data)
    setElibilityQuestionEdit(response?.data);
    setUpdateModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setElibilityQuestionCreate({
      optionText: '',
      value: '',
      response1: '',
      response2: '',
      response3: '',
      response4: '',
      hardStop1: false,
      hardStop2: false,
      hardStop3: false,
      hardStop4: false,
    });

    setErrors({
      question: '',
      type: '',
      productType: '',
      identifier: ''
    })
  };

  const handleUpdateCloseModal = () => {
    setUpdateModalOpen(false);
  };

  const getAllQuestionList = async () => {
    try {
      const response = await axio.get("/api/questions/public");
      // console.log(response?.data)
      setSelectedProductType('showAll')
      setElibilityQuestion(response?.data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const getAllProductType = async () => {
    try {
      const response = await axios.get("/api/application/Lov/productType");
      setAllProductType(response?.data || []);

    } catch (error) {
      console.error("Axios Error:", error);
      setAllProductType([]);
    }
  };

  const getAllProductKeys = async () => {
    try {
      const response = await axios.get("/api/application/Lov/list-of-values?listType=questionIdentifier");
      setAllProductKeys(response?.data || []);
      // console.log(response?.data)

    } catch (error) {
      console.error("Axios Error:", error);
      setAllProductType([]);
    }
  };

  useEffect(() => {
    getAllQuestionList();
    getAllProductType();
    getAllProductKeys();
  }, []);

  const handleAddChange = (property, value, index) => {
    const updatedOptions = [...eligibilityQuestionEdit.options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [property]: value
    };

    const filteredOptions = updatedOptions.map(option => {
      const { optionId, optionText, value, hardStop } = option;
      return { optionId, optionText, value, hardStop };
    });

    setElibilityQuestionEdit(prevState => ({
      ...prevState,
      options: filteredOptions
    }));
  };

  const handleAddQuestionChange = (property, value) => {
    setElibilityQuestionCreate(prevState => ({
      ...prevState,
      [property]: value
    }));
  };

  const handleHardStopChange = (index, value) => {
    const updatedOptions = [...eligibilityQuestionEdit.options];
    updatedOptions[index].hardStop = value;

    setElibilityQuestionEdit(prevState => ({
      ...prevState,
      options: updatedOptions
    }));
  };

  const handleSubmitModal = async () => {
    if (validateForm()) {
      let options;
      if (eligibilityQuestionCreate.type !== 'range') {
        options = [
          { "optionText": "Yes", "value": 20, "hardStop": false },
          { "optionText": "No", "value": 0, "hardStop": eligibilityQuestionCreate.hardStop0 }
        ];
      } else {
        options = [
          { "optionText": eligibilityQuestionCreate.response1, "value": 20, "hardStop": eligibilityQuestionCreate.hardStop1 },
          { "optionText": eligibilityQuestionCreate.response2, "value": 30, "hardStop": eligibilityQuestionCreate.hardStop2 },
          { "optionText": eligibilityQuestionCreate.response3, "value": 40, "hardStop": eligibilityQuestionCreate.hardStop3 },
          { "optionText": eligibilityQuestionCreate.response4, "value": 50, "hardStop": eligibilityQuestionCreate.hardStop4 }
        ];
      }
      const addQuestion = {
        question: eligibilityQuestionCreate.question,
        type: eligibilityQuestionCreate.type,
        options: options,
        productType:eligibilityQuestionCreate.productType,
        identifier:eligibilityQuestionCreate.identifier
      };
      console.log(addQuestion)
      try {
        await axios.post("/api/questions", addQuestion, {
          headers: { "Content-Type": "application/json" }
        });
       
        handleCloseModal();
        setModalOpen(false);
        getAllQuestionList();
        console.log("Question created successfully!");
      } catch (err) {
        console.error("Error occurred. Please try again.");
      }
    }
  };

  const handleUpdatedModal = async () => {
    const res = {
      question: eligibilityQuestionEdit.question,
      productType: eligibilityQuestionEdit.productType,
      identifier: eligibilityQuestionEdit.identifier,
      type: eligibilityQuestionEdit.type,
      options: eligibilityQuestionEdit.options,
    }
// console.log(res)
    try {
      await axios.put(`/api/questions/${eligibilityQuestionEdit.questionId}`, res, {
        headers: {
          "Content-Type": "application/json"
        },
      });

      setUpdateModalOpen(false);
      getAllQuestionList();
      console.log("Question updated successfully!");
    } catch (err) {
      if (err.response && err.response.data) {
        console.log("Internal Server Error");
      } else {
        console.log("Error occurred. Please try again.")
      }
    }
  };

  const [userId, setuserId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteRecords = (userID) => {
    setuserId(userID);
    setDeleteDialogOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setuserId(null);
  };

  const handleDeleteDialog = async () => {
    console.log(userId, "deleting user");

    try {
      await axios.delete(`/api/questions/${userId}`);
      setDeleteDialogOpen(false);
      setuserId(null);
      getAllQuestionList();
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
      } else {
        console.log("Internal Server Error");
      }
    }
  };

 
  useEffect(() => {
    setFilteredQuestions(eligibilityQuestion);
  }, [eligibilityQuestion]);

  const handleProductTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedProductType(selectedValue);

    if (selectedValue === 'showAll') {
      setFilteredQuestions(eligibilityQuestion);
    } else {
      const filteredData = eligibilityQuestion.filter(question => question.productType === selectedValue);
      setFilteredQuestions(filteredData);
    }
  };

  const handleEditChange = (property, value) => {
    setElibilityQuestionEdit(prevState => ({
      ...prevState,
      [property]: value
    }));
  };

  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
      <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
        <h2>Eligibility Questions</h2>
      </div>

      <div style={{
        padding: '21px',
        paddingTop: '5px',
        paddingBottom: '10px',
        marginTop: '20px',
        width: '93%',
        backgroundColor: '#ffffff',
        marginLeft: '2%',
        borderRadius: '4px',
        boxShadow: '0px 0px 7px 0px #cfc5c56b'
      }}>
        <Grid container spacing={1} sx={{ paddingLeft: "10px", marginTop: "10px" }}>
          <Grid container spacing={4}>
            <Grid item xs={3} md={3} lg={3}>
              <TextField
                id="filled-select-currency"
                select
                label="Select Products"
                value={selectedProductType}
                onChange={handleProductTypeChange}
                variant="filled"
                fullWidth
                size="small"
                sx={{
                  '& .MuiInputBase-root': {
                    width: '100%',
                    fontSize: '11px',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: "13px"
                  },
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'white',
                  },
                  '& .MuiFilledInput-underline:before': {
                    borderBottom: 'none',
                  },
                  '& .MuiFilledInput-underline:after': {
                    borderBottom: 'none',
                  },
                }}
              >
                <MenuItem value="showAll">Show All</MenuItem>
{
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
} 
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Button onClick={() => handleAddClick()}
        variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right', marginTop: '2%', marginRight: '3%' }}>
        Add Question
      </Button>

      <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>
        <TableContainer className="table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-heading">
                <TableCell>List</TableCell>
                <TableCell>Response Type</TableCell>
                <TableCell>Product Type</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredQuestions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filteredQuestions
              ).map(user => (
                <TableRow key={user.questionId}>
                  <TableCell>{user.question}</TableCell>
                  <TableCell>{user.type !== 'yes/no' ? 'Range' : 'Yes/No'}</TableCell>
                  <TableCell>{user.productType || 'Null'}</TableCell>
                  <TableCell>
                    <Button color="primary" onClick={() => handleModifyClick(user.questionId)}>
                      Modify
                    </Button>
                    <Button
                      color='error'
                      sx={{
                        '& .MuiButton-root': {
                          height: '30px',
                          textTransform: 'none',
                        },
                      }}
                      onClick={() => handleDeleteRecords(user.questionId)}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={filteredQuestions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2>Add Question</h2>
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={8} lg={8}>
                  <TextField
                    label="Question"
                    placeholder="Enter question"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    error={!!errors.question}
                    helperText={errors.question}
                    value={eligibilityQuestionCreate.question}
                    onChange={(e) => handleAddQuestionChange('question', e.target.value)}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                  <FormControl error={!!errors.productType} fullWidth size="small" margin="normal" sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                    <Select
                      labelId="customer-name-label"
                      value={eligibilityQuestionCreate.productType}
                      error={!!errors.productType}
                      helperText={errors.productType}
                      onChange={(e) => handleAddQuestionChange('productType', e.target.value)}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 50,
                          },
                        },
                      }}
                    >
                          <MenuItem value="" disabled>
      Product Type
    </MenuItem>
    {
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
} 
                   
                    </Select>
                    <FormHelperText>{errors.type}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>

                <Grid item xs={6} md={6} lg={6}>
                  <FormControl error={!!errors.type} fullWidth size="small" margin="normal" sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                    <Select
                      labelId="customer-name-label"
                      value={eligibilityQuestionCreate.type}
                      error={!!errors.type}
                      helperText={errors.type}
                      onChange={(e) => handleAddQuestionChange('type', e.target.value)}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 50,
                          },
                        },
                      }}
                    >
                          <MenuItem value="" disabled>
    Question Type
    </MenuItem>
                      {questionList.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.type}</FormHelperText>
                  </FormControl>

                  {eligibilityQuestionCreate.type === 'yes/no' && (
                  <FormControlLabel
                        control={
                          <Checkbox
                            checked={eligibilityQuestionCreate.hardStop0}
                            onChange={(e) => handleAddQuestionChange('hardStop0', e.target.checked)}
                          />
                        }
                        label="Hard Stop"
                      />
                  )}
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <FormControl error={!!errors.type} fullWidth size="small" margin="normal" sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                    <Select
                      labelId="customer-name-label"
                      value={eligibilityQuestionCreate.identifier}
                      error={!!errors.identifier}
                      helperText={errors.identifier}
                      onChange={(e) => handleAddQuestionChange('identifier', e.target.value)}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 50,
                          },
                        },
                      }}
                    >
                          <MenuItem value="" disabled>
    Select Identifier
    </MenuItem>
                      {allProductKeys.map((item) => (
                        <MenuItem key={item.lovVal} value={item.lovKey}>
                          {item.lovKey}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors.identifier}</FormHelperText>
                  </FormControl>
                </Grid>

              </Grid>

              {eligibilityQuestionCreate.type === 'range' && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        label="Option 1"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        value={eligibilityQuestionCreate.response1}
                        onChange={(e) => handleAddQuestionChange('response1', e.target.value)}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '30px', fontSize: '11px'
                          }, '& .MuiFormLabel-root': {
                            fontSize: "11px"
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eligibilityQuestionCreate.hardStop1}
                            onChange={(e) => handleAddQuestionChange('hardStop1', e.target.checked)}
                          />
                        }
                        label="Hard Stop"
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        label="Option 2"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        value={eligibilityQuestionCreate.response2}
                        onChange={(e) => handleAddQuestionChange('response2', e.target.value)}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '30px', fontSize: '11px'
                          }, '& .MuiFormLabel-root': {
                            fontSize: "11px"
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eligibilityQuestionCreate.hardStop2}
                            onChange={(e) => handleAddQuestionChange('hardStop2', e.target.checked)}
                          />
                        }
                        label="Hard Stop"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        label="Option 3"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        value={eligibilityQuestionCreate.response3}
                        onChange={(e) => handleAddQuestionChange('response3', e.target.value)}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '30px', fontSize: '11px'
                          }, '& .MuiFormLabel-root': {
                            fontSize: "11px"
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eligibilityQuestionCreate.hardStop3}
                            onChange={(e) => handleAddQuestionChange('hardStop3', e.target.checked)}
                          />
                        }
                        label="Hard Stop"
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <TextField
                        label="Option 4"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        size="small"
                        value={eligibilityQuestionCreate.response4}
                        onChange={(e) => handleAddQuestionChange('response4', e.target.value)}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '30px', fontSize: '11px'
                          }, '& .MuiFormLabel-root': {
                            fontSize: "11px"
                          }
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eligibilityQuestionCreate.hardStop4}
                            onChange={(e) => handleAddQuestionChange('hardStop4', e.target.checked)}
                          />
                        }
                        label="Hard Stop"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
          <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary" onClick={handleSubmitModal}>
            Continue
          </Button>
          <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal open={updateModalOpen} onClose={handleUpdateCloseModal}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2>Modify Question</h2>
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={8} lg={8}>
                  <TextField
                    label="Question"
                    placeholder='Enter  question'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={eligibilityQuestionEdit.question}
                    onChange={(e) => handleEditChange('question', e.target.value)}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
               

<FormControl  fullWidth size="small" margin="normal" sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                    <Select
                      labelId="customer-name-label"
                      value={eligibilityQuestionEdit.productType}
                      onChange={(e) => handleEditChange('productType', e.target.value)}
                       MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 50,
                          },
                        },
                      }}
                    >

{
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
} 
                    </Select>
                    <FormHelperText>{errors.type}</FormHelperText>
                  </FormControl>
                </Grid>
             
              </Grid>

              <Grid container spacing={2}>
              <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    label="Type"
                    placeholder='Question Type'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={eligibilityQuestionEdit.type}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

{eligibilityQuestionEdit.type === 'yes/no' && (
 eligibilityQuestionEdit.options.map((option, index) => (
option.optionText === 'No' && (
    <FormControlLabel
    control={
      <Checkbox
        checked={option.hardStop}
        onChange={(e) => handleHardStopChange(index, e.target.checked)}
      />
    }
    label="Hard Stop"
  />
)
 ))
)}

                </Grid>


                <Grid item xs={6} md={6} lg={6}>
               

               <FormControl  fullWidth size="small" margin="normal" sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                                   <Select
                                     labelId="customer-name-label"
                                     value={eligibilityQuestionEdit.identifier}
                                     onChange={(e) => handleEditChange('identifier', e.target.value)}
                                      MenuProps={{
                                       PaperProps: {
                                         style: {
                                           maxHeight: 200,
                                           width: 50,
                                         },
                                       },
                                     }}
                                   >
                                     {allProductKeys.map((item) => (
                                           <MenuItem key={item.lovVal} value={item.lovKey}>
                                           {item.lovKey}
                                         </MenuItem>
                                     ))}
                                   </Select>
                                   <FormHelperText>{errors.identifier}</FormHelperText>
                                 </FormControl>
                               </Grid>

              </Grid>

              {eligibilityQuestionEdit.type === 'range' && (
                <>
                  <Grid container spacing={2}>
                    {eligibilityQuestionEdit.options.map((option, index) => (
                      <Grid item xs={6} md={6} lg={6} key={option.optionId}>
                        <TextField
                          label={`Option ${index + 1}`}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          size="small"
                          value={option.optionText}
                          onChange={(e) => handleAddChange('optionText', e.target.value, index)}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: '30px',
                              fontSize: '11px'
                            }, '& .MuiFormLabel-root': {
                              fontSize: "11px"
                            }
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={option.hardStop}
                              onChange={(e) => handleHardStopChange(index, e.target.checked)}
                            />
                          }
                          label="Hard Stop"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
          <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary" onClick={handleUpdatedModal}>
            Continue
          </Button>
          <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleUpdateCloseModal}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal open={deleteDialogOpen} onClose={handleDeleteDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Paper style={{ padding: '20px', background: '#fff', width: '70vh', height: "auto", padding: "20px 50px", zIndex: 1 }}>
            <h2 style={{ fontSize: 'bold' }}>Delete Question</h2>
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "20px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <h4 style={{ marginLeft: '70px' }}>Are you sure you want to delete ? </h4>
              </Grid>
            </Grid>
            <Button onClick={handleDeleteDialog} variant="contained" color="success" style={{ marginTop: '20px', float: "right" }}>
              Yes
            </Button>
            <Button onClick={handleDeleteDialogClose} variant="contained" style={{ marginTop: '20px', float: "right", marginRight: '10px' }}>
              No
            </Button>
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

export default EligibilityQuestion;
