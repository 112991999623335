// import React, { useState, useEffect } from 'react';
// import axios from '../../api/axios';
// import { Link } from 'react-router-dom';
// // import { message } from 'antd';
// import {
//   Table,
//   Box,
//   Tabs,
//   Tab,
//   TextField,
//   TableBody,
//   TableCell,
//   TableContainer,Snackbar,
//   TableHead,
//   TableRow,
//   Paper,Alert,
//   Modal,
//   InputAdornment,
//   Backdrop,
//   Grid,
//   Button,
//   TablePagination,
// } from '@mui/material';

// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import '../../App.css';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// const UserTable = () => {

//   // Get the permission string from localStorage
//   const permissionString = localStorage.getItem("Permission");

//   // Check if the permissionString is not null or undefined
//   const permissions = permissionString ? permissionString.split(',') : [];

//   // const [messageApi, contextHolder] = message.useMessage();
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [selectedstatus, setSelectedstatus] = useState(null);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//   const [value, setValue] = useState(0); // Tab value
//   const [userId, setuserId] = useState(null);
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [editActiveDialogOpen, setEditActiveDialogOpen] = useState(false);
//   const [editSuspendedDialogOpen, setSuspendedDialogOpen] = useState(false);
//   const [openStatus, setOpenStatus] = React.useState(false);

//   const [userDetails, setUserDetails] = useState({
//     name: '',
//     email: '',
//     password: '',
//     status: 'Y',
//     roleId: ''
//   });


//   const [errors, setErrors] = useState({
//     name: '',
//     email: '',
//     roleId: '',
//   });
  
//   const handleEditClick = (user) => {
//     // console.log(user);
//     setSelectedUser(user);
//     setEditDialogOpen(true);
//   };


//   const handleEditDialogClose = () => {
//     setEditDialogOpen(false);
//     setSelectedUser(null);
//   };


//   const handleEditActiveClick = (user) => {
//     // console.log(user);
//     setSelectedUser(user);
//     setEditActiveDialogOpen(true);
//   };
//   const handleEditActiveDialogClose = () => {
//     setEditActiveDialogOpen(false);
//     setSelectedUser(null);
//   };

//   const handleSuspendedClick = (user) => {
//     console.log(user);
//     handleEditActiveDialogClose(false);
//     handleEditDialogClose(false);
//     setSelectedstatus(user);
//     setSuspendedDialogOpen(true);
//   };

//   const handleSuspendedDialogClose = () => {
//     setSuspendedDialogOpen(false);
//     setSelectedstatus(null);
//   };


//   const handleSuspendedDialogSave =async () => {
   

//     const UserList = {
//       userId: selectedstatus.id,
//       status: selectedstatus.status === 'Y' ? 'N' : 'Y'
//   };
//         console.log(UserList);
    
//         try {
   
//           const response = await axios.post(
//             "/api/user/updateStatus",
//             UserList,
//             {
//               headers: {
//                 "Content-Type": "application/json"
//               },
//             }
//           );
      
        
//           setSuspendedDialogOpen(false);
//           getUsers();
//         console.log("Status Update successfully !");
     
       
//         } catch (err) {
//           if (err.response && err.response.data) {
          
//             console.log("Internal Server Error");
//           } else {
//             console.log("Error occurred. Please try again.")
//           }
//         }
  
//   };

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handleAddChange = (field, value) => {
//     setUserDetails((prevDetails) => ({
//       ...prevDetails,
//       [field]: value,
//     }));
 
//     setErrors((prevState) => ({ ...prevState, [field]: '' }));

//   };


//   useEffect(() => {
//     filterUsers();
//   }, [value, users]); 

  

//   const handleAddClick = async () => {
//     setModalOpen(true);

//   };

//   const handleCreateModal = async () => {
// // console.log(userDetails);
// const newErrors = {};
//     if (!userDetails.name) {
//       newErrors.name = 'Please enter a name.';
//     }
//     if (!userDetails.email) {
//       newErrors.email = 'Please enter an email.';
//     }
//     if (!userDetails.roleId) {
//       newErrors.roleId = 'Please select a role.';
//     }

//     if (Object.keys(newErrors).length === 0) {
      
// try {
   
//   const response = await axios.post(
//     "/api/user/register",
//     userDetails,
//     {
//       headers: {
//         "Content-Type": "application/json"
//       },
//     }
//   );

// // console.log(response.data);

//   setModalOpen(false);
//   setUserDetails({
//     name: '',
//     email: '',
//     roleId: '',
//   });
//   getUsers();
// console.log("User create successfully !");


// } catch (err) {
//   if (err.response && err.response.data) {
//       setOpenStatus(true);
//               setTimeout(()=>{
//                 handleClose();
//               },3000)
//     console.log("Internal Server Error");


//   } else {
//     console.log("Error occurred. Please try again.")
//   }
// }
// } else {
//   // Display validation errors
//   setErrors(newErrors);
// }
//   }



//   const handleUserCloseModal = () =>{
//     setModalOpen(false);

//     setUserDetails({
//       name: '',
//       email: '',
//       roleId: '',
//     });
  
//     // Reset the errors state to empty strings
//     setErrors({
//       name: '',
//       email: '',
//       roleId: '',
//     });
//   }

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };


//   const getUsers = async () => {
//     try {
//       const response = await axios.get("/api/user");
//       // console.log(response?.data)
//       setUsers(response?.data);

//     } catch (error) {
//       console.error("Axios Error:", error);
//     }
//   };

//   useEffect(() => {


//     getUsers();
//   }, []);


//   const handleSearchChange = (event) => {
//     const searchQuery = (event.target.value || '').toLowerCase(); // Add null check here
//     const filtered = filterUsersByTab().filter(user =>
//       user.name && user.name.toLowerCase().includes(searchQuery) ||
//       (user.email && user.email.toLowerCase().includes(searchQuery)) || // Add null check here
//       user.role.name.toLowerCase().includes(searchQuery) 
//     );
//     setFilteredUsers(filtered);
//     setPage(0); // Reset page number when filtering
//   };
  
  


//   const filterUsersByTab = () => {
//     switch (value) {
//       case 0:
//         return users; // Return all users for 'All User' tab
//       case 1:
//         return users.filter(user => user.status === 'N'); // Filter suspended users for 'Suspended' tab
//       case 2:
//         return users.filter(user => user.status === 'Y'); // Filter reinstated users for 'Reinstated' tab
//       default:
//         return users;
//     }
//   };

//   const filterUsers = () => {
//     const filtered = filterUsersByTab();
//     setFilteredUsers(filtered);
//     setPage(0);
//   };



//   const handleClose = () => {

//     setOpenStatus(false);

//   };

//   return (
//     <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
//       {/* {contextHolder} */}
//       <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '2%', paddingBottom: '1%' }}>
//         <h2>Users Management</h2>
//       </div>

//       <Box sx={{ width: '95%', bgcolor: 'background.paper', margin: '2%' }} component={Paper} className="table-container">
//         {/* Tabs for filtering users */}
//         <Tabs value={value} onChange={handleChange}>
//           <Tab label="All User" />
//           <Tab label="Suspended" />
//           <Tab label="Reinstated" />
//         </Tabs>
//       </Box>


//       <div style={{ margin: '2%', marginTop: '3%', width: '95%', }}>
//         <TextField
//           sx={{ width: '40%' }}
//           component={Paper}
//           className="table-container"
//           onChange={handleSearchChange}
//           name='search'
//           placeholder='Search'
//           variant='outlined'
//           size='small'
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <ManageSearchIcon />
//               </InputAdornment>
//             ),
//             endAdornment: (
//               <InputAdornment position="end">
//                 <FilterAltIcon style={{ color: 'blue' }} />
//               </InputAdornment>
//             ),
//           }}
//         />


//         <Button onClick={() => handleAddClick()}
//           variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right' }}>
//           Add User
//           <AddIcon />
//         </Button>



//       </div>
//       <div style={{ margin: '2%', marginTop: '3%', width: '95%', }}>
//         <TableContainer className="table-container" >
//           <Table stickyHeader>
//             <TableHead>
//               <TableRow className="table-heading">
//                 {/* <TableCell>User ID</TableCell> */}
//                 <TableCell>User Name</TableCell>
//                 <TableCell>User Email</TableCell>
//                 <TableCell>User Type</TableCell>
//                 <TableCell>Last Login</TableCell>
//                 <TableCell>Status</TableCell>
//                 <TableCell>Action</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//             {filteredUsers ? (
//   (rowsPerPage > 0
//     ? filteredUsers
//         .sort((a, b) => b.id - a.id) // Sort the array in descending order based on featureId
//         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//     : filteredUsers.sort((a, b) => b.id - a.id) // Sort the array in descending order based on featureId
//   ).map((user) => (
         
//                 <TableRow key={user.id}>
//                   {/* <TableCell>{user.userID}</TableCell> */}
//                   <TableCell>{user.name}</TableCell>
//                   <TableCell>{user.email}</TableCell>
//                   <TableCell>{user.role.name}</TableCell>
//                   <TableCell>{user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : 'N/A'}</TableCell>
                  

//                   {user.status == 'Y' ? (
//                     <TableCell>
//                       <Button variant="contained" color="success" style={{ borderRadius: '20px', fontSize: 'smaller', width: 'max-content' }}>
//                       Active
//                       </Button>
//                     </TableCell>
//                   ) : (
//                     <TableCell>
//                       <Button variant="contained" color="error" style={{ borderRadius: '20px', fontSize: 'smaller', width: 'max-content' }}>
//                         Suspended
//                       </Button>
//                     </TableCell>
//                   )}

//                   <TableCell>
              
//                       {/* <Button color="primary">
//                         View
//                       </Button>
                  
//                       <Button  color="primary">
//                         Edit
//                       </Button> */}
                   
                    
//                         {user.status == 'Y' ? (
                 
//                       <Button  color="primary" onClick={() => handleEditClick(user)}>
//                      Suspended
//                       </Button>
                   
//                   ) : (
             
//                       <Button  color="primary" onClick={() => handleEditActiveClick(user)}>
//                           Reinstated
                       
//                       </Button>
               
//                   )}
                

//                   </TableCell>
//                 </TableRow>
//               ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={6}>Loading...</TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <TablePagination
//         rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
//         component="div"
//         count={filteredUsers.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />

//       </div>




//       <Modal open={modalOpen} onClose={handleUserCloseModal}>
//         <div className="modal-content">
//           <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
//             <h2>Add New User</h2>
//             <hr></hr>
//             <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "30px" }}>
            
//               <Grid container spacing={2} >
//                 <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="User Name"
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={userDetails.name}
//                     onChange={(e) => handleAddChange('name', e.target.value)}
//                     error={!!errors.name}
//                   helperText={errors.name}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>
//                 <Grid item xs={6} md={6} lg={6}>
//                   <TextField
//                     label="Email"
//                     variant="outlined"
//                     margin="normal"
//                     fullWidth
//                     size="small"
//                     value={userDetails.email}
//                     onChange={(e) => handleAddChange('email', e.target.value)}
//                     error={!!errors.email}
//                     helperText={errors.email}
//                     sx={{
//                       '& .MuiInputBase-root': {
//                         height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
//                       }, '& .MuiFormLabel-root': {
//                         fontSize: "11px"
//                       }
//                     }}

//                   />
//                 </Grid>


//               </Grid>
//               <Grid container spacing={2} sx={{ marginBottom: '3%', marginTop: '1%' }}>
//   <Grid item xs={3} sm={3} md={3}>
//     User:
//     <input
//       style={{ height: '16px', width: '44px' }}
//       type="checkbox"
//       checked={userDetails.roleId === '2'} // Check if roleId is for User
//       onChange={() => setUserDetails(prevState => ({ ...prevState, roleId: '2' }))} // Set roleId to 1 for User
//     />
//   </Grid>
//   <Grid item xs={4} sm={4} md={4}>
//     Merchant:
//     <input
//       style={{ height: '16px', width: '44px' }}
//       type="checkbox"
//       checked={userDetails.roleId === '3'} // Check if roleId is for Merchant
//       onChange={() => setUserDetails(prevState => ({ ...prevState, roleId: '3' }))} // Set roleId to 3 for Merchant
//     />
//   </Grid>

//   {errors.roleId && (
//                 <Grid item xs={12}>
//                   <span style={{ color: 'red' }}>{errors.roleId}</span>
//                 </Grid>
//               )}

// </Grid>

//               {/* </> */}
//               {/* )} */}
//             </Grid>
//           </Paper>
//           <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary" onClick={handleCreateModal}>
//             Continue
//           </Button>
//           <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleUserCloseModal}>
//             Cancel
//           </Button>

//         </div>
//       </Modal>


//       <Modal open={editDialogOpen} onClose={handleEditDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
//       <div className="modal-content">
//           <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
//            <h2 style={{textAlign:'center'}}>Suspend Access?</h2>
//            <p style={{textAlign:'center'}}>Are you sure you want to suspend the following Account:</p>
//             <hr></hr>
//            <div style={{textAlign:'center',marginTop:'3%'}}>
// User Name: <span style={{fontWeight:'bold'}}>{selectedUser?.name || ''} </span>
//            </div>
//            <div style={{textAlign:'center'}}>
// Email: <span style={{fontWeight:'bold'}}>{selectedUser?.email || ''} </span>
//            </div>
//            <div style={{textAlign:'center'}}>
// User Role: <span style={{fontWeight:'bold'}}>{selectedUser?.role.name || 'N/A'} </span>
//            </div>
//            <div style={{textAlign:'center'}}>
// Last Login: <span style={{fontWeight:'bold'}}>{selectedUser?.lastLogin ? new Date(selectedUser.lastLogin).toLocaleDateString() : 'N/A'} </span>
//            </div>
//            <div style={{textAlign:'center',marginBottom:'3%'}}>
// Status: <span style={{fontWeight:'bold'}}>{selectedUser?.status || 'N/A'} </span>
//            </div>
           
//            <Button onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
//             Proceed
//           </Button>
//           <Button onClick={handleEditDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
//             Cancel
//           </Button>
//         </Paper>
//       </div>
//     </Modal>

//     <Modal open={editActiveDialogOpen} onClose={handleEditActiveDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
//       <div className="modal-content">
//           <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
//            <h2 style={{textAlign:'center'}}>Reinstate Access?</h2>
//            <p style={{textAlign:'center'}}>Are you sure you want to reinstate the following Account:</p>
//             <hr></hr>
//            <div style={{textAlign:'center',marginTop:'3%'}}>
// User Name: <span style={{fontWeight:'bold'}}>{selectedUser?.name || ''} </span>
//            </div>
//            <div style={{textAlign:'center'}}>
// Email: <span style={{fontWeight:'bold'}}>{selectedUser?.email || ''} </span>
//            </div>
//            <div style={{textAlign:'center'}}>
// User Role: <span style={{fontWeight:'bold'}}>{selectedUser?.role.name || 'N/A'} </span>
//            </div>
//            <div style={{textAlign:'center'}}>
// Last Login: <span style={{fontWeight:'bold'}}>{selectedUser?.lastLogin ? new Date(selectedUser.lastLogin).toLocaleDateString() : 'N/A'} </span>
//            </div>
//            <div style={{textAlign:'center',marginBottom:'3%'}}>
// Status: <span style={{fontWeight:'bold'}}>{selectedUser?.status || 'N/A'} </span>
//            </div>
           
        
//           <Button  onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
//             Proceed
//           </Button>
//           <Button onClick={handleEditActiveDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
//             Cancel
//           </Button>
//         </Paper>
//       </div>
//     </Modal>


//     <Modal open={editSuspendedDialogOpen} onClose={handleSuspendedDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
//       <div className="modal-content">
//           <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
           
//            {selectedstatus?.status != 'Y' ? (
//             <>
//                 <h2 style={{textAlign:'center'}}> Account Reinstated </h2>
//                 <p style={{textAlign:'center'}}>The Account access has been reinstated. User will not be able to perform the assigned function(s)</p>
//             <hr></hr>
//             </>
//              ) : (
//               <>
//               <h2 style={{textAlign:'center'}}> Account Suspended </h2>
//               <p style={{textAlign:'center'}}>The Account access has been suspended. User will not be able to perform the assigned function(s)</p>
//               <hr></hr>   
//               </>
//              )}
           
           
           
//           <Button onClick={() => handleSuspendedDialogSave()} style={{ float: "inline-end", marginRight: '37%', marginTop: '4%' }} variant="contained" color="primary">
//             Proceed
//           </Button>
                    
//         </Paper>
//       </div>
//     </Modal>


//     <Snackbar
//   open={openStatus}
//   autoHideDuration={6000}
//   onClose={handleClose}
//   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
// >
//   <Alert
//     onClose={handleClose}
//     severity="warning"
//     variant="filled"
//     sx={{ width: '100%' }}
//   >
//         This email address is already exists on the systems
//   </Alert>
// </Snackbar>

//     </div>
//   );
// };

// export default UserTable;
// =====
import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../api/axios';
import { Link } from 'react-router-dom';
// import { message } from 'antd';
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,Snackbar,
  TableHead,
  TableRow,
  Paper,Alert,
  Modal,
  InputAdornment,
  Backdrop,
  Grid,
  Button,
  TablePagination,
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import '../../App.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const UserTable = () => {

  const axios = useAxiosInstance();

  // Get the permission string from localStorage
  const permissionString = localStorage.getItem("Permission");

  // Check if the permissionString is not null or undefined
  const permissions = permissionString ? permissionString.split(',') : [];

  // const [messageApi, contextHolder] = message.useMessage();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedstatus, setSelectedstatus] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [value, setValue] = useState(0); // Tab value
  const [userId, setuserId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editActiveDialogOpen, setEditActiveDialogOpen] = useState(false);
  const [editSuspendedDialogOpen, setSuspendedDialogOpen] = useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    password: '',
    status: 'Y',
    roleId: ''
  });


  const [errors, setErrors] = useState({
    name: '',
    email: '',
    roleId: '',
  });
  
  const handleEditClick = (user) => {
    // console.log(user);
    setSelectedUser(user);
    setEditDialogOpen(true);
  };


  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedUser(null);
  };


  const handleEditActiveClick = (user) => {
    // console.log(user);
    setSelectedUser(user);
    setEditActiveDialogOpen(true);
  };
  const handleEditActiveDialogClose = () => {
    setEditActiveDialogOpen(false);
    setSelectedUser(null);
  };

  const handleSuspendedClick = (user) => {
    console.log(user);
    handleEditActiveDialogClose(false);
    handleEditDialogClose(false);
    setSelectedstatus(user);
    setSuspendedDialogOpen(true);
  };

  const handleSuspendedDialogClose = () => {
    setSuspendedDialogOpen(false);
    setSelectedstatus(null);
  };


  const handleSuspendedDialogSave =async () => {
   

    const UserList = {
      userId: selectedstatus.id,
      status: selectedstatus.status === 'Y' ? 'N' : 'Y'
  };
        console.log(UserList);
    
        try {
   
          const response = await axios.post(
            "/api/user/updateStatus",
            UserList,
            {
              headers: {
                "Content-Type": "application/json"
              },
            }
          );
      
        
          setSuspendedDialogOpen(false);
          getUsers();
        console.log("Status Update successfully !");
     
       
        } catch (err) {
          if (err.response && err.response.data) {
          
            console.log("Internal Server Error");
          } else {
            console.log("Error occurred. Please try again.")
          }
        }
  
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
 
    setErrors((prevState) => ({ ...prevState, [field]: '' }));

  };


  useEffect(() => {
    filterUsers();
  }, [value, users]); 

  

  const handleAddClick = async () => {
    setModalOpen(true);

  };

  const handleCreateModal = async () => {
    const newErrors = {};
    if (!userDetails.name) {
      newErrors.name = 'Please enter a name.';
    }
    if (!userDetails.email) {
      newErrors.email = 'Please enter an email.';
    } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
      newErrors.email = 'Please enter a valid email.';
    }
    if (!userDetails.roleId) {
      newErrors.roleId = 'Please select a role.';
    }

    if (Object.keys(newErrors).length === 0) {
      console.log(userDetails)
      try {
    
   
                  const response = await axios.post(
                      "/api/user/register",
                      userDetails,
                      {
                          headers: {
                              "Content-Type": "application/json",
                          },
                      }
                  );
                  console.log(response.data);
         
  

        setModalOpen(false);
        setUserDetails({
          name: '',
          email: '',
          password: '',
          status: 'Y',
          roleId: ''
        });
        getUsers();
        console.log("User create successfully !");
      } catch (err) {
        if (err.response && err.response.data) {
          setOpenStatus(true);
          setTimeout(() => {
            handleClose();
          }, 3000);
          console.log("Internal Server Error");
        } else {
          console.log("Error occurred. Please try again.");
        }
      }
    } else {
      // Display validation errors
      setErrors(newErrors);
    }
  }

  const handleUserCloseModal = () => {
    setModalOpen(false);
    setUserDetails({
      name: '',
      email: '',
      roleId: '',
    });
    setErrors({
      name: '',
      email: '',
      roleId: '',
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUsers = async () => {
    try {
      const response = await axios.get("/api/user");
      setUsers(response?.data);
      console.log(response?.data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSearchChange = (event) => {
    const searchQuery = (event.target.value || '').toLowerCase();
    const filtered = filterUsersByTab().filter(user =>
      user.name && user.name.toLowerCase().includes(searchQuery) ||
      (user.email && user.email.toLowerCase().includes(searchQuery)) ||
      (user.role && user.role.name && user.role.name.toLowerCase().includes(searchQuery)) // Add check for user.role
    );
    setFilteredUsers(filtered);
    setPage(0);
  };
  

  const filterUsersByTab = () => {
    switch (value) {
      case 0:
        return users;
      case 1:
        return users.filter(user => user.status === 'N');
      case 2:
        return users.filter(user => user.status === 'Y');
      default:
        return users;
    }
  };

  const filterUsers = () => {
    const filtered = filterUsersByTab();
    setFilteredUsers(filtered);
    setPage(0);
  };

  const handleClose = () => {
    setOpenStatus(false);
  };

  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
      <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '2%', paddingBottom: '1%' }}>
        <h2>Users Management</h2>
      </div>

      <Box sx={{ width: '95%', bgcolor: 'background.paper', margin: '2%' }} component={Paper} className="table-container">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="All User" />
          <Tab label="Suspended" />
          <Tab label="Reinstated" />
        </Tabs>
      </Box>

      <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>
        <TextField
          sx={{ width: '40%' }}
          component={Paper}
          className="table-container"
          onChange={handleSearchChange}
          name='search'
          placeholder='Search'
          variant='outlined'
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ManageSearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <FilterAltIcon style={{ color: 'blue' }} />
              </InputAdornment>
            ),
          }}
        />

        <Button onClick={() => handleAddClick()}
          variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right' }}>
          Add User
          <AddIcon />
        </Button>
      </div>

      <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>
        <TableContainer className="table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-heading">
                <TableCell>User Name</TableCell>
                <TableCell>User Email</TableCell>
                <TableCell>User Type</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredUsers ? (
  (rowsPerPage > 0
    ? filteredUsers
        .sort((a, b) => b.id - a.id)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredUsers.sort((a, b) => b.id - a.id)
  ).map((user) => (
    <TableRow key={user.id}>
      <TableCell>{user.name ? user.name : ''}</TableCell>
      <TableCell>{user.email ? user.email : ''}</TableCell>
      <TableCell>{user.role && user.role.name ? user.role.name : ''}</TableCell>
      <TableCell>{user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : 'N/A'}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          color={user.status === 'Y' ? "success" : "error"}
          style={{ borderRadius: '20px', fontSize: 'smaller', width: 'max-content' }}
        >
          {user.status === 'Y' ? "Active" : "Suspended"}
        </Button>
      </TableCell>
      <TableCell>
        {user.status === 'Y' ? (
          <Button color="primary" onClick={() => handleEditClick(user)}>
            Suspend
          </Button>
        ) : (
          <Button color="primary" onClick={() => handleEditActiveClick(user)}>
            Reinstate
          </Button>
        )}
      </TableCell>
    </TableRow>
  ))
) : (
  <TableRow>
    <TableCell colSpan={6}>Loading...</TableCell>
  </TableRow>
)}

            </TableBody>  
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Modal open={modalOpen} onClose={handleUserCloseModal}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2>Add New User</h2>
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    label="User Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={userDetails.name}
                    onChange={(e) => handleAddChange('name', e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px',
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={userDetails.email}
                    onChange={(e) => handleAddChange('email', e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px',
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: '3%', marginTop: '1%' }}>
                <Grid item xs={3} sm={3} md={3}>
                  User:
                  <input
                    style={{ height: '16px', width: '44px' }}
                    type="checkbox"
                    checked={userDetails.roleId === '2'}
                    onChange={() => setUserDetails(prevState => ({ ...prevState, roleId: '2' }))}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  Merchant:
                  <input
                    style={{ height: '16px', width: '44px' }}
                    type="checkbox"
                    checked={userDetails.roleId === '3'}
                    onChange={() => setUserDetails(prevState => ({ ...prevState, roleId: '3' }))}
                  />
                </Grid>

                {errors.roleId && (
                  <Grid item xs={12}>
                    <span style={{ color: 'red' }}>{errors.roleId}</span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary" onClick={handleCreateModal}>
            Continue
          </Button>
          <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleUserCloseModal}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal open={editDialogOpen} onClose={handleEditDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2 style={{textAlign:'center'}}>Suspend Access?</h2>
            <p style={{textAlign:'center'}}>Are you sure you want to suspend the following Account:</p>
            <hr></hr>
            <div style={{textAlign:'center',marginTop:'3%'}}>
              User Name: <span style={{fontWeight:'bold'}}>{selectedUser?.name || ''}</span>
            </div>
            <div style={{textAlign:'center'}}>
              Email: <span style={{fontWeight:'bold'}}>{selectedUser?.email || ''}</span>
            </div>
            <div style={{textAlign:'center'}}>
              User Role: <span style={{fontWeight:'bold'}}>{selectedUser?.role.name || 'N/A'}</span>
            </div>
            <div style={{textAlign:'center'}}>
              Last Login: <span style={{fontWeight:'bold'}}>{selectedUser?.lastLogin ? new Date(selectedUser.lastLogin).toLocaleDateString() : 'N/A'}</span>
            </div>
            <div style={{textAlign:'center',marginBottom:'3%'}}>
              Status: <span style={{fontWeight:'bold'}}>{selectedUser?.status || 'N/A'}</span>
            </div>
            <Button onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
              Proceed
            </Button>
            <Button onClick={handleEditDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
              Cancel
            </Button>
          </Paper>
        </div>
      </Modal>

      <Modal open={editActiveDialogOpen} onClose={handleEditActiveDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2 style={{textAlign:'center'}}>Reinstate Access?</h2>
            <p style={{textAlign:'center'}}>Are you sure you want to reinstate the following Account:</p>
            <hr></hr>
            <div style={{textAlign:'center',marginTop:'3%'}}>
              User Name: <span style={{fontWeight:'bold'}}>{selectedUser?.name || ''}</span>
            </div>
            <div style={{textAlign:'center'}}>
              Email: <span style={{fontWeight:'bold'}}>{selectedUser?.email || ''}</span>
            </div>
            <div style={{textAlign:'center'}}>
              User Role: <span style={{fontWeight:'bold'}}>{selectedUser?.role.name || 'N/A'}</span>
            </div>
            <div style={{textAlign:'center'}}>
              Last Login: <span style={{fontWeight:'bold'}}>{selectedUser?.lastLogin ? new Date(selectedUser.lastLogin).toLocaleDateString() : 'N/A'}</span>
            </div>
            <div style={{textAlign:'center',marginBottom:'3%'}}>
              Status: <span style={{fontWeight:'bold'}}>{selectedUser?.status || 'N/A'}</span>
            </div>
            <Button onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
              Proceed
            </Button>
            <Button onClick={handleEditActiveDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
              Cancel
            </Button>
          </Paper>
        </div>
      </Modal>

      <Modal open={editSuspendedDialogOpen} onClose={handleSuspendedDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            {selectedstatus?.status !== 'Y' ? (
              <>
                <h2 style={{textAlign:'center'}}>Account Reinstated</h2>
                <p style={{textAlign:'center'}}>The Account access has been reinstated. User will not be able to perform the assigned function(s)</p>
                <hr></hr>
              </>
            ) : (
              <>
                <h2 style={{textAlign:'center'}}>Account Suspended</h2>
                <p style={{textAlign:'center'}}>The Account access has been suspended. User will not be able to perform the assigned function(s)</p>
                <hr></hr>
              </>
            )}
            <Button onClick={() => handleSuspendedDialogSave()} style={{ float: "inline-end", marginRight: '37%', marginTop: '4%' }} variant="contained" color="primary">
              Proceed
            </Button>
          </Paper>
        </div>
      </Modal>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          This email address already exists in the system
        </Alert>
      </Snackbar>

      <style>
        {`
          @media (max-width: 600px) {
            .MuiTableCell-root {
              padding: 8px;
              font-size: 12px;
            }
            .MuiTextField-root {
              width: 100%;
            }
            .table-container {
              overflow-x: auto;
            }
            .action-container {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-bottom: 10px;
            }
            .add-user-button {
              width: 100%;
            }
            .MuiTab-root {
              font-size: 12px;
              min-width: unset !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default UserTable;
