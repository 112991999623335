import React,{useEffect,useState} from 'react';
import { Table,
    Box,
    Tabs,
    Tab,
    TextField,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Modal,
    MenuItem,
    InputAdornment,
    FormControl,
    Select,
    Backdrop,
    Grid,
    Button,
    TablePagination } from '@mui/material';
    import { Link } from 'react-router-dom';
    import FilterAltIcon from '@mui/icons-material/FilterAlt';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import useAxiosInstance from '../../api/axios';


const currencies = [
  {
    value: 'USD',
    label: 'Credit Card',
  },
  {
    value: 'EUR',
    label: 'October',
  },
  {
    value: 'BTC',
    label: '2023',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];



const MerchantDetails = () => {

  const [productDetails,setProductDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [merchant, setMerchant] = useState([]);



  const axios = useAxiosInstance();


    const handleAddClick = async () => {
      setModalOpen(true);
  
    };

    const handleCloseModal = () => {
      setModalOpen(false);
      setSelectedOption('');
     
    };

      
  const getAllMerchant = async (page, size) => {
    try {

      // const response = await axios.get("/api/merchants");
      const response = await axios.get(`/api/merchants/filter?page=${page}&size=${size}`);
      setMerchant(response?.data.content);
      // console.log(response?.data.content);
      setTotalElements(response.data.totalElements);

    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    getAllMerchant(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0); // Reset to first page
  };


      const [selectedIds, setSelectedIds] = useState([]);

      const handleCheckBox = (id) => {
        const isSelected = selectedIds.includes(id);
      
        let newSelectedIds = [];
        if (isSelected) {
          // Remove the ID if it was previously selected
          newSelectedIds = selectedIds.filter((selectedId) => selectedId !== id);
        } else {
          // Add the ID if it was not previously selected and is not null
          if (id !== null) {
            newSelectedIds = [...selectedIds, id];
          } else {
            newSelectedIds = [...selectedIds]; // No change
          }
        }
      
        setSelectedIds(newSelectedIds);
      };
      
      const [editSuspendedDialogOpen, setSuspendedDialogOpen] = useState(false);

      const handleCheckBoxClick = () =>{
        
      
        setSuspendedDialogOpen(true);
      }
      
      
      const handleSuspendedDialogClose = () => {
        setSuspendedDialogOpen(false);
     
      };

      const handleSuspendedDialogSave =async () => {
  
        console.log(selectedIds);
              try {
         
              await axios.put(
                  `/api/merchants/toggle-status`,
                  selectedIds,
                  {
                    headers: {
                      "Content-Type": "application/json"
                    },
                  }
               
                );
            
                setSelectedIds([]);
                setSuspendedDialogOpen(false);
                getAllMerchant(page, rowsPerPage);
              console.log("Status Update successfully !");
           
             
              } catch (err) {
                if (err.response && err.response.data) {
                
                  console.log("Internal Server Error");
                } else {
                  console.log("Error occurred. Please try again.")
                }
              }
        
        };



        // const [searchQuery, setSearchQuery] = useState('');
        
  const handleSearchChange = async (event) => {
    try {
      const response = await axios.get(`/api/merchants/filter?merchantName=${event.target.value}`);
      setMerchant(response.data.content);
      console.log(response.data.content);

      setTotalElements(response.data.totalElements);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };
      
       
        // const filteredUsers = merchant.filter(user => {
        //   const merchantName = user.merchantName || ''; 
        //   const email = user.email || ''; 
        //   const contactNumber = user.contactNumber || ''; 
        //   const contactPerson = user.contactPerson || ''; 
        //   return merchantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //   email.toLowerCase().includes(searchQuery.toLowerCase()) || 
        //   contactNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //   contactPerson.toLowerCase().includes(searchQuery.toLowerCase()) ;
        // });

 
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
  
    <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'1.5%',paddingBottom:'2%'}}>
      <h2>Merchant Details</h2>
    </div>

    <div style={{margin:'2%',marginTop:'3%',width: '95%',}}>
    <TextField
        sx={{ width: '40%' }}
        component={Paper}
        className="table-container"
        onChange={handleSearchChange}
        name='search'
        placeholder='Search'
        variant='outlined'
        size='small'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ManageSearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
               <FilterAltIcon style={{ color: 'blue' }} />
            </InputAdornment>
          ),
        }}
      />

        </div>

  

    <Link to="/dashboard/addMerchant">
    <Button 
          variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right',marginTop:'2%',marginRight:'3%' ,marginBottom:'2%'}}>
          Add New Merchant(s)
          {/* <AddIcon /> */}
        </Button>
  </Link>

<Button
  onClick={() => handleCheckBoxClick()}
  variant="contained"
  style={{
    backgroundColor: selectedIds.length === 0 ? 'gray' : 'red',
    float: 'right',
    marginTop: '2%',
    marginRight: '3%',
    marginBottom: '2%'
  }}
  disabled={selectedIds.length === 0} // Disable button if no selected IDs
>
  Suspended/Reinstated Merchant(s)
</Button>



{/* <div style={{marginLeft:'4%',marginTop:'8%',backgroundColor:'#f0f0ff',width:'91%'}}>
    <div style={{fontWeight:'bold',fontSize:'19px',padding:'1%'}}>Card A </div>
<span style={{float:'right',color:'blue',marginTop:'-31px',marginRight:'2%',fontWeight:'bold'}}>View Perfomance</span>
</div> */}

<div style={{margin:'2%',marginTop:'3%',width: '95%',}}>
      <TableContainer className="table-container" >
        <Table stickyHeader>
          <TableHead>
            <TableRow className="table-heading">
              <TableCell>Selection 
              {/* <input
      style={{ height: '16px', width: '44px' }}
      type="checkbox"
     
    /> */}

              </TableCell>
              <TableCell>Merchant Code</TableCell>
              <TableCell>Merchant Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Offer Code</TableCell>
              <TableCell>Status</TableCell>
        
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
     

          {merchant.map((user) => (
              <TableRow key={user.merchantId}>
                    <TableCell>
<input
  style={{ height: '16px', width: '44px' }}
  type="checkbox"
  checked={selectedIds.includes(user.merchantId)}
  onChange={() => handleCheckBox(user.merchantId)}
/>

              </TableCell>
                 <TableCell>{user.merchantId}</TableCell>
                 <TableCell>
      <Link to={`/merchant/view/${user.merchantId}`} style={{textDecoration:"", color:"blue"}}>
        {user.merchantName}
      </Link>
    </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.contactNumber}</TableCell>
                <TableCell>{user.contactPerson}</TableCell>
                <TableCell>{user.offerCode}</TableCell>
               
                <TableCell>{user.active != "Y" ? "Suspended"  : "Active"}</TableCell>

                <TableCell>
                  {/* <Link to={`/merchant/view/${user.merchantId}`}>
                      <Button color="primary">
                         View
                      </Button>
                      </Link> */}

                      <Link to={`/merchant/edit/${user.merchantId}`}>
                      <Button color="primary">
                         Edit
                      </Button>
                  </Link>

                  </TableCell>


              </TableRow>
     ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

</div>


<Modal open={editSuspendedDialogOpen} onClose={handleSuspendedDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
      <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
           
          
                <h2 style={{textAlign:'center'}}> Merchant Reinstated/Suspended</h2>
                <p style={{textAlign:'center'}}>Are you sure you want to Reinstated/Suspended the following Merchant.</p>
            <hr></hr>
          
           
           
           
         

          <Button  onClick={() => handleSuspendedDialogSave()} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%',backgroundColor:'red' }} variant="contained">
            Yes
          </Button>
          <Button onClick={handleSuspendedDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
            No
          </Button>
                    
        </Paper>
      </div>
    </Modal>


    <style>
        {`
          @media (max-width: 600px) {
            .button-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
            .button-link, .suspend-button {
              margin-bottom: 10px;
            }
            .MuiTableCell-root {
              padding: 8px;
              font-size: 12px;
            }
            .MuiTextField-root {
              width: 100%;
            }
            .table-container {
              overflow-x: auto;
            }
          }
          .button-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-right: 2%;
            margin-top: 1%;
          }
          .button-link {
            margin-right: 10px;
          }
        `}
      </style>


    </div>
  );
};

export default MerchantDetails;
