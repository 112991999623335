import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  Select,
  TableRow,
  Paper,
  Modal,
  MenuItem,
  InputAdornment,
  Backdrop,
  Typography,
  Grid,
  Button,
  FormHelperText,
  TablePagination,
} from "@mui/material";

import useAxiosInstance from '../../api/axios';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { width } from "@mui/system";

const ProductList = [
  {
    value: 'Card',
    label: 'Card',
  },
  {
    value: 'Loan',
    label: 'Loan',
  }
];


const AddProductFeatures = () => {
  const navigate = useNavigate();
  const [productFeature, setProductFeature] = useState(null);

  const [featureByDefault, setFeatureByDefault] = useState([]);

  const [productFeatureCreate, setProductFeatureCreate] = useState({
    featureId: "",
    featureName: "",
    featureWeight: "",
    productType: "",
    status: "",
    Icon: null,
    subFeatures: [],
  });
  const [validationErrors, setValidationErrors] = useState({
    featureName: "",
    featureWeight: "",
    productType: "",
    featureIcon: "",
  });

  const axios = useAxiosInstance();
  
  const validateFeatures = () => {
    let errors = {};
   
    if (!productFeatureCreate.productType.trim()) {
      errors.productType = "Product type is required.";
    }
    if(productFeatureCreate.productType == 'Card'){ 
      if (!productFeatureCreate.featureName.trim()) {
        errors.featureName = "Feature name is required.";
      }

          if(!productFeatureCreate.featureWeight.trim()) {
              errors.featureWeight = "Feature weight is required.";
           }  
   else if (
      isNaN(productFeatureCreate.featureWeight) ||
      productFeatureCreate.featureWeight < 0 ||
      productFeatureCreate.featureWeight > 100
    ) {
      errors.featureWeight = "Weight must be a number between 0 and 100.";
    }
    if (!productFeatureCreate.Icon) {
      errors.featureIcon = "Feature icon is required.";  // Check if the icon is uploaded
    }
  }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  const handleAddChange = (field, value) => {
    setProductFeatureCreate((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSubmitModal = async () => {
    // Perform validation
    if (!validateFeatures()) {
      return; // Stop the submission if validation fails
    }

 try {

      if (productFeatureCreate.productType === 'Loan') {
 
        console.log(productFeatureCreate)
  //    const MoreLoanFeatures = productFeatureCreate.subFeatures.map(
  //           ({ featureName,images}) => ({
  //             featureName,
  //             images:images.file,
  //             productType: "Loan",
  //             status: "active",
  //           })
  //         )
  // console.log(MoreLoanFeatures)

  productFeatureCreate.subFeatures.forEach(element => {

    console.log(element);
    const addLoanG = {
      featureName: element.featureName,
      productType: "Loan",
              status: "active",
    }

    const formData = new FormData();
    formData.append("feature", JSON.stringify(addLoanG));

    formData.append("image", element.file);

    // console.log(element.images)

  axios.post(
    "/api/productFeatures/addParentFeature",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
    
  });
        


      }else{

        const addQuestion = {
          featureName: productFeatureCreate.featureName,
          featureWeight: productFeatureCreate.featureWeight ,
          productType: productFeatureCreate.productType,
          status: "active",
        };
    
        // console.log(addQuestion);
    
        const formData = new FormData();
        formData.append("feature", JSON.stringify(addQuestion));
    
        formData.append("image", productFeatureCreate.Icon);
    
        // console.log(productFeatureCreate.Icon)

      const response = await axios.post(
        "/api/productFeatures/addParentFeature",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response:", response?.data);

      const { featureId } = response?.data;

      const subFormData = new FormData();
      const subF = {
        parentFeatureId: featureId,
        subFeatures: productFeatureCreate.subFeatures.map(
          ({ featureName, featureWeight }) => ({
            featureName,
            featureWeight,
            productType:'Card',
            status: "active",
          })
        ),
      };

    
      subFormData.append("subFeatures", JSON.stringify(subF));

      productFeatureCreate.subFeatures.forEach((images) => {
        subFormData.append("images", images.file);
        // console.log("Files ", images.file);
      });

      // console.log(subF)

      if (productFeatureCreate.subFeatures.length !== 0) {
        await axios.post("/api/productFeatures/addSubFeatures", subFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    }
      console.log("Product Feature create successfully !");

      setTimeout(() => {
        navigate("/dashboard/productFeatures");
      }, 1000);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log("Internal Server Error");
      } else {
        console.log("Error occurred. Please try again.");
      }
    }

  };

  const handleAddLocationClick = () => {
    setLocations((prevLocations) => [
      ...prevLocations,
      renderLocationGrid(prevLocations.length),
    ]);
  };

  const [subFeatures, setLocations] = useState([]);

  const renderLocationGrid = (index) => {
    const handleAreaChange = (event) => {
      const { value } = event.target;
      setProductFeatureCreate((prevDetails) => {
        const updatedLocations = [...prevDetails.subFeatures];
        if (!updatedLocations[index]) {
          updatedLocations[index] = {};
        }
        updatedLocations[index].featureName = value;
        return { ...prevDetails, subFeatures: updatedLocations };
      });
    };

    const handleLocationChange = (event) => {
      const { value } = event.target;
      setProductFeatureCreate((prevDetails) => {
        const updatedLocations = [...prevDetails.subFeatures];
        if (!updatedLocations[index]) {
          updatedLocations[index] = {}; // Ensure the address object exists
        }
        updatedLocations[index].featureWeight = value;
        return { ...prevDetails, subFeatures: updatedLocations };
      });
    };
 

    const handleImageChange = (event, index) => {
      const file = event.target.files[0];

      if (file && file.type.startsWith("image/")) {
        const formData = new FormData(); // Create a new FormData object
        formData.append("image", file); // Append the file to the FormData object

        // Assuming productFeatureCreate is your state object
        const updatedLocations = [...productFeatureCreate.subFeatures];
        if (!updatedLocations[index]) {
          updatedLocations[index] = {}; // Ensure the sub-feature object exists
        }

        // Store the uploaded file in the state
        updatedLocations[index].file = file;

        // Now, you can update the state with the FormData object and the file
        setProductFeatureCreate((prevDetails) => ({
          ...prevDetails,
          subFeatures: updatedLocations,
          formData: formData, // Store the FormData object in the state if needed
        }));
      } else {
        // Handle non-image file types here if needed
        console.log("Please select an image file.");
        // You can also show an error message to the user or take other actions
      }
    };

    return (  
      <>
        <Grid item xs={4} sm={4} md={4}>
          <TextField
            placeholder="Enter feature "
            label="Feature Name"
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            onChange={handleAreaChange}
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "11px",
              },
              "& .MuiFormLabel-root": {
                fontSize: "11px",
              },
            }}
          />
        </Grid>
       

        {productFeatureCreate.productType === 'Card' && (
        <Grid item xs={4} sm={4} md={4}>
          <TextField
            placeholder="Enter Weightage %"
            type="number"
            label="Weightage %"
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            onChange={handleLocationChange}
            sx={{
              "& .MuiInputBase-root": {
                height: "30px",
                fontSize: "11px",
              },
              "& .MuiFormLabel-root": {
                fontSize: "11px",
              },
            }}
          />
        </Grid>
        )}
        <Grid item xs={4} md={4} lg={4}>
          <Box
            mt={2}
            style={{
              width: "192px",
              height: "75px",
              backgroundColor: "#f0f0f0",
              border: "2px solid #f0f0f0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            {productFeatureCreate.subFeatures[index]?.file ? (
              <img
                src={URL.createObjectURL(
                  productFeatureCreate.subFeatures[index].file
                )}
                alt="Uploaded"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Typography variant="body2" color="textSecondary">
                Select Feature Icon
              </Typography>
            )}
          </Box>
          <Button
            variant="outlined"
            component="label"
            style={{
           
                height: "40px",
                marginTop: "2%",
                width:'65%',
                marginBottom: "0%",
                textTransform: "none",


        
            }}
          >
            Upload Icon
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleImageChange(e, index)}
            />
          </Button>
        </Grid>
      </>
    );
  };

  const handleImageChange = (field, file) => {
    if (file) {
      setProductFeatureCreate((prevDetails) => ({
        ...prevDetails,
        [field]: file,
      }));
    } else {
      console.log("File selection canceled");
    }
  };

  const countKey = localStorage.getItem('X-Country-Key');

  return (
    <div
      style={{
        flexGrow: 1,
        marginTop: "0px",
        position: "absolute",
        right: "0",
        top: "0",
        width: "82%",
      }}
    >
      <div
        style={{
          backgroundColor: "#f3f7f6",
          marginTop: "5%",
          padding: "1.5%",
          paddingBottom: "2%",
        }}
      >
        <h2>Add Product Features</h2>
      </div>

      <div>
        <Paper
          style={{
            padding: "20px",
            background: "#fff",
            width: "85%",
            height: "auto",
            padding: "12px 65px",
            marginLeft: "2%",
            marginTop: "4%",
          }}
        >
          {productFeatureCreate.productType === 'Card'?
          <h2>Main Feature</h2>
          :   
        <h2> Product Type</h2>
        }
          <hr></hr>
          <Grid
            container
            spacing={2}
            sx={{ paddingLeft: "10px", marginTop: "20px" }}
          >
            <Grid container spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
            
            <FormControl
             fullWidth
             size="small"
             margin="normal"
             sx={{
               '& .MuiInputBase-root': { height: '30px', fontSize: '11px' },
               '& .MuiFormLabel-root': { fontSize: '11px' }
             }}
           >
             <Select
               labelId="customer-name-label"
               value={productFeatureCreate.productType}
               error={!!validationErrors.productType}
               onChange={(e) => handleAddChange('productType', e.target.value)}
               displayEmpty
               MenuProps={{
                 PaperProps: {
                   style: {
                     maxHeight: 200,
                     width: 200,
                   },
                 },
               }}
             >
               <MenuItem value="" disabled>
                 Select Product Type
               </MenuItem>

               {
  countKey !== 'AE' ?
  ProductList
    .filter(option => option.value !== "Loan")
    .map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))


                : 

                ProductList.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
                
} 
             </Select>
             <FormHelperText>{validationErrors.productType}</FormHelperText>
           </FormControl>
           
           
                         </Grid>


              <Grid item xs={3} md={3} lg={3}>
              {productFeatureCreate.productType === 'Card' && (
                <TextField
                  label="Feature Name"
                  placeholder="Enter Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  
                  value={productFeatureCreate.featureName}
                  onChange={(e) =>
                    handleAddChange("featureName", e.target.value)
                  }
                  error={!!validationErrors.featureName}
                  helperText={validationErrors.featureName}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
           
                />
                )
               
                }
              </Grid>


              {productFeatureCreate.productType === 'Card' && (
              <Grid item xs={3} md={3} lg={3}>
                <TextField
                  label="Feature Weight"
                  type="number"
                  placeholder="Enter Feature Weight %"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  value={productFeatureCreate.featureWeight}
                  onChange={(e) =>
                    handleAddChange("featureWeight", e.target.value)
                  }
                  error={!!validationErrors.featureWeight}
                  helperText={validationErrors.featureWeight}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>
               )}
   {productFeatureCreate.productType === 'Card' && (
              <Grid className="imageGrid" item xs={3} md={3} lg={3}>
  <Grid item xs={12} sm={12} md={12}>
    <Box mt={2} style={{
        width: '192px',
        height: '75px',
        backgroundColor: '#f0f0f0',
        border: "2px solid #f0f0f0",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    }}>
      {productFeatureCreate.Icon ? (
        <img
          src={URL.createObjectURL(productFeatureCreate.Icon)}
          alt="Uploaded"
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
        />
      ) : (
        <Typography variant="body2" color="textSecondary">
          Select Feature Icon
        </Typography>
      )}
    </Box>
    <Typography variant="caption" color="error">
      {validationErrors.featureIcon}
    </Typography>
    <Button
      variant="outlined"
      component="label"
      style={{ marginTop: '5px', marginBottom: '12px', textTransform: 'none' }}
    >
      Upload Icon
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleImageChange('Icon', e.target.files[0])}
      />
    </Button>
  </Grid>
</Grid>
   )}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                {productFeatureCreate.productType === 'Card' ?
                 <h2 style={{ marginBottom: "2%", marginTop: "5%" }}>
                   Sub Features
               </h2>
                 :   
                <h2 style={{ marginBottom: "2%", marginTop: "5%" }}>
                   Features
                </h2>
}
                <hr></hr>
              </Grid>
            </Grid>

            {subFeatures.map((address, index) => (
              <Grid key={index} spacing={2} container>
                {renderLocationGrid(index)}
              </Grid>
            ))}

            <Grid container spacing={2}>
              {productFeatureCreate.productType === 'Card' ?
              <Button
              onClick={handleAddLocationClick}
              sx={{ float: "left", marginTop: "2%" }}
            >  Add Sub Feature (+)
            </Button>
              :    

              <Button
              onClick={handleAddLocationClick}
              sx={{ float: "left", marginTop: "2%" }}
            >
            Add More Features (+)
            </Button>
             
}
            </Grid>
          </Grid>
        </Paper>
        <Button
          style={{
            float: "right",
            marginTop: "2%",
            marginBottom: "4%",
            marginRight: "2%",
          }}
          variant="contained"
          color="primary"
          onClick={handleSubmitModal}
        >
          Create
        </Button>
        <Link to="/dashboard/productFeatures">
          <Button
            style={{ float: "right", marginRight: "1%", marginTop: "2%" }}
            variant="outlined"
          >
            Back
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AddProductFeatures;
