import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  MenuItem,
  InputAdornment,
  FormControl,
  Select,
  Backdrop,
  Grid,
  Button,
  TablePagination,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import useAxiosInstance from '../../api/axios';

const currencies = [
  {
    value: "USD",
    label: "Credit Card",
  },
  {
    value: "EUR",
    label: "October",
  },
  {
    value: "BTC",
    label: "2023",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  
  const [selectedProductType, setSelectedProductType] = useState('showAll');
  


  const [errors, setErrors] = useState({
    fromDate: false,
    toDate: false
  });
  
  const [allProductType, setAllProductType] = useState([]);

  const axios = useAxiosInstance();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleSearch = async () => {
    const newErrors = {
      fromDate: !fromDate,
      toDate: !toDate
    };

    setErrors(newErrors);

    // If any of the fields have an error, stop the function
    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    try {
      console.log("Formatted From Date:", fromDate);
      console.log("Formatted To Date:", toDate);
      const response = await axios.get('/api/productFeatures/filterProductDetail', {
        params: {
          fromDate,
          toDate,
          productType: selectedProductType
        }
      });
      setProductDetails(response.data); // Assuming the response data is the array of products
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  };
  
  const getAllProductsList = async () => {
    try {
      const response = await axios.get("/api/applications/product-performance");
      // console.log(response?.data)
      setProductDetails(response?.data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const getAllProductType = async () => {
    try {
      const response = await axios.get("/api/application/Lov/productType");
      setAllProductType(response?.data || []); // Set data or empty array on error
    } catch (error) {
      console.error("Axios Error:", error);
      setAllProductType([]); // Set empty array on error
    }
  };

  useEffect(() => {
    getAllProductsList();
    getAllProductType();
  }, []);


  const [filteredQuestions, setFilteredQuestions] = useState(productDetails);

  useEffect(() => {
    setFilteredQuestions(productDetails);
  }, [productDetails]);

  const handleProductTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedProductType(selectedValue);
    console.log(selectedValue)
    if (selectedValue === 'showAll') {
      setFilteredQuestions(productDetails);
      setPage(0);
      
    } else {
      const filteredData = productDetails.filter(question => question.productType === selectedValue);
      setFilteredQuestions(filteredData);
      setPage(0);
    }
  };

  const countKey = localStorage.getItem('X-Country-Key');

  return (
    <div
      style={{
        flexGrow: 1,
        marginTop: "0px",
        position: "absolute",
        right: "0",
        top: "0",
        width: "82%",
      }}
    >
      <div
        style={{
          backgroundColor: "#f3f7f6",
          marginTop: "5%",
          padding: "1.5%",
          paddingBottom: "2%",
        }}
      >
        <h2>Product Details</h2>
      </div>

      <div
        style={{
          padding: "21px",
          paddingTop: "5px",
          paddingBottom: "10px",
          marginTop: "20px",
          width: "93%",
          backgroundColor: "#ffffff",
          marginLeft: "2%",
          borderRadius: "4px",
          boxShadow: "0px 0px 7px 0px #cfc5c56b",
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ paddingLeft: "10px", marginTop: "10px" }}
        >
          <Grid container spacing={4}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Product Type
              </Typography>
              <TextField
                id="filled-select-currency"
                select
                value={selectedProductType}
                onChange={handleProductTypeChange}

                defaultValue="USD"
                variant="filled"
                fullWidth
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    width: "100%",
                    fontSize: "11px",
                    borderRadius: "8px", // Border radius
                    backgroundColor: "white", // Background color
                    border: "1px solid #ccc",
                  },
                  "& .MuiFormLabel-root": {
                    fontSize: "13px",
                  },
                  "& .MuiFilledInput-root": {
                    // Additional style for filled input
                    backgroundColor: "white", // Background color
                  },
                  "& .MuiFilledInput-underline:before": {
                    // Additional style for filled input underline
                    borderBottom: "none", // Remove default underline
                  },
                  "& .MuiFilledInput-underline:after": {
                    // Additional style for filled input underline after focus
                    borderBottom: "none", // Remove default underline
                  },
                }}
              >
                <MenuItem value="showAll">Show All</MenuItem>
{
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
}
              </TextField>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                From Date
              </Typography>
              <TextField
               value={fromDate}
               onChange={(e) => setFromDate(e.target.value)}
               fullWidth
                type="date"
                error={errors.fromDate}
                helperText={errors.fromDate && 'From Date is required'}
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    width: "100%",

                    borderRadius: "8px", // Border radius
                    backgroundColor: "white", // Background color
                    border: "1px solid #ccc",
                  },
                }}
              ></TextField>
            </Grid>

            <Grid item xs={2} md={2} lg={2}>
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                To Date
              </Typography>
              <TextField
               value={toDate}
               onChange={(e) => setToDate(e.target.value)}
                type="date"
                fullWidth
                error={errors.toDate}
                helperText={errors.toDate && 'To Date is required'}
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    width: "100%",

                    borderRadius: "8px", // Border radius
                    backgroundColor: "white", // Background color
                    border: "1px solid #ccc",
                  },
                }}
              ></TextField>
            </Grid>

            <Grid item xs={2} md={2} lg={2}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ marginTop: "17px" }}
                onClick={handleSearch} // Attach the search function to button click

              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Link to="/dashboard/addProduct">
        <Button
          variant="contained"
          style={{
            backgroundColor: "#3e3ecd",
            float: "right",
            marginTop: "2%",
            marginRight: "3%",
            marginBottom: "2%",
          }}
        >
          Add Product(s)
          {/* <AddIcon /> */}
        </Button>
      </Link>

      {/* <div style={{marginLeft:'4%',marginTop:'8%',backgroundColor:'#f0f0ff',width:'91%'}}>
    <div style={{fontWeight:'bold',fontSize:'19px',padding:'1%'}}>Card A </div>
<span style={{float:'right',color:'blue',marginTop:'-31px',marginRight:'2%',fontWeight:'bold'}}>View Perfomance</span>
</div> */}

      <div style={{ margin: "2%", marginTop: "3%", width: "95%" }}>
        <TableContainer className="table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-heading">
                {/* <TableCell>User ID</TableCell> */}
                <TableCell> ID</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Organization Name</TableCell>
                <TableCell>No. of Recommendations</TableCell>
                <TableCell>No. of Selections</TableCell>
                <TableCell>No. of Submissions</TableCell>
                <TableCell>No. of Approvals</TableCell>
                <TableCell>Product Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {(
  (rowsPerPage > 0
    ? filteredQuestions
        .sort((a, b) => b.productId - a.productId)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredQuestions.sort((a, b) => b.productId - a.productId)
  ).map(user => (
                    <TableRow>
                      <TableCell style={{ color: "blue" }}>
                        <Link to={`/product/edit/${user.productId}`}>
                          {user.productId}
                        </Link>
                      </TableCell>
                      <TableCell>{user.productName}</TableCell>
                      <TableCell>{user.organizationName}</TableCell>
                      <TableCell>{user.numberOfRecommendation}</TableCell>
                      <TableCell>{user.selection}</TableCell>
                      <TableCell>{user.submission}</TableCell>
                      <TableCell>{user.approvals}</TableCell>
                      <TableCell>{user.productType}</TableCell>

                      {/* <TableCell>
                  <Link to={`/product/view/${user.productId}`}>
                      <Button color="primary">
                         View
                      </Button>
                      </Link>

                      <Link to={`/product/edit/${user.productId}`}>
                      <Button color="primary">
                         Edit
                      </Button>
                  </Link>

                  </TableCell> */}
                    </TableRow>
                  )))}
             
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          component="div"
          count={filteredQuestions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
