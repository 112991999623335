import React,{useEffect,useState} from 'react';
import { Table,
    Box,
    Tabs,
    Tab,
    TextField,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    FormControl,
    Select,
    TableRow,
    Paper,
    Modal,
    MenuItem,
    InputAdornment,
    Backdrop,
    Typography,
    Grid,
    Button,
    TablePagination } from '@mui/material';

import useAxiosInstance from '../../api/axios';
import { Link } from 'react-router-dom';




const statusList = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Suspend',
    label: 'Suspend',
  }
];


const ProductFeatures = () => {

    const [productFeature,setProductFeature] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [featureByDefault,setFeatureByDefault] = useState([]);
  
    const [allProductType, setAllProductType] = useState([]);
    const [productFeatureCreate,setProductFeatureCreate] = useState({
      featureId:'',
      featureName:'',
      featureWeight:'',
      active:'',
      subFeatures:[]
    });

    const axios = useAxiosInstance();
 
 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const getAllFeaturesList = async () => {
        try {
          const response = await axios.get("/api/productFeatures/getFeatureDetails");
          console.log(response?.data)
          setProductFeature(response?.data);
         
        } catch (error) {
          console.error("Axios Error:", error);
        }
      };

      const getAllProductType = async () => {
        try {
          const response = await axios.get("/api/application/Lov/productType");
          setAllProductType(response?.data || []); // Set data or empty array on error
        } catch (error) {
          console.error("Axios Error:", error);
          setAllProductType([]); // Set empty array on error
        }
      };
    
      useEffect(() => {
        getAllFeaturesList();
        getAllProductType();
      }, []);


      const [selectedUser, setSelectedUser] = useState(null);
      const [editDialogOpen, setEditDialogOpen] = useState(false);
      const [editActiveDialogOpen, setEditActiveDialogOpen] = useState(false);
      const [editSuspendedDialogOpen, setSuspendedDialogOpen] = useState(false);
      const [selectedstatus, setSelectedstatus] = useState(null);

      const handleEditClick = (user) => {
        // console.log(user);
        setSelectedUser(user);
        setEditDialogOpen(true);
      };

      const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setSelectedUser(null);
      };
    
    
      const handleEditActiveClick = (user) => {
        // console.log(user);
        setSelectedUser(user);
        setEditActiveDialogOpen(true);
      };
      const handleEditActiveDialogClose = () => {
        setEditActiveDialogOpen(false);
        setSelectedUser(null);
      };
 

      const handleSuspendedClick = (user) => {
        console.log(user);
        handleEditActiveDialogClose(false);
        handleEditDialogClose(false);
        setSelectedstatus(user);
        setSuspendedDialogOpen(true);
      };
    
      const handleSuspendedDialogClose = () => {
        setSuspendedDialogOpen(false);
        setSelectedstatus(null);
      };


      const handleSuspendedDialogSave =async () => {
   

      //   const UserList = {
      //     userId: selectedstatus.featureId,
      //     active: selectedstatus.active === 'Y' ? 'N' : 'Y'
      // };
      //       console.log(UserList);
        
            try {
       
              const response = await axios.put(
                `/api/productFeatures/${selectedstatus.featureId}/toggle-status`
             
              );
          
            
              setSuspendedDialogOpen(false);
              getAllFeaturesList();
            console.log("Status Update successfully !");
         
           
            } catch (err) {
              if (err.response && err.response.data) {
              
                console.log("Internal Server Error");
              } else {
                console.log("Error occurred. Please try again.")
              }
            }
      
      };


      const [selectedProductType, setSelectedProductType] = useState('showAll');
      const [filteredProductFeature, setFilteredProductFeature] = useState(productFeature);
    
      useEffect(() => {
        // Update filteredProductFeature when productFeature changes
        setFilteredProductFeature(productFeature);
      }, [productFeature]);
    
      const handleProductTypeChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedProductType(selectedValue);
    
        if (selectedValue === 'showAll') {
          setFilteredProductFeature(productFeature); // Show all data
        } else {
          const filteredData = productFeature.filter(question => question.productType === selectedValue);
          setFilteredProductFeature(filteredData);
        }
      };


      const countKey = localStorage.getItem('X-Country-Key');

    
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
  
    <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'1.5%',paddingBottom:'2%'}}>
      <h2>Product Features</h2>
    </div>

    <div style={{
      padding:'21px',
      paddingTop:'5px',
      paddingBottom:'10px',
      marginTop:'20px',
    width: '93%',
    backgroundColor: '#ffffff',
    marginLeft: '2%',
    borderRadius: '4px',
    boxShadow: '0px 0px 7px 0px #cfc5c56b'}}>

<Grid container spacing={1} sx={{paddingLeft:"10px", marginTop:"10px"}}>
       
          <Grid container spacing={4} >
          <Grid item xs={3} md={3} lg={3}>
          <TextField
        id="filled-select-currency"
        select
        label="Select Products"
        value={selectedProductType}
        onChange={handleProductTypeChange}
        variant="filled"
        fullWidth
        size="small"
        sx={{
          '& .MuiInputBase-root': {
            width: '100%',
            fontSize: '11px',
            borderRadius: '8px', // Border radius
            backgroundColor: 'white', // Background color
            border: '1px solid #ccc',
          },
          '& .MuiFormLabel-root': {
            fontSize: "13px"
          },
          '& .MuiFilledInput-root': { // Additional style for filled input
            backgroundColor: 'white', // Background color
          },
          '& .MuiFilledInput-underline:before': { // Additional style for filled input underline
            borderBottom: 'none', // Remove default underline
          },
          '& .MuiFilledInput-underline:after': { // Additional style for filled input underline after focus
            borderBottom: 'none', // Remove default underline
          },
        }}
      >

<MenuItem value="showAll">Show All</MenuItem>
{
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
} 
       
      </TextField>


  </Grid>


 

 
         </Grid>

         </Grid>

    </div>


    <Link to="/dashboard/addProductFeature">
    <Button 
          variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right',marginTop:'2%',marginRight:'3%' }}>
          Add Features
          {/* <AddIcon /> */}
        </Button>
</Link>
        <div style={{ margin: '2%', marginTop: '3%', width: '95%', }}>
        <TableContainer className="table-container" >
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-heading">
                {/* <TableCell>User ID</TableCell> */}
                <TableCell>Feature</TableCell>
                <TableCell>No. of client selecting</TableCell>
                <TableCell>No. of card with this features</TableCell>
                <TableCell>Weightage %</TableCell>
                <TableCell>Product Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredProductFeature ? (
  (rowsPerPage > 0
    ? filteredProductFeature
        .sort((a, b) => b.featureId - a.featureId) // Sort the array in descending order based on featureId
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredProductFeature.sort((a, b) => b.featureId - a.featureId) // Sort the array in descending order based on featureId
  ).map((user) => (
    <TableRow key={user.featureId}>
      <TableCell>{user.featureName}</TableCell>
      <TableCell>{user.clientsWithThisFeature}</TableCell>
      <TableCell>{user.cardsWithThisFeature}</TableCell>
    
      <TableCell>{user.featureWeight}</TableCell>
      <TableCell>{user.productType || 'Null'}</TableCell>
      {user.active == 'Y' ? (
                    <TableCell>
                      <Button variant="contained" color="success" style={{ borderRadius: '20px', fontSize: 'smaller', width: 'max-content' }}>
                      Active
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Button variant="contained" color="error" style={{ borderRadius: '20px', fontSize: 'smaller', width: 'max-content' }}>
                        Suspended
                      </Button>
                    </TableCell>
                  )}
      <TableCell>
        <Link to={`/productFeature/view/${user.featureId}`}>
          <Button color="primary">View</Button>
        </Link>
        <Link to={`/productFeature/edit/${user.featureId}`}>
          <Button color="primary">Edit</Button>
        </Link>

            
        {user.active == 'Y' ? (
                 
                 <Button  color="primary" onClick={() => handleEditClick(user)}>
                Suspended
                 </Button>
              
             ) : (
        
                 <Button  color="primary" onClick={() => handleEditActiveClick(user)}>
                     Reinstated
                  
                 </Button>
          
             )}
        

       
      </TableCell>
    </TableRow>
  ))
) : (
  <TableRow>
    <TableCell colSpan={6}>Loading...</TableCell>
  </TableRow>
)}

</TableBody>

          </Table>
        </TableContainer>

        <TablePagination
  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
  component="div"
  count={productFeature ? productFeature.length : 0} // Ensure productFeature is not null before accessing length
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>

      </div>


    
      <Modal open={editDialogOpen} onClose={handleEditDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
      <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
           <h2 style={{textAlign:'center'}}>Suspend Access?</h2>
           <p style={{textAlign:'center'}}>Are you sure you want to suspend the following Product Features:</p>
            <hr></hr>
       
           <div style={{textAlign:'center',marginTop:'3%'}}>
List: <span style={{fontWeight:'bold'}}>{selectedUser?.featureName || ''} </span>
           </div>
           <div style={{textAlign:'center'}}>
No. of client selecting: <span style={{fontWeight:'bold'}}>50 </span>
           </div>
           <div style={{textAlign:'center'}}>
No. of card: <span style={{fontWeight:'bold'}}>100 </span>
           </div>
           <div style={{textAlign:'center'}}>
Weightage: <span style={{fontWeight:'bold'}}>{selectedUser?.featureWeight} </span>
           </div>
           <div style={{textAlign:'center',marginBottom:'3%'}}>
Status: <span style={{fontWeight:'bold'}}>{selectedUser?.active == 'Y' ? "Active" :"Suspended" } </span>
           </div>
           
           <Button onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
            Proceed
          </Button>
          <Button onClick={handleEditDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
            Cancel
          </Button>
        </Paper>
      </div>
    </Modal>

    <Modal open={editActiveDialogOpen} onClose={handleEditActiveDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
      <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
           <h2 style={{textAlign:'center'}}>Reinstate Access?</h2>
           <p style={{textAlign:'center'}}>Are you sure you want to reinstate the following Product Features:</p>
            <hr></hr>
            <div style={{textAlign:'center',marginTop:'3%'}}>
List: <span style={{fontWeight:'bold'}}>{selectedUser?.featureName || ''} </span>
           </div>
           <div style={{textAlign:'center'}}>
No. of client selecting: <span style={{fontWeight:'bold'}}>50 </span>
           </div>
           <div style={{textAlign:'center'}}>
No. of card: <span style={{fontWeight:'bold'}}>100 </span>
           </div>
           <div style={{textAlign:'center'}}>
Weightage: <span style={{fontWeight:'bold'}}>{selectedUser?.featureWeight} </span>
           </div>
           <div style={{textAlign:'center',marginBottom:'3%'}}>
Status: <span style={{fontWeight:'bold'}}>{selectedUser?.active == 'Y' ? "Active" :"Suspended" } </span>
           </div>
           
        
          <Button  onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
            Proceed
          </Button>
          <Button onClick={handleEditActiveDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
            Cancel
          </Button>
        </Paper>
      </div>
    </Modal>


    <Modal open={editSuspendedDialogOpen} onClose={handleSuspendedDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
      <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
           
           {selectedstatus?.active != 'Y' ? (
            <>
                <h2 style={{textAlign:'center'}}> Product Features Reinstated </h2>
                <p style={{textAlign:'center'}}>The Product Features has been reinstated. User will not be able to perform the assigned function(s)</p>
            <hr></hr>
            </>
             ) : (
              <>
              <h2 style={{textAlign:'center'}}> Product Features Suspended </h2>
              <p style={{textAlign:'center'}}>The Product Features has been suspended. User will not be able to perform the assigned function(s)</p>
              <hr></hr>   
              </>
             )}
           
           
           
          <Button onClick={() => handleSuspendedDialogSave()} style={{ float: "inline-end", marginRight: '37%', marginTop: '4%' }} variant="contained" color="primary">
            Proceed
          </Button>
                    
        </Paper>
      </div>
    </Modal>



    </div>
  );
};

export default ProductFeatures;
