// // import React, { useEffect, useState } from "react";
// // import {
// //   TextField,
// //   Box,
// //   FormControl,
// //   input,
// //   Checkbox,
// //   Alert,
// //   InputLabel,
// //   Select,
// //   MenuItem,
// //   Typography,
// //   Button,
// //   Grid,
// //   Container,
// //   Snackbar,
// //   IconButton,
// //   InputAdornment,
// //   Input,
// // } from "@mui/material";
// // import InputMask from "react-input-mask";

// // import axios from "../../api/axios";
// // import { useNavigate, useParams } from "react-router-dom";
// // import Accordion from "@mui/material/Accordion";
// // import AccordionActions from "@mui/material/AccordionActions";
// // import AccordionSummary from "@mui/material/AccordionSummary";
// // import AccordionDetails from "@mui/material/AccordionDetails";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// // import { Link } from "react-router-dom";
// // import ListProductDetail from "./ListProductDetail";

// // const EmployeeList = [
// //   {
// //     value: "Salaried",
// //     label: "Salaried",
// //   },
// //   {
// //     value: "Self-Employed",
// //     label: "Self - Employed",
// //   },
// // ];

// // const instantIssuanceList = [
// //   {
// //     value: "true",
// //     label: "true",
// //   },
// //   {
// //     value: "false",
// //     label: "false",
// //   },
// // ];

// // const RatingList = [
// //   {
// //     value: "1",
// //     label: "1",
// //   },
// //   {
// //     value: "2",
// //     label: "2",
// //   },
// //   {
// //     value: "3",
// //     label: "3",
// //   },
// //   {
// //     value: "4",
// //     label: "4",
// //   },
// //   {
// //     value: "5",
// //     label: "5",
// //   },
// // ];

// // const ViewProduct = () => {
// //   // const [listData, setListData] = useState([]); // Initialize listData as an array or fetch from API

// //   const [errors, setErrors] = useState({
// //     selectedMainFeatureId: '',
// //     mainFeatureWeightage: '',
// //     selectedSubFeatureId: '',
// //     subFeatureWeightage: ''
// //   });
  

// //   const { Id } = useParams();
// //   const navigate = useNavigate();
// //   const [editProductDetails, setEditProductDetails] = useState(true);
// //   const [editProductFeature, setEditProductFeature] = useState(true);
// //   // const [productDetails, setProductDetails] = useState({});
// //   const [productImage, setProductImage] = useState(null);

// //   const [featureData, setFeatureData] = useState([]);
// //   const [exitsFeatureData, setExitsFeatureData] = useState([]);
// //   const [productFeatureData, setProductFeatureData] = useState([]);
// //   const [combineFeatureData, setCombineFeatureData] = useState([]);

// //   useEffect(() => {
// //     setCombineFeatureData([...exitsFeatureData]);
// //   }, [featureData, exitsFeatureData]);

// //   const [productDetails, setProductDetails] = useState({
// //     productName: "",
// //     organizationName: "",
// //     routingUrl: "",
// //     employmentType: "",
// //     minimumIncome: "",
// //     rating: "",
// //     instantIssuance: "",
// //     incentive: "",
// //     productImage: null,
// //     description: "",
// //     status: "Active",
// //     features: [],
// //   });

// //   const handleChange = (field, value) => {
// //     setProductDetails({
// //       ...productDetails,
// //       [field]: value,
// //     });
// //   };

// //   const handleDocumentUpload = (field, file) => {
// //     if (file) {
// //       setProductDetails((prevDetails) => ({
// //         ...prevDetails,
// //         [field]: file,
// //       }));
// //     } else {
// //       console.log("File selection canceled");
// //     }
// //   };

// //   useEffect(() => {
// //     const getByProductId = async () => {
// //       try {
// //         const response = await axios.get(`/api/products/${Id}`);
// //         const productData = response?.data;

// //         // Set productImage to null
// //         if (productData) {
// //           productData.productImage = null;
// //         }

// //         const response1 = await axios.get(
// //           `/api/products/getProductFeatures/${Id}`
// //         );
// //         const productFeatures = response1?.data;

// //         // setProductDetails(productData);
// //         // setFeatureByDefault(response?.data.productFeatures);
// //         // console.log(productData)

// //         const transformedFeatures = productFeatures.features.map((feature) => ({
// //           mainFeatureId: feature.featureId, // Assuming featureId is the mainFeatureId
// //           mainFeatureNames: feature.featureName, // Assuming featureName is the mainFeatureNames
// //           mainFeatureWeightage: feature.featureWeight || "", // Assuming featureWeight is the mainFeatureWeightage
// //           subFeatures: feature.subFeatures.map((subFeature) => ({
// //             subFeatureId: subFeature.featureId, // Assuming featureId is the subFeatureId
// //             subFeatureName: subFeature.featureName, // Assuming featureName is the subFeatureName
// //             subFeatureWeightage: subFeature.featureWeight || "", // Assuming featureWeight is the subFeatureWeightage
// //           })),
// //         }));

// //         console.log(transformedFeatures);
// //         setProductDetails(response?.data);
// //         setExitsFeatureData(transformedFeatures);
// //         setProductFeatureData(transformedFeatures);
// //       } catch (error) {
// //         console.error("Axios Error:", error);
// //       }
// //     };

// //     getByProductId();
// //   }, [Id]);

// //   useEffect(() => {
// //     const getProductImage = async () => {
// //       try {
// //         const responseImage = await axios.get(`/api/products/image/${Id}`, {
// //           responseType: "blob", // Set responseType to 'blob' to get the image as a Blob object
// //         });
// //         setProductImage(responseImage.data);
// //       } catch (error) {
// //         console.error("Axios Error:", error);
// //       }
// //     };

// //     getProductImage();
// //   }, [Id]);

// //   function transformFeatureData(productId, productFeatureData) {
// //     const transformedData = {
// //       productId: productId,
// //       features: [],
// //     };

// //     productFeatureData.forEach((mainFeature) => {
// //       const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

// //       // Add main feature to transformed data
// //       transformedData.features.push({
// //         featureId: mainFeature.mainFeatureId,
// //         productWeight: mainFeatureWeight,
// //       });

// //       // Add subfeatures to transformed data
// //       if (mainFeature.subFeatures) {
// //         mainFeature.subFeatures.forEach((subFeature) => {
// //           const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
// //           transformedData.features.push({
// //             featureId: subFeature.subFeatureId,
// //             productWeight: subFeatureWeight,
// //           });
// //         });
// //       }
// //     });

// //     return transformedData;
// //   }

// //   const SubmitProductDetails = async () => {
// //     console.log("Submitting the following data:", combineFeatureData); // Log the data being submitted

// //     // Gather primary product information into a FormData object
// //     const formData = new FormData();
// //     const res = {
// //       productId: productDetails.productId,
// //       productName: productDetails.productName,
// //       organizationName: productDetails.organizationName,
// //       routingUrl: productDetails.routingUrl,
// //       employmentType: productDetails.employmentType,
// //       minimumIncome: productDetails.minimumIncome,
// //       rating: productDetails.rating,
// //       instantIssuance: productDetails.instantIssuance,
// //       incentive: productDetails.incentive,
// //       description: productDetails.description,
// //       featureDescription: "",
// //       uniqueProductId: "",
// //       productType: "",
// //       offer: "",
// //       offerWeight: "",
// //       productFeatures: null, // Leave as null if not directly attached
// //     };

// //     // Append product data to FormData
// //     formData.append("productDTO", JSON.stringify(res));
// //     if (productDetails.productImage) {
// //       formData.append("file", productDetails.productImage);
// //     } else {
// //       // If no image selected, append a placeholder
// //       const emptyBlob = new Blob([], { type: "application/octet-stream" });
// //       const file = new File([emptyBlob], "empty.png", { type: "image/png" });
// //       formData.append("file", file);
// //     }

// //     // Submit main product details
// //     try {
// //       const productResponse = await axios.put("/api/products", formData, {
// //         headers: { "Content-Type": "multipart/form-data" },
// //       });

// //       console.log(
// //         "Product details updated successfully!",
// //         productResponse.data
// //       );

// //       // Now, handle updating the feature data
// //       const transformedFeatureData = transformFeatureData(
// //         productDetails.productId,
// //         combineFeatureData
// //       ); // Adjust as needed
// //       console.log("Transformed Data for submission:", transformedFeatureData);

// //       if (transformedFeatureData.features.length) {
// //         try {
// //           const featureResponse = await axios.post(
// //             "/api/products/addProductFeatures",
// //             transformedFeatureData,
// //             {
// //               headers: { "Content-Type": "application/json" },
// //             }
// //           );
// //           console.log(
// //             "POST request for features successful:",
// //             featureResponse.data
// //           );
// //         } catch (featureError) {
// //           console.error(
// //             "Error while making POST request for features:",
// //             featureError
// //           );
// //         }
// //       } else {
// //         console.warn("No features to update.");
// //       }

// //       // Optionally navigate after a successful update
// //       setTimeout(() => {
// //         navigate("/dashboard/productDetails");
// //       }, 1000);
// //     } catch (error) {
// //       console.error("Error in submitting product details:", error);
// //       if (error.response && error.response.data) {
// //         console.log("Internal Server Error:", error.response.data);
// //       } else {
// //         console.log("Error occurred. Please try again.");
// //       }
// //     }
// //   };


// //   const toggleEdit = () => {
// //     setEditProductDetails(false);
// //   };
// //   const toggleFeatureEdit = () => {
// //     setEditProductFeature(false);
// //   };

// //   //----------------------- Features List -----------------//

// //   const [createFeature, setCreateFeature] = React.useState(false);
// //   const [openedStatus, setOpenedStatus] = React.useState(false);
// //   const [openStatus, setOpenStatus] = React.useState(false);
// //   const [updatedOpenStatus, setUpdatedOpenStatus] = React.useState(false);

// //   const [productFeature, setProductFeature] = useState(null);
// //   const [productSubFeature, setProductSubFeature] = useState(null);

// //   const [selectedMainFeatureId, setSelectedMainFeatureId] = useState("");
// //   const [mainFeatureWeightage, setMainFeatureWeightage] = useState("");
// //   const [mainFeatureName, setMainFeatureName] = useState("");

// //   const [selectedSubFeatureId, setSelectedSubFeatureId] = useState("");
// //   const [subFeatureWeightage, setSubFeatureWeightage] = useState("");
// //   const [subFeatureName, setSubFeatureName] = useState("");

// //   const handleMainWeightageChange = (e) => {
// //     const weightageValue = e.target.value;
// //     setMainFeatureWeightage(weightageValue);

// //     // Update errors based on weightageValue
// //     setErrors((prevErrors) => ({
// //       ...prevErrors,
// //       mainFeatureWeightage: "", // Assuming 'mainFeatureWeightage' is the key for this error
// //       // Add more error keys as needed based on your validation logic
// //     }));
// //   };

// //   const handleSubWeightageChange = (e) => {
// //     const weightageValue = e.target.value;
// //     setSubFeatureWeightage(weightageValue);
// //   };

// //   const getAllFeaturesList = async () => {
// //     try {
// //       const response = await axios.get("/api/productFeatures/parentFeatures");
// //       // console.log(response?.data);
// //       setProductFeature(response?.data);
// //     } catch (error) {
// //       console.error("Axios Error:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     getAllFeaturesList();
// //   }, []);

// //   const handleFeatureNameChange = async (e) => {
// //     const selectedFeatureId = e.target.value;
// //     // console.log(selectedFeatureId);
// //     setSelectedMainFeatureId(selectedFeatureId);

// //     setErrors((prevErrors) => ({
// //       ...prevErrors,
// //       selectedMainFeatureId: "", // Assuming 'mainFeatureWeightage' is the key for this error
// //     }));

// //     try {
// //       const response = await axios.get(
// //         `/api/productFeatures/subFeatures/${selectedFeatureId}`
// //       );
// //       const subFeatures = response?.data || [];
// //       setProductSubFeature(subFeatures);
// //       // console.log(subFeatures);

// //       // Set main feature ID and values
// //       const selectedMainFeature = productFeature.find(
// //         (item) => item.featureId === selectedFeatureId
// //       );
// //       if (selectedMainFeature) {
// //         setSelectedMainFeatureId(selectedMainFeature.featureId);
// //         setMainFeatureName(selectedMainFeature.featureName);
// //         // console.log(selectedMainFeature)
// //         // Set other main feature values as needed
// //       }
// //     } catch (error) {
// //       console.error("Axios Error:", error);
// //     }
// //   };

// //   const handleSubFeatureChange = (e) => {
// //     const selectedSubFeatureId = e.target.value;
// //     setSelectedSubFeatureId(selectedSubFeatureId);

// //     const selectedSubFeature = productSubFeature.find(
// //       (item) => item.featureId === selectedSubFeatureId
// //     );
// //     if (selectedSubFeature) {
// //       setSelectedSubFeatureId(selectedSubFeature.featureId);
// //       setSubFeatureName(selectedSubFeature.featureName);
// //       // console.log(selectedSubFeature)
// //       // Set other main feature values as needed
// //     }
// //   };

// //   useEffect(() => {
// //     console.log("Updated Feature Data:", featureData);
// //   }, [featureData]); // Dependency array ensures this effect runs only when featureData changes

// //   const handleClose = () => {
// //     setOpenStatus(false);
// //     setCreateFeature(false);
// //     setUpdatedOpenStatus(false);
// //     setOpenedStatus(false);
// //   };
// //   const handleAddFeature = () => {
// //    // Reset existing errors
// //   const newErrors = {};

// //   // Validate main feature weightage

// //   // Check for empty fields and set errors accordingly
// //   if (!selectedMainFeatureId) {
// //     newErrors.selectedMainFeatureId = 'Main feature is required';
// //   }
// //   if (!mainFeatureWeightage) {
// //     newErrors.mainFeatureWeightage = 'Weightage for main feature is required';
// //   }
// //   if (!selectedSubFeatureId) {
// //     newErrors.selectedSubFeatureId = 'Sub feature selection is required';
// //   }
// //   if (!subFeatureWeightage) {
// //     newErrors.subFeatureWeightage = 'Weightage for sub feature is required';
// //   }

// //   // Update error state with new errors
// //   setErrors(newErrors);
  
// //     // Stop processing if there are errors
// //     if (Object.keys(newErrors).length > 0) {
// //       console.log('All fields are valid, proceed with adding the feature');

// //       setOpenedStatus(true); // Use existing mechanism to show error Snackbar
// //       return; // Exit the function to prevent adding the feature
// //     }
  
// //     // Proceed with adding the feature if validations pass
// //     const existingFeatureIndex = combineFeatureData.findIndex(feature => feature.mainFeatureId === selectedMainFeatureId);
  
// //     if (existingFeatureIndex >= 0) {
// //       // Main feature exists, check sub-feature
// //       const existingFeature = combineFeatureData[existingFeatureIndex];
// //       const subFeatureExists = existingFeature.subFeatures.some(sub => sub.subFeatureId === selectedSubFeatureId);
  
// //       if (!subFeatureExists) {
// //         // Add sub-feature if it doesn't exist
// //         existingFeature.subFeatures.push({
// //           subFeatureId: selectedSubFeatureId,
// //           subFeatureName: subFeatureName,
// //           subFeatureWeightage: subFeatureWeightage,
// //         });
// //         const updatedFeatures = [...combineFeatureData];
// //         updatedFeatures[existingFeatureIndex] = existingFeature;
// //         setCombineFeatureData(updatedFeatures);
// //         setCreateFeature(true); // Trigger Snackbar for success
// //       } else {
// //         // Handle duplicate sub-feature case
// //         setOpenStatus(true); // Already existing, show error
// //       }
// //     } else {
// //       // Add new main feature if it does not exist
// //       const newFeature = {
// //         mainFeatureId: selectedMainFeatureId,
// //         mainFeatureNames: mainFeatureName,
// //         mainFeatureWeightage: mainFeatureWeightage,
// //         subFeatures: [{
// //           subFeatureId: selectedSubFeatureId,
// //           subFeatureName: subFeatureName,
// //           subFeatureWeightage: subFeatureWeightage,
// //         }],
// //       };
// //       setCombineFeatureData([...combineFeatureData, newFeature]);
// //       setCreateFeature(true); // Trigger Snackbar for success
// //     }
  
// //     // Reset form fields after successful addition
// //     setSelectedMainFeatureId('');
// //     setMainFeatureWeightage('');
// //     setMainFeatureName('');
// //     setSelectedSubFeatureId('');
// //     setSubFeatureWeightage('');
// //     setSubFeatureName('');
// //   };
  
  
// //   const [listData, setListData] = useState([]); // Initialize with your actual data
// //   const handleRowRemove = (mainFeatureId) => {
// //     console.log("Before removal:", combineFeatureData); // Log state before removal
// //     const updatedFeatures = combineFeatureData.filter(
// //       (feature) => feature.mainFeatureId !== mainFeatureId
// //     );
// //     setCombineFeatureData(updatedFeatures);
// //     console.log("After removal of main feature:", updatedFeatures); // Log state after removal
// //   };


// //   const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
// //     console.log("Before sub-feature removal:", combineFeatureData); // Log state before removal
// //     const updatedFeatures = combineFeatureData.map((feature) => {
// //       if (feature.mainFeatureId === mainFeatureId) {
// //         const updatedSubFeatures = feature.subFeatures.filter(
// //           (sub) => sub.subFeatureId !== subFeatureId
// //         );
// //         return { ...feature, subFeatures: updatedSubFeatures };
// //       }
// //       return feature;
// //     });
// //     setCombineFeatureData(updatedFeatures);
// //     console.log("After sub-feature removal:", updatedFeatures); // Log state after removal
// //   };

// //   // const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
// //   //   const updatedFeatures = combineFeatureData.map(feature => {
// //   //     if (feature.mainFeatureId === mainFeatureId) {
// //   //       // Filter out the sub-feature to remove
// //   //       const updatedSubFeatures = feature.subFeatures.filter(sub => sub.subFeatureId !== subFeatureId);
// //   //       return {...feature, subFeatures: updatedSubFeatures};
// //   //     }
// //   //     return feature;
// //   //   });
// //   //   setCombineFeatureData(updatedFeatures);
// //   // };

// //   // const handleRowRemove = (mainFeatureId) => {
// //   //   const updatedFeatureData = featureData.filter(feature => feature.mainFeatureId !== mainFeatureId);
// //   //   setFeatureData(updatedFeatureData);
// //   // };

// //   // // Function to remove sub-feature
// //   // const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
// //   //   const updatedFeatureData = featureData.map(feature => {
// //   //     if (feature.mainFeatureId === mainFeatureId) {
// //   //       feature.subFeatures = feature.subFeatures.filter(sub => sub.subFeatureId !== subFeatureId);
// //   //     }
// //   //     return feature;
// //   //   });
// //   //   setFeatureData(updatedFeatureData);
// //   // };

// //   return (
// //     <div
// //       style={{
// //         flexGrow: 1,
// //         marginTop: "0px",
// //         position: "absolute",
// //         right: "0",
// //         top: "0",
// //         width: "82%",
// //       }}
// //     >
// //       <div
// //         style={{
// //           backgroundColor: "#f3f7f6",
// //           marginTop: "5%",
// //           padding: "1.5%",
// //           paddingBottom: "2%",
// //         }}
// //       >
// //         <h2>View Product</h2>
// //       </div>

// //       <div
// //         style={{
// //           padding: "2%",
// //           backgroundColor: "#ffffff",
// //           borderRadius: "4px",
// //           boxShadow: "0px 0px 7px 0px #cfc5c56b",
// //           width: "91%",
// //           marginLeft: "2%",
// //           marginTop: "3%",
// //           marginBottom: "3%",
// //         }}
// //       >
// //         <Container>
// //           <Grid container spacing={2}>
// //             <Grid item xs={6} md={6} lg={6}>
// //               <Typography
// //                 variant="h5"
// //                 gutterBottom
// //                 sx={{
// //                   fontWeight: "bold",
// //                   marginTop: "20px",
// //                 }}
// //               >
// //                 Product Details
// //               </Typography>
// //             </Grid>
// //             <Grid item xs={6} md={6} lg={6}>
// //               {editProductDetails && (
// //                 <Button
// //                   onClick={toggleEdit}
// //                   variant="text"
// //                   style={{
// //                     fontWeight: "bold",
// //                     color: "blue",
// //                     textDecoration: "underline",
// //                     marginTop: "20px",
// //                   }}
// //                 >
// //                   Edit
// //                 </Button>
// //               )}
// //             </Grid>
// //           </Grid>

// //           <Grid
// //             container
// //             spacing={2}
// //             style={{ margin: "5vh 10px 10px 0px" }}
// //             className="form-container"
// //           >
// //             <Grid container spacing={2} item xs={8} md={8} lg={8}>
// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Product Name
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter card name"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px" /* Add other custom styles as needed */,
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails.productName}
// //                   onChange={(e) => handleChange("productName", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Organization Name
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter organization name"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px" /* Add other custom styles as needed */,
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails.organizationName}
// //                   onChange={(e) =>
// //                     handleChange("organizationName", e.target.value)
// //                   }
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Routing Url
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter routingUrl"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px" /* Add other custom styles as needed */,
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.routingUrl || ""}
// //                   onChange={(e) => handleChange("routingUrl", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} md={6} lg={6}>
// //                 <Typography
// //                   variant="subtitle1"
// //                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
// //                 >
// //                   Employee Type
// //                 </Typography>
// //                 <FormControl
// //                   fullWidth
// //                   size="small"
// //                   margin="normal"
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": { fontSize: "11px" },
// //                   }}
// //                 >
// //                   <Select
// //                     labelId="employee-type-label"
// //                     value={productDetails.employmentType}
// //                     onChange={(e) =>
// //                       handleChange("employmentType", e.target.value)
// //                     }
// //                     inputProps={{ readOnly: editProductDetails }}
// //                     MenuProps={{
// //                       PaperProps: {
// //                         style: {
// //                           maxHeight: 200, // Set the maximum height of the dropdown menu
// //                           width: 250, // Set the width of the dropdown menu if needed
// //                         },
// //                       },
// //                     }}
// //                   >
// //                     {EmployeeList.map((item) => (
// //                       <MenuItem key={item.value} value={item.value}>
// //                         {item.label}
// //                       </MenuItem>
// //                     ))}
// //                   </Select>
// //                 </FormControl>
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Minimum Salary
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter Minimum Salary"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px" /* Add other custom styles as needed */,
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.minimumIncome || ""}
// //                   onChange={(e) =>
// //                     handleChange("minimumIncome", e.target.value)
// //                   }
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} md={6} lg={6}>
// //                 <Typography
// //                   variant="subtitle1"
// //                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
// //                 >
// //                   Rating
// //                 </Typography>
// //                 <FormControl
// //                   fullWidth
// //                   size="small"
// //                   margin="normal"
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": { fontSize: "11px" },
// //                   }}
// //                 >
// //                   <Select
// //                     labelId="customer-name-label"
// //                     value={productDetails.rating}
// //                     onChange={(e) => handleChange("rating", e.target.value)}
// //                     inputProps={{ readOnly: editProductDetails }}
// //                     MenuProps={{
// //                       PaperProps: {
// //                         style: {
// //                           maxHeight: 200, // Set the maximum height of the dropdown menu
// //                           width: 250, // Set the width of the dropdown menu if needed
// //                         },
// //                       },
// //                     }}
// //                   >
// //                     {RatingList.map((item) => (
// //                       <MenuItem key={item.value} value={item.value}>
// //                         {item.label}
// //                       </MenuItem>
// //                     ))}
// //                   </Select>
// //                 </FormControl>
// //               </Grid>

// //               <Grid item xs={6} md={6} lg={6}>
// //                 <Typography
// //                   variant="subtitle1"
// //                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
// //                 >
// //                   Instant Issuance
// //                 </Typography>
// //                 <FormControl
// //                   fullWidth
// //                   size="small"
// //                   margin="normal"
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": { fontSize: "11px" },
// //                   }}
// //                 >
// //                   <Select
// //                     labelId="instant-issuance-label"
// //                     value={productDetails.instantIssuance}
// //                     onChange={(e) =>
// //                       handleChange("instantIssuance", e.target.value)
// //                     }
// //                     inputProps={{ readOnly: editProductDetails }}
// //                     MenuProps={{
// //                       PaperProps: {
// //                         style: {
// //                           maxHeight: 200, // Set the maximum height of the dropdown menu
// //                           width: 250, // Set the width of the dropdown menu if needed
// //                         },
// //                       },
// //                     }}
// //                   >
// //                     {instantIssuanceList.map((item) => (
// //                       <MenuItem key={item.value} value={item.value}>
// //                         {item.label}
// //                       </MenuItem>
// //                     ))}
// //                   </Select>
// //                 </FormControl>
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Incentive
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter incentive"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px" /* Add other custom styles as needed */,
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.incentive || ""}
// //                   onChange={(e) => handleChange("incentive", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>
// //               <Grid item xs={12} sm={12} md={12}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Feature Description
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter feature description"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px" /* Add other custom styles as needed */,
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.description || ""}
// //                   onChange={(e) => handleChange("description", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>
// //             </Grid>

// //             <Grid className="imageGrid" item xs={4} md={4} lg={4}>
// //               <Grid item xs={12} sm={12} md={12}>
// //                 <Box
// //                   mt={2}
// //                   style={{
// //                     width: "180px", // Set your desired width
// //                     height: "180px", // Set your desired height
// //                     backgroundColor: "#f0f0f0", // Set your default background color
// //                     border: "2px solid #f0f0f0",
// //                     display: "flex",
// //                     alignItems: "center",
// //                     justifyContent: "center",
// //                     overflow: "hidden",
// //                   }}
// //                 >
// //                   {productDetails.productImage ? (
// //                     <img
// //                       src={URL.createObjectURL(productDetails.productImage)}
// //                       alt="Uploaded"
// //                       style={{
// //                         maxWidth: "100%",
// //                         maxHeight: "100%",
// //                         objectFit: "cover",
// //                       }}
// //                     />
// //                   ) : (
// //                     <img
// //                       src={
// //                         productImage ? URL.createObjectURL(productImage) : ""
// //                       }
// //                       alt="Uploaded"
// //                       style={{
// //                         maxWidth: "100%",
// //                         maxHeight: "100%",
// //                         objectFit: "cover",
// //                       }}
// //                     />
// //                   )}
// //                 </Box>

// //                 {!editProductDetails && (
// //                   <Button
// //                     variant="outlined"
// //                     component="label"
// //                     style={{
// //                       "& .MuiButton-root": {
// //                         height: "40px",
// //                         textTransform: "none",
// //                       },
// //                     }}
// //                   >
// //                     Upload Image
// //                     <input
// //                       type="file"
// //                       accept="image/*"
// //                       style={{ display: "none" }}
// //                       onChange={(e) =>
// //                         handleDocumentUpload("productImage", e.target.files[0])
// //                       }
// //                     />
// //                   </Button>
// //                 )}
// //               </Grid>
// //             </Grid>
// //           </Grid>
// //         </Container>
// //       </div>

// //       {!editProductFeature && (
// //         <div
// //           style={{
// //             padding: "2%",
// //             backgroundColor: "#ffffff",
// //             borderRadius: "4px",
// //             boxShadow: "0px 0px 7px 0px #cfc5c56b",
// //             width: "91%",
// //             marginLeft: "2%",
// //             marginTop: "3%",
// //             marginBottom: "3%",
// //           }}
// //         >
// //          <Container>
// //   <Typography
// //     variant="h5"
// //     gutterBottom
// //     sx={{
// //       fontWeight: "bold",
// //       marginTop: "20px",
// //       marginBottom: "4%",
// //     }}
// //   >
// //     Assigned Product Feature
// //   </Typography>

// //   <Grid container spacing={1} className="form-container">
// //     {/* Main Feature Selection */}
// //     <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
// //       <FormControl   error={!!errors.selectedMainFeatureId}fullWidth size="small" margin="normal" sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": { fontSize: "11px" }}}>
// //         <InputLabel id="main-feature-label">Select Main Feature</InputLabel>
// //         <Select
// //           labelId="main-feature-label"
// //           value={selectedMainFeatureId}
// //           onChange={handleFeatureNameChange}
// //           error={!!errors.selectedMainFeatureId}
// //           helperText={errors.selectedMainFeatureId || 'Select a main feature.'}
// //           MenuProps={{
// //             PaperProps: {
// //               style: {
// //                 maxHeight: 200,
// //                 width: 250,
// //               },
// //             },
// //           }}
// //         >
// //           {productFeature ? productFeature.map((item) => (
// //             <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
// //           )) : <MenuItem disabled>No Main features available</MenuItem>}
// //         </Select>
// //       </FormControl>
// //     </Grid>

// //     {/* Main Feature Weightage */}
// //     <Grid item xs={1.5} sm={1.5} md={1.5}>
// //       <div style={{textAlign: "center", backgroundColor: "#efefef", borderRadius: "4px", padding: "4%", marginRight: "-10%", paddingLeft: "22%"}}>Weightage %</div>
// //     </Grid>
// //     <Grid item xs={1} sm={1} md={1}>
// //       <TextField
// //         required
// //         placeholder="%"
// //         type="number"
// //         fullWidth
// //         sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": {fontSize: "11px"}}}
// //         size="small"
// //         value={mainFeatureWeightage}
// //         onChange={handleMainWeightageChange}
// //         error={!!errors.mainFeatureWeightage}
// //         helperText={errors.mainFeatureWeightage }
// //       />
// //     </Grid>

// //     {/* Sub Feature Selection */}
// //     <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
// //       <FormControl  error={!!errors.selectedSubFeatureId} fullWidth size="small" margin="normal" sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": { fontSize: "11px" }}}>
// //         <InputLabel id="sub-feature-label">Select Sub Feature</InputLabel>
// //         <Select
// //           labelId="sub-feature-label"
// //           value={selectedSubFeatureId}
// //           onChange={handleSubFeatureChange}
// //           error={!!errors.selectedSubFeatureId}
// //           helperText={errors.selectedSubFeatureId || 'Select a sub feature.'}
// //           MenuProps={{
// //             PaperProps: {
// //               style: {
// //                 maxHeight: 200,
// //                 width: 250,
// //               },
// //             },
// //           }}
// //         >
// //           {productSubFeature ? productSubFeature.map((item) => (
// //             <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
// //           )) : <MenuItem disabled>No sub features available</MenuItem>}
// //         </Select>

// //       </FormControl>
// //    {/* <FormControl error={Boolean(errors.selectedSubFeatureId)} fullWidth size="small" margin="normal">
// //   <InputLabel id="sub-feature-label">Select Sub Feature</InputLabel>
// //   <Select
// //       labelId="sub-feature-label"
// //       value={selectedSubFeatureId}
// //       onChange={handleSubFeatureChange}
// //       helperText={errors.selectedSubFeatureId || 'Select a sub feature.'}
// //   >
// //       {productSubFeature ? productSubFeature.map((item) => (
// //           <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
// //       )) : <MenuItem disabled>No sub features available</MenuItem>}
// //   </Select>
// // </FormControl> */}
// //     </Grid>

// //     {/* Sub Feature Weightage */}
// //     <Grid item xs={1.5} sm={1.5} md={1.5}>
// //       <div style={{textAlign: "center", backgroundColor: "#efefef", borderRadius: "4px", padding: "4%", marginRight: "-10%", paddingLeft: "22%"}}>Weightage %</div>
// //     </Grid>
// //     <Grid item xs={1} sm={1} md={1}>
// //     {/* <TextField
// //   placeholder="%"
// //   type="number"
// //   fullWidth
// //   value={subFeatureWeightage}
// //   onChange={handleSubWeightageChange}
// //   error={Boolean(errors.subFeatureWeightage)}
// //   helperText={errors.subFeatureWeightage || 'Enter weightage for the sub feature.'}
// // /> */}

// //       <TextField
// //         placeholder="%"
// //         type="number"
// //         fullWidth
// //         sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": {fontSize: "11px"}}}
// //         size="small"
// //         value={subFeatureWeightage}
// //         onChange={handleSubWeightageChange}
// //         error={!!errors.subFeatureWeightage}
// //   helperText={errors.subFeatureWeightage}
// //       />
// //     </Grid>
// //   </Grid>

// //   {/* Add Feature Button */}
// //   <Grid container spacing={1} className="form-container" sx={{ marginTop: "2%" }}>
// //     <Grid item xs={12} sm={12} md={12} textAlign="right">
// //       <Button variant="contained" onClick={handleAddFeature}>
// //         Add Feature(s)
// //       </Button>
// //     </Grid>
// //   </Grid>
// // </Container>

// //         </div>
// //       )}

// //       <div
// //         style={{
// //           padding: "2%",
// //           backgroundColor: "#ffffff",
// //           borderRadius: "4px",
// //           boxShadow: "0px 0px 7px 0px #cfc5c56b",
// //           width: "91%",
// //           marginLeft: "2%",
// //           marginTop: "3%",
// //           marginBottom: "4%",
// //         }}
// //       >
// //         {/* <div style={{fontWeight:'bold',marginLeft:'1%',fontSize:'23px',marginBottom:'2%'}}> Product Features Details</div> */}

// //         <Grid container spacing={2}>
// //           <Grid item xs={6} md={6} lg={6}>
// //             <Typography
// //               variant="h5"
// //               gutterBottom
// //               sx={{
// //                 fontWeight: "bold",
// //                 marginLeft: "1%",
// //                 fontSize: "23px",
// //                 marginBottom: "2%",
// //               }}
// //             >
// //               Product Features Details
// //             </Typography>
// //           </Grid>
// //           <Grid item xs={6} md={6} lg={6}>
// //             {editProductFeature && (
// //               <Button
// //                 onClick={toggleFeatureEdit}
// //                 variant="text"
// //                 style={{
// //                   fontWeight: "bold",
// //                   color: "blue",
// //                   textDecoration: "underline",
// //                   marginBottom: "2%",
// //                 }}
// //               >
// //                 Edit
// //               </Button>
// //             )}
// //           </Grid>
// //         </Grid>

// //         {/* <ListProductDetail
// //   listData={combineFeatureData}
// //   handleRowRemove={handleRowRemove}
// //   handleSubFeatureRemove={handleSubFeatureRemove}
// // /> */}
// //         {/* <ListProductDetail
// //     listData={combineFeatureData} // assume combineFeatureData is your state managed in ViewProduct
// //     handleRowRemove={handleRowRemove} // function defined in ViewProduct
// //     handleSubFeatureRemove={handleSubFeatureRemove} // function defined in ViewProduct
// // /> */}
// //         <ListProductDetail
// //           listData={combineFeatureData}
// //           handleRowRemove={handleRowRemove}
// //           handleSubFeatureRemove={handleSubFeatureRemove}
// //         />
// //       </div>

// //       {(!editProductDetails || !editProductFeature) && (
// //         <Button
// //           variant="contained"
// //           color="primary"
// //           onClick={SubmitProductDetails}
// //           sx={{
// //             float: "right",
// //             marginTop: "1%",
// //             marginRight: "3%",
// //             marginLeft: "2%",
// //             marginBottom: "3%",
// //           }}
// //         >
// //           Update
// //         </Button>
// //       )}
// //       <Link to="/dashboard/productDetails">
// //         <Button
// //           variant="outlined"
// //           sx={{
// //             float: "right",
// //             marginTop: "1%",
// //             marginBottom: "3%",
// //           }}
// //         >
// //           Back
// //         </Button>
// //       </Link>

// //       <Snackbar
// //         open={createFeature}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="success"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           New Feature Added!
// //         </Alert>
// //       </Snackbar>
// //       <Snackbar
// //         open={openedStatus}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="warning"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           Selected main feature and sub-feature.
// //         </Alert>
// //       </Snackbar>
// //       <Snackbar
// //         open={openStatus}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="warning"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           Selected sub-feature already exists under this main feature.
// //         </Alert>
// //       </Snackbar>

// //       <Snackbar
// //         open={updatedOpenStatus}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="neutral"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           Existing Feature Updated!
// //         </Alert>
// //       </Snackbar>
// //     </div>
// //   );
// // };

// // export default ViewProduct;
// // ===================
// // import React, { useEffect, useState } from "react";
// // import {
// //   TextField,
// //   Box,
// //   FormControl,
// //   input,
// //   Checkbox,
// //   Alert,
// //   InputLabel,
// //   Select,
// //   MenuItem,
// //   Typography,
// //   Button,
// //   Grid,
// //   Container,
// //   Snackbar,
// //   IconButton,
// //   InputAdornment,
// //   Input,
// // } from "@mui/material";
// // import InputMask from "react-input-mask";
// // import axios from "../../api/axios";
// // import { useNavigate, useParams } from "react-router-dom";
// // import Accordion from "@mui/material/Accordion";
// // import AccordionActions from "@mui/material/AccordionActions";
// // import AccordionSummary from "@mui/material/AccordionSummary";
// // import AccordionDetails from "@mui/material/AccordionDetails";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// // import { Link } from "react-router-dom";
// // import ListProductDetail from "./ListProductDetail";

// // const EmployeeList = [
// //   {
// //     value: "Salaried",
// //     label: "Salaried",
// //   },
// //   {
// //     value: "Self-Employed",
// //     label: "Self - Employed",
// //   },
// // ];

// // const instantIssuanceList = [
// //   {
// //     value: "true",
// //     label: "true",
// //   },
// //   {
// //     value: "false",
// //     label: "false",
// //   },
// // ];

// // const RatingList = [
// //   {
// //     value: "1",
// //     label: "1",
// //   },
// //   {
// //     value: "2",
// //     label: "2",
// //   },
// //   {
// //     value: "3",
// //     label: "3",
// //   },
// //   {
// //     value: "4",
// //     label: "4",
// //   },
// //   {
// //     value: "5",
// //     label: "5",
// //   },
// // ];

// // const ViewProduct = () => {
// //   const [errors, setErrors] = useState({
// //     selectedMainFeatureId: '',
// //     mainFeatureWeightage: '',
// //     selectedSubFeatureId: '',
// //     subFeatureWeightage: ''
// //   });
  
// //   const { Id } = useParams();
// //   const navigate = useNavigate();
// //   const [editProductDetails, setEditProductDetails] = useState(true);
// //   const [editProductFeature, setEditProductFeature] = useState(true);
// //   const [productImage, setProductImage] = useState(null);

// //   const [featureData, setFeatureData] = useState([]);
// //   const [exitsFeatureData, setExitsFeatureData] = useState([]);
// //   const [productFeatureData, setProductFeatureData] = useState([]);
// //   const [combineFeatureData, setCombineFeatureData] = useState([]);

// //   useEffect(() => {
// //     setCombineFeatureData([...exitsFeatureData]);
// //   }, [featureData, exitsFeatureData]);

// //   const [productDetails, setProductDetails] = useState({
// //     productName: "",
// //     organizationName: "",
// //     routingUrl: "",
// //     employmentType: "",
// //     minimumIncome: "",
// //     rating: "",
// //     instantIssuance: "",
// //     incentive: "",
// //     productImage: null,
// //     description: "",
// //     status: "Active",
// //     features: [],
// //   });

// //   const handleChange = (field, value) => {
// //     setProductDetails({
// //       ...productDetails,
// //       [field]: value,
// //     });
// //   };

// //   const handleDocumentUpload = (field, file) => {
// //     if (file) {
// //       setProductDetails((prevDetails) => ({
// //         ...prevDetails,
// //         [field]: file,
// //       }));
// //     } else {
// //       console.log("File selection canceled");
// //     }
// //   };

// //   useEffect(() => {
// //     const getByProductId = async () => {
// //       try {
// //         const response = await axios.get(`/api/products/${Id}`);
// //         const productData = response?.data;

// //         if (productData) {
// //           productData.productImage = null;
// //         }

// //         const response1 = await axios.get(
// //           `/api/products/getProductFeatures/${Id}`
// //         );
// //         const productFeatures = response1?.data;

// //         const transformedFeatures = productFeatures.features.map((feature) => ({
// //           mainFeatureId: feature.featureId,
// //           mainFeatureNames: feature.featureName,
// //           mainFeatureWeightage: feature.featureWeight || "",
// //           subFeatures: feature.subFeatures.map((subFeature) => ({
// //             subFeatureId: subFeature.featureId,
// //             subFeatureName: subFeature.featureName,
// //             subFeatureWeightage: subFeature.featureWeight || "",
// //           })),
// //         }));

// //         setProductDetails(response?.data);
// //         setExitsFeatureData(transformedFeatures);
// //         setProductFeatureData(transformedFeatures);
// //       } catch (error) {
// //         console.error("Axios Error:", error);
// //       }
// //     };

// //     getByProductId();
// //   }, [Id]);

// //   useEffect(() => {
// //     const getProductImage = async () => {
// //       try {
// //         const responseImage = await axios.get(`/api/products/image/${Id}`, {
// //           responseType: "blob",
// //         });
// //         setProductImage(responseImage.data);
// //       } catch (error) {
// //         console.error("Axios Error:", error);
// //       }
// //     };

// //     getProductImage();
// //   }, [Id]);

// //   function transformFeatureData(productId, productFeatureData) {
// //     const transformedData = {
// //       productId: productId,
// //       features: [],
// //     };

// //     productFeatureData.forEach((mainFeature) => {
// //       const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

// //       transformedData.features.push({
// //         featureId: mainFeature.mainFeatureId,
// //         productWeight: mainFeatureWeight,
// //       });

// //       if (mainFeature.subFeatures) {
// //         mainFeature.subFeatures.forEach((subFeature) => {
// //           const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
// //           transformedData.features.push({
// //             featureId: subFeature.subFeatureId,
// //             productWeight: subFeatureWeight,
// //           });
// //         });
// //       }
// //     });

// //     return transformedData;
// //   }

// //   const SubmitProductDetails = async () => {
// //     const formData = new FormData();
// //     const res = {
// //       productId: productDetails.productId,
// //       productName: productDetails.productName,
// //       organizationName: productDetails.organizationName,
// //       routingUrl: productDetails.routingUrl,
// //       employmentType: productDetails.employmentType,
// //       minimumIncome: productDetails.minimumIncome,
// //       rating: productDetails.rating,
// //       instantIssuance: productDetails.instantIssuance,
// //       incentive: productDetails.incentive,
// //       description: productDetails.description,
// //       featureDescription: "",
// //       uniqueProductId: "",
// //       productType: "",
// //       offer: "",
// //       offerWeight: "",
// //       productFeatures: null,
// //     };

// //     formData.append("productDTO", JSON.stringify(res));
// //     if (productDetails.productImage) {
// //       formData.append("file", productDetails.productImage);
// //     } else {
// //       const emptyBlob = new Blob([], { type: "application/octet-stream" });
// //       const file = new File([emptyBlob], "empty.png", { type: "image/png" });
// //       formData.append("file", file);
// //     }

// //     try {
// //       const productResponse = await axios.put("/api/products", formData, {
// //         headers: { "Content-Type": "multipart/form-data" },
// //       });

// //       const transformedFeatureData = transformFeatureData(
// //         productDetails.productId,
// //         combineFeatureData
// //       );

// //       if (transformedFeatureData.features.length) {
// //         try {
// //           const featureResponse = await axios.post(
// //             "/api/products/addProductFeatures",
// //             transformedFeatureData,
// //             {
// //               headers: { "Content-Type": "application/json" },
// //             }
// //           );
// //           console.log("POST request for features successful:", featureResponse.data);
// //         } catch (featureError) {
// //           console.error("Error while making POST request for features:", featureError);
// //         }
// //       } else {
// //         console.warn("No features to update.");
// //       }

// //       setTimeout(() => {
// //         navigate("/dashboard/productDetails");
// //       }, 1000);
// //     } catch (error) {
// //       console.error("Error in submitting product details:", error);
// //       if (error.response && error.response.data) {
// //         console.log("Internal Server Error:", error.response.data);
// //       } else {
// //         console.log("Error occurred. Please try again.");
// //       }
// //     }
// //   };

// //   const toggleEdit = () => {
// //     setEditProductDetails(false);
// //   };
// //   const toggleFeatureEdit = () => {
// //     setEditProductFeature(false);
// //   };

// //   const [createFeature, setCreateFeature] = useState(false);
// //   const [openedStatus, setOpenedStatus] = useState(false);
// //   const [openStatus, setOpenStatus] = useState(false);
// //   const [updatedOpenStatus, setUpdatedOpenStatus] = useState(false);

// //   const [productFeature, setProductFeature] = useState(null);
// //   const [productSubFeature, setProductSubFeature] = useState(null);

// //   const [selectedMainFeatureId, setSelectedMainFeatureId] = useState("");
// //   const [mainFeatureWeightage, setMainFeatureWeightage] = useState("");
// //   const [mainFeatureName, setMainFeatureName] = useState("");

// //   const [selectedSubFeatureId, setSelectedSubFeatureId] = useState("");
// //   const [subFeatureWeightage, setSubFeatureWeightage] = useState("");
// //   const [subFeatureName, setSubFeatureName] = useState("");

// //   const handleMainWeightageChange = (e) => {
// //     const weightageValue = e.target.value;
// //     setMainFeatureWeightage(weightageValue);

// //     setErrors((prevErrors) => ({
// //       ...prevErrors,
// //       mainFeatureWeightage: "",
// //     }));
// //   };

// //   const handleSubWeightageChange = (e) => {
// //     const weightageValue = e.target.value;
// //     setSubFeatureWeightage(weightageValue);
// //   };

// //   const getAllFeaturesList = async () => {
// //     try {
// //       const response = await axios.get("/api/productFeatures/parentFeatures");
// //       setProductFeature(response?.data);
// //     } catch (error) {
// //       console.error("Axios Error:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     getAllFeaturesList();
// //   }, []);

// //   const handleFeatureNameChange = async (e) => {
// //     const selectedFeatureId = e.target.value;
// //     setSelectedMainFeatureId(selectedFeatureId);

// //     setErrors((prevErrors) => ({
// //       ...prevErrors,
// //       selectedMainFeatureId: "",
// //     }));

// //     try {
// //       const response = await axios.get(
// //         `/api/productFeatures/subFeatures/${selectedFeatureId}`
// //       );
// //       const subFeatures = response?.data || [];
// //       setProductSubFeature(subFeatures);

// //       const selectedMainFeature = productFeature.find(
// //         (item) => item.featureId === selectedFeatureId
// //       );
// //       if (selectedMainFeature) {
// //         setSelectedMainFeatureId(selectedMainFeature.featureId);
// //         setMainFeatureName(selectedMainFeature.featureName);
// //       }
// //     } catch (error) {
// //       console.error("Axios Error:", error);
// //     }
// //   };

// //   const handleSubFeatureChange = (e) => {
// //     const selectedSubFeatureId = e.target.value;
// //     setSelectedSubFeatureId(selectedSubFeatureId);

// //     const selectedSubFeature = productSubFeature.find(
// //       (item) => item.featureId === selectedSubFeatureId
// //     );
// //     if (selectedSubFeature) {
// //       setSelectedSubFeatureId(selectedSubFeature.featureId);
// //       setSubFeatureName(selectedSubFeature.featureName);
// //     }
// //   };

// //   useEffect(() => {
// //     console.log("Updated Feature Data:", featureData);
// //   }, [featureData]);

// //   const handleClose = () => {
// //     setOpenStatus(false);
// //     setCreateFeature(false);
// //     setUpdatedOpenStatus(false);
// //     setOpenedStatus(false);
// //   };

// //   const handleAddFeature = () => {
// //     const newErrors = {};

// //     if (!selectedMainFeatureId) {
// //       newErrors.selectedMainFeatureId = 'Main feature is required';
// //     }
// //     if (!mainFeatureWeightage) {
// //       newErrors.mainFeatureWeightage = 'Weightage for main feature is required';
// //     }
// //     if (!selectedSubFeatureId) {
// //       newErrors.selectedSubFeatureId = 'Sub feature selection is required';
// //     }
// //     if (!subFeatureWeightage) {
// //       newErrors.subFeatureWeightage = 'Weightage for sub feature is required';
// //     }

// //     setErrors(newErrors);

// //     if (Object.keys(newErrors).length > 0) {
// //       setOpenedStatus(true);
// //       return;
// //     }

// //     const existingFeatureIndex = combineFeatureData.findIndex(feature => feature.mainFeatureId === selectedMainFeatureId);

// //     if (existingFeatureIndex >= 0) {
// //       const existingFeature = combineFeatureData[existingFeatureIndex];
// //       const subFeatureExists = existingFeature.subFeatures.some(sub => sub.subFeatureId === selectedSubFeatureId);

// //       if (!subFeatureExists) {
// //         existingFeature.subFeatures.push({
// //           subFeatureId: selectedSubFeatureId,
// //           subFeatureName: subFeatureName,
// //           subFeatureWeightage: subFeatureWeightage,
// //         });
// //         const updatedFeatures = [...combineFeatureData];
// //         updatedFeatures[existingFeatureIndex] = existingFeature;
// //         setCombineFeatureData(updatedFeatures);
// //         setCreateFeature(true);
// //       } else {
// //         setOpenStatus(true);
// //       }
// //     } else {
// //       const newFeature = {
// //         mainFeatureId: selectedMainFeatureId,
// //         mainFeatureNames: mainFeatureName,
// //         mainFeatureWeightage: mainFeatureWeightage,
// //         subFeatures: [{
// //           subFeatureId: selectedSubFeatureId,
// //           subFeatureName: subFeatureName,
// //           subFeatureWeightage: subFeatureWeightage,
// //         }],
// //       };
// //       setCombineFeatureData([...combineFeatureData, newFeature]);
// //       setCreateFeature(true);
// //     }

// //     setSelectedMainFeatureId('');
// //     setMainFeatureWeightage('');
// //     setMainFeatureName('');
// //     setSelectedSubFeatureId('');
// //     setSubFeatureWeightage('');
// //     setSubFeatureName('');
// //   };

// //   const [listData, setListData] = useState([]);
// //   const handleRowRemove = (mainFeatureId) => {
// //     const updatedFeatures = combineFeatureData.filter(
// //       (feature) => feature.mainFeatureId !== mainFeatureId
// //     );
// //     setCombineFeatureData(updatedFeatures);
// //     setEditProductFeature(false); // Ensure the update button becomes visible
// //   };

// //   const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
// //     const updatedFeatures = combineFeatureData.map((feature) => {
// //       if (feature.mainFeatureId === mainFeatureId) {
// //         const updatedSubFeatures = feature.subFeatures.filter(
// //           (sub) => sub.subFeatureId !== subFeatureId
// //         );
// //         return { ...feature, subFeatures: updatedSubFeatures };
// //       }
// //       return feature;
// //     });
// //     setCombineFeatureData(updatedFeatures);
// //     setEditProductFeature(false); // Ensure the update button becomes visible
// //   };

// //   return (
// //     <div
// //       style={{
// //         flexGrow: 1,
// //         marginTop: "0px",
// //         position: "absolute",
// //         right: "0",
// //         top: "0",
// //         width: "82%",
// //       }}
// //     >
// //       <div
// //         style={{
// //           backgroundColor: "#f3f7f6",
// //           marginTop: "5%",
// //           padding: "1.5%",
// //           paddingBottom: "2%",
// //         }}
// //       >
// //         <h2>View Product</h2>
// //       </div>

// //       <div
// //         style={{
// //           padding: "2%",
// //           backgroundColor: "#ffffff",
// //           borderRadius: "4px",
// //           boxShadow: "0px 0px 7px 0px #cfc5c56b",
// //           width: "91%",
// //           marginLeft: "2%",
// //           marginTop: "3%",
// //           marginBottom: "3%",
// //         }}
// //       >
// //         <Container>
// //           <Grid container spacing={2}>
// //             <Grid item xs={6} md={6} lg={6}>
// //               <Typography
// //                 variant="h5"
// //                 gutterBottom
// //                 sx={{
// //                   fontWeight: "bold",
// //                   marginTop: "20px",
// //                 }}
// //               >
// //                 Product Details
// //               </Typography>
// //             </Grid>
// //             <Grid item xs={6} md={6} lg={6}>
// //               {editProductDetails && (
// //                 <Button
// //                   onClick={toggleEdit}
// //                   variant="text"
// //                   style={{
// //                     fontWeight: "bold",
// //                     color: "blue",
// //                     textDecoration: "underline",
// //                     marginTop: "20px",
// //                   }}
// //                 >
// //                   Edit
// //                 </Button>
// //               )}
// //             </Grid>
// //           </Grid>

// //           <Grid
// //             container
// //             spacing={2}
// //             style={{ margin: "5vh 10px 10px 0px" }}
// //             className="form-container"
// //           >
// //             <Grid container spacing={2} item xs={8} md={8} lg={8}>
// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Product Name
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter card name"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails.productName}
// //                   onChange={(e) => handleChange("productName", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Organization Name
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter organization name"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails.organizationName}
// //                   onChange={(e) =>
// //                     handleChange("organizationName", e.target.value)
// //                   }
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Routing Url
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter routingUrl"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.routingUrl || ""}
// //                   onChange={(e) => handleChange("routingUrl", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} md={6} lg={6}>
// //                 <Typography
// //                   variant="subtitle1"
// //                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
// //                 >
// //                   Employee Type
// //                 </Typography>
// //                 <FormControl
// //                   fullWidth
// //                   size="small"
// //                   margin="normal"
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": { fontSize: "11px" },
// //                   }}
// //                 >
// //                   <Select
// //                     labelId="employee-type-label"
// //                     value={productDetails.employmentType}
// //                     onChange={(e) =>
// //                       handleChange("employmentType", e.target.value)
// //                     }
// //                     inputProps={{ readOnly: editProductDetails }}
// //                     MenuProps={{
// //                       PaperProps: {
// //                         style: {
// //                           maxHeight: 200,
// //                           width: 250,
// //                         },
// //                       },
// //                     }}
// //                   >
// //                     {EmployeeList.map((item) => (
// //                       <MenuItem key={item.value} value={item.value}>
// //                         {item.label}
// //                       </MenuItem>
// //                     ))}
// //                   </Select>
// //                 </FormControl>
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Minimum Salary
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter Minimum Salary"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.minimumIncome || ""}
// //                   onChange={(e) =>
// //                     handleChange("minimumIncome", e.target.value)
// //                   }
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>

// //               <Grid item xs={6} md={6} lg={6}>
// //                 <Typography
// //                   variant="subtitle1"
// //                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
// //                 >
// //                   Rating
// //                 </Typography>
// //                 <FormControl
// //                   fullWidth
// //                   size="small"
// //                   margin="normal"
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": { fontSize: "11px" },
// //                   }}
// //                 >
// //                   <Select
// //                     labelId="customer-name-label"
// //                     value={productDetails.rating}
// //                     onChange={(e) => handleChange("rating", e.target.value)}
// //                     inputProps={{ readOnly: editProductDetails }}
// //                     MenuProps={{
// //                       PaperProps: {
// //                         style: {
// //                           maxHeight: 200,
// //                           width: 250,
// //                         },
// //                       },
// //                     }}
// //                   >
// //                     {RatingList.map((item) => (
// //                       <MenuItem key={item.value} value={item.value}>
// //                         {item.label}
// //                       </MenuItem>
// //                     ))}
// //                   </Select>
// //                 </FormControl>
// //               </Grid>

// //               <Grid item xs={6} md={6} lg={6}>
// //                 <Typography
// //                   variant="subtitle1"
// //                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
// //                 >
// //                   Instant Issuance
// //                 </Typography>
// //                 <FormControl
// //                   fullWidth
// //                   size="small"
// //                   margin="normal"
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": { fontSize: "11px" },
// //                   }}
// //                 >
// //                   <Select
// //                     labelId="instant-issuance-label"
// //                     value={productDetails.instantIssuance}
// //                     onChange={(e) =>
// //                       handleChange("instantIssuance", e.target.value)
// //                     }
// //                     inputProps={{ readOnly: editProductDetails }}
// //                     MenuProps={{
// //                       PaperProps: {
// //                         style: {
// //                           maxHeight: 200,
// //                           width: 250,
// //                         },
// //                       },
// //                     }}
// //                   >
// //                     {instantIssuanceList.map((item) => (
// //                       <MenuItem key={item.value} value={item.value}>
// //                         {item.label}
// //                       </MenuItem>
// //                     ))}
// //                   </Select>
// //                 </FormControl>
// //               </Grid>

// //               <Grid item xs={6} sm={6} md={6}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Incentive
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter incentive"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.incentive || ""}
// //                   onChange={(e) => handleChange("incentive", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>
// //               <Grid item xs={12} sm={12} md={12}>
// //                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
// //                   Feature Description
// //                 </Typography>
// //                 <TextField
// //                   placeholder="Enter feature description"
// //                   fullWidth
// //                   sx={{
// //                     "& .MuiInputBase-root": {
// //                       height: "30px",
// //                       fontSize: "11px",
// //                     },
// //                     "& .MuiFormLabel-root": {
// //                       fontSize: "11px",
// //                     },
// //                   }}
// //                   size="small"
// //                   value={productDetails?.description || ""}
// //                   onChange={(e) => handleChange("description", e.target.value)}
// //                   InputProps={editProductDetails ? { readOnly: true } : {}}
// //                 />
// //               </Grid>
// //             </Grid>

// //             <Grid className="imageGrid" item xs={4} md={4} lg={4}>
// //               <Grid item xs={12} sm={12} md={12}>
// //                 <Box
// //                   mt={2}
// //                   style={{
// //                     width: "180px",
// //                     height: "180px",
// //                     backgroundColor: "#f0f0f0",
// //                     border: "2px solid #f0f0f0",
// //                     display: "flex",
// //                     alignItems: "center",
// //                     justifyContent: "center",
// //                     overflow: "hidden",
// //                   }}
// //                 >
// //                   {productDetails.productImage ? (
// //                     <img
// //                       src={URL.createObjectURL(productDetails.productImage)}
// //                       alt="Uploaded"
// //                       style={{
// //                         maxWidth: "100%",
// //                         maxHeight: "100%",
// //                         objectFit: "cover",
// //                       }}
// //                     />
// //                   ) : (
// //                     <img
// //                       src={
// //                         productImage ? URL.createObjectURL(productImage) : ""
// //                       }
// //                       alt="Uploaded"
// //                       style={{
// //                         maxWidth: "100%",
// //                         maxHeight: "100%",
// //                         objectFit: "cover",
// //                       }}
// //                     />
// //                   )}
// //                 </Box>

// //                 {!editProductDetails && (
// //                   <Button
// //                     variant="outlined"
// //                     component="label"
// //                     style={{
// //                       "& .MuiButton-root": {
// //                         height: "40px",
// //                         textTransform: "none",
// //                       },
// //                     }}
// //                   >
// //                     Upload Image
// //                     <input
// //                       type="file"
// //                       accept="image/*"
// //                       style={{ display: "none" }}
// //                       onChange={(e) =>
// //                         handleDocumentUpload("productImage", e.target.files[0])
// //                       }
// //                     />
// //                   </Button>
// //                 )}
// //               </Grid>
// //             </Grid>
// //           </Grid>
// //         </Container>
// //       </div>

// //       {!editProductFeature && (
// //         <div
// //           style={{
// //             padding: "2%",
// //             backgroundColor: "#ffffff",
// //             borderRadius: "4px",
// //             boxShadow: "0px 0px 7px 0px #cfc5c56b",
// //             width: "91%",
// //             marginLeft: "2%",
// //             marginTop: "3%",
// //             marginBottom: "3%",
// //           }}
// //         >
// //          <Container>
// //   <Typography
// //     variant="h5"
// //     gutterBottom
// //     sx={{
// //       fontWeight: "bold",
// //       marginTop: "20px",
// //       marginBottom: "4%",
// //     }}
// //   >
// //     Assigned Product Feature
// //   </Typography>

// //   <Grid container spacing={1} className="form-container">
// //     <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
// //       <FormControl error={!!errors.selectedMainFeatureId} fullWidth size="small" margin="normal" sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": { fontSize: "11px" }}}>
// //         <InputLabel id="main-feature-label">Select Main Feature</InputLabel>
// //         <Select
// //           labelId="main-feature-label"
// //           value={selectedMainFeatureId}
// //           onChange={handleFeatureNameChange}
// //           error={!!errors.selectedMainFeatureId}
// //           helperText={errors.selectedMainFeatureId || 'Select a main feature.'}
// //           MenuProps={{
// //             PaperProps: {
// //               style: {
// //                 maxHeight: 200,
// //                 width: 250,
// //               },
// //             },
// //           }}
// //         >
// //           {productFeature ? productFeature.map((item) => (
// //             <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
// //           )) : <MenuItem disabled>No Main features available</MenuItem>}
// //         </Select>
// //       </FormControl>
// //     </Grid>

// //     <Grid item xs={1.5} sm={1.5} md={1.5}>
// //       <div style={{textAlign: "center", backgroundColor: "#efefef", borderRadius: "4px", padding: "4%", marginRight: "-10%", paddingLeft: "22%"}}>Weightage %</div>
// //     </Grid>
// //     <Grid item xs={1} sm={1} md={1}>
// //       <TextField
// //         required
// //         placeholder="%"
// //         type="number"
// //         fullWidth
// //         sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": {fontSize: "11px"}}}
// //         size="small"
// //         value={mainFeatureWeightage}
// //         onChange={handleMainWeightageChange}
// //         error={!!errors.mainFeatureWeightage}
// //         helperText={errors.mainFeatureWeightage }
// //       />
// //     </Grid>

// //     <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
// //       <FormControl  error={!!errors.selectedSubFeatureId} fullWidth size="small" margin="normal" sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": { fontSize: "11px" }}}>
// //         <InputLabel id="sub-feature-label">Select Sub Feature</InputLabel>
// //         <Select
// //           labelId="sub-feature-label"
// //           value={selectedSubFeatureId}
// //           onChange={handleSubFeatureChange}
// //           error={!!errors.selectedSubFeatureId}
// //           helperText={errors.selectedSubFeatureId || 'Select a sub feature.'}
// //           MenuProps={{
// //             PaperProps: {
// //               style: {
// //                 maxHeight: 200,
// //                 width: 250,
// //               },
// //             },
// //           }}
// //         >
// //           {productSubFeature ? productSubFeature.map((item) => (
// //             <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
// //           )) : <MenuItem disabled>No sub features available</MenuItem>}
// //         </Select>
// //       </FormControl>
// //     </Grid>

// //     <Grid item xs={1.5} sm={1.5} md={1.5}>
// //       <div style={{textAlign: "center", backgroundColor: "#efefef", borderRadius: "4px", padding: "4%", marginRight: "-10%", paddingLeft: "22%"}}>Weightage %</div>
// //     </Grid>
// //     <Grid item xs={1} sm={1} md={1}>
// //       <TextField
// //         placeholder="%"
// //         type="number"
// //         fullWidth
// //         sx={{"& .MuiInputBase-root": {height: "30px", fontSize: "11px"},"& .MuiFormLabel-root": {fontSize: "11px"}}}
// //         size="small"
// //         value={subFeatureWeightage}
// //         onChange={handleSubWeightageChange}
// //         error={!!errors.subFeatureWeightage}
// //         helperText={errors.subFeatureWeightage}
// //       />
// //     </Grid>
// //   </Grid>

// //   <Grid container spacing={1} className="form-container" sx={{ marginTop: "2%" }}>
// //     <Grid item xs={12} sm={12} md={12} textAlign="right">
// //       <Button variant="contained" onClick={handleAddFeature}>
// //         Add Feature(s)
// //       </Button>
// //     </Grid>
// //   </Grid>
// // </Container>

// //         </div>
// //       )}

// //       <div
// //         style={{
// //           padding: "2%",
// //           backgroundColor: "#ffffff",
// //           borderRadius: "4px",
// //           boxShadow: "0px 0px 7px 0px #cfc5c56b",
// //           width: "91%",
// //           marginLeft: "2%",
// //           marginTop: "3%",
// //           marginBottom: "4%",
// //         }}
// //       >
// //         <Grid container spacing={2}>
// //           <Grid item xs={6} md={6} lg={6}>
// //             <Typography
// //               variant="h5"
// //               gutterBottom
// //               sx={{
// //                 fontWeight: "bold",
// //                 marginLeft: "1%",
// //                 fontSize: "23px",
// //                 marginBottom: "2%",
// //               }}
// //             >
// //               Product Features Details
// //             </Typography>
// //           </Grid>
// //           <Grid item xs={6} md={6} lg={6}>
// //             {editProductFeature && (
// //               <Button
// //                 onClick={toggleFeatureEdit}
// //                 variant="text"
// //                 style={{
// //                   fontWeight: "bold",
// //                   color: "blue",
// //                   textDecoration: "underline",
// //                   marginBottom: "2%",
// //                 }}
// //               >
// //                 Edit
// //               </Button>
// //             )}
// //           </Grid>
// //         </Grid>

// //         <ListProductDetail
// //           listData={combineFeatureData}
// //           handleRowRemove={handleRowRemove}
// //           handleSubFeatureRemove={handleSubFeatureRemove}
// //         />
// //       </div>

// //       {(!editProductDetails || !editProductFeature) && (
// //         <Button
// //           variant="contained"
// //           color="primary"
// //           onClick={SubmitProductDetails}
// //           sx={{
// //             float: "right",
// //             marginTop: "1%",
// //             marginRight: "3%",
// //             marginLeft: "2%",
// //             marginBottom: "3%",
// //           }}
// //         >
// //           Update
// //         </Button>
// //       )}
// //       <Link to="/dashboard/productDetails">
// //         <Button
// //           variant="outlined"
// //           sx={{
// //             float: "right",
// //             marginTop: "1%",
// //             marginBottom: "3%",
// //           }}
// //         >
// //           Back
// //         </Button>
// //       </Link>

// //       <Snackbar
// //         open={createFeature}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="success"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           New Feature Added!
// //         </Alert>
// //       </Snackbar>
// //       <Snackbar
// //         open={openedStatus}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="warning"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           Selected main feature and sub-feature.
// //         </Alert>
// //       </Snackbar>
// //       <Snackbar
// //         open={openStatus}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="warning"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           Selected sub-feature already exists under this main feature.
// //         </Alert>
// //       </Snackbar>

// //       <Snackbar
// //         open={updatedOpenStatus}
// //         autoHideDuration={6000}
// //         onClose={handleClose}
// //         anchorOrigin={{ vertical: "top", horizontal: "center" }}
// //       >
// //         <Alert
// //           onClose={handleClose}
// //           severity="neutral"
// //           variant="filled"
// //           sx={{ width: "100%" }}
// //         >
// //           Existing Feature Updated!
// //         </Alert>
// //       </Snackbar>
// //     </div>
// //   );
// // };

// // export default ViewProduct;
// import React, { useEffect, useState } from "react";
// import {
//   TextField,
//   Box,
//   FormControl,
//   Alert,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Button,
//   Grid,
//   Container,
//   Snackbar,
// } from "@mui/material";
// import axios from "../../api/axios";
// import { useNavigate, useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import ListProductDetail from "./ListProductDetail";

// const EmployeeList = [
//   { value: "Salaried", label: "Salaried" },
//   { value: "Self-Employed", label: "Self - Employed" },
// ];

// const instantIssuanceList = [
//   { value: "true", label: "true" },
//   { value: "false", label: "false" },
// ];

// const RatingList = [
//   { value: "1", label: "1" },
//   { value: "2", label: "2" },
//   { value: "3", label: "3" },
//   { value: "4", label: "4" },
//   { value: "5", label: "5" },
// ];

// const ViewProduct = () => {
//   const [errors, setErrors] = useState({
//     selectedMainFeatureId: "",
//     mainFeatureWeightage: "",
//     selectedSubFeatureId: "",
//     subFeatureWeightage: "",
//   });

//   const { Id } = useParams();
//   const navigate = useNavigate();
//   const [editProductDetails, setEditProductDetails] = useState(true);
//   const [editProductFeature, setEditProductFeature] = useState(true);
//   const [productImage, setProductImage] = useState(null);

//   const [featureData, setFeatureData] = useState([]);
//   const [exitsFeatureData, setExitsFeatureData] = useState([]);
//   const [productFeatureData, setProductFeatureData] = useState([]);
//   const [combineFeatureData, setCombineFeatureData] = useState([]);

//   useEffect(() => {
//     setCombineFeatureData([...exitsFeatureData]);
//   }, [featureData, exitsFeatureData]);

//   const [productDetails, setProductDetails] = useState({
//     productName: "",
//     organizationName: "",
//     routingUrl: "",
//     employmentType: "",
//     minimumIncome: "",
//     rating: "",
//     instantIssuance: "",
//     incentive: "",
//     productImage: null,
//     description: "",
//     status: "Active",
//     features: [],
//   });

//   const handleChange = (field, value) => {
//     setProductDetails({
//       ...productDetails,
//       [field]: value,
//     });
//   };

//   const handleDocumentUpload = (field, file) => {
//     if (file) {
//       setProductDetails((prevDetails) => ({
//         ...prevDetails,
//         [field]: file,
//       }));
//     } else {
//       console.log("File selection canceled");
//     }
//   };

//   useEffect(() => {
//     const getByProductId = async () => {
//       try {
//         const response = await axios.get(`/api/products/${Id}`);
//         const productData = response?.data;

//         console.log("Product data fetched by ID:", productData);

//         if (productData) {
//           productData.productImage = null;
//         }

//         const response1 = await axios.get(
//           `/api/products/getProductFeatures/${Id}`
//         );
//         const productFeatures = response1?.data;

//         console.log("Product features fetched by ID:", productFeatures);

//         const transformedFeatures = productFeatures.features.map((feature) => ({
//           mainFeatureId: feature.featureId,
//           mainFeatureNames: feature.featureName,
//           mainFeatureWeightage: feature.featureWeight || "",
//           subFeatures: feature.subFeatures.map((subFeature) => ({
//             subFeatureId: subFeature.featureId,
//             subFeatureName: subFeature.featureName,
//             subFeatureWeightage: subFeature.featureWeight || "",
//           })),
//         }));

//         setProductDetails(response?.data);
//         setExitsFeatureData(transformedFeatures);
//         setProductFeatureData(transformedFeatures);
//       } catch (error) {
//         console.error("Error fetching product by ID:", error);
//       }
//     };

//     getByProductId();
//   }, [Id]);

//   useEffect(() => {
//     const getProductImage = async () => {
//       try {
//         const responseImage = await axios.get(`/api/products/image/${Id}`, {
//           responseType: "blob",
//         });
//         setProductImage(responseImage.data);
//       } catch (error) {
//         console.error("Error fetching product image:", error);
//       }
//     };

//     getProductImage();
//   }, [Id]);

//   function transformFeatureData(productId, productFeatureData) {
//     const transformedData = {
//       productId: productId,
//       features: [],
//     };

//     productFeatureData.forEach((mainFeature) => {
//       const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

//       transformedData.features.push({
//         featureId: mainFeature.mainFeatureId,
//         productWeight: mainFeatureWeight,
//       });

//       if (mainFeature.subFeatures) {
//         mainFeature.subFeatures.forEach((subFeature) => {
//           const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
//           transformedData.features.push({
//             featureId: subFeature.subFeatureId,
//             productWeight: subFeatureWeight,
//           });
//         });
//       }
//     });

//     return transformedData;
//   }

//   const SubmitProductDetails = async () => {
//     console.log("Submitting product details:", productDetails);
//     console.log("Combine feature data before transformation:", combineFeatureData);

//     const formData = new FormData();
//     const res = {
//       productId: productDetails.productId,
//       productName: productDetails.productName,
//       organizationName: productDetails.organizationName,
//       routingUrl: productDetails.routingUrl,
//       employmentType: productDetails.employmentType,
//       minimumIncome: productDetails.minimumIncome,
//       rating: productDetails.rating,
//       instantIssuance: productDetails.instantIssuance,
//       incentive: productDetails.incentive,
//       description: productDetails.description,
//       featureDescription: "",
//       uniqueProductId: "",
//       productType: "",
//       offer: "",
//       offerWeight: "",
//       productFeatures: null,
//     };

//     console.log("Product data to be submitted:", res);

//     formData.append("productDTO", JSON.stringify(res));
//     if (productDetails.productImage) {
//       formData.append("file", productDetails.productImage);
//     } else {
//       const emptyBlob = new Blob([], { type: "application/octet-stream" });
//       const file = new File([emptyBlob], "empty.png", { type: "image/png" });
//       formData.append("file", file);
//     }

//     try {
//       const productResponse = await axios.put("/api/products", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       console.log("Product update response:", productResponse.data);

//       const transformedFeatureData = transformFeatureData(
//         productDetails.productId,
//         combineFeatureData
//       );

//       console.log("Transformed feature data to be submitted:", transformedFeatureData);

//       if (transformedFeatureData.features.length) {
//         try {
//           const featureResponse = await axios.post(
//             "/api/products/addProductFeatures",
//             transformedFeatureData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("POST request for features successful:", featureResponse.data);
//         } catch (featureError) {
//           console.error("Error while making POST request for features:", featureError);
//         }
//       } else {
//         console.warn("No features to update.");
//       }

//       setTimeout(() => {
//         navigate("/dashboard/productDetails");
//       }, 1000);
//     } catch (error) {
//       console.error("Error in submitting product details:", error);
//       if (error.response && error.response.data) {
//         console.log("Internal Server Error:", error.response.data);
//       } else {
//         console.log("Error occurred. Please try again.");
//       }
//     }
//   };

//   const toggleEdit = () => {
//     setEditProductDetails(false);
//   };
//   const toggleFeatureEdit = () => {
//     setEditProductFeature(false);
//   };

//   const [createFeature, setCreateFeature] = useState(false);
//   const [openedStatus, setOpenedStatus] = useState(false);
//   const [openStatus, setOpenStatus] = useState(false);
//   const [updatedOpenStatus, setUpdatedOpenStatus] = useState(false);

//   const [productFeature, setProductFeature] = useState(null);
//   const [productSubFeature, setProductSubFeature] = useState(null);

//   const [selectedMainFeatureId, setSelectedMainFeatureId] = useState("");
//   const [mainFeatureWeightage, setMainFeatureWeightage] = useState("");
//   const [mainFeatureName, setMainFeatureName] = useState("");

//   const [selectedSubFeatureId, setSelectedSubFeatureId] = useState("");
//   const [subFeatureWeightage, setSubFeatureWeightage] = useState("");
//   const [subFeatureName, setSubFeatureName] = useState("");

//   const handleMainWeightageChange = (e) => {
//     const weightageValue = e.target.value;
//     setMainFeatureWeightage(weightageValue);

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       mainFeatureWeightage: "",
//     }));
//   };

//   const handleSubWeightageChange = (e) => {
//     const weightageValue = e.target.value;
//     setSubFeatureWeightage(weightageValue);
//   };

//   const getAllFeaturesList = async () => {
//     try {
//       const response = await axios.get("/api/productFeatures/parentFeatures");
//       setProductFeature(response?.data);
//     } catch (error) {
//       console.error("Error fetching all features:", error);
//     }
//   };

//   useEffect(() => {
//     getAllFeaturesList();
//   }, []);

//   const handleFeatureNameChange = async (e) => {
//     const selectedFeatureId = e.target.value;
//     setSelectedMainFeatureId(selectedFeatureId);

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       selectedMainFeatureId: "",
//     }));

//     try {
//       const response = await axios.get(
//         `/api/productFeatures/subFeatures/${selectedFeatureId}`
//       );
//       const subFeatures = response?.data || [];
//       setProductSubFeature(subFeatures);

//       const selectedMainFeature = productFeature.find(
//         (item) => item.featureId === selectedFeatureId
//       );
//       if (selectedMainFeature) {
//         setSelectedMainFeatureId(selectedMainFeature.featureId);
//         setMainFeatureName(selectedMainFeature.featureName);
//       }
//     } catch (error) {
//       console.error("Error fetching sub features:", error);
//     }
//   };

//   const handleSubFeatureChange = (e) => {
//     const selectedSubFeatureId = e.target.value;
//     setSelectedSubFeatureId(selectedSubFeatureId);

//     const selectedSubFeature = productSubFeature.find(
//       (item) => item.featureId === selectedSubFeatureId
//     );
//     if (selectedSubFeature) {
//       setSelectedSubFeatureId(selectedSubFeature.featureId);
//       setSubFeatureName(selectedSubFeature.featureName);
//     }
//   };

//   useEffect(() => {
//     console.log("Updated Feature Data:", featureData);
//   }, [featureData]);

//   const handleClose = () => {
//     setOpenStatus(false);
//     setCreateFeature(false);
//     setUpdatedOpenStatus(false);
//     setOpenedStatus(false);
//   };

//   const handleAddFeature = () => {
//     const newErrors = {};

//     if (!selectedMainFeatureId) {
//       newErrors.selectedMainFeatureId = "Main feature is required";
//     }
//     if (!mainFeatureWeightage) {
//       newErrors.mainFeatureWeightage = "Weightage for main feature is required";
//     }
//     if (!selectedSubFeatureId) {
//       newErrors.selectedSubFeatureId = "Sub feature selection is required";
//     }
//     if (!subFeatureWeightage) {
//       newErrors.subFeatureWeightage = "Weightage for sub feature is required";
//     }

//     setErrors(newErrors);

//     if (Object.keys(newErrors).length > 0) {
//       setOpenedStatus(true);
//       return;
//     }

//     const existingFeatureIndex = combineFeatureData.findIndex(
//       (feature) => feature.mainFeatureId === selectedMainFeatureId
//     );

//     if (existingFeatureIndex >= 0) {
//       const existingFeature = combineFeatureData[existingFeatureIndex];
//       const subFeatureExists = existingFeature.subFeatures.some(
//         (sub) => sub.subFeatureId === selectedSubFeatureId
//       );

//       if (!subFeatureExists) {
//         existingFeature.subFeatures.push({
//           subFeatureId: selectedSubFeatureId,
//           subFeatureName: subFeatureName,
//           subFeatureWeightage: subFeatureWeightage,
//         });
//         const updatedFeatures = [...combineFeatureData];
//         updatedFeatures[existingFeatureIndex] = existingFeature;
//         setCombineFeatureData(updatedFeatures);
//         setCreateFeature(true);
//       } else {
//         setOpenStatus(true);
//       }
//     } else {
//       const newFeature = {
//         mainFeatureId: selectedMainFeatureId,
//         mainFeatureNames: mainFeatureName,
//         mainFeatureWeightage: mainFeatureWeightage,
//         subFeatures: [
//           {
//             subFeatureId: selectedSubFeatureId,
//             subFeatureName: subFeatureName,
//             subFeatureWeightage: subFeatureWeightage,
//           },
//         ],
//       };
//       setCombineFeatureData([...combineFeatureData, newFeature]);
//       setCreateFeature(true);
//     }

//     setSelectedMainFeatureId("");
//     setMainFeatureWeightage("");
//     setMainFeatureName("");
//     setSelectedSubFeatureId("");
//     setSubFeatureWeightage("");
//     setSubFeatureName("");
//   };

//   const [listData, setListData] = useState([]);
//   const handleRowRemove = (mainFeatureId) => {
//     const updatedFeatures = combineFeatureData.filter(
//       (feature) => feature.mainFeatureId !== mainFeatureId
//     );
//     setCombineFeatureData(updatedFeatures);
//     setEditProductFeature(false);
//   };

//   const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
//     const updatedFeatures = combineFeatureData.map((feature) => {
//       if (feature.mainFeatureId === mainFeatureId) {
//         const updatedSubFeatures = feature.subFeatures.filter(
//           (sub) => sub.subFeatureId !== subFeatureId
//         );
//         return { ...feature, subFeatures: updatedSubFeatures };
//       }
//       return feature;
//     });
//     setCombineFeatureData(updatedFeatures);
//     setEditProductFeature(false);
//   };

//   return (
//     <div
//       style={{
//         flexGrow: 1,
//         marginTop: "0px",
//         position: "absolute",
//         right: "0",
//         top: "0",
//         width: "82%",
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: "#f3f7f6",
//           marginTop: "5%",
//           padding: "1.5%",
//           paddingBottom: "2%",
//         }}
//       >
//         <h2>View Product</h2>
//       </div>

//       <div
//         style={{
//           padding: "2%",
//           backgroundColor: "#ffffff",
//           borderRadius: "4px",
//           boxShadow: "0px 0px 7px 0px #cfc5c56b",
//           width: "91%",
//           marginLeft: "2%",
//           marginTop: "3%",
//           marginBottom: "3%",
//         }}
//       >
//         <Container>
//           <Grid container spacing={2}>
//             <Grid item xs={6} md={6} lg={6}>
//               <Typography
//                 variant="h5"
//                 gutterBottom
//                 sx={{
//                   fontWeight: "bold",
//                   marginTop: "20px",
//                 }}
//               >
//                 Product Details
//               </Typography>
//             </Grid>
//             <Grid item xs={6} md={6} lg={6}>
//               {editProductDetails && (
//                 <Button
//                   onClick={toggleEdit}
//                   variant="text"
//                   style={{
//                     fontWeight: "bold",
//                     color: "blue",
//                     textDecoration: "underline",
//                     marginTop: "20px",
//                   }}
//                 >
//                   Edit
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           <Grid
//             container
//             spacing={2}
//             style={{ margin: "5vh 10px 10px 0px" }}
//             className="form-container"
//           >
//             <Grid container spacing={2} item xs={8} md={8} lg={8}>
//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Product Name
//                 </Typography>
//                 <TextField
//                   placeholder="Enter card name"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails.productName}
//                   onChange={(e) => handleChange("productName", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Organization Name
//                 </Typography>
//                 <TextField
//                   placeholder="Enter organization name"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails.organizationName}
//                   onChange={(e) =>
//                     handleChange("organizationName", e.target.value)
//                   }
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Routing Url
//                 </Typography>
//                 <TextField
//                   placeholder="Enter routingUrl"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.routingUrl || ""}
//                   onChange={(e) => handleChange("routingUrl", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Employee Type
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="employee-type-label"
//                     value={productDetails.employmentType}
//                     onChange={(e) =>
//                       handleChange("employmentType", e.target.value)
//                     }
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {EmployeeList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Minimum Salary
//                 </Typography>
//                 <TextField
//                   placeholder="Enter Minimum Salary"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.minimumIncome || ""}
//                   onChange={(e) =>
//                     handleChange("minimumIncome", e.target.value)
//                   }
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Rating
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="customer-name-label"
//                     value={productDetails.rating}
//                     onChange={(e) => handleChange("rating", e.target.value)}
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {RatingList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Instant Issuance
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="instant-issuance-label"
//                     value={productDetails.instantIssuance}
//                     onChange={(e) =>
//                       handleChange("instantIssuance", e.target.value)
//                     }
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {instantIssuanceList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Incentive
//                 </Typography>
//                 <TextField
//                   placeholder="Enter incentive"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.incentive || ""}
//                   onChange={(e) => handleChange("incentive", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} md={12}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Feature Description
//                 </Typography>
//                 <TextField
//                   placeholder="Enter feature description"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.description || ""}
//                   onChange={(e) => handleChange("description", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>
//             </Grid>

//             <Grid className="imageGrid" item xs={4} md={4} lg={4}>
//               <Grid item xs={12} sm={12} md={12}>
//                 <Box
//                   mt={2}
//                   style={{
//                     width: "180px",
//                     height: "180px",
//                     backgroundColor: "#f0f0f0",
//                     border: "2px solid #f0f0f0",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     overflow: "hidden",
//                   }}
//                 >
//                   {productDetails.productImage ? (
//                     <img
//                       src={URL.createObjectURL(productDetails.productImage)}
//                       alt="Uploaded"
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={
//                         productImage ? URL.createObjectURL(productImage) : ""
//                       }
//                       alt="Uploaded"
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   )}
//                 </Box>

//                 {!editProductDetails && (
//                   <Button
//                     variant="outlined"
//                     component="label"
//                     style={{
//                       "& .MuiButton-root": {
//                         height: "40px",
//                         textTransform: "none",
//                       },
//                     }}
//                   >
//                     Upload Image
//                     <input
//                       type="file"
//                       accept="image/*"
//                       style={{ display: "none" }}
//                       onChange={(e) =>
//                         handleDocumentUpload("productImage", e.target.files[0])
//                       }
//                     />
//                   </Button>
//                 )}
//               </Grid>
//             </Grid>
//           </Grid>
//         </Container>
//       </div>

//       {!editProductFeature && (
//         <div
//           style={{
//             padding: "2%",
//             backgroundColor: "#ffffff",
//             borderRadius: "4px",
//             boxShadow: "0px 0px 7px 0px #cfc5c56b",
//             width: "91%",
//             marginLeft: "2%",
//             marginTop: "3%",
//             marginBottom: "3%",
//           }}
//         >
//           <Container>
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontWeight: "bold",
//                 marginTop: "20px",
//                 marginBottom: "4%",
//               }}
//             >
//               Assigned Product Feature
//             </Typography>

//             <Grid container spacing={1} className="form-container">
//               <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
//                 <FormControl
//                   error={!!errors.selectedMainFeatureId}
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <InputLabel id="main-feature-label">
//                     Select Main Feature
//                   </InputLabel>
//                   <Select
//                     labelId="main-feature-label"
//                     value={selectedMainFeatureId}
//                     onChange={handleFeatureNameChange}
//                     error={!!errors.selectedMainFeatureId}
//                     helperText={
//                       errors.selectedMainFeatureId || "Select a main feature."
//                     }
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {productFeature ? (
//                       productFeature.map((item) => (
//                         <MenuItem key={item.featureId} value={item.featureId}>
//                           {item.featureName}
//                         </MenuItem>
//                       ))
//                     ) : (
//                       <MenuItem disabled>No Main features available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={1.5} sm={1.5} md={1.5}>
//                 <div
//                   style={{
//                     textAlign: "center",
//                     backgroundColor: "#efefef",
//                     borderRadius: "4px",
//                     padding: "4%",
//                     marginRight: "-10%",
//                     paddingLeft: "22%",
//                   }}
//                 >
//                   Weightage %
//                 </div>
//               </Grid>
//               <Grid item xs={1} sm={1} md={1}>
//                 <TextField
//                   required
//                   placeholder="%"
//                   type="number"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                   size="small"
//                   value={mainFeatureWeightage}
//                   onChange={handleMainWeightageChange}
//                   error={!!errors.mainFeatureWeightage}
//                   helperText={errors.mainFeatureWeightage}
//                 />
//               </Grid>

//               <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
//                 <FormControl
//                   error={!!errors.selectedSubFeatureId}
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <InputLabel id="sub-feature-label">
//                     Select Sub Feature
//                   </InputLabel>
//                   <Select
//                     labelId="sub-feature-label"
//                     value={selectedSubFeatureId}
//                     onChange={handleSubFeatureChange}
//                     error={!!errors.selectedSubFeatureId}
//                     helperText={
//                       errors.selectedSubFeatureId || "Select a sub feature."
//                     }
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {productSubFeature ? (
//                       productSubFeature.map((item) => (
//                         <MenuItem key={item.featureId} value={item.featureId}>
//                           {item.featureName}
//                         </MenuItem>
//                       ))
//                     ) : (
//                       <MenuItem disabled>No sub features available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={1.5} sm={1.5} md={1.5}>
//                 <div
//                   style={{
//                     textAlign: "center",
//                     backgroundColor: "#efefef",
//                     borderRadius: "4px",
//                     padding: "4%",
//                     marginRight: "-10%",
//                     paddingLeft: "22%",
//                   }}
//                 >
//                   Weightage %
//                 </div>
//               </Grid>
//               <Grid item xs={1} sm={1} md={1}>
//                 <TextField
//                   placeholder="%"
//                   type="number"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                   size="small"
//                   value={subFeatureWeightage}
//                   onChange={handleSubWeightageChange}
//                   error={!!errors.subFeatureWeightage}
//                   helperText={errors.subFeatureWeightage}
//                 />
//               </Grid>
//             </Grid>

//             <Grid
//               container
//               spacing={1}
//               className="form-container"
//               sx={{ marginTop: "2%" }}
//             >
//               <Grid item xs={12} sm={12} md={12} textAlign="right">
//                 <Button variant="contained" onClick={handleAddFeature}>
//                   Add Feature(s)
//                 </Button>
//               </Grid>
//             </Grid>
//           </Container>
//         </div>
//       )}

//       <div
//         style={{
//           padding: "2%",
//           backgroundColor: "#ffffff",
//           borderRadius: "4px",
//           boxShadow: "0px 0px 7px 0px #cfc5c56b",
//           width: "91%",
//           marginLeft: "2%",
//           marginTop: "3%",
//           marginBottom: "4%",
//         }}
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={6} md={6} lg={6}>
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontWeight: "bold",
//                 marginLeft: "1%",
//                 fontSize: "23px",
//                 marginBottom: "2%",
//               }}
//             >
//               Product Features Details
//             </Typography>
//           </Grid>
//           <Grid item xs={6} md={6} lg={6}>
//             {editProductFeature && (
//               <Button
//                 onClick={toggleFeatureEdit}
//                 variant="text"
//                 style={{
//                   fontWeight: "bold",
//                   color: "blue",
//                   textDecoration: "underline",
//                   marginBottom: "2%",
//                 }}
//               >
//                 Edit
//               </Button>
//             )}
//           </Grid>
//         </Grid>

//         <ListProductDetail
//           listData={combineFeatureData}
//           handleRowRemove={handleRowRemove}
//           handleSubFeatureRemove={handleSubFeatureRemove}
//         />
//       </div>

//       {(!editProductDetails || !editProductFeature) && (
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={SubmitProductDetails}
//           sx={{
//             float: "right",
//             marginTop: "1%",
//             marginRight: "3%",
//             marginLeft: "2%",
//             marginBottom: "3%",
//           }}
//         >
//           Update
//         </Button>
//       )}
//       <Link to="/dashboard/productDetails">
//         <Button
//           variant="outlined"
//           sx={{
//             float: "right",
//             marginTop: "1%",
//             marginBottom: "3%",
//           }}
//         >
//           Back
//         </Button>
//       </Link>

//       <Snackbar
//         open={createFeature}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="success"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           New Feature Added!
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={openedStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="warning"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Selected main feature and sub-feature.
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={openStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="warning"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Selected sub-feature already exists under this main feature.
//         </Alert>
//       </Snackbar>

//       <Snackbar
//         open={updatedOpenStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="neutral"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Existing Feature Updated!
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// };

// export default ViewProduct;
// =====
// import React, { useEffect, useState } from "react";
// import {
//   TextField,
//   Box,
//   FormControl,
//   Alert,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Button,
//   Grid,
//   Container,
//   Snackbar,
// } from "@mui/material";
// import axios from "../../api/axios";
// import { useNavigate, useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import ListProductDetail from "./ListProductDetail";

// const EmployeeList = [
//   { value: "Salaried", label: "Salaried" },
//   { value: "Self-Employed", label: "Self - Employed" },
// ];

// const instantIssuanceList = [
//   { value: "true", label: "true" },
//   { value: "false", label: "false" },
// ];

// const RatingList = [
//   { value: "1", label: "1" },
//   { value: "2", label: "2" },
//   { value: "3", label: "3" },
//   { value: "4", label: "4" },
//   { value: "5", label: "5" },
// ];

// const ViewProduct = () => {
//   const [errors, setErrors] = useState({
//     selectedMainFeatureId: "",
//     mainFeatureWeightage: "",
//     selectedSubFeatureId: "",
//     subFeatureWeightage: "",
//   });

//   const { Id } = useParams();
//   const navigate = useNavigate();
//   const [editProductDetails, setEditProductDetails] = useState(true);
//   const [editProductFeature, setEditProductFeature] = useState(true);
//   const [productImage, setProductImage] = useState(null);

//   const [featureData, setFeatureData] = useState([]);
//   const [exitsFeatureData, setExitsFeatureData] = useState([]);
//   const [productFeatureData, setProductFeatureData] = useState([]);
//   const [combineFeatureData, setCombineFeatureData] = useState([]);

//   useEffect(() => {
//     setCombineFeatureData([...exitsFeatureData]);
//   }, [featureData, exitsFeatureData]);

//   const [productDetails, setProductDetails] = useState({
//     productName: "",
//     organizationName: "",
//     routingUrl: "",
//     employmentType: "",
//     minimumIncome: "",
//     rating: "",
//     instantIssuance: "",
//     incentive: "",
//     productImage: null,
//     description: "",
//     status: "Active",
//     features: [],
//   });

//   const handleChange = (field, value) => {
//     setProductDetails({
//       ...productDetails,
//       [field]: value,
//     });
//   };

//   const handleDocumentUpload = (field, file) => {
//     if (file) {
//       setProductDetails((prevDetails) => ({
//         ...prevDetails,
//         [field]: file,
//       }));
//     } else {
//       console.log("File selection canceled");
//     }
//   };

//   useEffect(() => {
//     const getByProductId = async () => {
//       try {
//         const response = await axios.get(`/api/products/${Id}`);
//         const productData = response?.data;

//         console.log("Product data fetched by ID:", productData);

//         if (productData) {
//           productData.productImage = null;
//         }

//         const response1 = await axios.get(
//           `/api/products/getProductFeatures/${Id}`
//         );
//         const productFeatures = response1?.data;

//         console.log("Product features fetched by ID:", productFeatures);

//         const transformedFeatures = productFeatures.features.map((feature) => ({
//           mainFeatureId: feature.featureId,
//           mainFeatureNames: feature.featureName,
//           mainFeatureWeightage: feature.featureWeight || "",
//           subFeatures: feature.subFeatures.map((subFeature) => ({
//             subFeatureId: subFeature.featureId,
//             subFeatureName: subFeature.featureName,
//             subFeatureWeightage: subFeature.featureWeight || "",
//           })),
//         }));

//         setProductDetails(response?.data);
//         setExitsFeatureData(transformedFeatures);
//         setProductFeatureData(transformedFeatures);
//       } catch (error) {
//         console.error("Error fetching product by ID:", error);
//       }
//     };

//     getByProductId();
//   }, [Id]);

//   useEffect(() => {
//     const getProductImage = async () => {
//       try {
//         const responseImage = await axios.get(`/api/products/image/${Id}`, {
//           responseType: "blob",
//         });
//         setProductImage(responseImage.data);
//       } catch (error) {
//         console.error("Error fetching product image:", error);
//       }
//     };

//     getProductImage();
//   }, [Id]);

//   function transformFeatureData(productId, productFeatureData) {
//     const transformedData = {
//       productId: productId,
//       features: [],
//     };

//     productFeatureData.forEach((mainFeature) => {
//       const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

//       transformedData.features.push({
//         featureId: mainFeature.mainFeatureId,
//         productWeight: mainFeatureWeight,
//       });

//       if (mainFeature.subFeatures) {
//         mainFeature.subFeatures.forEach((subFeature) => {
//           const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
//           transformedData.features.push({
//             featureId: subFeature.subFeatureId,
//             productWeight: subFeatureWeight,
//           });
//         });
//       }
//     });

//     return transformedData;
//   }

//   const deleteFeature = async (productId, featureIds) => {
//     try {
//       const response = await axios.delete('/api/products/removeProductFeatures', {
//         headers: { 'Content-Type': 'application/json' },
//         data: { productId, featureIds }
//       });
//       console.log('Delete feature response:', response.data);
//     } catch (error) {
//       console.error('Error deleting feature:', error);
//     }
//   };

//   const SubmitProductDetails = async () => {
//     console.log("Submitting product details:", productDetails);
//     console.log("Combine feature data before transformation:", combineFeatureData);

//     const formData = new FormData();
//     const res = {
//       productId: productDetails.productId,
//       productName: productDetails.productName,
//       organizationName: productDetails.organizationName,
//       routingUrl: productDetails.routingUrl,
//       employmentType: productDetails.employmentType,
//       minimumIncome: productDetails.minimumIncome,
//       rating: productDetails.rating,
//       instantIssuance: productDetails.instantIssuance,
//       incentive: productDetails.incentive,
//       description: productDetails.description,
//       featureDescription: "",
//       uniqueProductId: "",
//       productType: "",
//       offer: "",
//       offerWeight: "",
//       productFeatures: null,
//     };

//     console.log("Product data to be submitted:", res);

//     formData.append("productDTO", JSON.stringify(res));
//     if (productDetails.productImage) {
//       formData.append("file", productDetails.productImage);
//     } else {
//       const emptyBlob = new Blob([], { type: "application/octet-stream" });
//       const file = new File([emptyBlob], "empty.png", { type: "image/png" });
//       formData.append("file", file);
//     }

//     try {
//       const productResponse = await axios.put("/api/products", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       console.log("Product update response:", productResponse.data);

//       const transformedFeatureData = transformFeatureData(
//         productDetails.productId,
//         combineFeatureData
//       );

//       console.log("Transformed feature data to be submitted:", transformedFeatureData);

//       if (transformedFeatureData.features.length) {
//         try {
//           const featureResponse = await axios.post(
//             "/api/products/addProductFeatures",
//             transformedFeatureData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("POST request for features successful:", featureResponse.data);
//         } catch (featureError) {
//           console.error("Error while making POST request for features:", featureError);
//         }
//       } else {
//         console.warn("No features to update.");
//       }

//       setTimeout(() => {
//         navigate("/dashboard/productDetails");
//       }, 1000);
//     } catch (error) {
//       console.error("Error in submitting product details:", error);
//       if (error.response && error.response.data) {
//         console.log("Internal Server Error:", error.response.data);
//       } else {
//         console.log("Error occurred. Please try again.");
//       }
//     }
//   };

//   const toggleEdit = () => {
//     setEditProductDetails(false);
//   };
//   const toggleFeatureEdit = () => {
//     setEditProductFeature(false);
//   };

//   const [createFeature, setCreateFeature] = useState(false);
//   const [openedStatus, setOpenedStatus] = useState(false);
//   const [openStatus, setOpenStatus] = useState(false);
//   const [updatedOpenStatus, setUpdatedOpenStatus] = useState(false);

//   const [productFeature, setProductFeature] = useState(null);
//   const [productSubFeature, setProductSubFeature] = useState(null);

//   const [selectedMainFeatureId, setSelectedMainFeatureId] = useState("");
//   const [mainFeatureWeightage, setMainFeatureWeightage] = useState("");
//   const [mainFeatureName, setMainFeatureName] = useState("");

//   const [selectedSubFeatureId, setSelectedSubFeatureId] = useState("");
//   const [subFeatureWeightage, setSubFeatureWeightage] = useState("");
//   const [subFeatureName, setSubFeatureName] = useState("");

//   const handleMainWeightageChange = (e) => {
//     const weightageValue = e.target.value;
//     setMainFeatureWeightage(weightageValue);

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       mainFeatureWeightage: "",
//     }));
//   };

//   const handleSubWeightageChange = (e) => {
//     const weightageValue = e.target.value;
//     setSubFeatureWeightage(weightageValue);
//   };

//   const getAllFeaturesList = async () => {
//     try {
//       const response = await axios.get("/api/productFeatures/parentFeatures");
//       setProductFeature(response?.data);
//     } catch (error) {
//       console.error("Error fetching all features:", error);
//     }
//   };

//   useEffect(() => {
//     getAllFeaturesList();
//   }, []);

//   const handleFeatureNameChange = async (e) => {
//     const selectedFeatureId = e.target.value;
//     setSelectedMainFeatureId(selectedFeatureId);

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       selectedMainFeatureId: "",
//     }));

//     try {
//       const response = await axios.get(
//         `/api/productFeatures/subFeatures/${selectedFeatureId}`
//       );
//       const subFeatures = response?.data || [];
//       setProductSubFeature(subFeatures);

//       const selectedMainFeature = productFeature.find(
//         (item) => item.featureId === selectedFeatureId
//       );
//       if (selectedMainFeature) {
//         setSelectedMainFeatureId(selectedMainFeature.featureId);
//         setMainFeatureName(selectedMainFeature.featureName);
//       }
//     } catch (error) {
//       console.error("Error fetching sub features:", error);
//     }
//   };

//   const handleSubFeatureChange = (e) => {
//     const selectedSubFeatureId = e.target.value;
//     setSelectedSubFeatureId(selectedSubFeatureId);

//     const selectedSubFeature = productSubFeature.find(
//       (item) => item.featureId === selectedSubFeatureId
//     );
//     if (selectedSubFeature) {
//       setSelectedSubFeatureId(selectedSubFeature.featureId);
//       setSubFeatureName(selectedSubFeature.featureName);
//     }
//   };

//   useEffect(() => {
//     console.log("Updated Feature Data:", featureData);
//   }, [featureData]);

//   const handleClose = () => {
//     setOpenStatus(false);
//     setCreateFeature(false);
//     setUpdatedOpenStatus(false);
//     setOpenedStatus(false);
//   };

//   const handleAddFeature = () => {
//     const newErrors = {};

//     if (!selectedMainFeatureId) {
//       newErrors.selectedMainFeatureId = "Main feature is required";
//     }
//     if (!mainFeatureWeightage) {
//       newErrors.mainFeatureWeightage = "Weightage for main feature is required";
//     }
//     if (!selectedSubFeatureId) {
//       newErrors.selectedSubFeatureId = "Sub feature selection is required";
//     }
//     if (!subFeatureWeightage) {
//       newErrors.subFeatureWeightage = "Weightage for sub feature is required";
//     }

//     setErrors(newErrors);

//     if (Object.keys(newErrors).length > 0) {
//       setOpenedStatus(true);
//       return;
//     }

//     const existingFeatureIndex = combineFeatureData.findIndex(
//       (feature) => feature.mainFeatureId === selectedMainFeatureId
//     );

//     if (existingFeatureIndex >= 0) {
//       const existingFeature = combineFeatureData[existingFeatureIndex];
//       const subFeatureExists = existingFeature.subFeatures.some(
//         (sub) => sub.subFeatureId === selectedSubFeatureId
//       );

//       if (!subFeatureExists) {
//         existingFeature.subFeatures.push({
//           subFeatureId: selectedSubFeatureId,
//           subFeatureName: subFeatureName,
//           subFeatureWeightage: subFeatureWeightage,
//         });
//         const updatedFeatures = [...combineFeatureData];
//         updatedFeatures[existingFeatureIndex] = existingFeature;
//         setCombineFeatureData(updatedFeatures);
//         setCreateFeature(true);
//       } else {
//         setOpenStatus(true);
//       }
//     } else {
//       const newFeature = {
//         mainFeatureId: selectedMainFeatureId,
//         mainFeatureNames: mainFeatureName,
//         mainFeatureWeightage: mainFeatureWeightage,
//         subFeatures: [
//           {
//             subFeatureId: selectedSubFeatureId,
//             subFeatureName: subFeatureName,
//             subFeatureWeightage: subFeatureWeightage,
//           },
//         ],
//       };
//       setCombineFeatureData([...combineFeatureData, newFeature]);
//       setCreateFeature(true);
//     }

//     setSelectedMainFeatureId("");
//     setMainFeatureWeightage("");
//     setMainFeatureName("");
//     setSelectedSubFeatureId("");
//     setSubFeatureWeightage("");
//     setSubFeatureName("");
//   };

//   const [listData, setListData] = useState([]);
//   const handleRowRemove = async (mainFeatureId) => {
//     const featureIdsToDelete = [mainFeatureId, ...combineFeatureData.find(feature => feature.mainFeatureId === mainFeatureId)?.subFeatures.map(sub => sub.subFeatureId)];
//     await deleteFeature(productDetails.productId, featureIdsToDelete);

//     const updatedFeatures = combineFeatureData.filter(
//       (feature) => feature.mainFeatureId !== mainFeatureId
//     );
//     setCombineFeatureData(updatedFeatures);
//     setEditProductFeature(false);
//   };

//   const handleSubFeatureRemove = async (mainFeatureId, subFeatureId) => {
//     await deleteFeature(productDetails.productId, [subFeatureId]);

//     const updatedFeatures = combineFeatureData.map((feature) => {
//       if (feature.mainFeatureId === mainFeatureId) {
//         const updatedSubFeatures = feature.subFeatures.filter(
//           (sub) => sub.subFeatureId !== subFeatureId
//         );
//         return { ...feature, subFeatures: updatedSubFeatures };
//       }
//       return feature;
//     });
//     setCombineFeatureData(updatedFeatures);
//     setEditProductFeature(false);
//   };

//   return (
//     <div
//       style={{
//         flexGrow: 1,
//         marginTop: "0px",
//         position: "absolute",
//         right: "0",
//         top: "0",
//         width: "82%",
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: "#f3f7f6",
//           marginTop: "5%",
//           padding: "1.5%",
//           paddingBottom: "2%",
//         }}
//       >
//         <h2>View Product</h2>
//       </div>

//       <div
//         style={{
//           padding: "2%",
//           backgroundColor: "#ffffff",
//           borderRadius: "4px",
//           boxShadow: "0px 0px 7px 0px #cfc5c56b",
//           width: "91%",
//           marginLeft: "2%",
//           marginTop: "3%",
//           marginBottom: "3%",
//         }}
//       >
//         <Container>
//           <Grid container spacing={2}>
//             <Grid item xs={6} md={6} lg={6}>
//               <Typography
//                 variant="h5"
//                 gutterBottom
//                 sx={{
//                   fontWeight: "bold",
//                   marginTop: "20px",
//                 }}
//               >
//                 Product Details
//               </Typography>
//             </Grid>
//             <Grid item xs={6} md={6} lg={6}>
//               {editProductDetails && (
//                 <Button
//                   onClick={toggleEdit}
//                   variant="text"
//                   style={{
//                     fontWeight: "bold",
//                     color: "blue",
//                     textDecoration: "underline",
//                     marginTop: "20px",
//                   }}
//                 >
//                   Edit
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           <Grid
//             container
//             spacing={2}
//             style={{ margin: "5vh 10px 10px 0px" }}
//             className="form-container"
//           >
//             <Grid container spacing={2} item xs={8} md={8} lg={8}>
//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Product Name
//                 </Typography>
//                 <TextField
//                   placeholder="Enter card name"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails.productName}
//                   onChange={(e) => handleChange("productName", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Organization Name
//                 </Typography>
//                 <TextField
//                   placeholder="Enter organization name"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails.organizationName}
//                   onChange={(e) =>
//                     handleChange("organizationName", e.target.value)
//                   }
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Routing Url
//                 </Typography>
//                 <TextField
//                   placeholder="Enter routingUrl"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.routingUrl || ""}
//                   onChange={(e) => handleChange("routingUrl", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Employee Type
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="employee-type-label"
//                     value={productDetails.employmentType}
//                     onChange={(e) =>
//                       handleChange("employmentType", e.target.value)
//                     }
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {EmployeeList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Minimum Salary
//                 </Typography>
//                 <TextField
//                   placeholder="Enter Minimum Salary"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.minimumIncome || ""}
//                   onChange={(e) =>
//                     handleChange("minimumIncome", e.target.value)
//                   }
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Rating
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="customer-name-label"
//                     value={productDetails.rating}
//                     onChange={(e) => handleChange("rating", e.target.value)}
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {RatingList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Instant Issuance
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="instant-issuance-label"
//                     value={productDetails.instantIssuance}
//                     onChange={(e) =>
//                       handleChange("instantIssuance", e.target.value)
//                     }
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {instantIssuanceList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Incentive
//                 </Typography>
//                 <TextField
//                   placeholder="Enter incentive"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.incentive || ""}
//                   onChange={(e) => handleChange("incentive", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} md={12}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Feature Description
//                 </Typography>
//                 <TextField
//                   placeholder="Enter feature description"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.description || ""}
//                   onChange={(e) => handleChange("description", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>
//             </Grid>

//             <Grid className="imageGrid" item xs={4} md={4} lg={4}>
//               <Grid item xs={12} sm={12} md={12}>
//                 <Box
//                   mt={2}
//                   style={{
//                     width: "180px",
//                     height: "180px",
//                     backgroundColor: "#f0f0f0",
//                     border: "2px solid #f0f0f0",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     overflow: "hidden",
//                   }}
//                 >
//                   {productDetails.productImage ? (
//                     <img
//                       src={URL.createObjectURL(productDetails.productImage)}
//                       alt="Uploaded"
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={
//                         productImage ? URL.createObjectURL(productImage) : ""
//                       }
//                       alt="Uploaded"
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   )}
//                 </Box>

//                 {!editProductDetails && (
//                   <Button
//                     variant="outlined"
//                     component="label"
//                     style={{
//                       "& .MuiButton-root": {
//                         height: "40px",
//                         textTransform: "none",
//                       },
//                     }}
//                   >
//                     Upload Image
//                     <input
//                       type="file"
//                       accept="image/*"
//                       style={{ display: "none" }}
//                       onChange={(e) =>
//                         handleDocumentUpload("productImage", e.target.files[0])
//                       }
//                     />
//                   </Button>
//                 )}
//               </Grid>
//             </Grid>
//           </Grid>
//         </Container>
//       </div>

//       {!editProductFeature && (
//         <div
//           style={{
//             padding: "2%",
//             backgroundColor: "#ffffff",
//             borderRadius: "4px",
//             boxShadow: "0px 0px 7px 0px #cfc5c56b",
//             width: "91%",
//             marginLeft: "2%",
//             marginTop: "3%",
//             marginBottom: "3%",
//           }}
//         >
//           <Container>
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontWeight: "bold",
//                 marginTop: "20px",
//                 marginBottom: "4%",
//               }}
//             >
//               Assigned Product Feature
//             </Typography>

//             <Grid container spacing={1} className="form-container">
//               <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
//                 <FormControl
//                   error={!!errors.selectedMainFeatureId}
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <InputLabel id="main-feature-label">
//                     Select Main Feature
//                   </InputLabel>
//                   <Select
//                     labelId="main-feature-label"
//                     value={selectedMainFeatureId}
//                     onChange={handleFeatureNameChange}
//                     error={!!errors.selectedMainFeatureId}
//                     helperText={
//                       errors.selectedMainFeatureId || "Select a main feature."
//                     }
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {productFeature ? (
//                       productFeature.map((item) => (
//                         <MenuItem key={item.featureId} value={item.featureId}>
//                           {item.featureName}
//                         </MenuItem>
//                       ))
//                     ) : (
//                       <MenuItem disabled>No Main features available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={1.5} sm={1.5} md={1.5}>
//                 <div
//                   style={{
//                     textAlign: "center",
//                     backgroundColor: "#efefef",
//                     borderRadius: "4px",
//                     padding: "4%",
//                     marginRight: "-10%",
//                     paddingLeft: "22%",
//                   }}
//                 >
//                   Weightage %
//                 </div>
//               </Grid>
//               <Grid item xs={1} sm={1} md={1}>
//                 <TextField
//                   required
//                   placeholder="%"
//                   type="number"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                   size="small"
//                   value={mainFeatureWeightage}
//                   onChange={handleMainWeightageChange}
//                   error={!!errors.mainFeatureWeightage}
//                   helperText={errors.mainFeatureWeightage}
//                 />
//               </Grid>

//               <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
//                 <FormControl
//                   error={!!errors.selectedSubFeatureId}
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <InputLabel id="sub-feature-label">
//                     Select Sub Feature
//                   </InputLabel>
//                   <Select
//                     labelId="sub-feature-label"
//                     value={selectedSubFeatureId}
//                     onChange={handleSubFeatureChange}
//                     error={!!errors.selectedSubFeatureId}
//                     helperText={
//                       errors.selectedSubFeatureId || "Select a sub feature."
//                     }
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {productSubFeature ? (
//                       productSubFeature.map((item) => (
//                         <MenuItem key={item.featureId} value={item.featureId}>
//                           {item.featureName}
//                         </MenuItem>
//                       ))
//                     ) : (
//                       <MenuItem disabled>No sub features available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={1.5} sm={1.5} md={1.5}>
//                 <div
//                   style={{
//                     textAlign: "center",
//                     backgroundColor: "#efefef",
//                     borderRadius: "4px",
//                     padding: "4%",
//                     marginRight: "-10%",
//                     paddingLeft: "22%",
//                   }}
//                 >
//                   Weightage %
//                 </div>
//               </Grid>
//               <Grid item xs={1} sm={1} md={1}>
//                 <TextField
//                   placeholder="%"
//                   type="number"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                   size="small"
//                   value={subFeatureWeightage}
//                   onChange={handleSubWeightageChange}
//                   error={!!errors.subFeatureWeightage}
//                   helperText={errors.subFeatureWeightage}
//                 />
//               </Grid>
//             </Grid>

//             <Grid
//               container
//               spacing={1}
//               className="form-container"
//               sx={{ marginTop: "2%" }}
//             >
//               <Grid item xs={12} sm={12} md={12} textAlign="right">
//                 <Button variant="contained" onClick={handleAddFeature}>
//                   Add Feature(s)
//                 </Button>
//               </Grid>
//             </Grid>
//           </Container>
//         </div>
//       )}

//       <div
//         style={{
//           padding: "2%",
//           backgroundColor: "#ffffff",
//           borderRadius: "4px",
//           boxShadow: "0px 0px 7px 0px #cfc5c56b",
//           width: "91%",
//           marginLeft: "2%",
//           marginTop: "3%",
//           marginBottom: "4%",
//         }}
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={6} md={6} lg={6}>
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontWeight: "bold",
//                 marginLeft: "1%",
//                 fontSize: "23px",
//                 marginBottom: "2%",
//               }}
//             >
//               Product Features Details
//             </Typography>
//           </Grid>
//           <Grid item xs={6} md={6} lg={6}>
//             {editProductFeature && (
//               <Button
//                 onClick={toggleFeatureEdit}
//                 variant="text"
//                 style={{
//                   fontWeight: "bold",
//                   color: "blue",
//                   textDecoration: "underline",
//                   marginBottom: "2%",
//                 }}
//               >
//                 Edit
//               </Button>
//             )}
//           </Grid>
//         </Grid>

//         <ListProductDetail
//           listData={combineFeatureData}
//           handleRowRemove={handleRowRemove}
//           handleSubFeatureRemove={handleSubFeatureRemove}
//         />
//       </div>

//       {(!editProductDetails || !editProductFeature) && (
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={SubmitProductDetails}
//           sx={{
//             float: "right",
//             marginTop: "1%",
//             marginRight: "3%",
//             marginLeft: "2%",
//             marginBottom: "3%",
//           }}
//         >
//           Update
//         </Button>
//       )}
//       <Link to="/dashboard/productDetails">
//         <Button
//           variant="outlined"
//           sx={{
//             float: "right",
//             marginTop: "1%",
//             marginBottom: "3%",
//           }}
//         >
//           Back
//         </Button>
//       </Link>

//       <Snackbar
//         open={createFeature}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="success"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           New Feature Added!
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={openedStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="warning"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Selected main feature and sub-feature.
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={openStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="warning"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Selected sub-feature already exists under this main feature.
//         </Alert>
//       </Snackbar>

//       <Snackbar
//         open={updatedOpenStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="neutral"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Existing Feature Updated!
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// };

// export default ViewProduct;
// =======
// import React, { useEffect, useState } from "react";
// import {
//   TextField,
//   Box,
//   FormControl,
//   Alert,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Button,
//   Grid,
//   Container,
//   Snackbar,
// } from "@mui/material";
// import axios from "../../api/axios";
// import { useNavigate, useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import ListProductDetail from "./ListProductDetail";

// const EmployeeList = [
//   { value: "Salaried", label: "Salaried" },
//   { value: "Self-Employed", label: "Self - Employed" },
// ];

// const instantIssuanceList = [
//   { value: "true", label: "true" },
//   { value: "false", label: "false" },
// ];

// const RatingList = [
//   { value: "1", label: "1" },
//   { value: "2", label: "2" },
//   { value: "3", label: "3" },
//   { value: "4", label: "4" },
//   { value: "5", label: "5" },
// ];

// const ViewProduct = () => {
//   const [errors, setErrors] = useState({
//     selectedMainFeatureId: "",
//     mainFeatureWeightage: "",
//     selectedSubFeatureId: "",
//     subFeatureWeightage: "",
//   });

//   const { Id } = useParams();
//   const navigate = useNavigate();
//   const [editProductDetails, setEditProductDetails] = useState(true);
//   const [editProductFeature, setEditProductFeature] = useState(true);
//   const [productImage, setProductImage] = useState(null);

//   const [featureData, setFeatureData] = useState([]);
//   const [exitsFeatureData, setExitsFeatureData] = useState([]);
//   const [productFeatureData, setProductFeatureData] = useState([]);
//   const [combineFeatureData, setCombineFeatureData] = useState([]);

//   useEffect(() => {
//     setCombineFeatureData([...exitsFeatureData]);
//   }, [featureData, exitsFeatureData]);

//   const [productDetails, setProductDetails] = useState({
//     productName: "",
//     organizationName: "",
//     routingUrl: "",
//     employmentType: "",
//     minimumIncome: "",
//     rating: "",
//     instantIssuance: "",
//     incentive: "",
//     productImage: null,
//     description: "",
//     status: "Active",
//     features: [],
//   });

//   const handleChange = (field, value) => {
//     setProductDetails({
//       ...productDetails,
//       [field]: value,
//     });
//   };

//   const handleDocumentUpload = (field, file) => {
//     if (file) {
//       setProductDetails((prevDetails) => ({
//         ...prevDetails,
//         [field]: file,
//       }));
//     } else {
//       console.log("File selection canceled");
//     }
//   };

//   useEffect(() => {
//     const getByProductId = async () => {
//       try {
//         const response = await axios.get(`/api/products/${Id}`);
//         const productData = response?.data;

//         console.log("Product data fetched by ID:", productData);

//         if (productData) {
//           productData.productImage = null;
//         }

//         const response1 = await axios.get(
//           `/api/products/getProductFeatures/${Id}`
//         );
//         const productFeatures = response1?.data;

//         console.log("Product features fetched by ID:", productFeatures);

//         const transformedFeatures = productFeatures.features.map((feature) => ({
//           mainFeatureId: feature.featureId,
//           mainFeatureNames: feature.featureName,
//           mainFeatureWeightage: feature.featureWeight || "",
//           subFeatures: feature.subFeatures.map((subFeature) => ({
//             subFeatureId: subFeature.featureId,
//             subFeatureName: subFeature.featureName,
//             subFeatureWeightage: subFeature.featureWeight || "",
//           })),
//         }));

//         setProductDetails(response?.data);
//         setExitsFeatureData(transformedFeatures);
//         setProductFeatureData(transformedFeatures);
//       } catch (error) {
//         console.error("Error fetching product by ID:", error);
//       }
//     };

//     getByProductId();
//   }, [Id]);

//   useEffect(() => {
//     const getProductImage = async () => {
//       try {
//         const responseImage = await axios.get(`/api/products/image/${Id}`, {
//           responseType: "blob",
//         });
//         setProductImage(responseImage.data);
//       } catch (error) {
//         console.error("Error fetching product image:", error);
//       }
//     };

//     getProductImage();
//   }, [Id]);

//   function transformFeatureData(productId, productFeatureData) {
//     const transformedData = {
//       productId: productId,
//       features: [],
//     };

//     productFeatureData.forEach((mainFeature) => {
//       const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

//       transformedData.features.push({
//         featureId: mainFeature.mainFeatureId,
//         productWeight: mainFeatureWeight,
//       });

//       if (mainFeature.subFeatures) {
//         mainFeature.subFeatures.forEach((subFeature) => {
//           const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
//           transformedData.features.push({
//             featureId: subFeature.subFeatureId,
//             productWeight: subFeatureWeight,
//           });
//         });
//       }
//     });

//     return transformedData;
//   }

//   const deleteFeature = async (productId, featureIds) => {
//     try {
//       const response = await axios.delete('/api/products/removeProductFeatures', {
//         headers: { 'Content-Type': 'application/json' },
//         data: { productId, featureIds }
//       });
//       console.log('Delete feature response:', response.data);
//     } catch (error) {
//       console.error('Error deleting feature:', error);
//     }
//   };

//   const SubmitProductDetails = async () => {
//     console.log("Submitting product details:", productDetails);
//     console.log("Combine feature data before transformation:", combineFeatureData);

//     const formData = new FormData();
//     const res = {
//       productId: productDetails.productId,
//       productName: productDetails.productName,
//       organizationName: productDetails.organizationName,
//       routingUrl: productDetails.routingUrl,
//       employmentType: productDetails.employmentType,
//       minimumIncome: productDetails.minimumIncome,
//       rating: productDetails.rating,
//       instantIssuance: productDetails.instantIssuance,
//       incentive: productDetails.incentive,
//       description: productDetails.description,
//       featureDescription: "",
//       uniqueProductId: "",
//       productType: "",
//       offer: "",
//       offerWeight: "",
//       productFeatures: null,
//     };

//     console.log("Product data to be submitted:", res);

//     formData.append("productDTO", JSON.stringify(res));
//     if (productDetails.productImage) {
//       formData.append("file", productDetails.productImage);
//     } else {
//       const emptyBlob = new Blob([], { type: "application/octet-stream" });
//       const file = new File([emptyBlob], "empty.png", { type: "image/png" });
//       formData.append("file", file);
//     }

//     try {
//       const productResponse = await axios.put("/api/products", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       console.log("Product update response:", productResponse.data);

//       const transformedFeatureData = transformFeatureData(
//         productDetails.productId,
//         combineFeatureData
//       );

//       console.log("Transformed feature data to be submitted:", transformedFeatureData);

//       if (transformedFeatureData.features.length) {
//         try {
//           const featureResponse = await axios.post(
//             "/api/products/addProductFeatures",
//             transformedFeatureData,
//             {
//               headers: { "Content-Type": "application/json" },
//             }
//           );
//           console.log("POST request for features successful:", featureResponse.data);
//         } catch (featureError) {
//           console.error("Error while making POST request for features:", featureError);
//         }
//       } else {
//         console.warn("No features to update.");
//       }

//       setTimeout(() => {
//         navigate("/dashboard/productDetails");
//       }, 1000);
//     } catch (error) {
//       console.error("Error in submitting product details:", error);
//       if (error.response && error.response.data) {
//         console.log("Internal Server Error:", error.response.data);
//       } else {
//         console.log("Error occurred. Please try again.");
//       }
//     }
//   };

//   const toggleEdit = () => {
//     setEditProductDetails(false);
//   };
//   const toggleFeatureEdit = () => {
//     setEditProductFeature(false);
//   };

//   const [createFeature, setCreateFeature] = useState(false);
//   const [openedStatus, setOpenedStatus] = useState(false);
//   const [openStatus, setOpenStatus] = useState(false);
//   const [updatedOpenStatus, setUpdatedOpenStatus] = useState(false);

//   const [productFeature, setProductFeature] = useState(null);
//   const [productSubFeature, setProductSubFeature] = useState(null);

//   const [selectedMainFeatureId, setSelectedMainFeatureId] = useState("");
//   const [mainFeatureWeightage, setMainFeatureWeightage] = useState("");
//   const [mainFeatureName, setMainFeatureName] = useState("");

//   const [selectedSubFeatureId, setSelectedSubFeatureId] = useState("");
//   const [subFeatureWeightage, setSubFeatureWeightage] = useState("");
//   const [subFeatureName, setSubFeatureName] = useState("");

//   const handleMainWeightageChange = (e) => {
//     const weightageValue = e.target.value;
//     setMainFeatureWeightage(weightageValue);

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       mainFeatureWeightage: "",
//     }));
//   };

//   const handleSubWeightageChange = (e) => {
//     const weightageValue = e.target.value;
//     setSubFeatureWeightage(weightageValue);
//   };

//   const getAllFeaturesList = async () => {
//     try {
//       const response = await axios.get("/api/productFeatures/parentFeatures");
//       // Filter out the features with active: "Y"
//       const activeFeatures = response?.data?.filter(feature => feature.active === "Y");
//       setProductFeature(activeFeatures);
//     } catch (error) {
//       console.error("Error fetching all features:", error);
//     }
//   };

//   useEffect(() => {
//     getAllFeaturesList();
//   }, []);

//   const handleFeatureNameChange = async (e) => {
//     const selectedFeatureId = e.target.value;
//     setSelectedMainFeatureId(selectedFeatureId);

//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       selectedMainFeatureId: "",
//     }));

//     try {
//       const response = await axios.get(
//         `/api/productFeatures/subFeatures/${selectedFeatureId}`
//       );
//       // Filter out the sub-features with active: "Y"
//       const activeSubFeatures = response?.data?.filter(subFeature => subFeature.active === "Y");
//       setProductSubFeature(activeSubFeatures);

//       const selectedMainFeature = productFeature.find(
//         (item) => item.featureId === selectedFeatureId
//       );
//       if (selectedMainFeature) {
//         setSelectedMainFeatureId(selectedMainFeature.featureId);
//         setMainFeatureName(selectedMainFeature.featureName);
//       }
//     } catch (error) {
//       console.error("Error fetching sub features:", error);
//     }
//   };

//   const handleSubFeatureChange = (e) => {
//     const selectedSubFeatureId = e.target.value;
//     setSelectedSubFeatureId(selectedSubFeatureId);

//     const selectedSubFeature = productSubFeature.find(
//       (item) => item.featureId === selectedSubFeatureId
//     );
//     if (selectedSubFeature) {
//       setSelectedSubFeatureId(selectedSubFeature.featureId);
//       setSubFeatureName(selectedSubFeature.featureName);
//     }
//   };

//   useEffect(() => {
//     console.log("Updated Feature Data:", featureData);
//   }, [featureData]);

//   const handleClose = () => {
//     setOpenStatus(false);
//     setCreateFeature(false);
//     setUpdatedOpenStatus(false);
//     setOpenedStatus(false);
//   };

//   const handleAddFeature = () => {
//     const newErrors = {};

//     if (!selectedMainFeatureId) {
//       newErrors.selectedMainFeatureId = "Main feature is required";
//     }
//     if (!mainFeatureWeightage) {
//       newErrors.mainFeatureWeightage = "Weightage for main feature is required";
//     }
//     if (!selectedSubFeatureId) {
//       newErrors.selectedSubFeatureId = "Sub feature selection is required";
//     }
//     if (!subFeatureWeightage) {
//       newErrors.subFeatureWeightage = "Weightage for sub feature is required";
//     }

//     setErrors(newErrors);

//     if (Object.keys(newErrors).length > 0) {
//       setOpenedStatus(true);
//       return;
//     }

//     const existingFeatureIndex = combineFeatureData.findIndex(
//       (feature) => feature.mainFeatureId === selectedMainFeatureId
//     );

//     if (existingFeatureIndex >= 0) {
//       const existingFeature = combineFeatureData[existingFeatureIndex];
//       const subFeatureExists = existingFeature.subFeatures.some(
//         (sub) => sub.subFeatureId === selectedSubFeatureId
//       );

//       if (!subFeatureExists) {
//         existingFeature.subFeatures.push({
//           subFeatureId: selectedSubFeatureId,
//           subFeatureName: subFeatureName,
//           subFeatureWeightage: subFeatureWeightage,
//         });
//         const updatedFeatures = [...combineFeatureData];
//         updatedFeatures[existingFeatureIndex] = existingFeature;
//         setCombineFeatureData(updatedFeatures);
//         setCreateFeature(true);
//       } else {
//         setOpenStatus(true);
//       }
//     } else {
//       const newFeature = {
//         mainFeatureId: selectedMainFeatureId,
//         mainFeatureNames: mainFeatureName,
//         mainFeatureWeightage: mainFeatureWeightage,
//         subFeatures: [
//           {
//             subFeatureId: selectedSubFeatureId,
//             subFeatureName: subFeatureName,
//             subFeatureWeightage: subFeatureWeightage,
//           },
//         ],
//       };
//       setCombineFeatureData([...combineFeatureData, newFeature]);
//       setCreateFeature(true);
//     }

//     setSelectedMainFeatureId("");
//     setMainFeatureWeightage("");
//     setMainFeatureName("");
//     setSelectedSubFeatureId("");
//     setSubFeatureWeightage("");
//     setSubFeatureName("");
//   };

//   const [listData, setListData] = useState([]);
//   const handleRowRemove = async (mainFeatureId) => {
//     const featureIdsToDelete = [mainFeatureId, ...combineFeatureData.find(feature => feature.mainFeatureId === mainFeatureId)?.subFeatures.map(sub => sub.subFeatureId)];
//     await deleteFeature(productDetails.productId, featureIdsToDelete);

//     const updatedFeatures = combineFeatureData.filter(
//       (feature) => feature.mainFeatureId !== mainFeatureId
//     );
//     setCombineFeatureData(updatedFeatures);
//     setEditProductFeature(false);
//   };

//   const handleSubFeatureRemove = async (mainFeatureId, subFeatureId) => {
//     await deleteFeature(productDetails.productId, [subFeatureId]);

//     const updatedFeatures = combineFeatureData.map((feature) => {
//       if (feature.mainFeatureId === mainFeatureId) {
//         const updatedSubFeatures = feature.subFeatures.filter(
//           (sub) => sub.subFeatureId !== subFeatureId
//         );
//         return { ...feature, subFeatures: updatedSubFeatures };
//       }
//       return feature;
//     });
//     setCombineFeatureData(updatedFeatures);
//     setEditProductFeature(false);
//   };

//   return (
//     <div
//       style={{
//         flexGrow: 1,
//         marginTop: "0px",
//         position: "absolute",
//         right: "0",
//         top: "0",
//         width: "82%",
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: "#f3f7f6",
//           marginTop: "5%",
//           padding: "1.5%",
//           paddingBottom: "2%",
//         }}
//       >
//         <h2>View Product</h2>
//       </div>

//       <div
//         style={{
//           padding: "2%",
//           backgroundColor: "#ffffff",
//           borderRadius: "4px",
//           boxShadow: "0px 0px 7px 0px #cfc5c56b",
//           width: "91%",
//           marginLeft: "2%",
//           marginTop: "3%",
//           marginBottom: "3%",
//         }}
//       >
//         <Container>
//           <Grid container spacing={2}>
//             <Grid item xs={6} md={6} lg={6}>
//               <Typography
//                 variant="h5"
//                 gutterBottom
//                 sx={{
//                   fontWeight: "bold",
//                   marginTop: "20px",
//                 }}
//               >
//                 Product Details
//               </Typography>
//             </Grid>
//             <Grid item xs={6} md={6} lg={6}>
//               {editProductDetails && (
//                 <Button
//                   onClick={toggleEdit}
//                   variant="text"
//                   style={{
//                     fontWeight: "bold",
//                     color: "blue",
//                     textDecoration: "underline",
//                     marginTop: "20px",
//                   }}
//                 >
//                   Edit
//                 </Button>
//               )}
//             </Grid>
//           </Grid>

//           <Grid
//             container
//             spacing={2}
//             style={{ margin: "5vh 10px 10px 0px" }}
//             className="form-container"
//           >
//             <Grid container spacing={2} item xs={8} md={8} lg={8}>
//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Product Name
//                 </Typography>
//                 <TextField
//                   placeholder="Enter card name"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails.productName}
//                   onChange={(e) => handleChange("productName", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Organization Name
//                 </Typography>
//                 <TextField
//                   placeholder="Enter organization name"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails.organizationName}
//                   onChange={(e) =>
//                     handleChange("organizationName", e.target.value)
//                   }
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Routing Url
//                 </Typography>
//                 <TextField
//                   placeholder="Enter routingUrl"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.routingUrl || ""}
//                   onChange={(e) => handleChange("routingUrl", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Employee Type
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="employee-type-label"
//                     value={productDetails.employmentType}
//                     onChange={(e) =>
//                       handleChange("employmentType", e.target.value)
//                     }
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {EmployeeList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Minimum Salary
//                 </Typography>
//                 <TextField
//                   placeholder="Enter Minimum Salary"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.minimumIncome || ""}
//                   onChange={(e) =>
//                     handleChange("minimumIncome", e.target.value)
//                   }
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Rating
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="customer-name-label"
//                     value={productDetails.rating}
//                     onChange={(e) => handleChange("rating", e.target.value)}
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {RatingList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} md={6} lg={6}>
//                 <Typography
//                   variant="subtitle1"
//                   sx={{ fontSize: "11px", marginBottom: "-5%" }}
//                 >
//                   Instant Issuance
//                 </Typography>
//                 <FormControl
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <Select
//                     labelId="instant-issuance-label"
//                     value={productDetails.instantIssuance}
//                     onChange={(e) =>
//                       handleChange("instantIssuance", e.target.value)
//                     }
//                     inputProps={{ readOnly: editProductDetails }}
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {instantIssuanceList.map((item) => (
//                       <MenuItem key={item.value} value={item.value}>
//                         {item.label}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={6} sm={6} md={6}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Incentive
//                 </Typography>
//                 <TextField
//                   placeholder="Enter incentive"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.incentive || ""}
//                   onChange={(e) => handleChange("incentive", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={12} md={12}>
//                 <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
//                   Feature Description
//                 </Typography>
//                 <TextField
//                   placeholder="Enter feature description"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": {
//                       fontSize: "11px",
//                     },
//                   }}
//                   size="small"
//                   value={productDetails?.description || ""}
//                   onChange={(e) => handleChange("description", e.target.value)}
//                   InputProps={editProductDetails ? { readOnly: true } : {}}
//                 />
//               </Grid>
//             </Grid>

//             <Grid className="imageGrid" item xs={4} md={4} lg={4}>
//               <Grid item xs={12} sm={12} md={12}>
//                 <Box
//                   mt={2}
//                   style={{
//                     width: "180px",
//                     height: "180px",
//                     backgroundColor: "#f0f0f0",
//                     border: "2px solid #f0f0f0",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     overflow: "hidden",
//                   }}
//                 >
//                   {productDetails.productImage ? (
//                     <img
//                       src={URL.createObjectURL(productDetails.productImage)}
//                       alt="Uploaded"
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={
//                         productImage ? URL.createObjectURL(productImage) : ""
//                       }
//                       alt="Uploaded"
//                       style={{
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                         objectFit: "cover",
//                       }}
//                     />
//                   )}
//                 </Box>

//                 {!editProductDetails && (
//                   <Button
//                     variant="outlined"
//                     component="label"
//                     style={{
//                       "& .MuiButton-root": {
//                         height: "40px",
//                         textTransform: "none",
//                       },
//                     }}
//                   >
//                     Upload Image
//                     <input
//                       type="file"
//                       accept="image/*"
//                       style={{ display: "none" }}
//                       onChange={(e) =>
//                         handleDocumentUpload("productImage", e.target.files[0])
//                       }
//                     />
//                   </Button>
//                 )}
//               </Grid>
//             </Grid>
//           </Grid>
//         </Container>
//       </div>

//       {!editProductFeature && (
//         <div
//           style={{
//             padding: "2%",
//             backgroundColor: "#ffffff",
//             borderRadius: "4px",
//             boxShadow: "0px 0px 7px 0px #cfc5c56b",
//             width: "91%",
//             marginLeft: "2%",
//             marginTop: "3%",
//             marginBottom: "3%",
//           }}
//         >
//           <Container>
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontWeight: "bold",
//                 marginTop: "20px",
//                 marginBottom: "4%",
//               }}
//             >
//               Assigned Product Feature
//             </Typography>

//             <Grid container spacing={1} className="form-container">
//               <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
//                 <FormControl
//                   error={!!errors.selectedMainFeatureId}
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <InputLabel id="main-feature-label">
//                     Select Main Feature
//                   </InputLabel>
//                   <Select
//                     labelId="main-feature-label"
//                     value={selectedMainFeatureId}
//                     onChange={handleFeatureNameChange}
//                     error={!!errors.selectedMainFeatureId}
//                     helperText={
//                       errors.selectedMainFeatureId || "Select a main feature."
//                     }
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {productFeature ? (
//                       productFeature.map((item) => (
//                         <MenuItem key={item.featureId} value={item.featureId}>
//                           {item.featureName}
//                         </MenuItem>
//                       ))
//                     ) : (
//                       <MenuItem disabled>No Main features available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={1.5} sm={1.5} md={1.5}>
//                 <div
//                   style={{
//                     textAlign: "center",
//                     backgroundColor: "#efefef",
//                     borderRadius: "4px",
//                     padding: "4%",
//                     marginRight: "-10%",
//                     paddingLeft: "22%",
//                   }}
//                 >
//                   Weightage %
//                 </div>
//               </Grid>
//               <Grid item xs={1} sm={1} md={1}>
//                 <TextField
//                   required
//                   placeholder="%"
//                   type="number"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                   size="small"
//                   value={mainFeatureWeightage}
//                   onChange={handleMainWeightageChange}
//                   error={!!errors.mainFeatureWeightage}
//                   helperText={errors.mainFeatureWeightage}
//                 />
//               </Grid>

//               <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
//                 <FormControl
//                   error={!!errors.selectedSubFeatureId}
//                   fullWidth
//                   size="small"
//                   margin="normal"
//                   sx={{
//                     "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                 >
//                   <InputLabel id="sub-feature-label">
//                     Select Sub Feature
//                   </InputLabel>
//                   <Select
//                     labelId="sub-feature-label"
//                     value={selectedSubFeatureId}
//                     onChange={handleSubFeatureChange}
//                     error={!!errors.selectedSubFeatureId}
//                     helperText={
//                       errors.selectedSubFeatureId || "Select a sub feature."
//                     }
//                     MenuProps={{
//                       PaperProps: {
//                         style: {
//                           maxHeight: 200,
//                           width: 250,
//                         },
//                       },
//                     }}
//                   >
//                     {productSubFeature ? (
//                       productSubFeature.map((item) => (
//                         <MenuItem key={item.featureId} value={item.featureId}>
//                           {item.featureName}
//                         </MenuItem>
//                       ))
//                     ) : (
//                       <MenuItem disabled>No sub features available</MenuItem>
//                     )}
//                   </Select>
//                 </FormControl>
//               </Grid>

//               <Grid item xs={1.5} sm={1.5} md={1.5}>
//                 <div
//                   style={{
//                     textAlign: "center",
//                     backgroundColor: "#efefef",
//                     borderRadius: "4px",
//                     padding: "4%",
//                     marginRight: "-10%",
//                     paddingLeft: "22%",
//                   }}
//                 >
//                   Weightage %
//                 </div>
//               </Grid>
//               <Grid item xs={1} sm={1} md={1}>
//                 <TextField
//                   placeholder="%"
//                   type="number"
//                   fullWidth
//                   sx={{
//                     "& .MuiInputBase-root": {
//                       height: "30px",
//                       fontSize: "11px",
//                     },
//                     "& .MuiFormLabel-root": { fontSize: "11px" },
//                   }}
//                   size="small"
//                   value={subFeatureWeightage}
//                   onChange={handleSubWeightageChange}
//                   error={!!errors.subFeatureWeightage}
//                   helperText={errors.subFeatureWeightage}
//                 />
//               </Grid>
//             </Grid>

//             <Grid
//               container
//               spacing={1}
//               className="form-container"
//               sx={{ marginTop: "2%" }}
//             >
//               <Grid item xs={12} sm={12} md={12} textAlign="right">
//                 <Button variant="contained" onClick={handleAddFeature}>
//                   Add Feature(s)
//                 </Button>
//               </Grid>
//             </Grid>
//           </Container>
//         </div>
//       )}

//       <div
//         style={{
//           padding: "2%",
//           backgroundColor: "#ffffff",
//           borderRadius: "4px",
//           boxShadow: "0px 0px 7px 0px #cfc5c56b",
//           width: "91%",
//           marginLeft: "2%",
//           marginTop: "3%",
//           marginBottom: "4%",
//         }}
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={6} md={6} lg={6}>
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontWeight: "bold",
//                 marginLeft: "1%",
//                 fontSize: "23px",
//                 marginBottom: "2%",
//               }}
//             >
//               Product Features Details
//             </Typography>
//           </Grid>
//           <Grid item xs={6} md={6} lg={6}>
//             {editProductFeature && (
//               <Button
//                 onClick={toggleFeatureEdit}
//                 variant="text"
//                 style={{
//                   fontWeight: "bold",
//                   color: "blue",
//                   textDecoration: "underline",
//                   marginBottom: "2%",
//                 }}
//               >
//                 Edit
//               </Button>
//             )}
//           </Grid>
//         </Grid>

//         <ListProductDetail
//           listData={combineFeatureData}
//           handleRowRemove={handleRowRemove}
//           handleSubFeatureRemove={handleSubFeatureRemove}
//         />
//       </div>

//       {(!editProductDetails || !editProductFeature) && (
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={SubmitProductDetails}
//           sx={{
//             float: "right",
//             marginTop: "1%",
//             marginRight: "3%",
//             marginLeft: "2%",
//             marginBottom: "3%",
//           }}
//         >
//           Update
//         </Button>
//       )}
//       <Link to="/dashboard/productDetails">
//         <Button
//           variant="outlined"
//           sx={{
//             float: "right",
//             marginTop: "1%",
//             marginBottom: "3%",
//           }}
//         >
//           Back
//         </Button>
//       </Link>

//       <Snackbar
//         open={createFeature}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="success"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           New Feature Added!
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={openedStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="warning"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Selected main feature and sub-feature.
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={openStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="warning"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Selected sub-feature already exists under this main feature.
//         </Alert>
//       </Snackbar>

//       <Snackbar
//         open={updatedOpenStatus}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleClose}
//           severity="neutral"
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           Existing Feature Updated!
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// };

// export default ViewProduct;
// ====
import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../api/axios';
import usePublicAxiosInstance from '../../api/pubicAxios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  Autocomplete,
  Modal,
  Snackbar,
  Alert,
  InputLabel,
  Paper, FormHelperText,FormControlLabel,FormLabel,RadioGroup,Radio
} from '@mui/material';

import ListProductDetail from './ListProductDetail'; // Ensure this import path is correct
import axiosInstance from 'axios';


const EmployeeList = [
  { value: "Salaried", label: "Salaried" },
  { value: "Self-Employed", label: "Self - Employed" },
];

const instantIssuanceList = [
  { value: "true", label: "true" },
  { value: "false", label: "false" },
];

const RatingList = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

const ProductList = [
  {
    value: 'Card',
    label: 'Card',
  },
  {
    value: 'Loan',
    label: 'Loan',
  }
];

const ViewProduct = () => {

  const axio = usePublicAxiosInstance();

  const [errors, setErrors] = useState({
    selectedMainFeatureId: "",
    mainFeatureWeightage: "",
    selectedSubFeatureId: "",
    subFeatureWeightage: "",
  });

  const { Id } = useParams();
  const navigate = useNavigate();
  const [editProductDetails, setEditProductDetails] = useState(true);
  const [editProductFeature, setEditProductFeature] = useState(true);
  const [productImage, setProductImage] = useState(null);
  const [featureData, setFeatureData] = useState([]);
  const [exitsFeatureData, setExitsFeatureData] = useState([]);
  const [productFeatureData, setProductFeatureData] = useState([]);
  const [combineFeatureData, setCombineFeatureData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFeatureToDelete, setSelectedFeatureToDelete] = useState(null);
  const [selectedSubFeatureToDelete, setSelectedSubFeatureToDelete] = useState(null);


  const [endNodeUsers, setEndNodeUsers] = useState([]);
  const [featuresOld, setFeaturesOld] = useState([]);

  const handleChangeParents = (field, value) => {
    // Ensure no duplicate selections are added
    const uniqueNewValue = value.filter(
      newVal => !endNodeUsers.some(oldVal => oldVal.featureId === newVal.featureId)
    );
    // Combine existing values with new unique values
    const combinedValue = [...endNodeUsers.filter(val => value.some(newVal => newVal.featureId === val.featureId)), ...uniqueNewValue];
    setEndNodeUsers(combinedValue);
  };

  const axios = useAxiosInstance();

  useEffect(() => {
    setCombineFeatureData([...exitsFeatureData]);
  }, [featureData, exitsFeatureData]);

  const [productDetails, setProductDetails] = useState({
    productName: '',
    organizationName: '',
    routingUrl: '',
    employmentType: '',
    minimumIncome: '',
    rating: '',
    instantIssuance: '',
    incentive: '',
    productImage: null,
    description: '',
    annualFee:'',
    tenure:'',
    processingFee:'',
    interest:'',
    loanAmount:'',
    loanAmount:'',
    loanAmountTxt:'',
    salaryTransfer:'',
    productType:'',
    freeToJoin:'',
    welcomeBonus:'',
    status: 'Active'
  });

  const handleChange = (field, value) => {
    setProductDetails({
      ...productDetails,
      [field]: value,
    });
  };

  const handleDocumentUpload = (field, file) => {
    if (file) {
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        [field]: file,
      }));
    } else {
      console.log("File selection canceled");
    }
  };

  useEffect(() => {
    const getByProductId = async () => {
      try {
        const response = await axios.get(`/api/products/${Id}`);
        const productData = response?.data;

        console.log("Product data fetched by ID:", productData);

        if (productData) {
          productData.productImage = null;
        }

        if(productData.productType === 'Card'){

        const response1 = await axios.get(
          `/api/products/getProductFeatures/${Id}`
        );
        const productFeatures = response1?.data;

        console.log("Product features fetched by ID:", productFeatures);

        const transformedFeatures = productFeatures.features.map((feature) => ({
          mainFeatureId: feature.featureId,
          mainFeatureNames: feature.featureName,
          mainFeatureWeightage: feature.featureWeight || "",
          subFeatures: feature.subFeatures.map((subFeature) => ({
            subFeatureId: subFeature.featureId,
            subFeatureName: subFeature.featureName,
            subFeatureWeightage: subFeature.featureWeight || "",
          })),
        }));

        setExitsFeatureData(transformedFeatures);
        setProductFeatureData(transformedFeatures);
        setProductDetails(response?.data);
        setFeaturesOld(productData?.features || []);

      }else{
        // setProductDetails(response?.data);
        setFeaturesOld(productData?.features || []);

        setProductDetails({
          ...productData,
          salaryTransfer: productData.salaryTransfer ? '1' : '0'
        });
      }
        
      } catch (error) {
        console.error("Error fetching product by ID:", error);
      }
    };

    getByProductId();
  }, [Id]);

  useEffect(() => {
    const getProductImage = async () => {
      try {
        const responseImage = await axio.get(`/api/products/public/image/${Id}`, {
          responseType: "blob",
        });
        setProductImage(responseImage.data);
      } catch (error) {
        console.error("Error fetching product image:", error);
      }
    };

    getProductImage();
  }, [Id]);

  function transformFeatureData(productId, productFeatureData) {
    const transformedData = {
      productId: productId,
      features: [],
    };

    productFeatureData.forEach((mainFeature) => {
      const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

      transformedData.features.push({
        featureId: mainFeature.mainFeatureId,
        productWeight: mainFeatureWeight,
      });

      if (mainFeature.subFeatures) {
        mainFeature.subFeatures.forEach((subFeature) => {
          const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
          transformedData.features.push({
            featureId: subFeature.subFeatureId,
            productWeight: subFeatureWeight,
          });
        });
      }
    });

    return transformedData;
  }

  const deleteFeature = async (productId, featureIds) => {
    try {
      const response = await axios.delete('/api/products/removeProductFeatures', {
        headers: { 'Content-Type': 'application/json' },
        data: { productId, featureIds }
      });
      console.log('Delete feature response:', response.data);
    } catch (error) {
      console.error('Error deleting feature:', error);
    }
  };

  const SubmitProductDetails = async () => {
    // console.log("Submitting product details:", featuresOld);
    // console.log("Combine feature data before transformation:", endNodeUsers);

    let uniqueArray = endNodeUsers.filter(newItem => {
      return !featuresOld.some(assignItem => assignItem.featureId === newItem.featureId);
  });
  // console.log(uniqueArray);

    const formData = new FormData();

    const res = {
      productId:productDetails.productId,
      productName: productDetails.productName,
      organizationName: productDetails.organizationName,
      routingUrl: productDetails.routingUrl,
      employmentType: productDetails.employmentType,
      minimumIncome: productDetails.minimumIncome,
      rating: productDetails.rating,
      instantIssuance: productDetails.instantIssuance,
      incentive: productDetails.incentive,
      annualFee: productDetails.annualFee,
      productType: productDetails.productType,
      freeToJoin: productDetails.freeToJoin,
      welcomeBonus: productDetails.welcomeBonus,
      description: productDetails.description,
      tenure:productDetails.tenure,
      processingFee:productDetails.processingFee,
      interest:productDetails.interest,
      loanAmount:productDetails.loanAmount,
      salaryTransfer:productDetails.salaryTransfer === '1' ? true : false,
      featureDescription: "",
      loanTenure:productDetails.loanTenure,
      loanAmountTxt:productDetails.loanAmountTxt,
      productType: productDetails.productType,
      offer: "",
      offerWeight: "",
       status: "Active",
      productFeatures: null,
    };
  
   

    // console.log("Product data to be submitted:", res);

    formData.append("productDTO", JSON.stringify(res));
    if (productDetails.productImage) {
      formData.append("file", productDetails.productImage);
    } else {
      const emptyBlob = new Blob([], { type: "application/octet-stream" });
      const file = new File([emptyBlob], "empty.png", { type: "image/png" });
      formData.append("file", file);
    }

    try {
      const productResponse = await axios.put("/api/products", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // console.log("Product update response:", productResponse.data);

      if (uniqueArray && productDetails.productType === 'Loan') {

        const productId =  productDetails.productId;
        const resLoan = {
          productId,
          features : uniqueArray.map(
                      ({ featureId,featureName}) => ({
                        featureId,
                        featureName,
                        productType: "Loan",
                        active: "Y",
                      })
                    )
        }

        // console.log(resLoan)
        try {
           await axios.post(
            "/api/products/addProductFeatures",
            resLoan,
            { headers: { "Content-Type": "application/json" } }
          );
        } catch (error) {
          console.error("Error while making POST request:", error);
        }
      }else{
       

      const transformedFeatureData = transformFeatureData(
        productDetails.productId,
        combineFeatureData
      );

      console.log("Transformed feature data to be submitted:", transformedFeatureData);

      if (transformedFeatureData.features.length) {
        try {
          const featureResponse = await axios.post(
            "/api/products/addProductFeatures",
            transformedFeatureData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          console.log("POST request for features successful:", featureResponse.data);
        } catch (featureError) {
          console.error("Error while making POST request for features:", featureError);
        }
      } else {
        console.warn("No features to update.");
      }
 
    }

      setTimeout(() => {
        navigate("/dashboard/productDetails");
      }, 1000);
    } catch (error) {
      console.error("Error in submitting product details:", error);
      if (error.response && error.response.data) {
        console.log("Internal Server Error:", error.response.data);
      } else {
        console.log("Error occurred. Please try again.");
      }
    }
  };

  const toggleEdit = () => {
    setEditProductDetails(false);
  };
  const toggleFeatureEdit = () => {
    setEditProductFeature(false);
  };

  const [createFeature, setCreateFeature] = useState(false);
  const [openedStatus, setOpenedStatus] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [updatedOpenStatus, setUpdatedOpenStatus] = useState(false);

  const [productFeature, setProductFeature] = useState([]);
  const [productSubFeature, setProductSubFeature] = useState(null);

  const [selectedMainFeatureId, setSelectedMainFeatureId] = useState("");
  const [mainFeatureWeightage, setMainFeatureWeightage] = useState("");
  const [mainFeatureName, setMainFeatureName] = useState("");

  const [selectedSubFeatureId, setSelectedSubFeatureId] = useState("");
  const [subFeatureWeightage, setSubFeatureWeightage] = useState("");
  const [subFeatureName, setSubFeatureName] = useState("");

  const handleMainWeightageChange = (e) => {
    const weightageValue = e.target.value;
    setMainFeatureWeightage(weightageValue);

    setErrors((prevErrors) => ({
      ...prevErrors,
      mainFeatureWeightage: "",
    }));
  };

  const handleSubWeightageChange = (e) => {
    const weightageValue = e.target.value;
    setSubFeatureWeightage(weightageValue);
  };


  const getAllFeaturesList = async () => {
    try {
      const response = await axios.get(`/api/productFeatures/parentFeatures?productType=${productDetails.productType}`);
      
      if (response && response.data) {
        const activeParentFeatures = response.data.filter(item => item.active === "Y");
        setProductFeature(activeParentFeatures);
        console.log(activeParentFeatures);
      } else {
        setProductFeature([]); // set to an empty array if there's no data
      }
    } catch (error) {
      console.error("Axios Error:", error);
      setProductFeature([]); // set to an empty array in case of error
    }
  };
  
    useEffect(() => {
      if (productDetails.productType) {
        getAllFeaturesList();
      }
    }, [productDetails.productType]);
  

  const handleFeatureNameChange = async (e) => {
    const selectedFeatureId = e.target.value;
    setSelectedMainFeatureId(selectedFeatureId);

    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedMainFeatureId: "",
    }));

    try {
      const response = await axios.get(
        `/api/productFeatures/subFeatures/${selectedFeatureId}`
      );
      // Filter out the sub-features with active: "Y"
      const activeSubFeatures = response?.data?.filter(subFeature => subFeature.active === "Y");
      setProductSubFeature(activeSubFeatures);

      const selectedMainFeature = productFeature.find(
        (item) => item.featureId === selectedFeatureId
      );
      if (selectedMainFeature) {
        setSelectedMainFeatureId(selectedMainFeature.featureId);
        setMainFeatureName(selectedMainFeature.featureName);
      }
    } catch (error) {
      console.error("Error fetching sub features:", error);
    }
  };

  const handleSubFeatureChange = (e) => {
    const selectedSubFeatureId = e.target.value;
    setSelectedSubFeatureId(selectedSubFeatureId);

    const selectedSubFeature = productSubFeature.find(
      (item) => item.featureId === selectedSubFeatureId
    );
    if (selectedSubFeature) {
      setSelectedSubFeatureId(selectedSubFeature.featureId);
      setSubFeatureName(selectedSubFeature.featureName);
    }
  };

  useEffect(() => {
    console.log("Updated Feature Data:", featureData);
  }, [featureData]);

  const handleClose = () => {
    setOpenStatus(false);
    setCreateFeature(false);
    setUpdatedOpenStatus(false);
    setOpenedStatus(false);
  };

  const handleAddFeature = () => {
    const newErrors = {};

    if (!selectedMainFeatureId) {
      newErrors.selectedMainFeatureId = "Main feature is required";
    }
    if (!mainFeatureWeightage) {
      newErrors.mainFeatureWeightage = "Weightage for main feature is required";
    }
    if (!selectedSubFeatureId) {
      newErrors.selectedSubFeatureId = "Sub feature selection is required";
    }
    if (!subFeatureWeightage) {
      newErrors.subFeatureWeightage = "Weightage for sub feature is required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setOpenedStatus(true);
      return;
    }

    const existingFeatureIndex = combineFeatureData.findIndex(
      (feature) => feature.mainFeatureId === selectedMainFeatureId
    );

    if (existingFeatureIndex >= 0) {
      const existingFeature = combineFeatureData[existingFeatureIndex];
      const subFeatureExists = existingFeature.subFeatures.some(
        (sub) => sub.subFeatureId === selectedSubFeatureId
      );

      if (!subFeatureExists) {
        existingFeature.subFeatures.push({
          subFeatureId: selectedSubFeatureId,
          subFeatureName: subFeatureName,
          subFeatureWeightage: subFeatureWeightage,
        });
        const updatedFeatures = [...combineFeatureData];
        updatedFeatures[existingFeatureIndex] = existingFeature;
        setCombineFeatureData(updatedFeatures);
        setCreateFeature(true);
      } else {
        setOpenStatus(true);
      }
    } else {
      const newFeature = {
        mainFeatureId: selectedMainFeatureId,
        mainFeatureNames: mainFeatureName,
        mainFeatureWeightage: mainFeatureWeightage,
        subFeatures: [
          {
            subFeatureId: selectedSubFeatureId,
            subFeatureName: subFeatureName,
            subFeatureWeightage: subFeatureWeightage,
          },
        ],
      };
      setCombineFeatureData([...combineFeatureData, newFeature]);
      setCreateFeature(true);
    }

    setSelectedMainFeatureId("");
    setMainFeatureWeightage("");
    setMainFeatureName("");
    setSelectedSubFeatureId("");
    setSubFeatureWeightage("");
    setSubFeatureName("");
  };

  const handleRowRemove = (mainFeatureId) => {
    setSelectedFeatureToDelete(mainFeatureId);
    setModalOpen(true);
  };

  const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
    setSelectedFeatureToDelete(mainFeatureId);
    setSelectedSubFeatureToDelete(subFeatureId);
    setModalOpen(true);
  };

  const confirmDeleteFeature = async () => {
    if (selectedFeatureToDelete && !selectedSubFeatureToDelete) {
      const featureIdsToDelete = [selectedFeatureToDelete, ...combineFeatureData.find(feature => feature.mainFeatureId === selectedFeatureToDelete)?.subFeatures.map(sub => sub.subFeatureId)];
      await deleteFeature(productDetails.productId, featureIdsToDelete);

      const updatedFeatures = combineFeatureData.filter(
        (feature) => feature.mainFeatureId !== selectedFeatureToDelete
      );
      setCombineFeatureData(updatedFeatures);
    } else if (selectedFeatureToDelete && selectedSubFeatureToDelete) {
      await deleteFeature(productDetails.productId, [selectedSubFeatureToDelete]);

      const updatedFeatures = combineFeatureData.map((feature) => {
        if (feature.mainFeatureId === selectedFeatureToDelete) {
          const updatedSubFeatures = feature.subFeatures.filter(
            (sub) => sub.subFeatureId !== selectedSubFeatureToDelete
          );
          return { ...feature, subFeatures: updatedSubFeatures };
        }
        return feature;
      });
      setCombineFeatureData(updatedFeatures);
    }

    setModalOpen(false);
    setSelectedFeatureToDelete(null);
    setSelectedSubFeatureToDelete(null);
    setEditProductFeature(false);
  };

  const cancelDeleteFeature = () => {
    setModalOpen(false);
    setSelectedFeatureToDelete(null);
    setSelectedSubFeatureToDelete(null);
  };

 

  useEffect(() => {
    if (featuresOld.length > 0 && productFeature.length > 0) {
      const filteredNodes = featuresOld.filter((item) =>
        productFeature.some(feature => feature.featureId === item.featureId)
      );
      setEndNodeUsers(filteredNodes);
    }
  }, [productFeature, featuresOld]);






  return (
    <div
      style={{
        flexGrow: 1,
        marginTop: "0px",
        position: "absolute",
        right: "0",
        top: "0",
        width: "82%",
      }}
    >
      <div
        style={{
          backgroundColor: "#f3f7f6",
          marginTop: "5%",
          padding: "1.5%",
          paddingBottom: "2%",
        }}
      >
        <h2>View Product</h2>
      </div>

      <div
        style={{
          padding: "2%",
          backgroundColor: "#ffffff",
          borderRadius: "4px",
          boxShadow: "0px 0px 7px 0px #cfc5c56b",
          width: "91%",
          marginLeft: "2%",
          marginTop: "3%",
          marginBottom: "3%",
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                Product Details
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {editProductDetails && (
                <Button
                  onClick={toggleEdit}
                  variant="text"
                  style={{
                    fontWeight: "bold",
                    color: "blue",
                    textDecoration: "underline",
                    marginTop: "20px",
                  }}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ margin: "5vh 10px 10px 0px" }}
            className="form-container"
          >
            <Grid container spacing={2} item xs={8} md={8} lg={8}>

            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px", marginBottom: '-5%' }}>Product Type</Typography>
               
            <FormControl
             fullWidth
             size="small"
             margin="normal"
             sx={{
               '& .MuiInputBase-root': { height: '30px', fontSize: '11px' },
               '& .MuiFormLabel-root': { fontSize: '11px' }
             }}
           >
             <Select
               labelId="customer-name-label"
               value={productDetails.productType}
               onChange={(e) => handleChange('productType', e.target.value)}
               displayEmpty
               inputProps={{ readOnly: true }}
               MenuProps={{
                 PaperProps: {
                   style: {
                     maxHeight: 200,
                     width: 200,
                   },
                 },
               }}
             >
               <MenuItem value="" disabled>
                 Select Product Type
               </MenuItem>
               {ProductList.map((item) => (
                 <MenuItem key={item.value} value={item.value}>
                   {item.label}
                 </MenuItem>
               ))}
             </Select>
           </FormControl>
            </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Product Name
                </Typography>
                <TextField
                  placeholder="Enter card name"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails.productName}
                  onChange={(e) => handleChange("productName", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Organization Name
                </Typography>
                <TextField
                  placeholder="Enter organization name"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails.organizationName}
                  onChange={(e) =>
                    handleChange("organizationName", e.target.value)
                  }
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Routing Url
                </Typography>
                <TextField
                  placeholder="Enter routingUrl"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.routingUrl || ""}
                  onChange={(e) => handleChange("routingUrl", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>

              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "11px", marginBottom: "-5%" }}
                >
                  Employee Type
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  margin="normal"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                >
                  <Select
                    labelId="employee-type-label"
                    value={productDetails.employmentType}
                    onChange={(e) =>
                      handleChange("employmentType", e.target.value)
                    }
                    inputProps={{ readOnly: editProductDetails }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {EmployeeList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Minimum Salary
                </Typography>
                <TextField
                  placeholder="Enter Minimum Salary"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.minimumIncome || ""}
                  onChange={(e) =>
                    handleChange("minimumIncome", e.target.value)
                  }
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>


              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Incentive
                </Typography>
                <TextField
                  placeholder="Enter incentive"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.incentive || ""}
                  onChange={(e) => handleChange("incentive", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>


              {productDetails.productType === 'Loan' ?  

<>
<Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Tenure</Typography>
                <TextField
                  placeholder='Enter Tenure'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.tenure}
                  onChange={(e) => handleChange('tenure', e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>processing Fees</Typography>
                <TextField
                  placeholder='Enter Processing Fees'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.processingFee}
                  onChange={(e) => handleChange('processingFee', e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Interest Rate</Typography>
                <TextField
                  placeholder='Enter insteresr rate'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.interest}
                  onChange={(e) => handleChange('interest', e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Loan Amount</Typography>
                <TextField
                  placeholder='Enter loan amount'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.loanAmount}
                  onChange={(e) => handleChange('loanAmount', e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
  <FormControl>
    <FormLabel id="demo-row-radio-buttons-group-label">Salary Transfer</FormLabel>
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={productDetails.salaryTransfer}
      onChange={(e) => handleChange('salaryTransfer', e.target.value)}
    >
      <FormControlLabel value="1" control={<Radio disabled={editProductDetails} />} label="Required" />
      <FormControlLabel value="0" control={<Radio disabled={editProductDetails} />} label="Not Required" />
    </RadioGroup>
  </FormControl>
</Grid>


<Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Loan Amount Text</Typography>
                <TextField
                  placeholder='Enter loan amount text'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.loanAmountTxt}
                  onChange={(e) => handleChange('loanAmountTxt', e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>


              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Loan Tenure </Typography>
                <TextField
                  placeholder='Enter loan tenure'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.loanTenure}
                  onChange={(e) => handleChange('loanTenure', e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>

</>
:

<>     

              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "11px", marginBottom: "-5%" }}
                >
                  Rating
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  margin="normal"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                >
                  <Select
                    labelId="customer-name-label"
                    value={productDetails.rating}
                    onChange={(e) => handleChange("rating", e.target.value)}
                    inputProps={{ readOnly: editProductDetails }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {RatingList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} md={6} lg={6}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "11px", marginBottom: "-5%" }}
                >
                  Instant Issuance
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  margin="normal"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                >
                  <Select
                    labelId="instant-issuance-label"
                    value={productDetails.instantIssuance}
                    onChange={(e) =>
                      handleChange("instantIssuance", e.target.value)
                    }
                    inputProps={{ readOnly: editProductDetails }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {instantIssuanceList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                Joining Fee
                </Typography>
                <TextField
                  placeholder="Enter joining fee"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.freeToJoin || "0"}
                  onChange={(e) => handleChange("freeToJoin", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Annual Fee
                </Typography>
                <TextField
                  placeholder="Enter annual fee"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.annualFee || "0"}
                  onChange={(e) => handleChange("annualFee", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                Welcome Bonus
                </Typography>
                <TextField
                  placeholder="Enter welcome bonus"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.welcomeBonus || ""}
                  onChange={(e) => handleChange("welcomeBonus", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>
</>
}


              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>
                  Description
                </Typography>
                <TextField
                  placeholder="Enter description"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                  size="small"
                  value={productDetails?.description || ""}
                  onChange={(e) => handleChange("description", e.target.value)}
                  InputProps={editProductDetails ? { readOnly: true } : {}}
                />
              </Grid>
            </Grid>

            <Grid className="imageGrid" item xs={4} md={4} lg={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  mt={2}
                  style={{
                    width: "180px",
                    marginTop:'50%',
                    height: "180px",
                    backgroundColor: "#f0f0f0",
                    border: "2px solid #f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {productDetails.productImage ? (
                    <img
                      src={URL.createObjectURL(productDetails.productImage)}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={
                        productImage ? URL.createObjectURL(productImage) : ""
                      }
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>

                {!editProductDetails && (
                  <Button
                    variant="outlined"
                    component="label"
                    style={{
                      "& .MuiButton-root": {
                        height: "40px",
                        textTransform: "none",
                      },
                    }}
                  >
                    Upload Image
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleDocumentUpload("productImage", e.target.files[0])
                      }
                    />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>


      {productDetails.productType === 'Loan'  && ( 
        <>

        <div
          style={{
            padding: "2%",
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            boxShadow: "0px 0px 7px 0px #cfc5c56b",
            width: "91%",
            marginLeft: "2%",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <Container>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "4%",
              }}
            >
              Assigned Product Feature
            </Typography>
          
          <Grid item xs={12} className='endNodeAutoCompleteGrid'>
          <Autocomplete
      multiple
      id="endNode"
      options={productFeature || []}
      value={endNodeUsers}
      getOptionLabel={(option) => option.featureName}
      onChange={(event, newValue) => handleChangeParents('features', newValue)}
      renderInput={(params) => (
        <TextField 
        {...params} 
        size="small" 
        placeholder="Select Features" 
      />
      )}
      
      disabled={editProductDetails}
    />
</Grid>

</Container>
</div>
       
        </>
      )
    }

      {!editProductFeature && (
        <div
          style={{
            padding: "2%",
            backgroundColor: "#ffffff",
            borderRadius: "4px",
            boxShadow: "0px 0px 7px 0px #cfc5c56b",
            width: "91%",
            marginLeft: "2%",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <Container>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "4%",
              }}
            >
              Assigned Product Feature
            </Typography>

         
            <Grid container spacing={1} className="form-container">
              <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
                <FormControl
                  error={!!errors.selectedMainFeatureId}
                  fullWidth
                  size="small"
                  margin="normal"
                  sx={{
                    "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                >
                  <InputLabel id="main-feature-label">
                    Select Main Feature
                  </InputLabel>
                  <Select
                    labelId="main-feature-label"
                    value={selectedMainFeatureId}
                    onChange={handleFeatureNameChange}
                    error={!!errors.selectedMainFeatureId}
                    helperText={
                      errors.selectedMainFeatureId || "Select a main feature."
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {productFeature ? (
                      productFeature.map((item) => (
                        <MenuItem key={item.featureId} value={item.featureId}>
                          {item.featureName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Main features available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#efefef",
                    borderRadius: "4px",
                    padding: "4%",
                    marginRight: "-10%",
                    paddingLeft: "22%",
                  }}
                >
                  Weightage %
                </div>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  required
                  placeholder="%"
                  type="number"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                  size="small"
                  value={mainFeatureWeightage}
                  onChange={handleMainWeightageChange}
                  error={!!errors.mainFeatureWeightage}
                  helperText={errors.mainFeatureWeightage}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
                <FormControl
                  error={!!errors.selectedSubFeatureId}
                  fullWidth
                  size="small"
                  margin="normal"
                  sx={{
                    "& .MuiInputBase-root": { height: "30px", fontSize: "11px" },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                >
                  <InputLabel id="sub-feature-label">
                    Select Sub Feature
                  </InputLabel>
                  <Select
                    labelId="sub-feature-label"
                    value={selectedSubFeatureId}
                    onChange={handleSubFeatureChange}
                    error={!!errors.selectedSubFeatureId}
                    helperText={
                      errors.selectedSubFeatureId || "Select a sub feature."
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {productSubFeature ? (
                      productSubFeature.map((item) => (
                        <MenuItem key={item.featureId} value={item.featureId}>
                          {item.featureName}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No sub features available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#efefef",
                    borderRadius: "4px",
                    padding: "4%",
                    marginRight: "-10%",
                    paddingLeft: "22%",
                  }}
                >
                  Weightage %
                </div>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  placeholder="%"
                  type="number"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": { fontSize: "11px" },
                  }}
                  size="small"
                  value={subFeatureWeightage}
                  onChange={handleSubWeightageChange}
                  error={!!errors.subFeatureWeightage}
                  helperText={errors.subFeatureWeightage}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              className="form-container"
              sx={{ marginTop: "2%" }}
            >
              <Grid item xs={12} sm={12} md={12} textAlign="right">
                <Button variant="contained" onClick={handleAddFeature}>
                  Add Feature(s)
                </Button>
              </Grid>
            </Grid>

          
          </Container>
        </div>
      )}


{productDetails.productType === 'Loan' ? <>
    
      
    </> : 

      <div
        style={{
          padding: "2%",
          backgroundColor: "#ffffff",
          borderRadius: "4px",
          boxShadow: "0px 0px 7px 0px #cfc5c56b",
          width: "91%",
          marginLeft: "2%",
          marginTop: "3%",
          marginBottom: "4%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
                marginLeft: "1%",
                fontSize: "23px",
                marginBottom: "2%",
              }}
            >
              Product Features Details
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            {editProductFeature && (
              <Button
                onClick={toggleFeatureEdit}
                variant="text"
                style={{
                  fontWeight: "bold",
                  color: "blue",
                  textDecoration: "underline",
                  marginBottom: "2%",
                }}
              >
                Edit
              </Button>
            )}
          </Grid>
        </Grid>

        <ListProductDetail
          listData={combineFeatureData}
          handleRowRemove={handleRowRemove}
          handleSubFeatureRemove={handleSubFeatureRemove}
        />
      </div>

          }

      {(!editProductDetails || !editProductFeature) && (
        <Button
          variant="contained"
          color="primary"
          onClick={SubmitProductDetails}
          sx={{
            float: "right",
            marginTop: "1%",
            marginRight: "3%",
            marginLeft: "2%",
            marginBottom: "3%",
          }}
        >
          Update
        </Button>
      )}
      <Link to="/dashboard/productDetails">
        <Button
          variant="outlined"
          sx={{
            float: "right",
            marginTop: "1%",
            marginBottom: "3%",
          }}
        >
          Back
        </Button>
      </Link>

      <Modal open={modalOpen} onClose={cancelDeleteFeature}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2>Delete Feature</h2>
            <p>Are you sure you want to delete this feature?</p>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="outlined" onClick={cancelDeleteFeature}>
                  No
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={confirmDeleteFeature}>
                  Yes
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>

      <Snackbar
        open={createFeature}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          New Feature Added!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openedStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Selected main feature and sub-feature.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Selected sub-feature already exists under this main feature.
        </Alert>
      </Snackbar>

      <Snackbar
        open={updatedOpenStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="neutral"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Existing Feature Updated!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewProduct;
