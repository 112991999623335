import React, { useEffect, useState } from 'react';
import {TextField,Box,FormControl,MenuItem,Select, Typography, Button, Grid, Container } from '@mui/material';
import InputMask from 'react-input-mask';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import useAxiosInstance from '../../api/axios';
import usePublicAxiosInstance from '../../api/pubicAxios';
import { useNavigate,useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axiosInstance from 'axios';


const AddProduct = () => {

  
  const axio = usePublicAxiosInstance();

  const navigate = useNavigate();
  const { Id } = useParams();

  const [productFeature, setProductFeature] = useState(null);

  const [productImage, setProductImage] = useState(null);
  const [productDetails, setProductDetails] = useState({

    productName:'',
    organizationName:'',
    routingUrl:'',
    employmentType:'',
    minimumIncome:'',
    rating:'',
    instantIssuance:'',
    incentive:'',
    productImage:'null',
    description:'',
    status:'Active',
    features:[]
  });

  const axios = useAxiosInstance();

  const getAllFeaturesList = async () => {
    try {
      const response = await axios.get(`/api/products/${Id}`);
      console.log(response?.data);
      setProductDetails(response?.data);
     
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    getAllFeaturesList();
  }, []);

    

  
  useEffect(() => {
    const getProductImage = async () => {
        try {
            const responseImage = await axio.get(`/api/products/public/image/${Id}`, {
                responseType: 'blob' // Set responseType to 'blob' to get the image as a Blob object
              });
              setProductImage(responseImage.data);
           
        } catch (error) {
            console.error("Axios Error:", error);
        }
    };

    getProductImage();
}, [Id]);


   
    return (
        <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>

            <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
                <h2>View Product</h2>
            </div>

            <div style={{
                padding:'2%',
               backgroundColor: '#ffffff',
               borderRadius: '4px',
               boxShadow: '0px 0px 7px 0px #cfc5c56b',
                width:'91%',marginLeft:'2%',marginTop:'3%',marginBottom:'3%'}}>
  <Container>
      <Typography variant="h5" gutterBottom sx={{
        fontWeight:"bold",
        marginTop:"20px"
      }}>
     Product Details
      </Typography>

      <Grid container spacing={2} style={{ margin: '5vh 10px 10px 0px' }} className="form-container">
      <Grid container spacing={2} item xs={8} md={8} lg={8} >
    

  

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Product Name</Typography>
              <TextField
              placeholder='Enter card name'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.productName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Organization Name</Typography>
              <TextField
              placeholder='Enter organization name'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.organizationName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Routing Url</Typography>
              <TextField
              placeholder='Enter routingUrl'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.routingUrl}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>



            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Employee Type</Typography>
              <TextField
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.employmentType}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
         
            

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Minimum Salary</Typography>
              <TextField
              placeholder='Enter Minimum Salary'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.minimumIncome}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Rating</Typography>
              <TextField
              placeholder='Enter Minimum Salary'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.rating}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            
            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Instant Issuance</Typography>
              <TextField
              placeholder='Enter Minimum Salary'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails?.instantIssuance}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

        
         
            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Incentive</Typography>
              <TextField
              placeholder='Enter incentive'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.incentive}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Feature Description</Typography>
              <TextField
              placeholder='Enter feature description'
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px' /* Add other custom styles as needed */,
                    fontSize:"11px"

                  }, '& .MuiFormLabel-root':{
                      fontSize:"11px"
                  }
                }}
                size="small"
                value={productDetails.description}
                    InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>

          <Grid className="imageGrid" item xs={4} md={4} lg={4}>
      <Grid item xs={12} sm={12} md={12}>
        {/* Display the uploaded Image or text if the Image is not uploaded */}
        <Box mt={2} style={{
          width: '180px', // Set your desired width
          height: '180px', // Set your desired height
          backgroundColor: '#f0f0f0', // Set your default background color
          border: "2px solid #f0f0f0",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
          {productImage ? (
                  <img
                  src={productImage ? URL.createObjectURL(productImage) : ""}
                  alt="Uploaded"
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Card Image
            </Typography>
          )}
        </Box>
      
      </Grid>
    </Grid>

</Grid>
    </Container>
            </div>


            <div style={{width:'94%',marginLeft:'5%',marginTop:'3%',marginBottom:'3%'}}>
      <div style={{fontWeight:'bold',marginLeft:'1%',fontSize:'23px'}}>Product Features</div>

      {productDetails.features && productDetails.features.length > 0 ? (
  <>
      
      {productDetails.features.map((feature, featureIndex) => (
    
        <Grid container spacing={2} className="form-container" sx={{marginTop:'2%'}}>
        
        <Grid item xs={3} sm={3} md={3} sx={{marginTop:'-3%'}}>
            <Typography variant="subtitle1" sx={{ fontSize: "11px" ,marginBottom:'-5%'}}>Main Feature</Typography>

            <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
        value={feature.featureName}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
          />
             
                </Grid>
      

             
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <div style={{ textAlign: 'center', backgroundColor: '#efefef', borderRadius: '4px', padding: '4%', marginRight: '-16%', paddingLeft: '22%' }}> Weightage %</div>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  placeholder='%'
                  type='number'
                  fullWidth
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                      fontSize: "11px"
                    }, '& .MuiFormLabel-root': {
                      fontSize: "11px"
                    }
                  }}
                  size="small"
                  value={feature.featureWeight}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
              </Grid>

              <Grid item xs={3} sm={3} md={3}>
          
</Grid>




{feature.subFeatures.map((subFeature, subFeatureIndex) => (


<Grid key={subFeatureIndex} container spacing={2} className="form-container" sx={{marginTop:'1%'}}>


<Grid item xs={1.5} sm={1.5} md={1.5}>
</Grid>


<Grid item xs={2} sm={2} md={2}>
</Grid>
<Grid item xs={3} sm={3} md={3} sx={{marginTop:'-3%'}}>

  
<Typography variant="subtitle1" sx={{ fontSize: "11px" ,marginBottom:'-4%'}}>Sub Feature</Typography>

<TextField
          
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            value={subFeature.featureName}
            sx={{
              '& .MuiInputBase-root': {
                height: '30px',
                fontSize: '11px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '11px'
              }
            }}
          />
              
                </Grid>


             
              <Grid item xs={1.5} sm={1.5} md={1.5}>
                <div style={{ textAlign: 'center', backgroundColor: '#efefef', borderRadius: '4px', padding: '4%', marginRight: '-16%', paddingLeft: '22%' }}> Weightage %</div>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <TextField
                  placeholder='%'
                  type='number'
                  fullWidth
                  value={subFeature.featureWeight}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                      fontSize: "11px"
                    }, '& .MuiFormLabel-root': {
                      fontSize: "11px"
                    }
                  }}
                  size="small"
                
                />
              </Grid>
              </Grid>
           
))}   
        

            </Grid>

  
      ))}
  </>
) : (
  <div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No Sub Features available</div>
)}
    </div>
    
            <Link to="/dashboard/productDetails">
<Button variant="contained"  
        sx={{
          float: "right",
          marginTop:'1%',
          marginBottom:'3%',
          marginRight:'4%'
        }}>
        Go Back
      </Button>
         </Link>
        </div>
    );
};

export default AddProduct;
