import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

interface CustomerProps {
    locationCount: number;
    city: string;
}

const size = {
  width: 450,
  height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const LocationPieChart: React.FC<{ customer: CustomerProps[] }> = ({ customer }) => {
    // Ensure customer is always an array
    const appData = customer || [];

    if (appData.length === 0) {
        return (
            <div style={{ marginLeft: '-55px' }}>
                <PieChart series={[{ data: [], innerRadius: 80 }]} {...size}>
                    <PieCenterLabel>Total: 0</PieCenterLabel>
                </PieChart>
            </div>
        );
    }

    // Calculate the total location count
    const totalLocations = appData.reduce((sum, element) => sum + element.locationCount, 0);

    // Create data array for PieChart
    const data = appData.map(element => ({
        value: (element.locationCount / totalLocations) * 100,
        label: `${element.city} (${element.locationCount})`
    }));

    return (
      <div style={{ marginLeft: '-55px' }}>
        <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
          <PieCenterLabel>Total: {totalLocations}</PieCenterLabel>
        </PieChart>
      </div>
    );
};

export default LocationPieChart;
