import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../api/axios';
import usePublicAxiosInstance from '../../api/pubicAxios';
import { Link } from 'react-router-dom';
// import { message } from 'antd';
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,Snackbar,
  TableHead,
  TableRow,
  Paper,Alert,
  Modal,
  InputAdornment,
  Backdrop,
  Grid,
  Button,
  TablePagination,
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import '../../App.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';



const PromotionBanner = () => {
  
  const axio = usePublicAxiosInstance();
  const axios = useAxiosInstance();

  // Get the permission string from localStorage
  const permissionString = localStorage.getItem("Permission");

  // Check if the permissionString is not null or undefined
  const permissions = permissionString ? permissionString.split(',') : [];

  // const [messageApi, contextHolder] = message.useMessage();
  const [users, setBanner] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedstatus, setSelectedstatus] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [value, setValue] = useState(0); // Tab value
  const [userId, setuserId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editActiveDialogOpen, setEditActiveDialogOpen] = useState(false);
  const [editSuspendedDialogOpen, setSuspendedDialogOpen] = useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);

  
  const handleEditClick = (user) => {
    // console.log(user);
    setSelectedUser(user);
    setEditDialogOpen(true);
  };


  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedUser(null);
  };


  const handleEditActiveClick = (user) => {
    // console.log(user);
    setSelectedUser(user);
    setEditActiveDialogOpen(true);
  };
  const handleEditActiveDialogClose = () => {
    setEditActiveDialogOpen(false);
    setSelectedUser(null);
  };

  const handleSuspendedClick = (user) => {
    console.log(user);
    handleEditActiveDialogClose(false);
    handleEditDialogClose(false);
    setSelectedstatus(user);
    setSuspendedDialogOpen(true);
  };

  const handleSuspendedDialogClose = () => {
    setSuspendedDialogOpen(false);
    setSelectedstatus(null);
  };


  const handleSuspendedDialogSave =async () => {
   

    const UserList = {
      Id: selectedstatus.id,
      status: selectedstatus.active === 'Y' ? 'N' : 'Y'
  };
        console.log(UserList);
    
        try {
   
          const response = await axios.put(
            `/api/banners/status/${UserList.Id}?status=${UserList.status}`,
            {
              headers: {
                "Content-Type": "application/json"
              },
            }
          );
      
        
          setSuspendedDialogOpen(false);
          getUsers();
        console.log("Status Update successfully !");
     
       
        } catch (err) {
          if (err.response && err.response.data) {
          
            console.log("Internal Server Error");
          } else {
            console.log("Error occurred. Please try again.")
          }
        }
  
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUsers = async () => {
    try {
      const response = await axio.get("/api/banners/public");
      setBanner(response?.data);
      console.log(response?.data);
      setFilteredUsers(response?.data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSearchChange = (event) => {
    const searchQuery = (event.target.value || '').toLowerCase();
    const filtered = users.filter(user =>
      user.title && user.title.toLowerCase().includes(searchQuery) ||
      (user.routingUrl && user.routingUrl.toLowerCase().includes(searchQuery)) ||
      (user.active && user.active.toLowerCase().includes(searchQuery)) // Add check for user.role
    );
    setFilteredUsers(filtered);
    setPage(0);
  };
  


  const handleClose = () => {
    setOpenStatus(false);
  };




  const handleDeleteRecords = (userID) => {
    setuserId(userID);
    setDeleteDialogOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setuserId(null);
  };

  const handleDeleteDialog = async () => {
    console.log(userId, "deleting user");

    try {
      await axios.delete(`/api/banners/${userId}`);
      setDeleteDialogOpen(false);
      setuserId(null);
      getUsers();
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
      } else {
        console.log("Internal Server Error");
      }
    }
  };
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
      <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '2%', paddingBottom: '1%' }}>
        <h2>Promotional Banner</h2>
      </div>


      <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>
        <TextField
          sx={{ width: '40%' }}
          component={Paper}
          className="table-container"
          onChange={handleSearchChange}
          name='search'
          placeholder='Search'
          variant='outlined'
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ManageSearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <FilterAltIcon style={{ color: 'blue' }} />
              </InputAdornment>
            ),
          }}
        />
  <Link to="/Banner/addBanner">
        <Button
          variant="contained" style={{ backgroundColor: '#3e3ecd', float: 'right' }}>
          Add Banner (+)
          <AddIcon />
        </Button>
        </Link>
      </div>

      <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>
        <TableContainer className="table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow className="table-heading">
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Routing URL</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredUsers ? (
  (rowsPerPage > 0
    ? filteredUsers
        .sort((a, b) => b.id - a.id)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredUsers.sort((a, b) => b.id - a.id)
  ).map((user) => (
    <TableRow key={user.id}>

<TableCell>
      <Link to={`/banner/view/${user.id}`} style={{textDecoration:"", color:"blue"}}>
        {user.id}
      </Link>
    </TableCell>
      <TableCell>{user.title ? user.title : ''}</TableCell>
      <TableCell>{user.routingUrl ? user.routingUrl : ''}</TableCell>
      <TableCell>
        <Button
          variant="contained"
          color={user.active === 'Y' ? "success" : "error"}
          style={{ borderRadius: '20px', fontSize: 'smaller', width: 'max-content' }}
        >
          {user.active === 'Y' ? "Active" : "Suspended"}
        </Button>
      </TableCell>
      <TableCell>
        {user.active === 'Y' ? (
          <Button color="primary" onClick={() => handleEditClick(user)}>
            Suspend
          </Button>
        ) : (
          <Button color="primary" onClick={() => handleEditActiveClick(user)}>
            Reinstate
          </Button>
        )}
           <Link to={`/banner/edit/${user.id}`}>
                      <Button color="primary">
                         Edit
                      </Button>
                  </Link>

                  <Button
                      color='error'
                      sx={{
                        '& .MuiButton-root': {
                          height: '30px',
                          textTransform: 'none',
                        },
                      }}
                      onClick={() => handleDeleteRecords(user.id)}
                    >
                      <DeleteIcon />
                    </Button>
      </TableCell>
    </TableRow>
  ))
) : (
  <TableRow>
    <TableCell colSpan={6}>Loading...</TableCell>
  </TableRow>
)}

            </TableBody>  
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

     

      <Modal open={editDialogOpen} onClose={handleEditDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2 style={{textAlign:'center'}}>Suspend Access?</h2>
            <p style={{textAlign:'center'}}>Are you sure you want to suspend the following Banner:</p>
            <hr></hr>
            <div style={{textAlign:'center',marginTop:'3%'}}>
              Title: <span style={{fontWeight:'bold'}}>{selectedUser?.title || ''}</span>
            </div>
            <div style={{textAlign:'center'}}>
              Routing URL: <span style={{fontWeight:'bold'}}>{selectedUser?.routingUrl || ''}</span>
            </div>
            <div style={{textAlign:'center',marginBottom:'3%'}}>
              Status: <span style={{fontWeight:'bold'}}>{selectedUser?.active === 'Y' ? "Active" : "Inactive"}</span>
            </div>
            <Button onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
              Proceed
            </Button>
            <Button onClick={handleEditDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
              Cancel
            </Button>
          </Paper>
        </div>
      </Modal>

      <Modal open={editActiveDialogOpen} onClose={handleEditActiveDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2 style={{textAlign:'center'}}>Reinstate Access?</h2>
            <p style={{textAlign:'center'}}>Are you sure you want to reinstate the following Banner:</p>
            <hr></hr>
            <div style={{textAlign:'center',marginTop:'3%'}}>
              Title: <span style={{fontWeight:'bold'}}>{selectedUser?.title || ''}</span>
            </div>
            <div style={{textAlign:'center'}}>
              Routing URL: <span style={{fontWeight:'bold'}}>{selectedUser?.routingUrl || ''}</span>
            </div>
            <div style={{textAlign:'center',marginBottom:'3%'}}>
              Status: <span style={{fontWeight:'bold'}}>{selectedUser?.active === 'Y' ? "Active" : "Inactive"}</span>
            </div>
            <Button onClick={() => handleSuspendedClick(selectedUser)} style={{ float: "inline-end", marginRight: '31%', marginTop: '4%' }} variant="contained" color="primary">
              Proceed
            </Button>
            <Button onClick={handleEditActiveDialogClose} style={{ float: "inline-end", marginRight: '2%', marginTop: '4%' }} variant="outlined">
              Cancel
            </Button>
          </Paper>
        </div>
      </Modal>

      <Modal open={editSuspendedDialogOpen} onClose={handleSuspendedDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            {selectedstatus?.active !== 'Y' ? (
              <>
                <h2 style={{textAlign:'center'}}>Banner Reinstated</h2>
                <p style={{textAlign:'center'}}>The Banner access has been reinstated.</p>
                <hr></hr>
              </>
            ) : (
              <>
                <h2 style={{textAlign:'center'}}>Banner Suspended</h2>
                <p style={{textAlign:'center'}}>The Banner access has been suspended.</p>
                <hr></hr>
              </>
            )}
            <Button onClick={() => handleSuspendedDialogSave()} style={{ float: "inline-end", marginRight: '37%', marginTop: '4%' }} variant="contained" color="primary">
              Proceed
            </Button>
          </Paper>
        </div>
      </Modal>

      <Modal open={deleteDialogOpen} onClose={handleDeleteDialogClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Paper style={{ padding: '20px', background: '#fff', width: '70vh', height: "auto", padding: "20px 50px", zIndex: 1 }}>
            <h2 style={{ fontSize: 'bold' }}>Delete Promotional Banner</h2>
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "20px", marginTop: "20px" }}>
              <Grid container spacing={2}>
                <h4 style={{ marginLeft: '70px' }}>Are you sure you want to delete ? </h4>
              </Grid>
            </Grid>
            <Button onClick={handleDeleteDialog} variant="contained" color="success" style={{ marginTop: '20px', float: "right" }}>
              Yes
            </Button>
            <Button onClick={handleDeleteDialogClose} variant="contained" style={{ marginTop: '20px', float: "right", marginRight: '10px' }}>
              No
            </Button>
          </Paper>
        </div>
      </Modal>

      <Snackbar
        open={openStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          This email address already exists in the system
        </Alert>
      </Snackbar>

      <style>
        {`
          @media (max-width: 600px) {
            .MuiTableCell-root {
              padding: 8px;
              font-size: 12px;
            }
            .MuiTextField-root {
              width: 100%;
            }
            .table-container {
              overflow-x: auto;
            }
            .action-container {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-bottom: 10px;
            }
            .add-user-button {
              width: 100%;
            }
            .MuiTab-root {
              font-size: 12px;
              min-width: unset !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PromotionBanner;
