import { createSlice } from "@reduxjs/toolkit";

export interface authState {
  userDetails: any;

}

const initialState: authState = {
  userDetails:null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    logoutUserAuth: (state) => {
      state.userDetails = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getUserDetails, logoutUserAuth } = authSlice.actions;

export default authSlice.reducer;
