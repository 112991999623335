import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SummarizeSharpIcon from "@mui/icons-material/SummarizeSharp";
import * as XLSX from "xlsx"; // Import xlsx for Excel generation
import useAxiosInstance from '../../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Report = () => {
  const permission = localStorage.getItem("UserRoleName");
  const MerchantID = localStorage.getItem("MerchantID");

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [merchantReportData, setMerchantReportData] = useState([]);
  const [applicationReportData, setApplicationReportData] = useState([]);
  const [productReportData, setProductReportData] = useState([]);
  const [reportDetails, setReportDetails] = useState({
    generatedOn: '',
    user: '',
    location: '',
  });
  const axios = useAxiosInstance();

  const fetchMerchantReportData = async (fromDateString, toDateString) => {
    try {

      let url;
      if( permission.includes('MERCHANT')){
       url = `/api/merchants/report/${MerchantID}`;
      }else{
         url = '/api/merchants/report';
      }
      if (fromDateString && toDateString) {
        url += `?fromDate=${fromDateString}&toDate=${toDateString}`;
      }

      const response = await axios.get(url);
      setMerchantReportData(response.data);
      setReportDetails({
        generatedOn: new Date().toLocaleDateString(),
        user: 'Pop100',
        location: 'Islamabad',
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching merchant report data: ", error);
      return [];
    }
  };

  const fetchApplicationReportData = async (fromDateString, toDateString) => {
    try {
      let url = '/api/applications/report';
      if (fromDateString && toDateString) {
        url +=  `?fromDate=${fromDateString}&toDate=${toDateString}`;
      }

      const response = await axios.get(url);
      setApplicationReportData(response.data);
      setReportDetails({
        generatedOn: new Date().toLocaleDateString(),
        user: 'Pop100',
        location: 'Islamabad',
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching application report data: ", error);
      return [];
    }
  };

  const fetchProductReportData = async (fromDateString, toDateString) => {
    try {
      let url = '/api/products/reports/product-performance';
      if (fromDateString && toDateString) {
        url += `?fromDate=${fromDateString}&toDate=${toDateString}`;
      }

      const response = await axios.get(url);
      setProductReportData(response.data);
      setReportDetails({
        generatedOn: new Date().toLocaleDateString(),
        user: 'Pop100',
        location: 'Islamabad',
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching product report data: ", error);
      return [];
    }
  };

  const generateMerchantExcel = async () => {
    try {
      let data = merchantReportData;
      const fromDateString = fromDate ? fromDate.toISOString().split('T')[0] : '';
      const toDateString = toDate ? toDate.toISOString().split('T')[0] : '';

      if (merchantReportData.length === 0) {
        data = await fetchMerchantReportData(fromDateString, toDateString);
      }

      if (data.length === 0) {
        toast.error('No data available for Merchant Report between the selected dates.');
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(data.map((item) => ({
        "Merchant Name": item.merchantName ? item.merchantName : '-',
        "Location": item.location ? item.location : '-',
        "Product Type": "Cards",
        "Application Start": item.created ? item.created : '0',
        "Submitted": item.submitted ? item.submitted : '0',
        "Declined": item.rejected ? item.rejected : '0',
        "Approved": item.approved ? item.approved : '0',
        "Total Merchant Application" :item.totalMerchantApplication?item.totalMerchantApplication:'0'
      })));

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Merchant Report");
      XLSX.writeFile(workbook, "Merchant_Report.xlsx");
    } catch (error) {
      console.error("Error generating Merchant Excel: ", error);
    }
  };

  const generateApplicationExcel = async () => {
    try {
      let data = applicationReportData;
      const fromDateString = fromDate ? fromDate.toISOString().split('T')[0] : '';
      const toDateString = toDate ? toDate.toISOString().split('T')[0] : '';
  
      if (applicationReportData.length === 0) {
        data = await fetchApplicationReportData(fromDateString, toDateString);
      }
  
      if (data.length === 0) {
        toast.error('No data available for Application Report between the selected dates.');
        return;
      }
  
      const worksheet = XLSX.utils.json_to_sheet(data.map((item) => ({
        "Customer Number": item.customer?.customerId || '-',
        "Customer Name": item.customer?.name || '-',
        "Customer Email": item.customer?.email || '-',
        "Customer Contact Number": item.customer?.mobileNumber || '-',
        "Merchant Name": item.location?.merchantName || '-',
        "Merchant Location": item.location?.city || '-',
        "Card Selected": item.product?.productName || '-',
        "Card Feature Selected": item.preferences || '-',
        "Cards Recommended": item.recommendationProducts || '-',
        "Application Number": item.applicationId || '-',
        "Application Status": item.status || '-',
        "Eligibility Questions": item.questionsAndResponses ? item.questionsAndResponses.join('; ') : '-',
      })));
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Application Report");
      XLSX.writeFile(workbook, "Application_Report.xlsx");
    } catch (error) {
      console.error("Error generating Application Excel: ", error);
    }
  };
  

  const generateProductExcel = async () => {
    try {
      let data = productReportData;
      const fromDateString = fromDate ? fromDate.toISOString().split('T')[0] : '';
      const toDateString = toDate ? toDate.toISOString().split('T')[0] : '';

      if (productReportData.length === 0) {
        data = await fetchProductReportData(fromDateString, toDateString);
      }

      if (data.length === 0) {
        toast.error('No data available for Product Report between the selected dates.');
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(data.map((item) => ({
        "Produc tId": item.productId || '-',
        "Product Name": item.productName || '-',
        "Bank Name": item.organizationName || '-',
        "Number of Recommendation":item.numberOfRecommendation||'0',
                "No of Selections": item.selection || '0',
        "Total Submissions": item.submission || '0',
        "Approvals":item.approvals || '0',
      })));

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Product Report");
      XLSX.writeFile(workbook, "Product_Report.xlsx");
    } catch (error) {
      console.error("Error generating Product Excel: ", error);
    }
  };

  return (
    <div
      style={{
        flexGrow: 1,
        marginTop: "0px",
        position: "absolute",
        right: "0",
        top: "0",
        width: "82%",
      }}
    >
      <ToastContainer /> 
      
      <div
        style={{
          backgroundColor: "#f3f7f6",
          marginTop: "5%",
          padding: "1.5%",
          paddingBottom: "2%",
        }}
      >
        <h2>Reports</h2>
      </div>

      <div
        style={{
          padding: "21px",
          paddingTop: "5px",
          paddingBottom: "10px",
          marginTop: "20px",
          width: "93%",
          backgroundColor: "#ffffff",
          marginLeft: "2%",
          borderRadius: "4px",
          boxShadow: "0px 0px 7px 0px #cfc5c56b",
        }}
      >
        <Grid container spacing={1} sx={{ paddingLeft: "10px", marginTop: "10px" }}>
          <Grid container spacing={1}>
            <Grid item xs={5} md={5} lg={5}>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                customInput={<TextField label="From Date" variant="outlined" fullWidth />}
              />
            </Grid>
            <Grid item xs={5} md={5} lg={5} style={{marginLeft:'-18%'}}>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                customInput={<TextField label="To Date" variant="outlined" fullWidth />}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2} style={{marginLeft:'-18%'}}>
              <Button
                variant="contained"
                style={{
                  height: "56px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  fetchMerchantReportData(fromDate ? fromDate.toISOString().split('T')[0] : '', toDate ? toDate.toISOString().split('T')[0] : '');
                  if(!permission.includes('MERCHANT')){ 
                  fetchApplicationReportData(fromDate ? fromDate.toISOString().split('T')[0] : '', toDate ? toDate.toISOString().split('T')[0] : '');
                  fetchProductReportData(fromDate ? fromDate.toISOString().split('T')[0] : '', toDate ? toDate.toISOString().split('T')[0] : '');
                  }
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={1}
        sx={{ paddingLeft: "10px", marginTop: "10px" }}
      >
        <Grid container spacing={2}>
         {!permission.includes('MERCHANT') && (
          <Grid item xs={6} md={6} lg={6}>
            <div
              style={{
                padding: "21px",
                paddingTop: "5px",
                paddingBottom: "10px",
                marginTop: "50px",
                width: "70%",
                backgroundColor: "#ffffff",
                marginLeft: "7%",
                borderRadius: "4px",
                boxShadow: "0px 0px 7px 0px #cfc5c56b",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{ paddingLeft: "10px", marginTop: "10px" }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    lg={1}
                    sx={{
                      marginTop: "7%",
                      backgroundColor: "#5050db",
                      color: "white",
                      borderRadius: "16%",
                      height: "40px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <SummarizeSharpIcon />
                    </span>
                  </Grid>

                  <Grid item xs={5} md={5} lg={5}>
                    <div>
                      <p style={{ fontWeight: "bold" }}>Customer Report </p>
                      
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      variant="outlined"
                      style={{
                        float: "right",
                        marginTop: "10px",
                        borderRadius: "8px",
                        fontWeight: "bold",
                        marginTop: "22px",
                      }}
                      startIcon={<SystemUpdateAltIcon />}
                      onClick={generateApplicationExcel}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          )}


          <Grid item xs={6} md={6} lg={6}>
            <div
              style={{
                padding: "21px",
                paddingTop: "5px",
                paddingBottom: "10px",
                marginTop: "50px",
                width: "70%",
                backgroundColor: "#ffffff",
                marginLeft: "7%",
                borderRadius: "4px",
                boxShadow: "0px 0px 7px 0px #cfc5c56b",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{ paddingLeft: "10px", marginTop: "10px" }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    lg={1}
                    sx={{
                      marginTop: "7%",
                      backgroundColor: "#5050db",
                      color: "white",
                      borderRadius: "16%",
                      height: "40px",
                      padding: "5px",
                    }}
                  >
                    <span>
                      <SummarizeSharpIcon />
                    </span>
                  </Grid>

                  <Grid item xs={5} md={5} lg={5}>
                    <div>
                      <p style={{ fontWeight: "bold" }}>Merchant Report </p>
                      
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      variant="outlined"
                      style={{
                        float: "right",
                        marginTop: "10px",
                        borderRadius: "8px",
                        fontWeight: "bold",
                        marginTop: "22px",
                      }}
                      startIcon={<SystemUpdateAltIcon />}
                      onClick={generateMerchantExcel}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>

        </Grid>

        {!permission.includes('MERCHANT') && (
        <Grid container spacing={2} sx={{ marginTop: "-40px" }}>
          <Grid item xs={6} md={6} lg={6}>
            <div
              style={{
                padding: "21px",
                paddingTop: "5px",
                paddingBottom: "10px",
                marginTop: "50px",
                width: "70%",
                backgroundColor: "#ffffff",
                marginLeft: "7%",
                borderRadius: "4px",
                boxShadow: "0px 0px 7px 0px #cfc5c56b",
              }}
            >
              <Grid container spacing={1} sx={{ paddingLeft: "10px", marginTop: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={1} md={1} lg={1} sx={{ marginTop: "7%", backgroundColor: "#5050db", color: "white", borderRadius: "16%", height: "40px", padding: "5px" }}>
                    <span>
                      <SummarizeSharpIcon />
                    </span>
                  </Grid>

                  <Grid item xs={5} md={5} lg={5}>
                    <div>
                      <p style={{ fontWeight: "bold" }}>Products Report </p>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <Button
                      variant="outlined"
                      style={{
                        float: "right",
                        marginTop: "10px",
                        borderRadius: "8px",
                        fontWeight: "bold",
                        marginTop: "22px",
                      }}
                      startIcon={<SystemUpdateAltIcon />}
                      onClick={generateProductExcel}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Report;