import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../api/axios';
import { TextField, InputAdornment, Paper, Modal, Autocomplete,MenuItem, Typography,Button, Grid,Alert, Snackbar, InputLabel } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CollapsibleTable from './CollapsibleTable'; // Import the CollapsibleTable component
import { compose } from '@mui/system';

const ApplicationTracker = () => {

  const [openModal, setOpenModal] = useState(false);
  // const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const axios = useAxiosInstance();
  const [allProductType, setAllProductType] = useState([]);

  const [selectedProductType, setSelectedProductType] = useState('showAll');
  const [filteredQuestions, setFilteredQuestions] = useState(users);
  const countKey = localStorage.getItem('X-Country-Key');




  const fetchUsers = async (page, size,productType) => {
    try {

      
const response = productType === 'showAll'
? await axios.get(`/api/applications/filter?page=${page}&size=${size}`)
: await axios.get(`/api/applications/filter?applicationType=${productType}&page=${page}&size=${size}`);

    setUsers(response.data.content);
    setTotalElements(response.data.totalElements);
   
 
    if (productType === 'showAll') {
      setFilteredQuestions(response.data.content);
    } else {
      const filteredData = response.data.content.filter(question => question.applicationType === productType);
      setFilteredQuestions(filteredData);
    }
  
    }

    catch(e){
      console.log('Error fetching users',e)
    }
  };

  useEffect(() => {
    fetchUsers(page, rowsPerPage, selectedProductType);
  }, [page, rowsPerPage, selectedProductType]);


  useEffect(() => {
    setFilteredQuestions(users);
  }, [users]);

  const handleProductTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedProductType(selectedValue);
    setPage(0); // Reset to the first page
  };


  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    // fetchUsers(page, rowsPerPage);
    setPage(0); 
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');

  const [merchantOptions, setMerchantOptions] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerSelectedId, setCustomerSelectedId] = useState('');
  const [customerInputValue, setCustomerInputValue] = useState('');



  const handleFilterIconClick = () => {
    setOpenModal(true); // Open the modal when the filter icon is clicked
  };

  const handleCloseModal = () => {
    setStartDate('');
    setEndDate('');
    setSelectedId('');
    setCustomerSelectedId('');
    // getUsers();
    fetchUsers(page, rowsPerPage);
    setOpenModal(false); // Close the modal
  };


  const [searchQuery, setSearchQuery] = useState('');
        
  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
  };

 
  const filteredUsers = filteredQuestions.filter(user => {
    const customerName = user.customer?.name || ''; 
    const merchantName = user.location?.merchantName || '-'; 
    const productName = user.product?.productName || '-'; 
    const status = user.status || ''; 
    return merchantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customerName.toLowerCase().includes(searchQuery.toLowerCase()) || 
    productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    status.toLowerCase().includes(searchQuery.toLowerCase()) ;
  });

  const fetchMerchants = async (query) => {
    try {
      const response = await axios.get(`/api/merchants/search-lov?query=${query}`);
      setMerchantOptions(response.data || []);
          } catch (error) {
      console.log('Error fetching merchants:', error);
    }
  };

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
    if (newValue && newValue.length >= 2) {
      fetchMerchants(newValue);
    } else {
      setMerchantOptions([]);
    }
  };

  const handleMerchantChange = (event, value) => {
    if (value) {
      setSelectedId(value.lovKey); // Set selected ID
          } else {
      setSelectedId(''); // Clear selected ID if value is null
    }
  };

const fetchCustomers = async (query) => {
  try {
    const response = await axios.get(`/api/customer-profiles/search-lov?query=${query}`);
    setCustomerOptions(response.data || []);
      } catch (error) {
    console.log('Error fetching customers:', error);
  }

};

const handleCustomerInputChange = (event, newValue) => {
  setCustomerInputValue(newValue);
  if (newValue && newValue.length >= 2) {
    fetchCustomers(newValue);
  } else {
    setCustomerOptions([]);
  }
};

const handleCustomerChange = (event, value) => {
  if (value) {
    setCustomerSelectedId(value.lovKey); // Set selected ID
      } else {
    setCustomerSelectedId(''); // Clear selected ID if value is null
  }
};

const handleCreateModal = async () => {
  // Initialize filterParams as an empty string
  let filterParams = '';

  // Conditionally append each parameter to filterParams
  if (startDate) {
    filterParams += `&startDate=${startDate} 00:00:00`;
  }
  if (endDate) {
    filterParams += `&endDate=${endDate} 00:00:00`;
  }
  if (selectedId) {
    filterParams += `&merchantId=${selectedId}`;
  }
  if (customerSelectedId) {
    filterParams += `&customerId=${customerSelectedId}`;
  }

  // Remove the leading '&' if there are any parameters
  if (filterParams) {
    filterParams = filterParams.substring(1);
  }

  console.log(filterParams);

  try {
    // Send the request with the properly formatted filter parameters
    const response = await axios.get(`/api/applications/filter?${filterParams}`);

    // Set the users state with the response data
    setUsers(response.data.content);
    console.log(response.data.content);

    // Close the modal
    setOpenModal(false);
  } catch (error) {
    // Handle any errors that occur during the request
    console.log('Request failed', error);
  }
};


const [modalOpen, setModalOpen] = useState(false);
const [fileUpload, setFileUpload] = useState(null);
const [fileName, setFileName] = useState('');

const [open, setOpenFile] = React.useState(false);
const [fileError, setFileError] = React.useState(false);


const handleAddFileClick = () => {
  setModalOpen(true);
};

const handleFileCloseModal = () => {
  setModalOpen(false);
  setFileUpload(null);
  setFileName('');
};

const handleFileInputChange = (event) => {
  const file = event.target.files[0];
  if (file && file.type === 'text/csv') {
    setFileUpload(file);
    setFileName(file.name); // Set the file name
  } else {
    // alert('Please select a CSV file.');
    setFileError(true);
    setTimeout(()=>{
      handleClose();
    },3000)
  }
};


const handleSubmitModal =async () => {

  try {

  if (fileUpload) {
    // Handle uploading logic here
    // console.log('File uploaded:', fileUpload);
    const formData = new FormData();
    formData.append("file", fileUpload);

    await axios.post(
      "/api/applications/uploadApplicationStatus",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    setOpenFile(true);
    setModalOpen(false);
    setFileUpload(null);
    setFileName('');

    setTimeout(()=>{
      handleClose();
    },3000)
  } else {
    // alert('Please select a CSV file.');
    setFileError(true);
    setTimeout(()=>{
      handleClose();
    },3000)
  }

}catch (err) {
  if (err.response && err.response.data) {
    console.log("Internal Server Error");
  } else {
    console.log("Error occurred. Please try again.");
  }
}

};


const handleClose = () => {
  setOpenFile(false);
  setFileError(false);
};


const getAllProductType = async () => {
  try {
    const response = await axios.get("/api/application/Lov/productType");
    setAllProductType(response?.data || []);

  } catch (error) {
    console.log("Axios Error:", error);
    setAllProductType([]);
  }
};


useEffect(()=>{
  getAllProductType()
},[])

  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
      <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '2%', paddingBottom: '1%' }}>
        <h2>Application Tracker</h2>
      </div>

      {/* <div style={{ margin: '2%', marginTop: '3%', width: '20%' }}>
            
            </div> */}

      <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>

      <TextField
                id="filled-select-currency"
                select
                label="Select Products"
                value={selectedProductType}
                onChange={handleProductTypeChange}
                variant="filled"
                fullWidth
                size="small"
                sx={{
                  marginBottom:'-30%',
                  '& .MuiInputBase-root': {
                    width: '20%',
                    fontSize: '11px',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: "13px"
                  },
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'white',
                  },
                  '& .MuiFilledInput-underline:before': {
                    borderBottom: 'none',
                  },
                  '& .MuiFilledInput-underline:after': {
                    borderBottom: 'none',
                  },
                }}
              >
              <MenuItem value="showAll">Show All</MenuItem>
{
  countKey !== 'AE' ?
  allProductType &&
  allProductType
    .filter(option => option.lovKey !== "Loan")
    .map(option => (
      <MenuItem key={option.lovKey} value={option.lovKey}>
        {option.lovKey}
      </MenuItem>
    ))


                : 

                allProductType &&
                allProductType
                  .map(option => (
                    <MenuItem key={option.lovKey} value={option.lovKey}>
                      {option.lovKey}
                    </MenuItem>
                  ))
                
} 
              </TextField>


        <TextField
          sx={{ width: '40%' ,marginLeft:'21%',marginTop:'-1.9%'}}
          component={Paper}
          className="table-container"
          onChange={handleSearchChange}
                   name='search'
          placeholder='Search'
          variant='outlined'
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ManageSearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" onClick={handleFilterIconClick}>
                <FilterAltIcon style={{ color: 'blue' }} />
              </InputAdornment>
            ),
          }}
        />
    

       
          <Button onClick={() => handleAddFileClick()}
                variant="contained"  style={{backgroundColor:'#3e3ecd',float:'right',marginRight:'1%',marginTop:'-1.9%'}}>
                  Upload Bank File

          </Button>

          </div>

      <CollapsibleTable users={filteredUsers}  totalElements={totalElements} rowsPerPage={rowsPerPage} page={page} 
      handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
      /> {/* Render the CollapsibleTable component and pass users data */}

      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2>Filter</h2>
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "30px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} lg={6}>
              <InputLabel sx={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '-5.4%' }}>Start Date </InputLabel>
              <TextField
  variant="outlined"
  margin="normal"
  fullWidth
  size="small"
  type="date"
                    InputLabelProps={{
                      shrink: true, // Keep the label "shrink" when values are selected
                    }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '55px',
                        fontSize: '16px',
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: '17px',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={6} lg={6}>
                  <InputLabel sx={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '-5.4%' }}>End Date </InputLabel>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    type="date"
  InputLabelProps={{
    shrink: true, // Keep the label "shrink" when values are selected
  }}
value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
  sx={{
    '& .MuiInputBase-root': {
      height: '55px',
      fontSize: '16px',
    },
    '& .MuiFormLabel-root': {
      fontSize: '17px',
    },
  }}
/>

      </Grid>
  
     
              </Grid>

              <Grid container spacing={2} sx={{marginBottom:'8%'}}>

              <Grid item xs={6} md={6} lg={6} sx={{marginTop:'3%'}}>
      <InputLabel sx={{ fontSize: '13px', fontWeight: 'bold' }}>Merchant Name </InputLabel>
      <Autocomplete
        id="combo-box-demo"
        options={merchantOptions}
        getOptionLabel={(option) => option.lovVal || ''}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleMerchantChange} // Handle option change
        renderInput={(params) => (
          <TextField
            {...params}
                        value={inputValue}
                   />
        )}
      />
          </Grid>

              <Grid item xs={6} md={6} lg={6} sx={{marginTop:'3%'}}>
      <InputLabel sx={{ fontSize: '13px', fontWeight: 'bold' }}>Client Name </InputLabel>
      <Autocomplete
        id="combo-box-demo"
        options={customerOptions}
        getOptionLabel={(option) => option.lovVal || ''}
        inputValue={customerInputValue}
        onInputChange={handleCustomerInputChange}
        onChange={handleCustomerChange} // Handle option change
        renderInput={(params) => (
          <TextField
            {...params}
                        value={customerInputValue}
                   />
        )}
      />
          </Grid>
              </Grid>
             </Grid>
          </Paper>
          <Button style={{ float: "inline-end", marginRight: '31%', marginTop: '2%' }} variant="contained" color="primary" onClick={handleCreateModal}>
            Continue
          </Button>
          <Button style={{ float: "inline-end", marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleCloseModal}>
            Clear Filter
          </Button>
        </div>
      </Modal>


      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div className="modal-content">
        <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: 'auto', padding: '10px 40px', zIndex: 1 }}>
            <h2>Upload Bank File</h2>
            <hr />
            <Grid container spacing={2} sx={{ paddingLeft: '10px', marginTop: '20px' }}>
              <Grid item xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                <input type="file" accept=".csv" onChange={handleFileInputChange} style={{ display: 'none' }} id="fileInput" />
                <label htmlFor="fileInput">
                  <Button variant="contained" color="primary" component="span">
                    Upload CSV File
                  </Button>
                </label>
                {fileName && <p><b>Selected File:</b> {fileName}</p>} {/* Display file name */}
              </Grid>
            </Grid>
          </Paper>
          <Button style={{ float: 'inline-end', marginTop: '2%' }} variant="contained" color="primary" onClick={handleSubmitModal}>
            Continue
          </Button>
          <Button style={{ float: 'inline-end', marginRight: '2%', marginTop: '2%' }} variant="outlined" onClick={handleFileCloseModal}>
            Cancel
          </Button>
        </div>
      </Modal>


      <Snackbar
  open={open}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
   File Upload SuccessFully!
  </Alert>
</Snackbar>

<Snackbar
  open={fileError}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="warning"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Please select a CSV file.
  </Alert>
</Snackbar>


<style>
        {`
          @media (max-width: 600px) {
            .MuiTableCell-root {
              padding: 8px;
              font-size: 12px;
            }
            .MuiTextField-root {
              width: 100%;
            }
          }
        `}
      </style>
      

    </div>
  );
};

export default ApplicationTracker;
