import React,{useEffect,useState} from 'react';
import { Table,
    Box,
    Tabs,
    Tab,
    TextField,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    FormControl,
    Select,
    TableRow,
    Paper,
    Modal,
    MenuItem,
    InputAdornment,
    Backdrop,
    Typography,
    Grid,
    Button,
    TablePagination } from '@mui/material';

import useAxiosInstance from '../../api/axios';
import { Link } from 'react-router-dom';
import { useNavigate,useParams } from 'react-router-dom';
import axiosAPI from '../../api/axiosAPI';


const ViewProductFeatures = () => {
    const { Id } = useParams();
  const navigate = useNavigate();
  const base=`${axiosAPI()}api/images/public/`;
 

    const [productFeatureCreate,setProductFeatureCreate] = useState({
      featureId:'',
      featureName:'',
      featureWeight:'',
      status:'',
      productType:'',
      imageUrl :'',
      subFeatures:[]
    });

    const axios = useAxiosInstance();

        useEffect(() => {
            const getByProductFeatureId = async () => {
              try {
                const response = await axios.get(`/api/productFeatures/productFeature/${Id}`);
        
               
                // console.log(response?.data);
                setProductFeatureCreate(response?.data)
          
          
              } catch (error) {
                console.error("Axios Error:", error);
              }
            };

            getByProductFeatureId();
            
          }, [Id]);

      

          
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
  
    <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'1.5%',paddingBottom:'2%'}}>
      <h2>View Product Features</h2>
    </div>

 
        <div >
          <Paper style={{ padding: '20px', background: '#fff', width: '85%', height: "auto", padding: "12px 65px",marginLeft:'2%',marginTop:'4%' }}>
          {productFeatureCreate.productType === 'Loan'?
        <h2> Feature</h2>
        :   <h2>Main Feature</h2>
        }
            <hr></hr>
            <Grid container spacing={2} sx={{ paddingLeft: "10px", marginTop: "20px" }}>
            
              <Grid container spacing={2} >

              <Grid item xs={3} md={3} lg={3}>
                  <TextField
                    label="Product Type"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={productFeatureCreate.productType}
                    InputProps={{
                        readOnly: true,
                      }}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}

                  />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <TextField
                  label={productFeatureCreate.featureName === 'Loan' ? "Category Name": "Feature Name" }
                    placeholder='Enter Name'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={productFeatureCreate.featureName}
                    InputProps={{
                        readOnly: true,
                      }}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}

                  />
                </Grid>
              
              {productFeatureCreate.featureWeight !== 0 && (
                <Grid item xs={3} md={3} lg={3}>
                  <TextField
                    label="Feature Weight"
                    type='number'
                    placeholder='Enter Feature Weight %'
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    size="small"
                    value={productFeatureCreate.featureWeight}
                    InputProps={{
                        readOnly: true,
                      }}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '30px', fontSize: '11px'  /* Add other custom styles as needed */,
                      }, '& .MuiFormLabel-root': {
                        fontSize: "11px"
                      }
                    }}

                  />
                </Grid>
)}
{productFeatureCreate.imageUrl &&(
                <Grid className="imageGrid" item xs={3} md={3} lg={3}>
      <Grid item xs={12} sm={12} md={12}>
        {/* Display the uploaded Image or text if the Image is not uploaded */}
        <Box mt={2} style={{
          width: '192px', // Set your desired width
          height: '75px', // Set your desired height
          backgroundColor: '#f0f0f0', // Set your default background color
          border: "2px solid #f0f0f0",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
          {productFeatureCreate.imageUrl ? (
            <img
            src={`${ base + productFeatureCreate.imageUrl}`}
              alt="Icon"
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Feature Icon
            </Typography>
          )}
        </Box>
     
      </Grid>
    </Grid>
)}

                </Grid>

                {productFeatureCreate.productType === 'Card' &&(
                  <>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={12} lg={12}>
              <h2 style={{marginBottom:'2%',marginTop:'5%'}}>Sub Features</h2>
              <hr></hr>
              </Grid>
           </Grid>



           {productFeatureCreate.subFeatures && productFeatureCreate.subFeatures.length > 0 ? (
<>
{productFeatureCreate.subFeatures.map((location, index) => (
    
    <>
       <Grid spacing={2} container>
       <Grid item xs={4} sm={4} md={4} >
               <TextField
                placeholder="Enter feature "
                label="Feature Name"
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                value={location.featureName}
                InputProps={{
                    readOnly: true,
                  }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px',
                    fontSize: '11px'
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: '11px'
                  }
                }}
               
              />
            </Grid>
            {location.featureWeight !== 0 && (
            <Grid item xs={4} sm={4} md={4}>
              <TextField
                placeholder="Enter Weightage %"
                type='number'
                label="Weightage %"
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                value={location.featureWeight}
                InputProps={{
                    readOnly: true,
                  }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px',
                    fontSize: '11px'
                  },
                  '& .MuiFormLabel-root': {
                    fontSize: '11px'
                  }
                }}
              
              />
            </Grid>
)}
            <Grid item xs={4} md={4} lg={4}>
  <Box mt={2} style={{
    width: '192px',
    height: '75px',
    backgroundColor: '#f0f0f0',
    border: "2px solid #f0f0f0",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  }}>
  
  <Grid item key={index} xs={4} md={4} lg={4}>
  <Box mt={2} style={{
          width: '80px', // Set your desired width
          height: '60px', // Set your desired height
          backgroundColor: '#f0f0f0', // Set your default background color
          border: "2px solid #f0f0f0",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
{location?.imageUrl ? (
              <img
                src={base + location.imageUrl}
                alt="Uploaded"
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
              />
            ) : (
              <Typography variant="body2" color="textSecondary">
                No Sub Feature Icon
              </Typography>
            )}
     
    </Box>
  </Grid>


  </Box>
</Grid>




       </Grid>
        </>

   ))}
  </>  
): (
    <div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No Sub Features available</div>
)}

</>
                )}
     
            </Grid>
          </Paper>
 
          <Link to="/dashboard/productFeatures">
          <Button style={{ float: "right", marginRight: '4%', marginTop: '2%',marginBottom:'3%' }} variant="contained" >
           Go Back
          </Button>
          </Link>
        </div>
    


    

    </div>
  );
};

export default ViewProductFeatures;
