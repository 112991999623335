  // import React, { useState,useEffect } from 'react';
  // import Box from '@mui/material/Box';
  // import Collapse from '@mui/material/Collapse';
  // import IconButton from '@mui/material/IconButton';
  // import Table from '@mui/material/Table';
  // import TableBody from '@mui/material/TableBody';
  // import TableCell from '@mui/material/TableCell';
  // import TableContainer from '@mui/material/TableContainer';
  // import TableHead from '@mui/material/TableHead';
  // import TableRow from '@mui/material/TableRow';
  // import Typography from '@mui/material/Typography';
  // import Paper from '@mui/material/Paper';
  // import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  // import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
  // import Pagination from '@mui/material/Pagination';
  // import axios from '../../api/axios';
  // import TablePagination from '@mui/material/TablePagination';
  // import DeleteIcon from '@mui/icons-material/Delete';

  // function Row({ list, onRowRemove, onSubFeatureRemove }) {
  //     // const { list } = props;
  //     // const { list, onRowRemove, onSubFeatureRemove } = props;
  //     const [open, setOpen] = useState(false);

  //       // Remove main feature
  //       const handleRowRemoveClick = () => {
  //         onRowRemove(list.mainFeatureId);
  //       };
      
  //       const handleSubFeatureRemoveClick = (subFeatureId) => {
  //         onSubFeatureRemove(list.mainFeatureId, subFeatureId);
  //       };

    

  //     return (
  //       <React.Fragment>
  //         <TableRow sx={{ '& > *': {borderBottom: '1px solid rgba(224, 224, 224, 1)',padding: '12px'} }} key={list.mainFeatureId}>
  //           <TableCell component="th" scope="row">{list.mainFeatureId || 'N/A'}</TableCell>
  //           <TableCell component="th" scope="row">{list.mainFeatureNames || 'N/A'}</TableCell>
  //           <TableCell component="th" scope="row">{list.mainFeatureWeightage || '-'}</TableCell>
  //           <TableCell>

  //           <IconButton
  //   style={{color:'red'}}
  //   aria-label="remove row"
  //   size="small"
  //   onClick={handleRowRemoveClick}
    
  // >
  // <DeleteIcon />
  //   {/* Remove */}
  // </IconButton>


  //           </TableCell>
  //           <TableCell>
  //           <IconButton
  //             aria-label="expand row"
  //             size="small"
  //             onClick={() => setOpen(!open)}
  //           >
  //             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  //           </IconButton>
  //           </TableCell>
        
  //         </TableRow>
  //         <TableRow sx={{borderBottom: '10px solid rgba(224, 224, 224, 2)'}}>
  //           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
  //             <Collapse in={!open} timeout="auto" unmountOnExit>
  //               <Box sx={{ margin: 1 }}>
  //                 {/* <Typography variant="h6" gutterBottom component="div">
  //                   History
  //                 </Typography> */}
  //                 <Table size="small" aria-label="purchases">
  //                   <TableHead style={{ backgroundColor: 'rgb(245 244 244)' }}>
  //                     <TableRow>
  //                       <TableCell style={{ fontWeight: 'bold',color:'gray' }}>Sub Feature ID</TableCell>
  //                       <TableCell  style={{ fontWeight: 'bold' ,color:'gray' }}>Sub Feature Name</TableCell>
  //                       <TableCell align="right" style={{ fontWeight: 'bold',color:'gray' }}>Sub Feature Weightage</TableCell>
  //                       <TableCell align="right" style={{ fontWeight: 'bold',color:'gray' }}>Action</TableCell>
                
  //                     </TableRow>
  //                   </TableHead>
  //                   <TableBody>
  //                   {list && list.subFeatures ? (
  //   Array.isArray(list.subFeatures) && list.subFeatures.length > 0 ? (
  //     list.subFeatures.map((subFeature, index) => (
  //       <TableRow key={index}>
  //         <TableCell component="th" scope="row">{subFeature.subFeatureId || 'N/A'}</TableCell>
  //         <TableCell>{subFeature.subFeatureName || '-'}</TableCell>
  //         <TableCell align="right">{subFeature.subFeatureWeightage || '-'}</TableCell>
  //         <TableCell  align="right">
  //         <IconButton
  //       style={{color:'red'}}
  //       aria-label="remove sub feature"
  //       size="small"
  //       onClick={() => handleSubFeatureRemoveClick(subFeature.subFeatureId)}
  //     >
  //       {/* Remove */}
  //       <DeleteIcon />
  //     </IconButton>

  //                         </TableCell>
  //       </TableRow>
  //     ))
  //   ) : (
  //     <TableRow>
  //       <TableCell colSpan={3} align="center">No Data</TableCell>
  //     </TableRow>
  //   )
  // ) : (
  //   <TableRow>
  //     <TableCell colSpan={3} align="center">Loading...</TableCell>
  //   </TableRow>
  // )}

  //   </TableBody>
  //                 </Table>
  //               </Box>
  //             </Collapse>
  //           </TableCell>
  //         </TableRow>
  //       </React.Fragment>
  //     );
  //   }
    

  //   const ListProductDetail = ({ listData, handleRowRemove, handleSubFeatureRemove }) => {
  
  //   // const [listData, setListData] = useState([]); // Initialize as an empty array

  //   // useEffect(() => {
  //   //   if (ListData) {
  //   //     setListData(ListData);
  //   //   }
  //   // }, [ListData]);
    

  //   console.log(listData)
  //   const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(5); 


  //   const handleChangePage = (_, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };


  //   // const handleRowRemove = (mainFeatureId) => {
  //   //   // Logic to remove main feature based on mainFeatureId
  //   //   const updatedListData = listData.filter(item => item.mainFeatureId !== mainFeatureId);
  //   //   setListData(updatedListData);
  //   // };
    
  //   // const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
  //   //   // Logic to remove sub-feature based on mainFeatureId and subFeatureId
  //   //   const updatedListData = listData.map(item => {
  //   //     if (item.mainFeatureId === mainFeatureId) {
  //   //       item.subFeatures = item.subFeatures.filter(sub => sub.subFeatureId !== subFeatureId);
  //   //     }
  //   //     return item;
  //   //   });
  //   //   setListData(updatedListData);
  //   // };
    

  //   return (
  //     <>
  //       <TableContainer component={Paper} sx={{ width: '96%', marginLeft: '2%' }}>
  //         <Table aria-label="collapsible table">
  //           <TableHead style={{ backgroundColor: '#e1e1e1' }}>
  //             <TableRow>
  //               <TableCell  style={{ fontWeight: 'bold' }}>Main Feature ID</TableCell>
  //               <TableCell  style={{ fontWeight: 'bold' }}>Main Feature Name</TableCell>
  //               <TableCell  style={{ fontWeight: 'bold' }}>Main Feature Weightage</TableCell>
  //               <TableCell  style={{ fontWeight: 'bold' }}>Action</TableCell>
  //               <TableCell />
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //           {listData && listData.length > 0 ? (
  //   (rowsPerPage > 0
  //     ? listData
  //         .sort((a, b) => b.mainFeatureId - a.mainFeatureId)
  //         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //     : listData.sort((a, b) => b.mainFeatureId - a.mainFeatureId)
  //   ).map((list) => (
  //     // <Row key={list.mainFeatureId} list={list} />

  // <Row key={list.mainFeatureId} list={list} onRowRemove={handleRowRemove}
  //           onSubFeatureRemove={handleSubFeatureRemove}/>
  //     ))
  // ) : (
  //   <TableRow>
  //     <TableCell colSpan={4}>No data available</TableCell>
  //   </TableRow>
  // )}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
      
  //       <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '20px', marginBottom: '20px' }}>
  //         <TablePagination
  //           rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
  //           component="div"
  //           count={listData.length}
  //           rowsPerPage={rowsPerPage}
  //           page={page}
  //           onPageChange={handleChangePage}
  //           onRowsPerPageChange={handleChangeRowsPerPage}
  //         />
  //       </Box>
  //     </>
  //   );
  // };

  // export default ListProductDetail;

  // =

  // import React, { useState } from 'react';
  // import {
  //   Paper,
  //   Table,
  //   TableBody,
  //   TableCell,
  //   TableContainer,
  //   TableHead,
  //   TableRow,
  //   TablePagination,
  //   Box,
  //   IconButton,
  //   Collapse
  // } from '@mui/material';
  // import DeleteIcon from '@mui/icons-material/Delete';
  // import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  // import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
  
  // function Row({ list, onRowRemove, onSubFeatureRemove }) {
  //   const [open, setOpen] = useState(false);
  
  //   const handleRowRemoveClick = () => {
  //       onRowRemove(list.mainFeatureId);
  //   };
  
  //   const handleSubFeatureRemoveClick = (subFeatureId) => {
  //       onSubFeatureRemove(list.mainFeatureId, subFeatureId);
  //   };
  
  //   return (
  //     <React.Fragment>
  //       <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1)", padding: "12px" } }}>
  //         <TableCell component="th" scope="row">
  //           {list.mainFeatureId || "N/A"}
  //         </TableCell>
  //         <TableCell>{list.mainFeatureNames || "N/A"}</TableCell>
  //         <TableCell>{list.mainFeatureWeightage || "-"}</TableCell>
  //         <TableCell>
  //           <IconButton aria-label="remove row" size="small" onClick={handleRowRemoveClick} style={{ color: "red" }}>
  //             <DeleteIcon />
  //           </IconButton>
  //         </TableCell>
  //         <TableCell>
  //           <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
  //             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  //           </IconButton>
  //         </TableCell>
  //       </TableRow>
  //       {open && (
  //         <TableRow>
  //           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
  //             <Collapse in={open} timeout="auto" unmountOnExit>
  //               <Box sx={{ margin: 1 }}>
  //                 <Table size="small" aria-label="sub-features">
  //                   <TableHead>
  //                     <TableRow>
  //                       <TableCell>Sub Feature ID</TableCell>
  //                       <TableCell>Sub Feature Name</TableCell>
  //                       <TableCell align="right">Sub Feature Weightage</TableCell>
  //                       <TableCell align="right">Action</TableCell>
  //                     </TableRow>
  //                   </TableHead>
  //                   <TableBody>
  //                     {list.subFeatures.map((subFeature, index) => (
  //                       <TableRow key={index}>
  //                         <TableCell>{subFeature.subFeatureId || "N/A"}</TableCell>
  //                         <TableCell>{subFeature.subFeatureName || "-"}</TableCell>
  //                         <TableCell align="right">{subFeature.subFeatureWeightage || "-"}</TableCell>
  //                         <TableCell align="right">
  //                           <IconButton aria-label="remove sub feature" size="small" onClick={() => handleSubFeatureRemoveClick(subFeature.subFeatureId)} style={{ color: "red" }}>
  //                             <DeleteIcon />
  //                           </IconButton>
  //                         </TableCell>
  //                       </TableRow>
  //                     ))}
  //                   </TableBody>
  //                 </Table>
  //               </Box>
  //             </Collapse>
  //           </TableCell>
  //         </TableRow>
  //       )}
  //     </React.Fragment>
  //   );
  // }
  
  // const ListProductDetail = ({ listData, handleRowRemove, handleSubFeatureRemove }) => {
  //   const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(5);

  //   const handleChangePage = (_, newPage) => {
  //       setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //       setRowsPerPage(parseInt(event.target.value, 10));
  //       setPage(0);
  //   };
  
  //   return (
  //       <>
  //       <TableContainer component={Paper} sx={{ width: "96%", marginLeft: "2%" }}>
  //           <Table aria-label="collapsible table">
  //               <TableHead>
  //                   <TableRow>
  //                       <TableCell>Main Feature ID</TableCell>
  //                       <TableCell>Main Feature Name</TableCell>
  //                       <TableCell>Main Feature Weightage</TableCell>
  //                       <TableCell>Action</TableCell>
  //                       <TableCell />
  //                   </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                   {listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => (
  //                       <Row key={list.mainFeatureId} list={list} onRowRemove={handleRowRemove} onSubFeatureRemove={handleSubFeatureRemove} />
  //                   ))}
  //               </TableBody>
  //           </Table>
  //       </TableContainer>
  //       <Box sx={{ display: "flex", justifyContent: "end", marginTop: "20px", marginBottom: "20px" }}>
  //           <TablePagination
  //               rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
  //               component="div"
  //               count={listData.length}
  //               rowsPerPage={rowsPerPage}
  //               page={page}
  //               onPageChange={handleChangePage}
  //               onRowsPerPageChange={handleChangeRowsPerPage}
  //           />
  //       </Box>
  //   </>
  //   );
  // };
  
  // export default ListProductDetail;
  // ===============================================
  import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  IconButton,
  Collapse
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row({ list, onRowRemove, onSubFeatureRemove }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "1px solid rgba(224, 224, 224, 1)" } }}>
        <TableCell component="th" scope="row">
          {list.mainFeatureId || "N/A"}
        </TableCell>
        <TableCell>{list.mainFeatureNames || "N/A"}</TableCell>
        <TableCell>{list.mainFeatureWeightage || "-"}</TableCell>
        <TableCell>
          <IconButton aria-label="remove row" onClick={() => onRowRemove(list.mainFeatureId)} color="error">
            <DeleteIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="sub-features">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sub Feature ID</TableCell>
                      <TableCell>Sub Feature Name</TableCell>
                      <TableCell align="right">Sub Feature Weightage</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.subFeatures.map((subFeature, index) => (
                      <TableRow key={index}>
                        <TableCell>{subFeature.subFeatureId || "N/A"}</TableCell>
                        <TableCell>{subFeature.subFeatureName || "-"}</TableCell>
                        <TableCell align="right">{subFeature.subFeatureWeightage || "-"}</TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="remove sub feature" onClick={() => onSubFeatureRemove(list.mainFeatureId, subFeature.subFeatureId)} color="error">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

const ListProductDetail = ({ listData, handleRowRemove, handleSubFeatureRemove }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Main Feature ID</TableCell>
            <TableCell>Main Feature Name</TableCell>
            <TableCell>Main Feature Weightage</TableCell>
            <TableCell>Action</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list) => (
            <Row key={list.mainFeatureId} list={list} onRowRemove={handleRowRemove} onSubFeatureRemove={handleSubFeatureRemove} />
          ))}
        </TableBody>
      </Table>
      <Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </TableContainer>
  );
};

export default ListProductDetail;
