import React, { useEffect, useState } from 'react';
import { TextField, Box, Typography, Grid, Paper, TablePagination, TableHead, TableCell, TableRow, TableBody, Table, TableContainer, Container, Button } from '@mui/material';
import useAxiosInstance from '../../api/axios';
import { useNavigate, useParams, Link } from 'react-router-dom';

const ViewMerchantPerformance = () => {
  const { Id } = useParams();
  const navigate = useNavigate();

  const [merchantDetails, setMerchantDetails] = useState({
    merchantId: Id,
    merchantName: '',
    email: '',
    contactNumber: '',
    contactPerson: '',
    offerCode: '',
    commissionRate: '',
    locations: []
  });

  const [locations, setLocations] = useState([]);
  const [merchantStats, setMerchantStats] = useState([]);

  const axios = useAxiosInstance();

  useEffect(() => {
    const getByMerchantLocationId = async () => {
      try {
        const response = await axios.get(`/api/merchant-locations?merchantId=${Id}`);
        setLocations(response?.data.content);
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };

    const getByMerchantId = async () => {
      try {
        const response = await axios.get(`/api/merchants/${Id}`);
        setMerchantDetails(response?.data);
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };



    getByMerchantLocationId();
    getByMerchantId();
  
  }, [Id]);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);


  const getMerchantStats = async (page, size) => {
    try {

        const response = await axios.get(`/api/merchants/${Id}/stats?page=${page}&size=${size}`);
        setMerchantStats(response?.data.content);
         console.log(response?.data.content);
         setTotalElements(response.data.totalElements);
  

    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    getMerchantStats(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0); // Reset to first page
  };


 

  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
      <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
        <h2>View Merchant Performance</h2>
      </div>

      <div style={{
        padding: '2%',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0px 0px 7px 0px #cfc5c56b',
        width: '91%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%'
      }}>
        <Container>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", marginTop: "20px" }}>
            Merchant Details
          </Typography>
          <Grid container spacing={3} style={{ margin: '5vh 10px 10px 0px' }} className="form-container">
            <Grid container spacing={3} item xs={12} md={12} lg={12} >
              <Grid item xs={4} sm={4} md={4}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Name</Typography>
                <TextField
                  placeholder='Enter Name'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={merchantDetails.merchantName}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Email</Typography>
                <TextField
                  type='Enter email'
                  placeholder='Merchant Email'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={merchantDetails.email}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Offer Code</Typography>
                <TextField
                  placeholder='Enter offer code'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={merchantDetails.offerCode}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Person</Typography>
                <TextField
                  placeholder='Enter contact person'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={merchantDetails.contactPerson}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Merchant Contact Number</Typography>
                <TextField
                  placeholder='Enter contact no.'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={merchantDetails.contactNumber}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div style={{
        marginTop: '5%',
        padding: '3%',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0px 0px 7px 0px #cfc5c56b',
        width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%'
      }}>
        <Container>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", marginTop: "20px" }}>
            Merchant Performance - {merchantDetails.merchantName}
          </Typography>
          <Grid container spacing={3} style={{ margin: '5vh 10px 10px 5px' }} className="form-container">
          </Grid>
        </Container>

        {merchantStats && merchantStats.length > 0 ? (
          <Grid container item xs={12} md={12} lg={12} sx={{ marginTop: '1%', marginLeft: '1%', marginBottom: '1%' }}>
            <React.Fragment>
              <TableContainer component={Paper} className="table-container">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="table-heading">
                      <TableCell style={{ fontWeight: "bold" }}>Merchant Location</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Application Start</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Application Submitted</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Application Declined</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Application Approved</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Total Merchant Application</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {merchantStats.map((stat, index) => (
                      <TableRow key={index}>
                        <TableCell>{stat.location}</TableCell>
                        <TableCell>{stat.created}</TableCell>
                        <TableCell>{stat.submitted}</TableCell>
                        <TableCell>{stat.rejected}</TableCell>
                        <TableCell>{stat.approved}</TableCell>
                        <TableCell>{stat.totalMerchantApplication}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div style={{ float: 'right', marginTop: '10px' }}>
                <TablePagination
component="div"
count={totalElements}
rowsPerPage={rowsPerPage}
page={page}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
              
                />
              </div>
            </React.Fragment>
          </Grid>
        ) : (
          <div style={{ width: '89%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%' }}>No stats available</div>
        )}
      </div>

      <Link to="/dashboard/merchant">
        <Button variant="contained" sx={{ float: "right", marginTop: '1%', marginRight: '3%', marginBottom: '2%' }}>
          Go Back
        </Button>
      </Link>
    </div>
  );
};

export default ViewMerchantPerformance;
