import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  Select,
  TableRow,
  Paper,
  Modal,
  MenuItem,
  InputAdornment,
  Backdrop,
  Typography,
  Grid,
  Button,
  TablePagination,
} from "@mui/material";

import useAxiosInstance from '../../api/axios';
import usePublicAxiosInstance from '../../api/pubicAxios';
import { Link } from "react-router-dom";
import { useNavigate ,useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const ViewBanner = () => {

  const axio = usePublicAxiosInstance();

  const navigate = useNavigate();
  const { Id } = useParams();


  const [productImage, setProductImage] = useState(null);
  const [promotionalBannerView, SetPromotionalBannerView] = useState({
    title: "",
    routingUrl: "",
    description: "",
    imageUrl: null
  });


  const axios = useAxiosInstance();

  useEffect(() => {
    const getByBannerId = async () => {
      try {
        const response = await axios.get(`/api/banners/${Id}`);

        const responseImage = await axio.get(`/api/images/public/banner/${response?.data.imageUrl}`, {
                responseType: 'blob' 
              });
              setProductImage(responseImage.data);


        console.log(response?.data);
        SetPromotionalBannerView(response?.data)
    
  
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };
  
    getByBannerId();
  }, [Id]);

  

  return (
    <>
    
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
  
    <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
     <Link to="/dashboard/promotionalBanner" style={{float:"left",marginTop:'2%'}}>
        <Button style={{color:'black'}} >
        <KeyboardBackspaceIcon />
        </Button>
      </Link> 
       <h2 style={{fontWeight:"bold"}}> View Promotional Banner</h2>
  </div>

      <div>
        <Paper
          style={{
            padding: "20px",
            background: "#fff",
            width: "84%",
            height: "auto",
            padding: "12px 65px",
            marginLeft: "2%",
            marginTop: "2%",
          }}
        >
          <h2>Banner Details</h2>
          <hr></hr>
          <Grid
            container
            spacing={2}
            sx={{ paddingLeft: "10px", marginTop: "10px" }}
          >

<Grid container spacing={2} style={{ margin: '0vh 0px 10px 0px' }} className="form-container">
<Grid container spacing={2} item xs={8} md={8} lg={8} >
         
<Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Title"
                  placeholder="Enter Banner Title"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  value={promotionalBannerView.title}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="URL"
                  placeholder="Enter URL"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  value={promotionalBannerView.routingUrl}
                  InputProps={{
                    readOnly: true,
                  }}
               
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>



              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Description"
                  placeholder="Enter Description"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={2}
  maxRows={4}
                  size="small"
                  value={promotionalBannerView.description}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>

             

              </Grid>


              <Grid className="imageGrid" item xs={4} md={4} lg={4}>
  <Grid item xs={12} sm={12} md={12}>
    <Box mt={2} style={{
      width: '180px',
    //   marginTop:'50%',
      height: '140px',
      backgroundColor: '#f0f0f0',
      border: "2px solid #f0f0f0",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    }}>
      {productImage ? (
                  <img
                  src={productImage ? URL.createObjectURL(productImage) : ""}
          alt="Uploaded"
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
        />
      ) : (
        <Typography variant="body2" color="textSecondary">
          No Image Found
        </Typography>
      )}
    </Box>
  </Grid>
</Grid>

            </Grid>


            

          </Grid>
        </Paper>
       
        <Link to="/dashboard/promotionalBanner">
          <Button
            style={{ float: "right", marginRight: "3%", marginTop: "2%" }}
            variant="outlined"
          >
            Back
          </Button>
        </Link>
      </div>
    </div>

    </>
  );
};

export default ViewBanner;
