import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { store } from './app/store'
import { Provider } from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist';
import { Suspense } from 'react';
// import { Spin } from 'antd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let persistor=persistStore(store)

root.render(
  <Suspense fallback={<>
    {/* <Spin spinning  tip="Loading" size="large" style={{display:'flex', marginTop:350}}>
         <div className="content" />
       </Spin> */}
   </>}>
   <HashRouter basename='/'>
   <Provider store={store}>
     <PersistGate persistor={persistor}>
   
    <App />
 
     </PersistGate>
   </Provider>
 </HashRouter>
 </Suspense>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
