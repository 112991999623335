import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../api/axios';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props) {
    const { user } = props;
    const [open, setOpen] = useState(false);
    const [questions, setQuestion] = useState(null);

    const axios = useAxiosInstance();

    const getQuestion = async (Id) => {
        const response = await axios.get(`/api/applications/questions/${Id}`);
        setQuestion(response?.data);
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={user.applicationUniqueId}>
          <TableCell component="th" scope="row">{user.applicationId || 'N/A'}</TableCell>
          <TableCell component="th" scope="row">{user.customer?.name || 'N/A'}</TableCell>
          <TableCell >{user.location?.merchantName || '-'}</TableCell>
          <TableCell >{user.product?.productName || '-'}</TableCell>
          <TableCell align="right">{user.applicationType || '-'}</TableCell>
          <TableCell align="right">{user.status}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                getQuestion(user.applicationId);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead style={{ backgroundColor: 'rgb(245 244 244)' }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold', color: 'gray' }}>Client Number</TableCell>
                      <TableCell style={{ fontWeight: 'bold', color: 'gray' }}>Merchant Location</TableCell>
                      <TableCell style={{ fontWeight: 'bold', color: 'gray' }}>Merchant Code</TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold', color: 'gray' }}>Eligibility Question</TableCell>
                      <TableCell align="right" style={{ fontWeight: 'bold', color: 'gray' }}>Product Recommended</TableCell>
                      {user.applicationType !== 'Loan' && (
                      <TableCell align="right" style={{ fontWeight: 'bold', color: 'gray' }}>Product Feature Selected</TableCell>
                      )}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">{user.customer?.uniqueCustomerID || 'N/A'}</TableCell>
                      <TableCell>{user.location?.city || '-'}</TableCell>
                      
          <TableCell>{user.location?.merchantUniqueId || '-'}</TableCell>
                      <TableCell align="right">
                        {questions ? (
                          Array.isArray(questions) ? (
                            questions.map((product, index) => (
                              <div key={index}>{product.trim()}</div>
                            ))
                          ) : (
                            questions
                          )
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {user.recommendationProducts ? (
                          user.recommendationProducts.split(',').map((product, index) => (
                            <div key={index}>{product.trim()}</div>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>

                      {user.applicationType !== 'Loan' && (
                      <TableCell align="right">
                        {user.preferences ? (
                          user.preferences.split(',').map((product, index) => (
                            <div key={index}>{product.trim()}</div>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

const CollapsibleTable = ({ users,totalElements,rowsPerPage,page,handleChangePage,handleChangeRowsPerPage }) => {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // const axios = useAxiosInstance();

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const size = rowsPerPage === -1 ? totalElements : rowsPerPage;
  //     const response = await axios.get(`/api/applications/filter?page=${page}&size=${size}`);
  //     setUsers(response.data.content);
  //     setTotalElements(response.data.totalElements);
  //   };

  //   fetchUsers();
  // }, [page, rowsPerPage]);


  // const handleChangePage = (_, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };



  // Calculate the data to be displayed on the current page

  // const displayedUsers = rowsPerPage === -1
  //   ? users
  //   : users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
    <div style={{ margin: '2%', marginTop: '3%', width: '95%' }}>
        <TableContainer className="table-container" component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead style={{ backgroundColor: '#e1e1e1' }}>
 
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Application ID</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Client Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Merchant Name</TableCell>
              {/* <TableCell align="right"  style={{ fontWeight: 'bold' }}>Merchant ID</TableCell> */}
              <TableCell  style={{ fontWeight: 'bold' }}>Product Selected</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Application Type</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Application Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <Row key={user.applicationId} user={user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        // rowsPerPageOptions={[20, 40, 60, { label: 'All', value: -1 }]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </div>
    </>
  );
};

export default CollapsibleTable;
