import React, { useState ,useEffect} from 'react';
import { Modal, TextField, Button, Grid, Paper, Typography, Backdrop } from '@mui/material';



const AddLocationModal = ({ open, onClose, onAddLocation,setErrors,errors }) => {


 


  const [locationDetails, setLocationDetails] = useState({
 
    area:'',
    address:'',
    city:'',

  });


   useEffect(() => {
    if (open) {
      setLocationDetails({
        area:'',
        address:'',
        city:'',
      });
    }
  }, [open]);



  const handleChange = (field, value) => {
    setLocationDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    setErrors((prevState) => ({ ...prevState, [field]: '' }));
  };

  const handleAddLocation = () => {

    const newErrors = {};
    if (!locationDetails.area) {
      newErrors.area = 'Please enter a area.';
    }
    if (!locationDetails.address) {
      newErrors.address = 'Please enter an address.';
    }
    if (!locationDetails.city) {
      newErrors.city = 'Please select a city.';
    }
  

    if (Object.keys(newErrors).length === 0) {
    onAddLocation(locationDetails);

    onClose();
} else {
    // Display validation errors
    setErrors(newErrors);
  }

  };
  

  return (
    <>
      
    <Modal open={open} onClose={onClose} BackdropComponent={Backdrop} disableBackdropClick={false}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Paper style={{ padding: '20px', background: '#fff', width: '100vh', height:"auto", padding:"20px 50px", zIndex:1 }}>
          <h2 style={{fontSize:'bold'}}>Add Location</h2>
          <hr></hr>
          <Grid container spacing={2} sx={{paddingLeft:"20px", marginTop:"30px"}}>
        
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              <TextField
                label="Area"
                fullWidth
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                    }, '& .MuiFormLabel-root':{
                        fontSize:"11px"
                    }
                  }}
                value={locationDetails.area}
                onChange={(e) => handleChange('area', e.target.value)}
                error={!!errors.area}
                helperText={errors.area}
              />
            </Grid>
           
            <Grid item xs={6} md={6} lg={6}>
              <TextField
                label="City"
                fullWidth
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                    }, '& .MuiFormLabel-root':{
                        fontSize:"11px"
                    }
                  }}
                value={locationDetails.city}
                onChange={(e) => handleChange('city', e.target.value)}
                error={!!errors.city}
                helperText={errors.city}
              />
            </Grid>
         
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="Address"
                fullWidth
                size="small"
                sx={{
                    '& .MuiInputBase-root': {
                      height: '30px',
                    }, '& .MuiFormLabel-root':{
                        fontSize:"11px"
                    }
                  }}
                value={locationDetails.address}
                onChange={(e) => handleChange('address', e.target.value)}
                error={!!errors.address}
                helperText={errors.address}
              />
            </Grid>
            </Grid>
          </Grid>

          <Button variant="contained" color="primary"  onClick={handleAddLocation} style={{ marginTop: '20px', float:"right" }}>
            Add
          </Button>
          <Button variant="outlined" color="secondary"  onClick={onClose} style={{ marginTop: '20px', float:"right" ,marginRight:'2%'}}>
            Close
          </Button>
        </Paper>
      </div>
    </Modal>
    </>
  );
};

export default AddLocationModal;
