import React from 'react';
// import { Button, Result } from 'antd';
import { Box, TextField, IconButton,Link as Links,
  FormControl,Select,MenuItem,Typography, Button, Grid, Container,Alert, Snackbar, Checkbox, RadioGroup, FormControlLabel, Radio, Autocomplete  } from '@mui/material';


const ErrorPage: React.FC = () => {
 

  return (
    



<div style={{
  display: 'flex', // Enable flexbox
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
  height: '100vh' // Full viewport height
}}>
  <img src="assets/Home/404-Page.png" alt="Login" style={{ maxWidth: '50%', maxHeight: '50%' }} />
</div>




   
  )
};

export default ErrorPage;
