import React, { useEffect,useState } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { logoutUserAuth } from "../src/features/auth/authSlice";
import { useDispatch } from 'react-redux';
import {useNavigate } from 'react-router-dom';
import {Alert, Snackbar} from '@mui/material';



import Home from './pages/dashboard/Dashboard';
 import Login from './pages/user-managment/login/Login';
import User from './pages/user-managment/userManagment';
 import Error from './pages/404/ErrorPage';
 import ForgotPswd from './pages/user-managment/register/ForgotPassword';
 import ResetPswd from './pages/user-managment/reset-password/ResetPassword';

// import AddUser from './pages/user-managment/Users/';
 import Sidebar from './components/dynamic/Sidebar';

import MerchantManagent from './pages/MerchantPerformance/Merchant_Management';
import ViewMerchantPerformance from './pages/MerchantPerformance/ViewMerchantPerformance';
import ApplicationTracker from './pages/ApplicationTracker/ApplicationTracker';
import Report from './pages/Report/Report';
import EligilityQuestion from './pages/EligibilityQuestions/eligibilityQuestions';
import ProductFeatures from './pages/ProductFeatures/ProductFeatures';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import AddProduct from './pages/ProductDetails/CreateProduct';
import ViewProduct from './pages/ProductDetails/ViewProduct';
import EditProduct from './pages/ProductDetails/EditProduct';
import ViewMerchant from './pages/MerchantDetails/ViewMerchant';
import EditMerchant from './pages/MerchantDetails/EditMerchant';
import MerchantDetails from './pages/MerchantDetails/MerchantDetails';
import AddMerchant from './pages/MerchantDetails/CreateMerchantDetails';
import AddProductFeature from './pages/ProductFeatures/AddProductFeature';
import ViewProductFeature from './pages/ProductFeatures/ViewProductFeature';
import EditProductFeature from './pages/ProductFeatures/EditProductFeature';

import PromotionalBanner from './pages/Promotional_Banner/promotionalBanner';
import CreateBanner from './pages/Promotional_Banner/CreateBanner';
import ViewBanner from './pages/Promotional_Banner/ViewBanner';
import EditBanner from './pages/Promotional_Banner/EditBanner';

import useAxiosInstance from '../src/api/axios';

const SubRoutes = () => {
  const authObject = useSelector((state: any) => state?.auth?.userDetails);
  console.log(authObject);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axios = useAxiosInstance();
  const [openStatus, setOpenStatus] = React.useState(false);


  useEffect(() => {
  
    const getAllCustomer = async () => {
      try {
        const response = await axios.get("/api/customer-profiles/stats");
    
          // console.log(response?.data)
    
      } catch (error: any) {
        // console.error("Axios Error:", error);
        if (error.response && error.response.data && error.response.data.error === "Token has expired.") {
          console.log("Error Response Data:", error.response.data.error);
          localStorage.clear();
          dispatch(logoutUserAuth());
          alert("Session TimeOut, Please login again.")
          setOpenStatus(true);
          // setTimeout(()=>{
          //   handleClose();
          // },3000)
          
          navigate('/');
        }
      }
      
    };
    getAllCustomer();

  });

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setOpenStatus(false); // Set openStatus to false when the Snackbar is closed
  };


  return (
    <>
    <Routes>
      {authObject ? (
        <>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/dashboard/user" element={<User />} />
          <Route path="/dashboard/merchant" element={<MerchantManagent />} />
          <Route path="/dashboard/applicationTracker" element={<ApplicationTracker />} />
          <Route path="/dashboard/report" element={<Report />} />
          <Route path="/dashboard/eligibilityQuestion" element={<EligilityQuestion />} />
          <Route path="/dashboard/productFeatures" element={<ProductFeatures />} />
          <Route path="/dashboard/productDetails" element={<ProductDetails />} />
          <Route path="/dashboard/addProduct" element={<AddProduct />} />
          <Route path="/dashboard/addProductFeature" element={<AddProductFeature />} />
          <Route path="/productFeature/view/:Id" element={<ViewProductFeature />} />
          <Route path="/merchantPerformance/view/:Id" element={<ViewMerchantPerformance />} />
          <Route path="/productFeature/edit/:Id" element={<EditProductFeature />} />

          <Route path="/dashboard/merchantDetails" element={<MerchantDetails />} />
          <Route path="/dashboard/addMerchant" element={<AddMerchant />} />
       
          <Route path="/product/view/:Id" element={<ViewProduct />} />
          <Route path="/product/edit/:Id" element={<EditProduct />} />

          <Route path="/merchant/view/:Id" element={<ViewMerchant />} />
          <Route path="/merchant/edit/:Id" element={<EditMerchant />} />

          <Route path="/dashboard/promotionalBanner" element={<PromotionalBanner />} />
          <Route path="/Banner/addBanner" element={<CreateBanner />} />
          <Route path="/banner/view/:Id" element={<ViewBanner />} />
          <Route path="/banner/edit/:Id" element={<EditBanner />} />


          <Route path="/" element={<Sidebar />} />
          <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        </>
      ) : (

        <>
       
        <Route path="/" element={<Login />} />
        <Route path="/ForgotPswd" element={<ForgotPswd />} />
        <Route path="/ResetPswd/:email" element={<ResetPswd />} />
        <Route path="*" element={<Navigate to="/" replace/>} />
        
        </>
        )}
    </Routes>


    <Snackbar
  open={openStatus}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="info"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Session TimeOut, Please login again.
  </Alert>
</Snackbar>
    </>
  );
};

export default SubRoutes;
