import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  Select,
  TableRow,
  Paper,
  Modal,
  MenuItem,
  InputAdornment,
  Backdrop,
  Typography,
  Grid,
  Button,
  TablePagination,
} from "@mui/material";

import useAxiosInstance from '../../api/axios';
import usePublicAxiosInstance from '../../api/pubicAxios';
import { Link } from "react-router-dom";
import { useNavigate ,useParams} from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import axiosInstance from 'axios';

const EditBanner = () => {

  const axio = usePublicAxiosInstance();


  const navigate = useNavigate();
const {Id} = useParams();

const [productImage, setProductImage] = useState(false);
const [image, setImage] = useState(null);
  const [promotionalBannerCreate, SetPromotionalBannerCreate] = useState({
    title: "",
    routingUrl: "",
    description: "",
    imageUrl: null
  });
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    routingUrl: "",
    description: "",
    imageUrl: "",
  });
  const axios = useAxiosInstance();
  

  useEffect(() => {
    const getByBannerId = async () => {
      try {
        const response = await axios.get(`/api/banners/${Id}`);
        setImage(response?.data.imageUrl)
        const responseImage = await axio.get(`/api/images/public/banner/${response?.data.imageUrl}`, {
                responseType: 'blob' 
              });

              const filedata = response?.data
              filedata.imageUrl = responseImage.data;


        console.log(filedata);

        SetPromotionalBannerCreate(filedata)
    
  
      } catch (error) {
        console.error("Axios Error:", error);
      }
    };
  
    getByBannerId();
  }, [Id]);

  const validateFeatures = () => {
    let errors = {};
    if (!promotionalBannerCreate.title.trim()) {
      errors.title = "Title is required.";
    }
    if (!promotionalBannerCreate.routingUrl.trim()) {
      errors.routingUrl = "URL is required.";
    } 
    if (!promotionalBannerCreate.description.trim()) {
      errors.description = "Description is required.";
    } 
    if (!promotionalBannerCreate.imageUrl) {
      errors.imageUrl = "Image is required.";  // Check if the icon is uploaded
    }
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
 
  const handleAddChange = (field, value) => {
    SetPromotionalBannerCreate((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleSubmitModal = async () => {
    // Perform validation
    if (!validateFeatures()) {
      return; // Stop the submission if validation fails
    }
    


    try {

      if(productImage){
        const formData = new FormData();
    
        formData.append("image", promotionalBannerCreate.imageUrl);
  
      const response = await axios.post(
          "/api/images/banner",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        console.log(response.data)
  
        const res = {
          id:Id,
          title:promotionalBannerCreate.title,
          routingUrl:promotionalBannerCreate.routingUrl,
          description:promotionalBannerCreate.description,
          imageUrl:response.data,
          active:"Y"
        }
  
        console.log(res)
        await axios.put(
          `/api/banners`,
          res,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      }else{
      
        const res = {
          id:Id,
          title:promotionalBannerCreate.title,
          routingUrl:promotionalBannerCreate.routingUrl,
          description:promotionalBannerCreate.description,
          imageUrl:image,
          active:"Y"
        }
  
        console.log(res)
        await axios.put(
          `/api/banners`,
          res,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      }
     

      console.log("Banner Updated successfully !");

      setTimeout(() => {
        navigate("/dashboard/promotionalBanner");
      }, 1000);
    } catch (err) {
      if (err.response && err.response.data) {
        console.log("Internal Server Error");
      } else {
        console.log("Error occurred. Please try again.");
      }
    }
  };





  const handleImageChange = (field, file) => {
    if (file) {
      SetPromotionalBannerCreate((prevDetails) => ({
        ...prevDetails,
        [field]: file,
      }));
      setProductImage(true)
    } else {
      console.log("File selection canceled");
    }
  };

  return (
    <>
    
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
  
    <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
     <Link to="/dashboard/promotionalBanner" style={{float:"left",marginTop:'2%'}}>
        <Button style={{color:'black'}} >
        <KeyboardBackspaceIcon />
        </Button>
      </Link> 
       <h2 style={{fontWeight:"bold"}}> Edit Promotional Banner</h2>
  </div>

      <div>
        <Paper
          style={{
            padding: "20px",
            background: "#fff",
            width: "84%",
            height: "auto",
            padding: "12px 65px",
            marginLeft: "2%",
            marginTop: "2%",
          }}
        >
          <h2>Banner Details</h2>
          <hr></hr>
          <Grid
            container
            spacing={2}
            sx={{ paddingLeft: "10px", marginTop: "10px" }}
          >

<Grid container spacing={2} style={{ margin: '0vh 0px 10px 0px' }} className="form-container">
<Grid container spacing={2} item xs={8} md={8} lg={8} >
         
<Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Title"
                  placeholder="Enter Banner Title"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  value={promotionalBannerCreate.title}
                  onChange={(e) =>
                    handleAddChange("title", e.target.value)
                  }
                  error={!!validationErrors.title}
                  helperText={validationErrors.title}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="URL"
                  placeholder="Enter URL"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  value={promotionalBannerCreate.routingUrl}
                  onChange={(e) =>
                    handleAddChange("routingUrl", e.target.value)
                  }
                  error={!!validationErrors.routingUrl}
                  helperText={validationErrors.routingUrl}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "30px",
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>



              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Description"
                  placeholder="Enter Description"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={2}
  maxRows={4}
                  size="small"
                  value={promotionalBannerCreate.description}
                  onChange={(e) =>
                    handleAddChange("description", e.target.value)
                  }
                  error={!!validationErrors.description}
                  helperText={validationErrors.description}
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "11px",
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "11px",
                    },
                  }}
                />
              </Grid>

             

              </Grid>


              <Grid className="imageGrid" item xs={4} md={4} lg={4}>
  <Grid item xs={12} sm={12} md={12}>
    <Box mt={2} style={{
      width: '180px',
    //   marginTop:'50%',
      height: '140px',
      backgroundColor: '#f0f0f0',
      border: "2px solid #f0f0f0",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    }}>
         {promotionalBannerCreate.imageUrl ? (
                    <img
                      src={URL.createObjectURL(promotionalBannerCreate.imageUrl)}
                      alt="Uploaded"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                    Select Image
                  </Typography>
                  )}
    </Box>
    <Typography variant="caption" color="error">
      {validationErrors.imageUrl}
    </Typography>
    <Button
      variant="outlined"
      component="label"
      style={{ marginTop: '0px', marginBottom: '12px', textTransform: 'none' }}
    >
      Upload Image
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => handleImageChange('imageUrl', e.target.files[0])}
      />
    </Button>
  </Grid>
</Grid>

            </Grid>


            

          </Grid>
        </Paper>
        <Button
          style={{
            float: "right",
            marginTop: "2%",
            marginBottom: "4%",
            marginRight: "2%",
          }}
          variant="contained"
          color="primary"
          onClick={handleSubmitModal}
        >
          Update
        </Button>
        <Link to="/dashboard/promotionalBanner">
          <Button
            style={{ float: "right", marginRight: "1%", marginTop: "2%" }}
            variant="outlined"
          >
            Back
          </Button>
        </Link>
      </div>
    </div>

    </>
  );
};

export default EditBanner;
