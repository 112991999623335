import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

interface CustomerProps {
  created: number;
  submitted: number;
  approved: number;
  rejected: number;
}

const size = {
  width: 450,
  height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const ApplicationPieChart: React.FC<{ application: CustomerProps }> = ({ application }) => {
  const totalApplications = application.created + application.submitted + application.approved + application.rejected;

  // Calculate percentages based on totalApplications
  const data = [
    { value: (application.created / totalApplications) * 100, label: `Created (${application.created})` },
    { value: (application.submitted / totalApplications) * 100, label: `Submitted (${application.submitted})` },
    { value: (application.approved / totalApplications) * 100, label: `Approved (${application.approved})` },
    { value: (application.rejected / totalApplications) * 100, label: `Rejected (${application.rejected})` },
  ];

  return (
    <div style={{marginLeft:'-55px'}}>
    <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
      <PieCenterLabel>{totalApplications}%</PieCenterLabel>
    </PieChart>
    </div>
  );
};

export default ApplicationPieChart;
