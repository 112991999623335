import React, { useEffect, useState } from 'react';
import { TextField, Box, FormControl, MenuItem, Select, Alert,Autocomplete, InputLabel, 
  Typography, Snackbar, Button, Grid, Container, FormHelperText,FormControlLabel,FormLabel,RadioGroup,Radio } from '@mui/material';
import useAxiosInstance from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ListProductDetail from './ListProductDetail';

const EmployeeList = [
  { value: 'Salaried', label: 'Salaried' },
  { value: 'Self-Employed', label: 'Self - Employed' }
];

const RatingList = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' }
];

const instantIssuanceList = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
];

const ProductList = [
  {
    value: 'Card',
    label: 'Card',
  },
  {
    value: 'Loan',
    label: 'Loan',
  }
];

const AddProduct = () => {
  const [endNodeUserDetails, setEndNodeUserDetails] = useState([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    mainFeatureWeightage: '',
    selectedMainFeatureId: '',
    productName: '',
    organizationName: '',
    routingUrl: '',
    employmentType: '',
    minimumSalary: '', 
    productType:'',
    description:'',
    productImage: '',
  });
  const [featureData, setFeatureData] = useState([]);

  const [productDetails, setProductDetails] = useState({
    productName: '',
    organizationName: '',
    routingUrl: '',
    employmentType: '',
    minimumSalary: '',
    rating: '',
    instantIssuance: '',
    incentive: '',
    productImage: null,
    description: '',
    annualFee:'',
    tenure:'',
    processingFees:'',
    interestRate:'',
    loanAmount:'',
    loanAmountTxt:'',
    loanTenure:'',
    productType:'',
    salaryTransfer:'',
    freeToJoin:'',
    welcomeBonus:'',
    status: 'Active'
  });

  const axios = useAxiosInstance();

  const handleDocumentUpload = (field, file) => {
    if (file) {
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        [field]: file,
      }));
    } else {
      console.log("File selection canceled");
    }
  };

  const handleChange = (field, value) => {
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleChangeParents = (field, value) => {
    setEndNodeUserDetails(value);
  };

  function transformFeatureData(productId, featureData) {
    const transformedData = {
      productId: productId,
      features: []
    };

    featureData.forEach(mainFeature => {
      const mainFeatureWeight = parseInt(mainFeature.mainFeatureWeightage);

      transformedData.features.push({
        featureId: mainFeature.mainFeatureId,
        productWeight: mainFeatureWeight
      });

      if (mainFeature.subFeatures) {
        mainFeature.subFeatures.forEach(subFeature => {
          const subFeatureWeight = parseInt(subFeature.subFeatureWeightage);
          transformedData.features.push({
            featureId: subFeature.subFeatureId,
            productWeight: subFeatureWeight
          });
        });
      }
    });

    return transformedData;
  }

  const SubmitProductDetails = async () => {
    const newErrors = {};
    if (!productDetails.productName.trim()) newErrors.productName = 'Product name is required.';
    if (!productDetails.organizationName.trim()) newErrors.organizationName = 'Organization name is required.';
    if (!productDetails.routingUrl.trim()) newErrors.routingUrl = 'Routing URL is required.';
    if (!productDetails.employmentType.trim()) newErrors.employmentType = 'Employment type is required.';
    if (!productDetails.minimumSalary.trim()) newErrors.minimumSalary = 'Minimum salary is required.';
    if (!productDetails.productType.trim()) newErrors.productType = 'ProductType is required.';
    if (!productDetails.productImage) newErrors.productImage = 'Product image is required.';
    if (!productDetails.description) newErrors.description = 'Description is required.';
    if (!productDetails.incentive) newErrors.incentive = 'Incentive is required.';


    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const formData = new FormData();
    const res = {
      productName: productDetails.productName,
      organizationName: productDetails.organizationName,
      routingUrl: productDetails.routingUrl,
      employmentType: productDetails.employmentType,
      minimumIncome: productDetails.minimumSalary,
      rating: productDetails.rating,
      instantIssuance: productDetails.instantIssuance,
      incentive: productDetails.incentive,
      annualFee: productDetails.annualFee,
      productType: productDetails.productType,
      freeToJoin: productDetails.freeToJoin,
      welcomeBonus: productDetails.welcomeBonus,
      description: productDetails.description,
      tenure:productDetails.tenure,
      processingFee:productDetails.processingFees,
      interest:productDetails.interestRate,
      loanAmount:productDetails.loanAmount ,
      loanTenure:productDetails.loanTenure ,
      loanAmountTxt:productDetails.loanAmountTxt ,
      salaryTransfer:productDetails.salaryTransfer === '1' ? true : false,
      featureDescription: "",
      productType: productDetails.productType,
      offer: "",
      offerWeight: "",
      status: "Active"
    };

    // console.log(res)
    // console.log(endNodeUserDetails)
  

    formData.append('productDTO', JSON.stringify(res));
    formData.append('file', productDetails.productImage);

    try {

      const response = await axios.post("/api/products/add", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        });
        
        const { productId } = response.data;
        
          if (endNodeUserDetails && productDetails.productType === 'Loan') {

            const resLoan = {
              productId,
              features : endNodeUserDetails.map(
                          ({ featureId,featureName}) => ({
                            featureId,
                            featureName,
                            productType: "Loan",
                            active: "Y",
                          })
                        )
            }
            try {
               await axios.post(
                "/api/products/addProductFeatures",
                resLoan,
                { headers: { "Content-Type": "application/json" } }
              );
            } catch (error) {
              console.error("Error while making POST request:", error);
            }
          }
else{

      const transformedFeatureData = transformFeatureData(productId, featureData);

      if (transformedFeatureData) {
        try {
          const featureResponse = await axios.post(
            "/api/products/addProductFeatures",
            transformedFeatureData,
            { headers: { "Content-Type": "application/json" } }
          );
        } catch (error) {
          console.error("Error while making POST request:", error);
        }
      } else {
        console.warn("Transformed data is empty or invalid. Skipping POST request.");
      }

    }
      console.log("Product Details created successfully !");
      setTimeout(() => {
        navigate('/dashboard/productDetails');
      }, 1000);

    } catch (err) {
      if (err.response && err.response.data) {
        console.log("Internal Server Error");
      } else {
        console.log("Error occurred. Please try again.")
      }
    }
  }

  const [createFeature, setCreateFeature] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [updatedOpenStatus, setUpdatedOpenStatus] = React.useState(false);
  const [openedStatus, setOpenedStatus] = React.useState(false);

  const [productFeature, setProductFeature] = useState(null);
  const [productSubFeature, setProductSubFeature] = useState(null);

  const [selectedMainFeatureId, setSelectedMainFeatureId] = useState('');
  const [mainFeatureWeightage, setMainFeatureWeightage] = useState('');
  const [mainFeatureName, setMainFeatureName] = useState('');

  const [selectedSubFeatureId, setSelectedSubFeatureId] = useState('');
  const [subFeatureWeightage, setSubFeatureWeightage] = useState('');
  const [subFeatureName, setSubFeatureName] = useState('');


  const handleMainWeightageChange = (e) => {
    const weightageValue = e.target.value;
    setMainFeatureWeightage(weightageValue);

    setErrors((prevErrors) => ({
      ...prevErrors,
      mainFeatureWeightage: '',
    }));
  };

  const handleSubWeightageChange = (e) => {
    const weightageValue = e.target.value;
    setSubFeatureWeightage(weightageValue);
  };

const getAllFeaturesList = async () => {
  try {
    const response = await axios.get(`/api/productFeatures/parentFeatures?productType=${productDetails.productType}`);
    
    if (response && response.data) {
      const activeParentFeatures = response.data.filter(item => item.active === "Y");
      setProductFeature(activeParentFeatures);
    } else {
      setProductFeature([]); // set to an empty array if there's no data
    }
  } catch (error) {
    console.error("Axios Error:", error);
    setProductFeature([]); // set to an empty array in case of error
  }
};

  useEffect(() => {
    if (productDetails.productType) {
      getAllFeaturesList();
    }
  }, [productDetails.productType]);


  const handleFeatureNameChange = async (e) => {
    const selectedFeatureId = e.target.value;
    setSelectedMainFeatureId(selectedFeatureId);

    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedMainFeatureId: '',
    }));

    try {
      const response = await axios.get(`/api/productFeatures/subFeatures/${selectedFeatureId}`);
      const subFeatures = response?.data || [];
      setProductSubFeature(subFeatures);

      const selectedMainFeature = productFeature.find(item => item.featureId === selectedFeatureId);
      if (selectedMainFeature) {
        setSelectedMainFeatureId(selectedMainFeature.featureId);
        setMainFeatureName(selectedMainFeature.featureName);
      }
    } catch (error) {
      console.error("Axios Error:", error);
    }
  }

  const handleSubFeatureChange = (e) => {
    const selectedSubFeatureId = e.target.value;
    setSelectedSubFeatureId(selectedSubFeatureId);

    const selectedSubFeature = productSubFeature.find(item => item.featureId === selectedSubFeatureId);
    if (selectedSubFeature) {
      setSelectedSubFeatureId(selectedSubFeature.featureId);
      setSubFeatureName(selectedSubFeature.featureName);
    }
  };

  useEffect(() => {
    console.log("Updated Feature Data:", featureData);
  }, [featureData]);

  const handleClose = () => {
    setOpenStatus(false);
    setCreateFeature(false);
    setUpdatedOpenStatus(false);
    setUpdatedOpenStatus(false)
  };

  const handleAddFeature = () => {
    const newErrors = {};

    if (!selectedMainFeatureId) {
      newErrors.selectedMainFeatureId = 'Main feature is required';
    }
    if (!mainFeatureWeightage) {
      newErrors.mainFeatureWeightage = 'Weightage for main feature is required';
    }
    if (!selectedSubFeatureId) {
      newErrors.selectedSubFeatureId = 'Sub feature selection is required';
    }
    if (!subFeatureWeightage) {
      newErrors.subFeatureWeightage = 'Weightage for sub feature is required';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      console.log('All fields are valid, proceed with adding the feature');

      setOpenedStatus(true);
      return;
    }

    const mainFeatureId = selectedMainFeatureId;
    const mainFeatureNames = mainFeatureName;
    const mainFeatureWeightageValue = mainFeatureWeightage;

    const existingFeatureIndex = featureData.findIndex(feature => feature.mainFeatureId === mainFeatureId);

    if (existingFeatureIndex !== -1) {
      const existingFeature = featureData[existingFeatureIndex];
      const subFeatureExists = existingFeature.subFeatures.some(subFeature => subFeature.subFeatureId === selectedSubFeatureId);

      if (!subFeatureExists) {
        existingFeature.subFeatures.push({
          subFeatureName: subFeatureName,
          subFeatureId: selectedSubFeatureId,
          subFeatureWeightage: subFeatureWeightage
        });

        const updatedFeatureData = [...featureData];
        updatedFeatureData[existingFeatureIndex] = existingFeature;
        setFeatureData(updatedFeatureData);

        setSelectedSubFeatureId('');
        setSubFeatureWeightage('');
        setSubFeatureName('');
        setSelectedMainFeatureId('');
        setMainFeatureWeightage('');
        setMainFeatureName('');
      } else {
        setOpenStatus(true);
        setTimeout(() => {
          handleClose();
        }, 3000)
      }
    } else {
      const newFeatureData = {
        mainFeatureId,
        mainFeatureNames,
        mainFeatureWeightage: mainFeatureWeightageValue,
        subFeatures: [{
          subFeatureId: selectedSubFeatureId,
          subFeatureName: subFeatureName,
          subFeatureWeightage: subFeatureWeightage
        }]
      };
      setFeatureData([...featureData, newFeatureData]);

      setSelectedSubFeatureId('');
      setSubFeatureWeightage('');
      setSubFeatureName('');

      setCreateFeature(true);
      setTimeout(() => {
        handleClose();
      }, 3000)
    }
  };

  const deleteFeature = async (productId, featureIds) => {
    try {
      const response = await axios.delete('/api/products/removeProductFeatures', {
        headers: { 'Content-Type': 'application/json' },
        data: { productId, featureIds }
      });
      console.log('Delete feature response:', response.data);
    } catch (error) {
      console.error('Error deleting feature:', error);
    }
  };

  const handleRowRemove = (mainFeatureId) => {
    const updatedFeatureData = featureData.filter(feature => feature.mainFeatureId !== mainFeatureId);
    setFeatureData(updatedFeatureData);
  };

  const handleSubFeatureRemove = (mainFeatureId, subFeatureId) => {
    const updatedFeatureData = featureData.map(feature => {
      if (feature.mainFeatureId === mainFeatureId) {
        feature.subFeatures = feature.subFeatures.filter(sub => sub.subFeatureId !== subFeatureId);
      }
      return feature;
    });
    setFeatureData(updatedFeatureData);
  };


  const countKey = localStorage.getItem('X-Country-Key');

  
  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position: "absolute", right: "0", top: "0", width: "82%" }}>
      <div style={{ backgroundColor: '#f3f7f6', marginTop: '5%', padding: '1.5%', paddingBottom: '2%' }}>
        <h2>Add New Product</h2>
      </div>

      <div style={{
        padding: '2%',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0px 0px 7px 0px #cfc5c56b',
        width: '91%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%'
      }}>
        <Container>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", marginTop: "10px" }}>
            Product Details
          </Typography>

          <Grid container spacing={2} style={{ margin: '1vh 10px 10px 0px' }} className="form-container">
            <Grid container spacing={2} item xs={8} md={8} lg={8} >

            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px", marginBottom: '-5%' }}>Product Type</Typography>
               
            <FormControl
             fullWidth
             size="small"
             margin="normal"
             sx={{
               '& .MuiInputBase-root': { height: '30px', fontSize: '11px' },
               '& .MuiFormLabel-root': { fontSize: '11px' }
             }}
           >
             <Select
               labelId="customer-name-label"
               value={productDetails.productType}
               error={!!errors.productType}
              
               onChange={(e) => handleChange('productType', e.target.value)}
               displayEmpty
               MenuProps={{
                 PaperProps: {
                   style: {
                     maxHeight: 200,
                     width: 200,
                   },
                 },
               }}
             >
               <MenuItem value="" disabled>
                 Select Product Type
               </MenuItem>

               {
  countKey !== 'AE' ?
  ProductList
    .filter(option => option.value !== "Loan")
    .map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))


                : 

                ProductList.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
                
} 
            
             </Select>
             <FormHelperText>{errors.productType}</FormHelperText>
           </FormControl>
            </Grid>
            
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Product Name</Typography>
                <TextField
                  placeholder='Enter card name'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.productName}
                  error={!!errors.productName}
                  helperText={errors.productName}
                  onChange={(e) => handleChange('productName', e.target.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Organization Name</Typography>
                <TextField
                  placeholder='Enter organization name'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.organizationName}
                  error={!!errors.organizationName}
                  helperText={errors.organizationName}
                  onChange={(e) => handleChange('organizationName', e.target.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Routing Url</Typography>
                <TextField
                  placeholder='Enter routingUrl'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.routingUrl}
                  error={!!errors.routingUrl}
                  helperText={errors.routingUrl}
                  onChange={(e) => handleChange('routingUrl', e.target.value)}
                />
              </Grid>

              <Grid item xs={6} md={6} lg={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px", marginBottom: '-5%' }}>Employee Type</Typography>
                <FormControl error={!!errors.employmentType} fullWidth size="small" margin="normal"
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                  <Select
                    labelId="customer-name-label"
                    value={productDetails.employmentType}
                    onChange={(e) => handleChange('employmentType', e.target.value)}
                    displayEmpty
                    MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                  >
                     <MenuItem value="" disabled>
                 Select Employee Type
               </MenuItem>

                    {EmployeeList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.employmentType}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Minimum Salary</Typography>
                <TextField
                  placeholder='Enter Minimum Salary'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.minimumSalary}
                  error={!!errors.minimumSalary}
                  helperText={errors.minimumSalary}
                  onChange={(e) => handleChange('minimumSalary', e.target.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Incentive</Typography>
              <TextField
                placeholder='Enter incentive'
                fullWidth
                sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                size="small"
                value={productDetails.incentive}
                error={!!errors.incentive}
                helperText={errors.incentive}
                onChange={(e) => handleChange('incentive', e.target.value)}
              />
            </Grid>

{productDetails.productType === 'Loan' ?  

<>
<Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Tenure</Typography>
                <TextField
                  placeholder='Enter Tenure'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.tenure}
                  onChange={(e) => handleChange('tenure', e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>processing Fees</Typography>
                <TextField
                  placeholder='Enter Processing Fees'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.processingFees}
                  onChange={(e) => handleChange('processingFees', e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Interest Rate</Typography>
                <TextField
                  placeholder='Enter insteresr rate'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.interestRate}
                  onChange={(e) => handleChange('interestRate', e.target.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Loan Amount</Typography>
                <TextField
                  placeholder='Enter loan amount'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.loanAmount}
                  onChange={(e) => handleChange('loanAmount', e.target.value)}
                />
              </Grid>


              <Grid item xs={6} sm={6} md={6}>
  <FormControl>
    <FormLabel id="demo-row-radio-buttons-group-label">Salary Transfer</FormLabel>
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={productDetails.salaryTransfer}
      onChange={(e) => handleChange('salaryTransfer', e.target.value)}
    >
      <FormControlLabel value="1" control={<Radio />} label="Required" />
      <FormControlLabel value="0" control={<Radio />} label="Not Required" />
    </RadioGroup>
  </FormControl>
</Grid>


<Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Loan Amount Text</Typography>
                <TextField
                  placeholder='Enter loan amount text'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.loanAmountTxt}
                  onChange={(e) => handleChange('loanAmountTxt', e.target.value)}
                />
              </Grid>


              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Loan Tenure </Typography>
                <TextField
                  placeholder='Enter loan tenure'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.loanTenure}
                  onChange={(e) => handleChange('loanTenure', e.target.value)}
                />
              </Grid>



</>
:

<>

<Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px", marginBottom: '-5%' }}>Rating</Typography>
              <FormControl fullWidth size="small" margin="normal"
                sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                <Select
                  labelId="customer-name-label"
                  value={productDetails.rating}
                  onChange={(e) => handleChange('rating', e.target.value)}
                  displayEmpty
                  MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                >
                   <MenuItem value="" disabled>
               Select Rating
             </MenuItem>
                  {RatingList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px", marginBottom: '-5%' }}>Instant Issuance</Typography>
              <FormControl  fullWidth size="small" margin="normal"
                sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: '11px' }, '& .MuiFormLabel-root': { fontSize: '11px' } }}>
                <Select
                  labelId="customer-name-label"
                  value={productDetails?.instantIssuance}
                  onChange={(e) => handleChange('instantIssuance', e.target.value)}
                  displayEmpty
                  MenuProps={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                >
                   <MenuItem value="" disabled>
               Select Instant Issuance
             </MenuItem>
                  {instantIssuanceList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

         

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Joining Fee</Typography>
              <TextField
                placeholder='Enter joining fee'
                fullWidth
                sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                size="small"
                value={productDetails.freeToJoin}
                onChange={(e) => handleChange('freeToJoin', e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Annual Fee</Typography>
              <TextField
                placeholder='Enter annual fee'
                fullWidth
                sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                size="small"
                value={productDetails.annualFee}
                onChange={(e) => handleChange('annualFee', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Welcome Bonus</Typography>
              <TextField
                placeholder='Enter welcome bonus'
                fullWidth
                sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                size="small"
                value={productDetails.welcomeBonus}
                onChange={(e) => handleChange('welcomeBonus', e.target.value)}
              />
            </Grid>

</> 
}


              
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1" sx={{ fontSize: "11px" }}>Description</Typography>
                <TextField
                  placeholder='Enter description'
                  fullWidth
                  sx={{ '& .MuiInputBase-root': { height: '30px', fontSize: "11px" }, '& .MuiFormLabel-root': { fontSize: "11px" } }}
                  size="small"
                  value={productDetails.description}
                  error={!!errors.description}
                  helperText={errors.description}
                  onChange={(e) => handleChange('description', e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid className="imageGrid" item xs={4} md={4} lg={4}>
           
              <Grid error={!!errors.productImage} item xs={12} sm={12} md={12}>
                <Box mt={2} style={{
                  width: '180px',
                  marginTop:'50%',
                  height: '180px',
                  backgroundColor: '#f0f0f0',
                  border: "2px solid #f0f0f0",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }}>
                  {productDetails.productImage ? (
                    <img
                      src={URL.createObjectURL(productDetails.productImage)}
                      alt="Uploaded"
                      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Select Card Image
                    </Typography>
                  )}
                </Box>
                <FormHelperText>{errors.productImage}</FormHelperText>
                <Button
                  variant="outlined"
                  component="label"
                  style={{ '& .MuiButton-root': { height: '40px', textTransform: 'none' } }}
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => handleDocumentUpload('productImage', e.target.files[0])}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div style={{
        padding: '2%',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0px 0px 7px 0px #cfc5c56b',
        width: '91%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%'
      }}>
        <Container>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", marginTop: "20px", marginBottom: '4%' }}>
            Assigned Product Feature
          </Typography>

          {productDetails.productType === 'Loan' ? <>
          
            <Grid item xs={12} className='endNodeAutoCompleteGrid'>
  {/* <Typography variant="subtitle1">Features</Typography> */}
  <Autocomplete
        multiple
        id="endNode"
        options={productFeature || []} 
        getOptionLabel={(option) => option.featureName}
        value={(productFeature && endNodeUserDetails.length > 0)
          ? productFeature.filter((option) =>
              endNodeUserDetails.map((feature) => feature.featureId).includes(option.featureId))
          : []
        }
        onChange={(event, newValue) => handleChangeParents('features', newValue)}
        renderInput={(params) => (
          <TextField {...params} size="small" placeholder="Select Features" />
        )}
      />
</Grid>
          </> : 
          <>
          
          <Grid container spacing={1} className="form-container">
            <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
              <FormControl error={!!errors.selectedMainFeatureId} fullWidth size="small" margin="normal" sx={{ "& .MuiInputBase-root": { height: "30px", fontSize: "11px" }, "& .MuiFormLabel-root": { fontSize: "11px" } }}>
                <InputLabel id="main-feature-label">Select Main Feature</InputLabel>
                <Select
                  labelId="main-feature-label"
                  value={selectedMainFeatureId}
                  onChange={handleFeatureNameChange}
                  error={!!errors.selectedMainFeatureId}
                  helperText={errors.selectedMainFeatureId || 'Select a main feature.'}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 200, width: 250 },
                    },
                  }}
                >
                  {productFeature ? productFeature.map((item) => (
                    <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
                  )) : <MenuItem disabled>No Main features available</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5}>
              <div style={{ textAlign: "center", backgroundColor: "#efefef", borderRadius: "4px", padding: "4%", marginRight: "-10%", paddingLeft: "22%" }}>Weightage %</div>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                required
                placeholder="%"
                type="number"
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: "30px", fontSize: "11px" }, "& .MuiFormLabel-root": { fontSize: "11px" } }}
                size="small"
                value={mainFeatureWeightage}
                onChange={handleMainWeightageChange}
                error={!!errors.mainFeatureWeightage}
                helperText={errors.mainFeatureWeightage}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} sx={{ marginTop: "-1.6%" }}>
              <FormControl error={!!errors.selectedSubFeatureId} fullWidth size="small" margin="normal" sx={{ "& .MuiInputBase-root": { height: "30px", fontSize: "11px" }, "& .MuiFormLabel-root": { fontSize: "11px" } }}>
                <InputLabel id="sub-feature-label">Select Sub Feature</InputLabel>
                <Select
                  labelId="sub-feature-label"
                  value={selectedSubFeatureId}
                  onChange={handleSubFeatureChange}
                  error={!!errors.selectedSubFeatureId}
                  helperText={errors.selectedSubFeatureId || 'Select a sub feature.'}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: 200, width: 250 },
                    },
                  }}
                >
                  {productSubFeature ? productSubFeature.map((item) => (
                    <MenuItem key={item.featureId} value={item.featureId}>{item.featureName}</MenuItem>
                  )) : <MenuItem disabled>No sub features available</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5}>
              <div style={{ textAlign: "center", backgroundColor: "#efefef", borderRadius: "4px", padding: "4%", marginRight: "-10%", paddingLeft: "22%" }}>Weightage %</div>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                placeholder="%"
                type="number"
                fullWidth
                sx={{ "& .MuiInputBase-root": { height: "30px", fontSize: "11px" }, "& .MuiFormLabel-root": { fontSize: "11px" } }}
                size="small"
                value={subFeatureWeightage}
                onChange={handleSubWeightageChange}
                error={!!errors.subFeatureWeightage}
                helperText={errors.subFeatureWeightage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} className="form-container" sx={{ marginTop: '2%' }}>
            <Grid item xs={12} sm={12} md={12} textAlign="right">
              <Button variant="contained" onClick={handleAddFeature}>Add Feature(s)</Button>
            </Grid>
          </Grid>
          
          </> }
        </Container>
      </div>

      {productDetails.productType === 'Loan' ? <>
    
      
      </> : 
         
      <div style={{
        padding: '2%',
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0px 0px 7px 0px #cfc5c56b',
        width: '91%', marginLeft: '2%', marginTop: '3%', marginBottom: '3%'
      }}>
        <div style={{ fontWeight: 'bold', marginLeft: '1%', fontSize: '23px', marginBottom: '2%' }}>Product Features Details</div>
        <ListProductDetail
          listData={featureData}
          handleRowRemove={handleRowRemove}
          handleSubFeatureRemove={handleSubFeatureRemove}
        />
      </div>
}
      <Button variant="contained" color="primary" onClick={SubmitProductDetails}
        sx={{ float: "right", marginTop: '1%', marginRight: '3%', marginLeft: '2%', marginBottom: '3%' }}>
        Confirm
      </Button>

      <Link to="/dashboard/productDetails">
        <Button variant="outlined" sx={{ float: "right", marginTop: '1%', marginBottom: '3%' }}>Cancel</Button>
      </Link>

      <Snackbar open={createFeature} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          New Feature Added!
        </Alert>
      </Snackbar>

      <Snackbar open={openStatus} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="warning" variant="filled" sx={{ width: '100%' }}>
          Selected sub-feature already exists under this main feature.
        </Alert>
      </Snackbar>

      <Snackbar open={updatedOpenStatus} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="neutral" variant="filled" sx={{ width: '100%' }}>
          Existing Feature Updated!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddProduct;
