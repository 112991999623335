import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../api/axios';
import { Link } from 'react-router-dom';
// import { message } from 'antd';
import {
  Table,
  Box,
  Tabs,
  Tab,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  InputAdornment,
  Backdrop,
  Grid,
  Button,
  TablePagination,
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import '../../App.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const MerchantManagent = () => {

    // Get the permission string from localStorage
const permissionString = localStorage.getItem("Permission");

const MerchantID = localStorage.getItem("MerchantID");

// Check if the permissionString is not null or undefined
const permissions = permissionString ? permissionString.split(',') : [];

// const [messageApi, contextHolder] = message.useMessage();
  const [merchant, setMerchant] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalElements, setTotalElements] = useState(0);


  const [value, setValue] = React.useState(0);

  const axios = useAxiosInstance();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const permission = localStorage.getItem("UserRoleName");

 

  const getAllMerchant = async (page, size) => {
    try {

      var response;
      if( permission.includes('MERCHANT')){
       
         response = await axios.get(`/api/merchants/${MerchantID}/stats?page=${page}&size=${size}`);
         setMerchant(response?.data.content);
         console.log(response?.data.content);
         setTotalElements(response.data.totalElements);
      }else{
         response = await axios.get(`/api/merchants/stats?page=${page}&size=${size}`);
         setMerchant(response?.data.content);
         console.log(response?.data.content);
         setTotalElements(response.data.totalElements);
      }
      // console.log(response?.data)
    

    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    getAllMerchant(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0); // Reset to first page
  };


  // const handleSearchChange = event => {
  //   setSearchQuery(event.target.value);
  // };

  // const [searchQuery, setSearchQuery] = useState('');


  // const filteredUsers = merchant.filter(user => {
  //   const merchantName = user.merchantName || ''; // Ensure merchantName is not null or undefined
  //   return merchantName.toLowerCase().includes(searchQuery.toLowerCase());
  // });


  const handleSearchChange = async (event) => {
    try {
      const response = await axios.get(`/api/merchants/stats?merchantName=${event.target.value}`);
      setMerchant(response.data.content);
      console.log(response.data.content);

      setTotalElements(response.data.totalElements);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };
  

  return (
    <div style={{ flexGrow: 1, marginTop: '0px', position:"absolute", right:"0", top:"0", width:"82%"}}>
      {/* {contextHolder} */}
      <div style={{backgroundColor:'#f3f7f6',marginTop:'5%',padding:'2%',paddingBottom:'1%'}}>
        <h2>Merchant Performance</h2>
      </div>

    

      <div style={{margin:'2%',marginTop:'3%',width: '95%',}}>
      <TextField
          sx={{ width: '40%' }}
          component={Paper}
          className="table-container"
          onChange={handleSearchChange}
          name='search'
          placeholder='Search'
          variant='outlined'
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ManageSearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                 <FilterAltIcon style={{ color: 'blue' }} />
              </InputAdornment>
            ),
          }}
        />
       
       
    
       {/* <Button onClick={() => handleViewClick()}
                variant="contained"  style={{backgroundColor:'#3e3ecd',float:'right'}}>
                  Add Mercahnt
          <AddIcon />
          </Button> */}

       

       {/* <Link to="/user/Add-user" >
          <Button
                variant="contained"  style={{backgroundColor:'#3e3ecd',float:'right',marginRight:'1%'}}>
                  Export Pdf

          </Button>

        </Link> */}

     
      </div>
<div style={{margin:'2%',marginTop:'3%',width: '95%',}}>
      <TableContainer className="table-container" >
        <Table stickyHeader>
          <TableHead>
            <TableRow className="table-heading">
              {/* <TableCell>User ID</TableCell> */}
              <TableCell>Merchant Name</TableCell>
              {permission.includes('MERCHANT') && <TableCell>Location</TableCell>}
     
              {/* <TableCell>Product Type</TableCell> */}
              <TableCell>Application Start</TableCell>
              <TableCell>Application Submitted</TableCell>
              <TableCell>Application Declined</TableCell>
              <TableCell>Application Approved</TableCell>
              <TableCell>Total Merchant Application</TableCell>

              {!permission.includes('MERCHANT') && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
     
          {merchant.map((user) => (
              <TableRow key={user.applicationId}>
                <TableCell>{user.merchantName || '-'}</TableCell>
                {/* <TableCell>{user.uniqueLocationId}</TableCell> */}
                {permission.includes('MERCHANT') && <TableCell>{user.location || '-'}</TableCell>}
     
                {/* <TableCell>{user.productType}</TableCell> */}
                <TableCell>{user.created}</TableCell>
                <TableCell>{user.submitted}</TableCell>
                <TableCell>{user.rejected}</TableCell>
                <TableCell>{user.approved}</TableCell>
                                <TableCell>{user.totalMerchantApplication}</TableCell>

                <TableCell> 
                {!permission.includes('MERCHANT') &&   
                <Link to={`/merchantPerformance/view/${user.merchantId}`}>
                      <Button color="primary">
                         View
                      </Button>
                      </Link>
}
                      </TableCell>

              </TableRow>
             ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
        component="div"
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

</div>


<style>
                {`
                @media (max-width: 600px) {
                    .MuiTableCell-root {
                        padding: 8px;
                        font-size: 12px;
                    }
                    .MuiTextField-root {
                        width: 100%;
                    }
                }
                `}
            </style>

    </div>
  );
};

export default MerchantManagent;
