const axiosAPI = () => {
       
        // const baseURL = 'http://124.29.248.126:8089/'; // Dev Environment
        // const baseURL = 'http://124.29.248.126:9091/';  // PK Dev Environment
        const baseURL = 'https://api.yallabestoffers.com/';  // Prduction Environment
        // const baseURL = 'http://101.53.244.191:9091/';  // PK Prduction Environment
   
        return baseURL;
};

export default axiosAPI;
