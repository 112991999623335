import React, { useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, TextField, IconButton,Modal,Paper,
    FormControl,Select,MenuItem,Typography, Button, Grid, Container,Alert, Snackbar, Checkbox, RadioGroup, FormControlLabel, Radio, Autocomplete  } from '@mui/material';
    import VisibilityIcon from '@mui/icons-material/Visibility';
    import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
    import InputAdornment from '@mui/material/InputAdornment';
    // import { message } from "antd";
    import { useParams,useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import axiosInstance from 'axios';
import axiosAPI from '../../../api/axiosAPI';


const axios = axiosInstance.create({
  baseURL: axiosAPI(),
  headers: {
      'Content-Type': 'application/json'
  },
});

function ResetPassword() {

  const { email } = useParams();
  // const a = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
CreatePswd:'',
ReenterPswd:''

  });
  const [showPassword, setShowPassword] = useState(false);
  const [reenterShowPassword, setReenterShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const uppercaseRegex = /[A-Z]/; // Regular expression to match uppercase letters
  const digitRegex = /\d/; // Regular expression to match digits (0-9)
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/; // Regular expression to match special characters
const consecutiveCharRegex = /(.)\1/; // Regular expression to match consecutive identical characters
const allNumericRegex = /^\d+$/; // Regular expression to match all numeric characters
const allAlphabeticRegex = /^[a-zA-Z]+$/; 

  
  const handleAddClick = async () => {
    if (formData.CreatePswd.length < 11 || formData.CreatePswd.length > 16) {
      setPasswordError('Password length must br greater then or equal 11');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)
    } else if(!uppercaseRegex.test(formData.CreatePswd)) {
      setPasswordError('Password must contain at least one uppercase letter');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)

    } else if (!digitRegex.test(formData.CreatePswd)) {
      setPasswordError('Password must contain at least one digit (0-9)');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)
    } else if (!specialCharRegex.test(formData.CreatePswd)) {
      setPasswordError('Password must contain at least one special character');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)
    } else if (consecutiveCharRegex.test(formData.CreatePswd)) {
      setPasswordError('Password cannot contain consecutive identical characters');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)
    } else if (allNumericRegex.test(formData.CreatePswd) || allAlphabeticRegex.test(formData.CreatePswd)) {
      setPasswordError('Password cannot consist of all numeric or all alphabetic characters');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)
    }else if(formData.CreatePswd !== formData.ReenterPswd){
      setPasswordError('The passwords that you create and re-enter are different.');
      setOpen(true);
      setTimeout(()=>{
        handleClose();
      },4000)
    }
    else {

      var count_email = email;

// Split the string based on the "&" character
var parts = count_email.split('&');

// Extract email and country code
var emailvar = parts[0]; // The first part contains the email
var country = parts[1].split(':')[1]; // Split the second part based on ":" and get the second part

console.log("Email:", emailvar);
console.log("Country:", country);



      const data = {
        email:emailvar,
        newPassword:formData.CreatePswd
        }

        console.log(data)
      try {
          
        await axios.put(
          "/api/user/reset-password",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Country-Key":country
            },
            //  withCredentials: true, // Move this option to the config object
          }
        );
    
        setPasswordError(''); 
        setModalOpen(true);
        
      } catch (err) {
       
          console.error("Error:", err);
      
      }
   
    }


  };


    const handleChange = (field, value) => {
      setFormData((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
  };

  const handleUserCloseModal = () => {
    setModalOpen(false);
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleReenterPassword = () => {
    setReenterShowPassword(!reenterShowPassword);
  };

  return (
   <>

<Grid container spacing={2} >
<Grid item sm={6} md={6} lg={6} style={{ backgroundImage: `url("assets/Auth/LoginImage.png")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100vh' }}>

{/* <Grid item sm={6} md={6} lg={6} style={{backgroundImage: `url("assets/Auth/LoginImage.png")`,backgroundSize: 'cover', backgroundRepeat: 'no-repeat',height:'667px'}}> */}
{/* <LazyLoadImage 
        src="assets/Images/LoginImage.png"   alt="Login Image"
      /> */}
    </Grid>

    <Grid item sm={6} md={6} lg={6} >
    {/* <div style={{marginLeft:'80%',marginTop:'25px',color:'blue'}}>English</div> */}
   
    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: '15%',marginLeft:'13%' }}>
    Reset your password 
      </Typography>
      
      <Typography gutterBottom sx={{  marginTop: '2%',marginLeft:'13%' }}>
      Create a password that is unique
      </Typography>
    
     
      <Grid container spacing={2} sx={{marginLeft:'-7%', marginTop:'10px' }}>
    <Grid item sm={2} md={2} lg={2} >
</Grid>
    <Grid item sm={8} md={8} lg={8} >

      {/* <form id='login-form' > */}
  {/* <div >
    <TextField 
    value={formData.email}
    onChange={(e) => handleChange('email', e.target.value)}
      variant="outlined"
      margin="normal"
      placeholder='Enter email ID'
      required
      fullWidth
      id="username"
      name="email"
      autoComplete="username"
      autoFocus
    />
  </div> */}


 
{/* </form> */}
</Grid>
    </Grid>

    <Grid container spacing={2} sx={{marginLeft:'-7%', marginTop:'-20px' }}>
    <Grid item sm={2} md={2} lg={2} >
</Grid>
    <Grid item sm={8} md={8} lg={8} >

      {/* <form id='login-form' > */}
  <div >
  <TextField
   value={formData.CreatePswd}
   onChange={(e) => handleChange('CreatePswd', e.target.value)}
  variant="outlined"
  margin="normal"
  required
  fullWidth
  name="password"
  type={showPassword ? 'text' : 'password'} // Toggle between text and password type
  id="password"
  placeholder='Create password'
  autoComplete="current-password"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={handleTogglePassword}
          size="large"
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
  </div>

  


 
{/* </form> */}
</Grid>
    </Grid>

    <ul style={{marginLeft:'10%',marginTop:'-0.5%',marginBottom:'0.5%'}}>
 
  <li style={{color:'red', }}>Password length must br greater then or equal 11</li>
  <li style={{color:'red', }}>Password must contain at least one uppercase letter</li>
  <li style={{color:'red', }}>Password must contain at least one digit (0-9)</li>
  <li style={{color:'red', }}>Password must contain at least one special character</li>
  <li style={{color:'red', }}>Password cannot contain consecutive identical characters</li>
  <li style={{color:'red', }}>Password cannot consist of all numeric or all alphabetic characters</li>

</ul> 



    <Grid container spacing={2} sx={{marginLeft:'-7%' }}>
    <Grid item sm={2} md={2} lg={2} >
</Grid>
    <Grid item sm={8} md={8} lg={8} >

      {/* <form id='login-form' > */}
  <div >
  <TextField
value={formData.ReenterPswd}
onChange={(e) => handleChange('ReenterPswd', e.target.value)}
  variant="outlined"
  margin="normal"
  required
  fullWidth
  name="password"
  placeholder='Re-enter password'
  type={reenterShowPassword ? 'text' : 'password'} // Toggle between text and password type
  id="password"
  autoComplete="current-password"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={handleToggleReenterPassword}
          size="large"
        >
          {reenterShowPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
  </div>


 
{/* </form> */}
</Grid>
    </Grid>

    <Grid container spacing={2} style={{marginTop:'-1%',marginLeft:'-7%'}}>
    <Grid item sm={2} md={2} lg={2} >
      </Grid>
    <Grid item sm={4} md={4} lg={4} >
    <Link to="/">
    <Button 
    type="submit" 
    fullWidth
    variant="outlined"
    // color="primary"
    sx={{ mt: 2}}
  >
    Back to Login
  </Button>
  </Link>

</Grid>

<Grid item sm={4} md={4} lg={4} >
    <Button 
    onClick={() => handleAddClick()}
    type="submit" 
    fullWidth
    variant="contained"
    // color="primary"
    sx={{ mt: 2 ,backgroundColor:'blue'}}

  >
    Next
  </Button>

</Grid>

    </Grid>
    </Grid>
   
    </Grid>


    <Modal open={modalOpen} onClose={handleUserCloseModal}>
        <div className="modal-content">
          <Paper style={{ padding: '20px', background: '#fff', width: '80vh', height: "auto", padding: "10px 40px", zIndex: 1 }}>
            <h2 style={{textAlign:'center',marginBottom:'4%'}}>Password Reset</h2>
            {/* <hr></hr> */}

            <div style={{textAlign:"center" , marginBottom:'4%'}}> 
              Your password has been changed successfully
            </div>
          
          </Paper>

         <Link to="/"> 
          <Button style={{ float: "inline-end", marginRight: '36%', marginTop: '2%' }} variant="contained" color="primary">
            Proceed to Login
          </Button>
        </Link>
        </div>
      </Modal>


    <Snackbar
  open={open}
  autoHideDuration={6000}
  onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    onClose={handleClose}
    severity="warning"
    variant="filled"
    sx={{ width: '100%' }}
  >
    {passwordError}
  </Alert>
</Snackbar>




<Snackbar
//   open={openEmailPswd}
  autoHideDuration={6000}
//   onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    // onClose={handleClose}
    severity="error"
    variant="filled"
    sx={{ width: '100%' }}
  >
    Invalid email or password!
  </Alert>
</Snackbar>


<Snackbar
//   open={openStatus}
  autoHideDuration={6000}
//   onClose={handleClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <Alert
    // onClose={handleClose}
    severity="warning"
    variant="filled"
    sx={{ width: '100%' }}
  >
    This account is suspended. Try another account!
  </Alert>
</Snackbar>

   </>
  );
}

export default ResetPassword;